import type { TFunction } from 'i18next';

import type { FormFieldOption } from 'components/FormFieldSelect';

export const getSelectOptionsWithTranslation = (
    t: TFunction,
    key: string,
    options: string[] | readonly string[],
    disabledFn?: (_option: string) => boolean,
): FormFieldOption[] => {
    return options.map(
        (option): FormFieldOption => ({
            label: t(`${key}.${option}`),
            value: option,
            disabled: disabledFn ? disabledFn(option) : false,
        }),
    );
};
