import type { StateCreator } from 'zustand';

import { type Job, JobTypes } from 'interfaces/job';
import type { Prospect, LeadSearchResultItem } from 'interfaces/prospect';
import type { FormFilterProspectsFormData } from 'forms/FormFilterProspects';
import type { FormLeadSearchFormData } from 'forms/FormLeadSearch';
import * as nimbusService from 'services/nimbus';
import { handleAPIError } from 'services/errorHandler';

import { mapLeadSearchFormDataToRequest } from './mapLeadSearchFormDataToRequest';

export type LeadSearchConfigOption = {
    id: string;
    name: string;
};

export type LeadSearchConfig = {
    departments: LeadSearchConfigOption[];
    funding: LeadSearchConfigOption[];
    industries: LeadSearchConfigOption[];
    revenue: LeadSearchConfigOption[];
    seniorities: LeadSearchConfigOption[];
    technologies: LeadSearchConfigOption[];
};

export type ProspectSlice = {
    loading: boolean;
    error: string | undefined;
    data: Prospect[];
    recordCount: number;
    currentPage: number;
    currentFilter: FormFilterProspectsFormData;

    loadingSearch: boolean;
    leadSearchRequest: nimbusService.SearchLeadsRequest;
    leadSearchConfig: LeadSearchConfig;
    leadSearchRecordCount: number;
    userProspectCount: number;
    leadSearchCurrentPage: number;
    searchResults: LeadSearchResultItem[];

    selectedProspectId: string | undefined;
    selectProspectId: (_userProspectId: string | undefined) => void;

    openArchiveDialog: boolean;
    setOpenArchiveDialog: (_open: boolean) => void;

    importJobs: Job[];
    importJobsLoading: boolean;

    getProspects: (_filter: FormFilterProspectsFormData) => Promise<void>;
    getProspectsNextPage: () => Promise<void>;
    getProspectsPrevPage: () => Promise<void>;
    archiveProspect: (_userProspectId: string) => Promise<boolean>;

    getLeadsConfig: () => Promise<void>;
    searchLeads: (_values: FormLeadSearchFormData) => Promise<void>;
    searchLeadsCurrentPage: () => Promise<void>;
    searchLeadsNextPage: () => Promise<void>;
    searchLeadsPrevPage: () => Promise<void>;

    getImportJobs: () => Promise<void>;
    importProspects: () => Promise<void>;
};

export const createProspectSlice: StateCreator<ProspectSlice> = (set, get) => ({
    loading: false,
    error: undefined,
    data: [],
    recordCount: 0,
    currentPage: 0,
    currentFilter: {
        searchTerm: '',
        contactStatus: 'all',
        source: 'all',
    },

    loadingSearch: false,
    leadSearchRequest: {
        page: 0,
    },
    leadSearchConfig: {
        departments: [],
        funding: [],
        industries: [],
        revenue: [],
        seniorities: [],
        technologies: [],
    },
    leadSearchRecordCount: 0,
    userProspectCount: 0,
    leadSearchCurrentPage: 1,
    searchResults: [],

    importJobs: [],
    importJobsLoading: false,

    selectedProspectId: undefined,
    selectProspectId: (userProspectId: string | undefined): void =>
        set((_state) => ({ selectedProspectId: userProspectId })),

    openArchiveDialog: false,
    setOpenArchiveDialog: (open: boolean): void => set((_state) => ({ openArchiveDialog: open })),

    getProspects: async (filter: FormFilterProspectsFormData): Promise<void> => {
        set((_state) => ({ loading: true, error: undefined }));
        try {
            set((_state) => ({ currentFilter: filter }));

            const pageSize = localStorage.getItem(nimbusService.PROSPECT_PAGE_SIZE_ITEM);
            const pageSizeValue = pageSize
                ? parseInt(pageSize)
                : nimbusService.NUMBER_OF_PROSPECT_RECORDS_PER_PAGE;

            const response = await nimbusService.fetchProspects(
                filter,
                get().currentPage,
                pageSizeValue,
            );
            if (response?.data) {
                set((_state) => ({ data: response.data, recordCount: response.total }));
            }
        } catch (error: unknown) {
            set((_state) => ({ error: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loading: false }));
        }
    },

    getProspectsNextPage: async (): Promise<void> => {
        set((state) => {
            if (
                state.currentPage <
                state.recordCount / nimbusService.NUMBER_OF_PROSPECT_RECORDS_PER_PAGE
            ) {
                return { currentPage: state.currentPage + 1 };
            }
            return state;
        });
        await get().getProspects(get().currentFilter);
    },

    getProspectsPrevPage: async (): Promise<void> => {
        set((state) => ({ currentPage: state.currentPage > 0 ? state.currentPage - 1 : 0 }));
        await get().getProspects(get().currentFilter);
    },

    archiveProspect: async (userProspectId: string): Promise<boolean> => {
        set((_state) => ({ loading: true }));
        let accepted = false;
        try {
            const responseData = await nimbusService.updateUserProspect(userProspectId, {
                set: { status: 'archived' },
            });
            if (responseData?._id) {
                accepted = true;
                set((state) => ({
                    data: state.data.filter((prospect) => prospect._id !== userProspectId),
                }));
            }
        } catch (error: unknown) {
            handleAPIError(error);
            accepted = false;
        } finally {
            set((_state) => ({ loading: false }));
            return accepted;
        }
    },

    getLeadsConfig: async (): Promise<void> => {
        set((_state) => ({ loadingSearch: true }));
        try {
            const leadSearchConfig = await nimbusService.fetchLeadsConfig();
            if (leadSearchConfig) {
                set((_state) => ({ leadSearchConfig }));
            }
        } catch (error: unknown) {
            set((_state) => ({ error: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingSearch: false }));
        }
    },

    searchLeads: async (values: FormLeadSearchFormData): Promise<void> => {
        const filter = mapLeadSearchFormDataToRequest(values);
        set((_state) => ({
            leadSearchRequest: filter,
            leadSearchCurrentPage: 1,
            searchResults: [],
        }));
        await get().searchLeadsCurrentPage();
    },

    searchLeadsCurrentPage: async (): Promise<void> => {
        set((_state) => ({ loadingSearch: true }));
        try {
            const response = await nimbusService.searchLeads({
                ...get().leadSearchRequest,
                page: get().leadSearchCurrentPage,
            });
            if (response) {
                set((_state) => ({
                    searchResults: response.people,
                    leadSearchRecordCount: response.pagination.totalEntries,
                    userProspectCount: response.userProspectCount,
                }));
            }
        } catch (error: unknown) {
            set((_state) => ({ error: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingSearch: false }));
        }
    },

    searchLeadsNextPage: async (): Promise<void> => {
        set((state) => {
            if (
                state.leadSearchCurrentPage <
                state.leadSearchRecordCount / nimbusService.NUMBER_OF_PROSPECT_RECORDS_PER_PAGE
            ) {
                return { leadSearchCurrentPage: state.leadSearchCurrentPage + 1 };
            }
            return state;
        });
        await get().searchLeadsCurrentPage();
    },

    searchLeadsPrevPage: async (): Promise<void> => {
        set((state) => ({
            leadSearchCurrentPage:
                state.leadSearchCurrentPage > 0 ? state.leadSearchCurrentPage - 1 : 0,
        }));
        await get().searchLeadsCurrentPage();
    },

    getImportJobs: async (): Promise<void> => {
        set((_state) => ({ importJobsLoading: true }));
        try {
            // const response = await nimbusService.fetchJobs(JobTypes.ImportProspects);
            const response = await nimbusService.fetchJobs(JobTypes.EnrichProspects);
            if (response) {
                // set((_state) => ({ importJobs: response.data }));
                set((_state) => ({ importJobs: response }));
            }
        } catch (error: unknown) {
            set((_state) => ({ error: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ importJobsLoading: false }));
        }
    },

    importProspects: async (): Promise<void> => {
        set((_state) => ({ loading: true }));
        try {
            await nimbusService.importProspects();
        } catch (error: unknown) {
            handleAPIError(error);
        } finally {
            set((_state) => ({ loading: false }));
        }
    },
});
