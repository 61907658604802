import { type TFunction } from 'i18next';

import { PROFILE_TABS } from 'interfaces/profile.type';
// import { type UserEx } from 'interfaces/userEx.type';

import { type TabItem } from 'components/Tabs';
import env from 'env';

export const getProfileTabs = (t: TFunction): TabItem[] => {
    return Object.values(PROFILE_TABS)
        .filter((tab) => {
            switch (tab) {
                case PROFILE_TABS.Integrations:
                    return env.CRM_INTEGRATION_DISABLED === false;
                default:
                    return true;
            }
        })
        .map((tab) => ({
            label: t(`tabs.${tab}`),
            value: tab,
            link: `/profile/${tab}`,
        }));
};
