import type { StateCreator } from 'zustand';

import type { Match, SignalVisibilityStatus } from 'interfaces/match.type';
import * as nimbusService from 'services/nimbus';
import { mapAPIResponseSignalsToProspects, sortProspects } from 'services/mapper';
import { handleAPIError } from 'services/errorHandler';
import type { CRMSlice } from 'stores/crmSlice';
import { calculateSignalAutopilotTimer, getSignalToSendViaAupilot } from 'services/signal';

const TIMEOUT_HIDE_SAVED_CHANGES = 2 * 1000;

export type TestEmailPayload = {
    emailAddress: string;
};

export type SignalEmailPayload = {
    signalId: string;
    email: {
        firstName: string;
        lastName: string;
        address: string;
        subjectLine: string;
        message: string;
    };
};

export type SignalEmailMessagePayload = {
    emailSubject: string;
    emailMessage: string;
    emailSent?: boolean;
};

export type MatchSlice = {
    matches: Match[];
    matchForUndo: Match | undefined;
    loading: boolean;
    loadingMatches: boolean;
    loadingSendEmail: boolean;
    errorSendEmail: string | undefined;
    timer: NodeJS.Timer | undefined;

    initMatches: () => void;
    fetchMatches: () => Promise<void>;
    markProspectSignalAsRead: (_prospectId: string, _id: string) => Promise<void>;
    clearMatchForUndo: () => void;
    undoDiscardProspectSignal: (_prospect: Match, _id: string) => Promise<boolean>;
    discardProspectSignal: (
        _prospectId: string,
        _id: string,
        _visibilityStatus: SignalVisibilityStatus,
        _reason?: string,
        _customReason?: string,
    ) => Promise<boolean>;
    sendTestGmailMessage: (_payload: TestEmailPayload) => Promise<boolean>;
    sendSignalMessage: (_prospectId: string, _payload: SignalEmailPayload) => Promise<boolean>;
    filterOutDoneProspects: () => void;
    markProspectMessageChangesUnsaved: (_prospectId: string) => void;
    updateProspectSignalEmailMessage: (
        _prospectId: string,
        _id: string,
        _payload: SignalEmailMessagePayload,
    ) => Promise<void>;
    updateAutosendTimer: () => void;
    archiveProspect: (_userProspectId: string) => Promise<boolean>;
};

export type APIResponseMatches = {
    matches: Match[];
};

export type APIResponseMatch = {
    match: Match;
};

export const createMatchSlice: StateCreator<MatchSlice & CRMSlice, [], [], MatchSlice> = (
    set,
    get,
) => ({
    matches: [],
    matchForUndo: undefined,
    loading: false,
    loadingMatches: false,
    loadingSendEmail: false,
    errorSendEmail: undefined,
    timer: undefined,
    timeUntilAutosend: undefined,

    initMatches: (): void => {
        set((_state) => ({
            loading: false,
            loadingMatches: false,
            matches: [],
        }));
    },

    fetchMatches: async (): Promise<void> => {
        try {
            set((_state) => ({ loading: true, loadingMatches: true }));
            const signalsResponse = await nimbusService.fetchProspectsWithSignals();
            if (signalsResponse) {
                const matchesData = mapAPIResponseSignalsToProspects(signalsResponse);
                set((_state) => ({ matches: matchesData }));
            }
        } catch (error: unknown) {
            handleAPIError(error);
        }
        set((_state) => ({ loading: false, loadingMatches: false }));
    },

    markProspectSignalAsRead: async (prospectId: string, id: string): Promise<void> => {
        set((_state) => ({ loading: true }));
        try {
            const responseData = await nimbusService.markSignalRead(id);
            if (responseData?._id) {
                set((state) => ({
                    matches: state.matches.map((match) =>
                        match.prospectId === prospectId
                            ? {
                                  ...match,
                                  read: true,
                              }
                            : match,
                    ),
                }));
            }
        } catch (error: unknown) {
            handleAPIError(error);
        } finally {
            set((_state) => ({ loading: false }));
        }
    },

    clearMatchForUndo: (): void => {
        set((_state) => ({ matchForUndo: undefined }));
    },

    undoDiscardProspectSignal: async (prospect: Match, id: string): Promise<boolean> => {
        set((_state) => ({ loading: true }));
        let accepted = false;
        try {
            const responseData = await nimbusService.undoDiscardSignal(id);
            if (responseData?._id) {
                accepted = true;
                set((state) => {
                    const match = state.matches.find(
                        (match) => match.prospectId === prospect.prospectId,
                    );
                    if (!match) {
                        const matches = [...state.matches, prospect];
                        return { matches: sortProspects(matches) };
                    }
                    return {
                        matches: state.matches.map((match) =>
                            match.prospectId === prospect.prospectId
                                ? {
                                      ...prospect,
                                  }
                                : match,
                        ),
                    };
                });
            }
        } catch (error: unknown) {
            handleAPIError(error);
            accepted = false;
        } finally {
            set((_state) => ({ loading: false }));
            return accepted;
        }
    },

    discardProspectSignal: async (
        prospectId: string,
        id: string,
        visibilityStatus: SignalVisibilityStatus,
        reason?: string,
        customReason?: string,
    ): Promise<boolean> => {
        set((_state) => ({ loading: true }));
        let accepted = false;
        try {
            const responseData = await nimbusService.discardSignal(
                id,
                visibilityStatus,
                reason,
                customReason,
            );
            if (responseData?._id) {
                accepted = true;
                let removeMatch = false;
                set((state) => {
                    const matches = state.matches.map((match) => {
                        if (match.prospectId === prospectId) {
                            const signals = match.signals.filter((signal) => signal.id !== id);
                            if (signals.length > 0) {
                                return {
                                    ...match,
                                    signals,
                                };
                            }
                            removeMatch = true;
                        }
                        return match;
                    });
                    const matchForUndo = state.matches.find(
                        (match) => match.prospectId === prospectId,
                    );
                    if (removeMatch) {
                        return {
                            matches: matches.filter((match) => match.prospectId !== prospectId),
                        };
                    }
                    return { matches, matchForUndo };
                });
            }
        } catch (error: unknown) {
            handleAPIError(error);
            accepted = false;
        } finally {
            set((_state) => ({ loading: false }));
            return accepted;
        }
    },

    sendTestGmailMessage: async (payload: TestEmailPayload): Promise<boolean> => {
        set((_state) => ({ loadingSendEmail: true, errorSendEmail: undefined }));
        let response: nimbusService.APIResponseSendTestGmailMessage | undefined;
        try {
            response = await nimbusService.sendTestGmailMessage({
                ...payload,
            });
        } catch (error: unknown) {
            set((_state) => ({ loadingSendEmail: false, errorSendEmail: error?.toString() }));
            handleAPIError(error);
            return false;
        } finally {
            set((_state) => ({ loadingSendEmail: false }));
        }

        if (response?.accepted) {
            return true;
        }

        return false;
    },

    sendSignalMessage: async (
        prospectId: string,
        payload: SignalEmailPayload,
    ): Promise<boolean> => {
        let response: nimbusService.APIResponseSendSignalMessage | undefined;

        set((_state) => ({ loadingSendEmail: true, errorSendEmail: undefined }));

        // sending via nimbus
        try {
            response = await nimbusService.sendSignalMessage({
                ...payload,
                email: {
                    ...payload.email,
                    message: payload.email.message,
                },
            });
        } catch (error: unknown) {
            set((_state) => ({ loadingSendEmail: false, errorSendEmail: error?.toString() }));
            handleAPIError(error);
            return false;
        } finally {
            set((_state) => ({ loadingSendEmail: false }));
        }

        if (response?.accepted && prospectId) {
            set((state) => ({
                matches: state.matches.map((match) =>
                    match.prospectId === prospectId
                        ? {
                              ...match,
                              signals: match.signals.map((signal) =>
                                  signal.id === payload.signalId
                                      ? {
                                            ...signal,
                                            emailSubject: payload.email.subjectLine,
                                            emailMessage: payload.email.message,
                                            emailSentAt:
                                                response?.emailSentAt || new Date().toISOString(),
                                            emailSent: true,
                                        }
                                      : signal,
                              ),
                              done: true,
                          }
                        : match,
                ),
            }));
            return true;
        }

        return false;
    },

    filterOutDoneProspects: (): void => {
        set((state) => ({
            matches: state.matches.filter((match) => !match.done),
        }));
    },

    markProspectMessageChangesUnsaved: (prospectId: string): void => {
        set((state) => ({
            matches: state.matches.map((match) =>
                match.prospectId === prospectId
                    ? {
                          ...match,
                          messageChangesSaved: false,
                          messageChangesSavedVisible: true,
                      }
                    : match,
            ),
        }));
    },

    updateProspectSignalEmailMessage: async (
        prospectId: string,
        id: string,
        payload: SignalEmailMessagePayload,
    ): Promise<void> => {
        try {
            set((state) => ({
                loading: true,
                matches: state.matches.map((match) =>
                    match.prospectId === prospectId
                        ? {
                              ...match,
                              messageChangesSavedVisible: true,
                              signals: match.signals.map((signal) =>
                                  signal.id === id
                                      ? {
                                            ...signal,
                                            emailSubject: payload.emailSubject,
                                            emailMessage: payload.emailMessage,
                                        }
                                      : signal,
                              ),
                          }
                        : match,
                ),
            }));

            await nimbusService.updateSignalEmailMessage(id, payload);

            set((state) => ({
                matches: state.matches.map((match) =>
                    match.prospectId === prospectId
                        ? { ...match, messageChangesSaved: true }
                        : match,
                ),
            }));

            await new Promise((resolve) => setTimeout(resolve, TIMEOUT_HIDE_SAVED_CHANGES));

            set((state) => ({
                matches: state.matches.map((match) =>
                    match.prospectId === prospectId
                        ? { ...match, messageChangesSavedVisible: false }
                        : match,
                ),
            }));
        } catch (error: unknown) {
            handleAPIError(error);
        } finally {
            set((_state) => ({ loading: false }));
        }
    },

    updateAutosendTimer: (): void => {
        if (get().matches.length === 0) return;
        let timer = get().timer;
        if (timer) clearInterval(timer);
        timer = setInterval(() => {
            set((state) => ({
                matches: state.matches.map((prospect) => {
                    if (prospect.done) return prospect;
                    if (prospect.signals.length === 0) return prospect;
                    const signal = getSignalToSendViaAupilot(prospect.signals);
                    if (!signal) return prospect;
                    const timerValue = calculateSignalAutopilotTimer(signal);
                    return {
                        ...prospect,
                        timeUntilAutosend: timerValue,
                    };
                }),
            }));
        }, 1000);
        set((_state) => ({ timer }));
    },

    archiveProspect: async (userProspectId: string): Promise<boolean> => {
        set((_state) => ({ loading: true }));
        let accepted = false;
        try {
            const responseData = await nimbusService.updateUserProspect(userProspectId, {
                set: { status: 'archived' },
            });
            if (responseData?._id) {
                accepted = true;
                set((state) => ({
                    matches: state.matches.filter(
                        (match) => match.userProspectId !== userProspectId,
                    ),
                }));
            }
        } catch (error: unknown) {
            handleAPIError(error);
            accepted = false;
        } finally {
            set((_state) => ({ loading: false }));
            return accepted;
        }
    },
});
