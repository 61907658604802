import { Helmet } from 'react-helmet';
import i18next from 'i18next';

import env from 'env';

const AnalyticsProviderGA4 = (): JSX.Element | null => {
    if (!env.GA_TRACKING_ID) {
        return null;
    }
    return (
        <Helmet>
            <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config','${env.GA_TRACKING_ID}',{
                        locale_code: '${i18next.language}',
                        send_page_view: false,
                    });
                    var consentMode = localStorage.getItem('consentMode');
                    if (consentMode === null) {
                        gtag('consent', 'default', {
                            ad_storage: 'denied',
                            analytics_storage: 'denied',
                            functionality_storage: 'denied',
                            personalization_storage: 'denied',
                            security_storage: 'denied',
                        });
                    } else {
                        gtag(
                            'consent',
                            'default',
                            JSON.parse(consentMode)
                        );
                    }
                `}
            </script>
            {/* For use with Google Tag Manager:
<script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${env.GA_TRACKING_ID}');`}</script> */}
            <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${env.GA_TRACKING_ID}`}
            ></script>
        </Helmet>
    );
};

export default AnalyticsProviderGA4;
