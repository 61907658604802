import { type UserEx } from 'interfaces/userEx.type';

import { type NavItem } from 'components/Header';

export const getMenuItems = (navMenuItems: NavItem[], _user?: UserEx): NavItem[] => {
    return navMenuItems.filter((item) => {
        switch (item.path) {
            default:
                return true;
        }
    });
};
