import _map from 'lodash/map';
import _find from 'lodash/find';
import _merge from 'lodash/merge';
import _filter from 'lodash/filter';

import { type ActivityRecord } from 'interfaces/activity.type';

export const getMergedActivityRecords = (
    records: ActivityRecord[],
    recordsToAdd: ActivityRecord[],
): ActivityRecord[] => {
    return _map(records, (item1) => {
        const item2 = _find(recordsToAdd, (item) => item.signal._id === item1.signal._id);
        return item2 ? _merge({}, item1, item2) : item1;
    }).concat(
        _filter(
            recordsToAdd,
            (item2) => !_find(records, (item) => item.signal._id === item2.signal._id),
        ),
    );
};
