import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes/routes';
import { AnimatedStars } from 'components/AnimatedStars';
import Button from 'components/Button';
import HeaderLogout from 'components/HeaderLogout';
import Typography from 'components/Typography';

import { useAuthSignOut } from 'stores/useBoundStore';

import imgHeart from 'assets/images/heart60.png';

import styles from './style.module.scss';

const USE_ANIMATION = true;

const AuthLogoutPage = (): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation('auth');

    const navigate = useNavigate();

    const signOut = useAuthSignOut();

    useEffect(() => {
        signOut();
    }, []);

    const handleClick = async (): Promise<void> => {
        navigate(ROUTES.LOGIN);
    };

    return (
        <>
            <div className={styles.container}>
                <HeaderLogout />
                <div className={styles['stars-container']} ref={containerRef}>
                    <div className={styles['info-container']}>
                        <img src={imgHeart} alt='heart' width={30} height={30} />
                        <Typography className={styles.text} variant='bodyBold1'>
                            {t('form.logout_success')}
                        </Typography>
                        <Button onClick={handleClick} size='large' block>
                            {t('form.to_login')}
                        </Button>
                    </div>
                    {USE_ANIMATION && <AnimatedStars containerRef={containerRef} />}
                </div>
            </div>
        </>
    );
};

export default AuthLogoutPage;
