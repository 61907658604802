import { type SetPasswordFormData } from '.';

const defaultValuesSetPasswordFormData: SetPasswordFormData = {
    password: '',
    passwordConfirmation: '',
    code: '',
};

export const extractFormData = (formData: FormData): SetPasswordFormData => {
    const payload: SetPasswordFormData = defaultValuesSetPasswordFormData;
    const password = formData.get('password');
    if (typeof password === 'string') {
        payload.password = password;
    }
    const passwordConfirmation = formData.get('passwordConfirmation');
    if (typeof passwordConfirmation === 'string') {
        payload.passwordConfirmation = passwordConfirmation;
    }
    return payload;
};
