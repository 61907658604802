import { useTranslation } from 'react-i18next';

import Typography from 'components/Typography';

import ConfirmationDialog from '../ConfirmationDialog';

export type ArchiveProspectConfirmationDialogProps = {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

export const ArchiveProspectConfirmationDialog: React.FC<
    ArchiveProspectConfirmationDialogProps
> = ({ open, onConfirm, onCancel }) => {
    const { t } = useTranslation('prospects');

    const handleConfirm = (): void => {
        onConfirm();
    };

    const handleCancel = (): void => {
        onCancel();
    };

    return (
        <ConfirmationDialog
            open={open}
            title={t('dialogs.archive_prospect.title')}
            content={
                <Typography variant='bodyRegular1'>
                    {t('dialogs.archive_prospect.message')}
                </Typography>
            }
            confirmVariant='primary'
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        />
    );
};
