import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type MultiValue } from 'react-select';
import * as Form from '@radix-ui/react-form';

import type { Industry, UserIndustryPitch } from 'interfaces/userEx.type';
import Button from 'components/Button';
import Typography from 'components/Typography';
import FormFieldInput from 'components/FormFieldInput';
import { type FormFieldOption } from 'components/FormFieldSelect';
import FormFieldMultiSelect from 'components/FormFieldMultiSelect';
import FormFieldTextArea from 'components/FormFieldTextArea';

import styles from './style.module.scss';

const MAX_NUMBER_OF_INDUSTRIES = 50;

type FormIndustryPitchProps = {
    industries: Industry[];
    initialValues?: IndustryPitchFormData;
    disabled?: boolean;
    onSubmit: (_values: IndustryPitchFormData) => void;
    onCancel: () => void;
};

export type IndustryPitchFormData = UserIndustryPitch;

const getFormData = (
    formValues: { [key: string]: FormDataEntryValue },
    values: IndustryPitchFormData,
): IndustryPitchFormData => {
    return {
        pitchName: (formValues?.name || values.pitchName) as string,
        industries: (formValues?.industries || values.industries) as string[],
        pitch: (formValues?.pitch || values.pitch) as string,
        referenceCase: (formValues?.referenceCase || values.referenceCase) as string,
    };
};

const defaultValues: IndustryPitchFormData = {
    pitchName: '',
    industries: [],
    pitch: '',
    referenceCase: '',
};

const FormIndustryPitch: React.FC<FormIndustryPitchProps> = ({
    industries,
    initialValues = defaultValues,
    disabled = false,
    onSubmit,
    onCancel,
}) => {
    const [formData, setFormData] = React.useState<IndustryPitchFormData>(initialValues);

    const { t, i18n } = useTranslation(['forms']);

    const submitDisabled =
        disabled ||
        !formData.pitchName ||
        !formData.industries.length ||
        !formData.pitch ||
        !formData.referenceCase;

    const optionsIndustries = useMemo<FormFieldOption[]>((): FormFieldOption[] => {
        return industries.map(({ _id, name }) => ({
            value: _id,
            label: name[i18n.language],
        }));
    }, [industries, i18n.language]);

    useEffect(() => {
        if (initialValues) setFormData(initialValues);
    }, [initialValues]);

    const handleChangeInput = (name: string, value: string): void => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleOptionChangeIndustries = (value: MultiValue<FormFieldOption>): void => {
        const industries = value.map((option) => option.value);
        setFormData((prev) => ({ ...prev, industries }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formDataFromEntries = Object.fromEntries(new FormData(event.currentTarget));
        const formDataParsed = getFormData(formDataFromEntries, formData);
        if (onSubmit) onSubmit(formDataParsed);
    };

    const handleClickCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (onCancel) onCancel();
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <Typography variant='bodyRegular1'>{t('industry_pitch.description')}</Typography>

            <FormFieldInput
                label={t('industry_pitch.name.title')}
                name='pitchName'
                placeholder={t('industry_pitch.name.placeholder')}
                helperText={t('industry_pitch.name.helper_text')}
                value={formData.pitchName}
                maxLength={100}
                disabled={disabled}
                onChange={handleChangeInput}
            />

            <FormFieldMultiSelect
                label={t('industry_pitch.industries.title')}
                name={'industries'}
                placeholder={t('industry_pitch.industries.placeholder')}
                helperText={t('industry_pitch.industries.helper_text')}
                options={optionsIndustries}
                value={optionsIndustries.filter((option) =>
                    formData.industries.includes(option.value),
                )}
                maxLength={MAX_NUMBER_OF_INDUSTRIES}
                disabled={disabled}
                onChange={handleOptionChangeIndustries}
            />

            <FormFieldTextArea
                label={t('industry_pitch.pitch.title')}
                name={'pitch'}
                placeholder={t('industry_pitch.pitch.placeholder')}
                helperText={t('industry_pitch.pitch.helper_text')}
                rows={4}
                maxLength={500}
                value={formData.pitch}
                disabled={disabled}
                onChange={handleChangeInput}
            />

            <FormFieldTextArea
                label={t('industry_pitch.reference_case.title')}
                name={'referenceCase'}
                placeholder={t('industry_pitch.reference_case.placeholder')}
                helperText={t('industry_pitch.reference_case.helper_text')}
                rows={4}
                maxLength={500}
                value={formData.referenceCase}
                disabled={disabled}
                onChange={handleChangeInput}
            />

            <div className={styles.submit}>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={submitDisabled}>
                        {t('industry_pitch.button_save')}
                    </Button>
                </Form.Submit>
                <Button variant='secondary' disabled={disabled} onClick={handleClickCancel}>
                    {t('personal_information.cancel')}
                </Button>
            </div>
        </Form.Root>
    );
};

export default FormIndustryPitch;
