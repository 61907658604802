import { type Connection } from '@integration-app/sdk';

const CONNECTIONS_CRM = ['hubspot', 'salesforce'];
const CONNECTIONS_EMAIL = ['gmail', 'outlook'];

export const getConnectionsFiltered = (
    connections: Connection[],
    filter?: 'crm' | 'email',
): Connection[] => {
    if (connections && Array.isArray(connections)) {
        if (!filter) return connections;
        const connectionsFiltered = connections.filter((connection) => {
            if (!connection?.integration?.key) return false;
            const { key } = connection.integration;
            if (filter === 'crm') return CONNECTIONS_CRM.includes(key);
            if (filter === 'email') return CONNECTIONS_EMAIL.includes(key);
            return false;
        });
        return connectionsFiltered;
    }
    return [];
};
