import { type ForgotPasswordFormData } from '.';

const defaultValuesForgotPasswordFormData: ForgotPasswordFormData = {
    email: '',
};

export const extractFormData = (formData: FormData): ForgotPasswordFormData => {
    const payload: ForgotPasswordFormData = defaultValuesForgotPasswordFormData;
    const email = formData.get('email');
    if (typeof email === 'string') {
        payload.email = email;
    }
    return payload;
};
