import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import FormNotificationsSettings, {
    type FormNotificationsSettingsSubmit,
} from 'forms/FormNotificationsSettings';
import Typography from 'components/Typography';

import {
    useFetchCurrentUserEx,
    useLoadingUserEx,
    useUpdateUserEx,
    useUserEx,
} from 'stores/useBoundStore';

import styles from './style.module.scss';

const SettingsNotifications: React.FC = () => {
    const { t } = useTranslation('profile');

    const user = useUserEx();
    const fetchCurrentUser = useFetchCurrentUserEx();
    const loadingUser = useLoadingUserEx();
    const updateUser = useUpdateUserEx();

    const formDefaultValues = useMemo(
        () => ({
            emailSignals: user?.settings?.notifications?.emailSignals ?? false,
        }),
        [user],
    );

    useEffect(() => {
        fetchCurrentUser();
    }, []);

    const handleSubmit = async (
        values: Partial<FormNotificationsSettingsSubmit>,
    ): Promise<void> => {
        const { emailSignals } = values;
        if (emailSignals !== user?.settings?.notifications?.emailSignals) {
            try {
                await updateUser({
                    settings: {
                        ...user?.settings,
                        notifications: {
                            ...user?.settings?.notifications,
                            emailSignals: Boolean(emailSignals),
                        },
                    },
                });
                toast.success(t('notifications.success'));
            } catch (err) {}
        }
    };

    return (
        <>
            <div className={styles.container}>
                <Typography variant='heading3' inline>
                    {t('notifications.title')}
                </Typography>
                <FormNotificationsSettings
                    initialValues={formDefaultValues}
                    disabled={loadingUser}
                    onSubmit={handleSubmit}
                />
            </div>
        </>
    );
};

export default SettingsNotifications;
