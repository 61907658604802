import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { type ProfileTab } from 'interfaces/profile.type';
import { NAV_MENU_ITEMS } from 'constants/routes/navLinks';

import Tabs, { type TabItem } from 'components/Tabs';
import Header from 'components/Header';
import HeaderProxy from 'components/HeaderProxy';
import ProfileTabContent from 'components/ProfileTabContent';

import {
    useFetchCurrentUserEx,
    useFetchIndustries,
    useFetchSignalCategories,
    useInvalidateProxyLogin,
    useProxyLogin,
} from 'stores/useBoundStore';
import { getProfileTabs } from './getProfileTabs';

import styles from './style.module.scss';

const ProfilePage = (): JSX.Element => {
    const { t } = useTranslation('profile');

    const params = useParams();
    const navigate = useNavigate();

    const fetchCurrentUserEx = useFetchCurrentUserEx();
    const fetchSignalCategories = useFetchSignalCategories();
    const fetchIndustries = useFetchIndustries();
    const proxyLogin = useProxyLogin();
    const invalidateProxyLogin = useInvalidateProxyLogin();

    const tabs: TabItem[] = useMemo(() => getProfileTabs(t), [t]);

    useEffect(() => {
        invalidateProxyLogin();
        fetchCurrentUserEx();
        fetchSignalCategories();
        fetchIndustries();
    }, []);

    const handleTabChange = (tab: TabItem): void => {
        if (tab.link) navigate(tab.link);
    };

    return (
        <>
            {proxyLogin && <HeaderProxy />}
            <Header navMenuItems={NAV_MENU_ITEMS} />

            <div className={styles.container}>
                <Tabs items={tabs} onTabChange={handleTabChange} value={params?.page}>
                    {tabs.map((tab) => (
                        <Tabs.Content key={tab.value} value={tab.value}>
                            <ProfileTabContent tab={tab.value as ProfileTab} />
                        </Tabs.Content>
                    ))}
                </Tabs>
            </div>

            <ToastContainer position='bottom-right' />
        </>
    );
};

export default ProfilePage;
