import axios, {
    AxiosError,
    isAxiosError,
    type AxiosInstance,
    type AxiosRequestConfig,
    type RawAxiosRequestHeaders,
} from 'axios';
import { isObject } from 'utils/isObject';
import { apm } from './apm';

import { retrieveToken, retrieveUserId } from './storage';

export const handleAxiosError = (error: unknown): unknown => {
    if (isAxiosError(error)) {
        if (error.request) console.error(error.request);
        if (error.response) console.error(error.response.data);
        return new Error(`${error.response?.status}: ${error.response?.data}`);
    } else {
        return error;
    }
};

const getAuthHeaders = (): Partial<RawAxiosRequestHeaders> => {
    const tokenStringOrNull = retrieveToken();
    const userIdStringOrNull = retrieveUserId();
    const headers: RawAxiosRequestHeaders = {};
    if (tokenStringOrNull) {
        const tokenString = tokenStringOrNull.replaceAll(/[^0-9a-zA-Z-_.]+/g, '');
        headers['Authorization'] = `Bearer ${tokenString}`;
    }
    if (userIdStringOrNull) {
        const userIdString = userIdStringOrNull.replaceAll(/[^0-9]+/g, '');
        headers['X-User-Id'] = userIdString;
    }
    return headers;
};

type ApiClientConfig = {
    baseURL: string | undefined;
    withCredentials?: boolean;
};

export type ApiClient = {
    get: <T>(_url: string, _params?: unknown, _headers?: RawAxiosRequestHeaders) => Promise<T>;
    put: <T>(_url: string, _data?: unknown) => Promise<T>;
    post: <T>(_url: string, _data?: unknown) => Promise<T>;
    patch: <T>(_url: string, _data?: unknown) => Promise<T>;
};

const cutPersonalDataFromRequestBody = (body: unknown): unknown => {
    if (isObject(body) && 'password' in body) {
        const { password: _p, ...newBody } = body;
        return newBody;
    }

    return body;
};

const createApiClient = (
    config: ApiClientConfig,
    errorHandler: (_error: unknown) => unknown = handleAxiosError,
): ApiClient => {
    const axiosInstance: AxiosInstance = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        ...config,
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            const { headers, data, method = 'unknown', url } = config;

            apm.setCustomContext({
                [`req [${method}] ${url}`]: {
                    requestHeaders: headers,
                    requestBody: cutPersonalDataFromRequestBody(data),
                },
            });

            return config;
        },
        (error) => {
            apm.captureError(error);
            return Promise.reject(error);
        },
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            const {
                headers,
                statusText,
                data,
                config: { method, url },
            } = response;

            apm.setCustomContext({
                [`res [${method}] ${url}`]: {
                    responseHeaders: headers,
                    responseBody: data,
                    responseStatusText: statusText,
                },
            });

            return response;
        },
        (error) => {
            apm.captureError(error);
            if (error instanceof AxiosError) {
                const { response, config } = error;

                apm.setCustomContext({
                    [`failed res [${config?.method}] ${config?.url}`]: {
                        responseHeaders: response?.headers,
                        responseBody: response?.data,
                        responseStatusText: response?.statusText,
                    },
                });
            }

            return Promise.reject(error);
        },
    );

    const axiosRequest = async <T>({
        method,
        url,
        data,
        params,
        headers,
    }: {
        method: 'GET' | 'PUT' | 'POST' | 'PATCH';
        url: string;
        data?: unknown;
        params?: unknown;
        headers?: RawAxiosRequestHeaders;
    }): Promise<T> => {
        try {
            const requestConfig: AxiosRequestConfig = {
                method,
                url,
                data,
                params,
            };
            if (config.withCredentials) {
                requestConfig.headers = getAuthHeaders();
            }
            if (headers) {
                requestConfig.headers = { ...requestConfig.headers, ...headers };
            }
            const response = await axiosInstance.request<T>(requestConfig);
            return response.data;
        } catch (error) {
            throw errorHandler(error);
        }
    };

    const get = <T>(url: string, params?: unknown, headers?: RawAxiosRequestHeaders): Promise<T> =>
        axiosRequest<T>({ method: 'GET', url, params, headers });

    const put = <T>(url: string, data?: unknown): Promise<T> =>
        axiosRequest<T>({ method: 'PUT', url, data });

    const post = <T>(url: string, data?: unknown): Promise<T> =>
        axiosRequest<T>({ method: 'POST', url, data });

    const patch = <T>(url: string, data?: unknown): Promise<T> =>
        axiosRequest<T>({ method: 'PATCH', url, data });

    return {
        get,
        put,
        post,
        patch,
    };
};

export default createApiClient;
