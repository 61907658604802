import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConnection, useIntegrationApp } from '@integration-app/react';
import { type Connection } from '@integration-app/sdk';

import Button from 'components/Button';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import Dialog from 'components/Dialog';
import Icon, { ICON_NAMES } from 'components/Icon';
import Loading from 'components/Loading';
import Tag from 'components/Tag';
import Typography from 'components/Typography';
import { formatDateAsString } from 'components/SignalCard/utils';

import { getActiveConnection } from '../../stores/crmSlice/getActiveConnection';

import imgHubspot from '../../assets/images/hubspot.png';
import imgSalesforce from '../../assets/images/salesforce.png';

import styles from './style.module.scss';

const INTEGRATIONS_CRM = [
    {
        name: 'hubspot',
        image: imgHubspot,
    },
    {
        name: 'salesforce',
        image: imgSalesforce,
    },
];

type CRMIntegrationContentNotConnectedProps = {
    onClose: () => Promise<void>;
};

const CRMIntegrationContentNotConnected: React.FC<CRMIntegrationContentNotConnectedProps> = ({
    onClose,
}) => {
    const [showConnectDialog, setShowConnectDialog] = useState(false);

    const { t } = useTranslation('profile');

    const integrationApp = useIntegrationApp();

    const handleConnect = (): void => {
        setShowConnectDialog(true);
    };

    const handleCloseConnectDialog = (): void => {
        setShowConnectDialog(false);
    };

    const handleConnectIntegration = (integration: string): void => {
        setShowConnectDialog(false);
        if (integrationApp) {
            integrationApp.integration(integration).open({ onClose, showPoweredBy: false });
        }
    };

    return (
        <>
            <Typography variant='bodyBold1'>{t('crm_integration.connect_crm')}</Typography>
            <div className={styles['info-col']}>
                <Typography variant='bodyRegular1'>
                    {t('crm_integration.connect_benefits')}
                </Typography>
                <ul className={styles.list}>
                    <li>
                        <Typography variant='bodyRegular1'>
                            {t('crm_integration.connect_benefits_1')}
                        </Typography>
                    </li>
                </ul>
            </div>

            <div>
                <Button onClick={handleConnect} variant='primary'>
                    <div className={styles['info-row']}>
                        <Icon name={ICON_NAMES.Link} width={16} height={16} />{' '}
                        {t('crm_integration.button_connect')}
                    </div>
                </Button>
            </div>

            <div className={styles['info-col']}>
                <Typography variant='bodyRegular1'>
                    {t('crm_integration.crms_supported')}
                </Typography>
                <ul className={styles.list}>
                    <li>
                        <Typography variant='bodyRegular1'>
                            {t('crm_integration.crm_supported_hubspot')}
                        </Typography>
                    </li>
                </ul>
            </div>

            <Dialog
                open={showConnectDialog}
                className={styles.dialog}
                title={t('crm_integration.title_connect_crm')}
                onClose={handleCloseConnectDialog}
            >
                <Typography variant='bodyRegular1'>
                    {t('crm_integration.text_connect_crm')}
                </Typography>
                <div className={styles['integrations-list']}>
                    {INTEGRATIONS_CRM.map((integration) => (
                        <div
                            key={integration.name}
                            className={styles['integration-item']}
                            onClick={(): void => handleConnectIntegration(integration.name)}
                        >
                            <img src={integration.image} alt={integration.name} />
                            <Typography variant='bodyBold2'>
                                {t(`crm_integration.${integration.name}`)}
                            </Typography>
                        </div>
                    ))}
                </div>
            </Dialog>
        </>
    );
};

type CRMIntegrationContentConnectedProps = {
    connection: Connection;
    onClose: () => Promise<void>;
};

const CRMIntegrationContentConnected: React.FC<CRMIntegrationContentConnectedProps> = ({
    connection,
    onClose,
}) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const { t } = useTranslation('profile');

    const { loading, accessor } = useConnection(connection.id);

    if (!connection) return null;

    const integration = INTEGRATIONS_CRM.find((i) => i.name === connection.integration?.key);

    const handleDisconnectClick = async (): Promise<void> => {
        setShowConfirmation(true);
    };

    const handleDisconnectConfirm = async (): Promise<void> => {
        await accessor?.archive();
        onClose();
    };

    return (
        <>
            <div className={classNames(styles['info-col'], styles['info-col-centered'])}>
                <Tag text={t('crm_integration.crm')} variant='strong' gradient />

                <div className={styles.image}>
                    {integration && (
                        <img
                            src={integration.image}
                            alt={t(`crm_integration.${integration.name}`)}
                        />
                    )}
                    <Typography variant='bodyBold1'>{connection.name}</Typography>
                </div>

                <Typography variant='bodyRegular2'>
                    {t('crm_integration.connected_on', {
                        date: formatDateAsString(new Date(), 'PP'),
                    })}
                </Typography>

                <Button
                    variant='secondary-danger'
                    disabled={loading}
                    onClick={handleDisconnectClick}
                >
                    <div className={styles['info-row']}>
                        <Icon name={ICON_NAMES.Trash} width={16} height={16} />{' '}
                        {t('crm_integration.button_disconnect')}
                    </div>
                </Button>
            </div>

            <ConfirmationDialog
                open={showConfirmation}
                title={t('crm_integration.title_confirm_disconnect')}
                content={t('crm_integration.text_confirm_disconnect')}
                labelConfirm={t('crm_integration.button_disconnect')}
                confirmVariant='primary-danger'
                onConfirm={handleDisconnectConfirm}
                onCancel={(): void => setShowConfirmation(false)}
            />
        </>
    );
};

type SettingsCRMIntegrationProps = {
    connections: Connection[];
    loading: boolean;
    refresh: () => Promise<void>;
};

const SettingsCRMIntegration: React.FC<SettingsCRMIntegrationProps> = ({
    connections,
    loading,
    refresh,
}) => {
    const { t } = useTranslation('profile');

    const activeConnection = getActiveConnection(connections);

    const handleOnClose = async (): Promise<void> => {
        await refresh();
    };

    return (
        <div className={styles.card}>
            {loading ? (
                <div className={styles['info-row']}>
                    <Loading size={24} />{' '}
                    <Typography variant='bodyRegular1' inline>
                        {t('crm_integration.loading')}
                    </Typography>
                </div>
            ) : activeConnection ? (
                <CRMIntegrationContentConnected
                    connection={activeConnection}
                    onClose={handleOnClose}
                />
            ) : (
                <CRMIntegrationContentNotConnected onClose={handleOnClose} />
            )}
        </div>
    );
};

export default SettingsCRMIntegration;
