import React from 'react';

import { type UserIndustryPitch } from 'interfaces/userEx.type';
import Icon, { ICON_NAMES } from 'components/Icon';
import Typography from 'components/Typography';

import styles from './style.module.scss';

type IndustryPitchItemProps = {
    userIndustryPitch: UserIndustryPitch;
    onEditClick?: (_userIndustryPitch: UserIndustryPitch) => void;
    onDeleteClick?: (_userIndustryPitch: UserIndustryPitch) => void;
};

const IndustryPitchItem: React.FC<IndustryPitchItemProps> = ({
    userIndustryPitch,
    onEditClick,
    onDeleteClick,
}) => {
    const { pitchName, pitch } = userIndustryPitch;

    const handleDeleteClick = (): void => {
        if (onDeleteClick) onDeleteClick(userIndustryPitch);
    };

    const handleEditClick = (): void => {
        if (onEditClick) onEditClick(userIndustryPitch);
    };

    return (
        <div className={styles.container}>
            <div className={styles.name}>
                <Typography variant='bodyRegular2' className={styles.text}>
                    {pitchName}
                </Typography>
            </div>
            <div className={styles.pitch}>
                <Typography variant='bodyRegular2' className={styles.text}>
                    {pitch}
                </Typography>
            </div>
            <div className={styles.edit}>
                <Icon name={ICON_NAMES.Pencil} onClick={handleEditClick} />
                <Icon name={ICON_NAMES.Trash} onClick={handleDeleteClick} width={12} height={12} />
            </div>
        </div>
    );
};

export default IndustryPitchItem;
