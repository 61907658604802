import { type IntegrationAppClient } from '@integration-app/sdk';

import {
    type IAResponseSFUserInfo,
    type IAResponseSFUserDetails,
    executeAction,
} from 'services/integrationApp';

export const getSFUserSignature = async (
    integrationAppClient: IntegrationAppClient,
): Promise<string | undefined> => {
    // fetch user info
    const currentUserInfo = await executeAction<never, IAResponseSFUserInfo>(
        integrationAppClient,
        'get-sf-user-info',
        'salesforce',
    );
    if (!currentUserInfo?.email) {
        // No user email found = no signature
        return undefined;
    }

    // fetch signature
    const sfUserDetails = await executeAction<{ email: string }, IAResponseSFUserDetails>(
        integrationAppClient,
        'get-email-signature',
        'salesforce',
        {
            email: currentUserInfo.email,
        },
    );
    if (!sfUserDetails?.fields?.Signature) {
        // No user signature found
        return undefined;
    }
    return sfUserDetails.fields.Signature;
};
