import { type CRMContactHistoryRecord } from 'services/integrationApp';

export const getCreatedDateTimeFromContactHistory = (
    contactHistory: CRMContactHistoryRecord | null | undefined,
): Date | null | undefined => {
    if (!contactHistory) return contactHistory;

    const { createdTime, fields } = contactHistory;
    let createdDateTime;
    if (fields.createdTime) {
        createdDateTime = new Date(fields.createdTime);
    } else if (createdTime) {
        createdDateTime = new Date(createdTime);
    } else {
        createdDateTime = null;
    }

    return createdDateTime;
};
