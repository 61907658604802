import type { ProspectContactStatus, ProspectSource } from 'services/honeycomb';
import type { NimbusProspect } from './nimbusSignal';
import type { ObjectValues } from './types';

export type Prospect = {
    _id: string;
    name: string;
    contactStatus: ProspectContactStatus;
    jobTitle?: string;
    source: ProspectSource;
    email?: string;
    company?: {
        id?: string;
        name?: string;
    };
    prospect?: NimbusProspect;
    prospectId: string;
    lastReplyDate?: string;
};

export type LeadSearchResultItem = {
    firstName: string;
    lastName: string;
    title: string;
    headline?: string | undefined;
    city?: string | undefined;
    state?: string | undefined;
    country: string;
    seniority: string;
    departments: string[];
    linkedinUrl: string;
    photoUrl?: string | undefined;
    companyName: string;
    companyLinkedinUrl?: string | undefined;
    companyWebsite?: string | undefined;
};

export const PROSPECT_TABS = {
    ActiveProspects: 'active-prospects',
    ImportJobs: 'import-jobs',
} as const;

export type ProspectTab = ObjectValues<typeof PROSPECT_TABS>;
