import env from 'env';

/**
 * Measure pageviews.
 * https://developers.google.com/analytics/devguides/collection/ga4/views?client_type=gtag
 * */
export const gtagPageView = (url: URL): void => {
    if (typeof window.gtag === 'function') {
        window.gtag('event', 'page_view', {
            page_path: url,
        });
    }
};

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
 * https://support.google.com/analytics/answer/9267735?hl=en&ref_topic=13367566&sjid=514947604354845754-EU
 * Measure events
 * */

type CustomEventNames = Gtag.EventNames | (string & NonNullable<unknown>);

export const gtagEvent = (
    action: CustomEventNames,
    parameters?: Gtag.EventParams & Gtag.CustomParams,
): void => {
    if (typeof window.gtag === 'function') {
        window.gtag('event', action, {
            ...parameters,
        });
    }
};

/**
 * https://developers.google.com/tag-platform/gtagjs/reference#set
 */

export const gtagSet = (settings?: Gtag.EventParams & Gtag.CustomParams): void => {
    if (typeof window.gtag === 'function' && env.GA_TRACKING_ID) {
        window.gtag('set', {
            ...settings,
        });
        window.gtag('config', env.GA_TRACKING_ID);
    }
};

/**
 * https://developers.google.com/tag-platform/security/guides/consent#gtag.js_2
 */

export const gtagConsentUpdate = (params?: Gtag.ConsentParams): void => {
    if (typeof window.gtag === 'function') {
        window.gtag('consent', 'update', {
            ...params,
        });
    }
};
