import React from 'react';
import classNames from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import Icon, { ICON_NAMES, type IconName } from 'components/Icon';
import type { ButtonOption } from 'components/ButtonWithOptions';

import styles from './style.module.scss';

type ButtonWithDropdownProps = {
    disabled?: boolean;
    options: ButtonOption[];
    block?: boolean;
    icon?: IconName;
    size?: 'large' | 'medium' | 'small';
    variant?: 'primary' | 'secondary' | 'primary-danger' | 'secondary-danger' | 'ghost';
    autoFocus?: boolean;
    className?: string;
    onClick?: (_event: React.MouseEvent<HTMLButtonElement>) => void;
    onOptionClick?: (_event: React.MouseEvent<HTMLElement>, _value: string) => void;
};

const ButtonWithDropdown = React.forwardRef<HTMLDivElement, ButtonWithDropdownProps>(
    (
        {
            disabled = false,
            block = false,
            icon = ICON_NAMES.ChevronDown,
            options,
            size = 'medium',
            variant = 'ghost',
            className,
            onOptionClick,
        },
        ref,
    ) => {
        const classNamesContainer = classNames(styles.container, className);

        const optionsTriggerClassNames = classNames(
            styles['option-trigger-unset'],
            {
                [styles.block]: block,
                [styles.primary]: variant === 'primary',
                [styles['primary-danger']]: variant === 'primary-danger',
                [styles.secondary]: variant === 'secondary',
                [styles.ghost]: variant === 'ghost',
                [styles['secondary-danger']]: variant === 'secondary-danger',
                [styles.large]: size === 'large',
                [styles.small]: size === 'small',
            },
            styles['option-trigger'],
        );

        const optionItemClassNames = classNames(styles['option-item'], {
            [styles.large]: size === 'large',
            [styles['option-item-small']]: size === 'small',
        });

        const handleOptionClick = (
            event: React.MouseEvent<HTMLDivElement>,
            value: string,
        ): void => {
            if (disabled) return;
            if (onOptionClick && typeof onOptionClick === 'function') {
                onOptionClick(event, value);
            }
        };

        return (
            <div className={classNamesContainer} ref={ref}>
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                        <button className={optionsTriggerClassNames} disabled={disabled}>
                            <Icon name={icon} width={16} height={16} />
                        </button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                        <DropdownMenu.Content
                            className={styles['options-content']}
                            sideOffset={2}
                            align='start'
                        >
                            {options.map((option, index) => (
                                <DropdownMenu.Item
                                    key={index}
                                    className={optionItemClassNames}
                                    onClick={(event): void =>
                                        handleOptionClick(event, option.value)
                                    }
                                >
                                    {option.label}
                                </DropdownMenu.Item>
                            ))}
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </div>
        );
    },
);

export default ButtonWithDropdown;
