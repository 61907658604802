import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { MultiValue } from 'react-select';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldGroup from 'components/FormFieldGroup';
import FormFieldInput from 'components/FormFieldInput';
import FormFieldSelect, { type FormFieldOption } from 'components/FormFieldSelect';
import FormFieldMultiSelect from 'components/FormFieldMultiSelect';
import Loading from 'components/Loading';
import Typography from 'components/Typography';
import { useLeadSearchConfig, useLeadSearchLoading } from 'stores/useProspectStore';

import styles from './style.module.scss';

export type FormLeadSearchFormData = {
    personJobTitle?: string;
    personExcludeJobTitle?: string;
    personManagementLevel?: string[];
    personDepartment?: string[];
    personLocation?: string;
    companyIndustry?: string[];
    companyKeywords?: string;
    companyRevenueFrom?: string;
    companyRevenueTo?: string;
    companyDomain?: string;
    companyLocation?: string;
    companyEmployeeCountFrom?: number;
    companyEmployeeCountTo?: number;
    companyEmployeesByDepartment?: string;
    companyEmployeesByDepartmentFrom?: number;
    companyEmployeesByDepartmentTo?: number;
    companyFoundedYearFrom?: number;
    companyFoundedYearTo?: number;
    companyFundingLevel?: string[];
    companyTechnologies?: string[];
};

type FormLeadSearchFormDataKey = keyof FormLeadSearchFormData;

type FormLeadSearchProps = {
    initialValues?: FormLeadSearchFormData;
    disabled?: boolean;
    onSubmit: (_values: FormLeadSearchFormData) => void;
    onCancel?: () => void;
};

const defaultValues: FormLeadSearchFormData = {
    personJobTitle: '',
    personExcludeJobTitle: '',
    personManagementLevel: [],
    personDepartment: [],
    personLocation: '',
    companyIndustry: [],
    companyKeywords: '',
    companyRevenueFrom: '',
    companyRevenueTo: '',
    companyDomain: '',
    companyLocation: '',
    companyEmployeeCountFrom: undefined,
    companyEmployeeCountTo: undefined,
    companyEmployeesByDepartment: '',
    companyEmployeesByDepartmentFrom: undefined,
    companyEmployeesByDepartmentTo: undefined,
    companyFoundedYearFrom: undefined,
    companyFoundedYearTo: undefined,
    companyFundingLevel: [],
    companyTechnologies: [],
};

const getFormData = (
    formValues: { [key: string]: FormDataEntryValue },
    values: FormLeadSearchFormData = defaultValues,
): FormLeadSearchFormData => {
    return {
        personJobTitle: (formValues?.personJobTitle === undefined
            ? values.personJobTitle
            : formValues.personJobTitle) as string,
        personExcludeJobTitle: (formValues?.personExcludeJobTitle === undefined
            ? values.personExcludeJobTitle
            : formValues.personExcludeJobTitle) as string,
        personManagementLevel: (formValues?.personManagementLevel === undefined
            ? values.personManagementLevel
            : formValues.personManagementLevel) as string[],
        personDepartment: (formValues?.personDepartment === undefined
            ? values.personDepartment
            : formValues.personDepartment) as string[],
        personLocation: (formValues?.personLocation === undefined
            ? values.personLocation
            : formValues.personLocation) as string,

        companyIndustry: (formValues?.companyIndustry === undefined
            ? values.companyIndustry
            : formValues.companyIndustry) as string[],
        companyKeywords: (formValues?.companyKeywords === undefined
            ? values.companyKeywords
            : formValues.companyKeywords) as string,
        companyLocation: (formValues?.companyLocation === undefined
            ? values.companyLocation
            : formValues.companyLocation) as string,
        companyRevenueFrom: (formValues?.companyRevenueFrom === undefined
            ? values.companyRevenueFrom
            : formValues.companyRevenueFrom) as string,
        companyRevenueTo: (formValues?.companyRevenueTo === undefined
            ? values.companyRevenueTo
            : formValues.companyRevenueTo) as string,
        companyDomain: (formValues?.companyDomain === undefined
            ? values.companyDomain
            : formValues.companyDomain) as string,
        companyEmployeeCountFrom:
            formValues?.companyEmployeeCountFrom === undefined
                ? values.companyEmployeeCountFrom
                : parseInt(formValues.companyEmployeeCountFrom as string),
        companyEmployeeCountTo:
            formValues?.companyEmployeeCountTo === undefined
                ? values.companyEmployeeCountTo
                : parseInt(formValues.companyEmployeeCountTo as string),
        companyEmployeesByDepartment: (formValues?.companyEmployeesByDepartment === undefined
            ? values.companyEmployeesByDepartment
            : formValues.companyEmployeesByDepartment === 'any'
            ? undefined
            : formValues.companyEmployeesByDepartment) as string,
        companyEmployeesByDepartmentFrom:
            formValues?.companyEmployeesByDepartmentFrom === undefined
                ? values.companyEmployeesByDepartmentFrom
                : parseInt(formValues.companyEmployeesByDepartmentFrom as string),
        companyEmployeesByDepartmentTo:
            formValues?.companyEmployeesByDepartmentTo === undefined
                ? values.companyEmployeesByDepartmentTo
                : parseInt(formValues.companyEmployeesByDepartmentTo as string),
        companyFoundedYearFrom:
            formValues?.companyFoundedYearFrom === undefined
                ? values.companyFoundedYearFrom
                : parseInt(formValues.companyFoundedYearFrom as string),
        companyFoundedYearTo:
            formValues?.companyFoundedYearTo === undefined
                ? values.companyFoundedYearTo
                : parseInt(formValues.companyFoundedYearTo as string),
        companyFundingLevel: (formValues?.companyFundingLevel === undefined
            ? values.companyFundingLevel
            : formValues.companyFundingLevel) as string[],
        companyTechnologies: (formValues?.companyTechnologies === undefined
            ? values.companyTechnologies
            : formValues.companyTechnologies) as string[],
    };
};

const FormLeadSearch: React.FC<FormLeadSearchProps> = ({
    initialValues = defaultValues,
    disabled = false,
    onSubmit,
}) => {
    const [formData, setFormData] = React.useState<FormLeadSearchFormData>(initialValues);
    const [showAdvanced, setShowAdvanced] = React.useState<boolean>(false);

    const { t } = useTranslation('prospects');

    const leadSearchConfig = useLeadSearchConfig();
    const leadSearchLoading = useLeadSearchLoading();

    const optionAny = useMemo(() => ({ label: t('search.any'), value: 'any' }), [t]);

    const optionsManagementLevel = useMemo(
        () => leadSearchConfig.seniorities.map((item) => ({ label: item.name, value: item.id })),
        [leadSearchConfig],
    );

    const optionsCompanyIndustry = useMemo(
        () => leadSearchConfig.industries.map((item) => ({ label: item.name, value: item.id })),
        [leadSearchConfig],
    );

    const optionsCompanyRevenue = useMemo(
        () => [
            optionAny,
            ...leadSearchConfig.revenue.map((item) => ({ label: item.name, value: item.id })),
        ],
        [leadSearchConfig],
    );

    // list of departments
    const optionsDepartment = useMemo(
        () => leadSearchConfig.departments.map((item) => ({ label: item.name, value: item.id })),
        [leadSearchConfig],
    );

    // list of funding levels
    const optionsCompanyFundingLevel = useMemo(
        () => leadSearchConfig.funding.map((item) => ({ label: item.name, value: item.id })),
        [leadSearchConfig],
    );

    // list of technologies
    const optionsCompanyTechnologies = useMemo(
        () => leadSearchConfig.technologies.map((item) => ({ label: item.name, value: item.id })),
        [leadSearchConfig],
    );

    const submitDisabled = disabled;

    useEffect(() => {
        if (initialValues) {
            const values: FormLeadSearchFormData = {
                ...initialValues,
            };
            setFormData(values);
        }
    }, [initialValues]);

    const handleInputChange = (name: string, value: string): void => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleMultiOptionChange = (name: string, value: MultiValue<FormFieldOption>): void => {
        const values = value.map((option) => option.value);
        setFormData((prev) => ({ ...prev, [name]: values }));
    };

    const handleOptionChange = (name: FormLeadSearchFormDataKey, value: string): void => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formDataFromEntries = Object.fromEntries(new FormData(event.currentTarget));
        const formDataParsed = getFormData(formDataFromEntries, formData);
        if (onSubmit) onSubmit(formDataParsed);
    };

    const handleClickToggleAdvanced = (event: React.FormEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        setShowAdvanced((prev) => !prev);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <div className={styles.items}>
                <div className={classNames(styles.item, styles.item1)}>
                    <Typography variant='heading3'>{t('search.person_criteria')}</Typography>
                    <FormFieldInput
                        label={t('search.job_title.label')}
                        name='personJobTitle'
                        placeholder={t('search.job_title.placeholder')}
                        helperText={t('search.job_title.helper_text')}
                        disabled={disabled}
                        value={formData.personJobTitle}
                        onChange={handleInputChange}
                    />

                    {showAdvanced && (
                        <div className={styles.advanced}>
                            <FormFieldInput
                                label={t('search.exclude_job_title.label')}
                                name='personExcludeJobTitle'
                                placeholder={t('search.exclude_job_title.placeholder')}
                                helperText={t('search.exclude_job_title.helper_text')}
                                disabled={disabled}
                                value={formData.personExcludeJobTitle}
                                onChange={handleInputChange}
                            />
                        </div>
                    )}

                    <FormFieldMultiSelect
                        label={t('search.management_level.label')}
                        name='personManagementLevel'
                        placeholder={t('search.management_level.placeholder')}
                        helperText={t('search.management_level.helper_text')}
                        options={optionsManagementLevel}
                        value={optionsManagementLevel.filter(
                            (option) => formData?.personManagementLevel?.includes(option.value),
                        )}
                        disabled={disabled}
                        onChange={handleMultiOptionChange.bind(null, 'personManagementLevel')}
                    />
                    <FormFieldMultiSelect
                        label={t('search.department.label')}
                        name='personDepartment'
                        placeholder={t('search.department.placeholder')}
                        helperText={t('search.department.helper_text')}
                        options={optionsDepartment}
                        value={optionsDepartment.filter(
                            (option) => formData?.personDepartment?.includes(option.value),
                        )}
                        disabled={disabled}
                        onChange={handleMultiOptionChange.bind(null, 'personDepartment')}
                    />

                    <FormFieldInput
                        label={t('search.person_location.label')}
                        name='personLocation'
                        placeholder={t('search.person_location.placeholder')}
                        helperText={t('search.person_location.helper_text')}
                        disabled={disabled}
                        value={formData.personLocation}
                        onChange={handleInputChange}
                    />
                </div>

                <div className={classNames(styles.item, styles.item2)}>
                    <div className={styles['item2-header']}>
                        <Typography variant='heading3'>{t('search.company_criteria')}</Typography>
                        <Button variant='link' onClick={handleClickToggleAdvanced} type='button'>
                            {t(showAdvanced ? 'search.hide_advanced' : 'search.show_advanced')}
                        </Button>
                    </div>

                    <div className={styles.item20}>
                        <div className={styles.item21}>
                            <FormFieldMultiSelect
                                label={t('search.industry.label')}
                                name='companyIndustry'
                                placeholder={t('search.industry.placeholder')}
                                helperText={t('search.industry.helper_text')}
                                options={optionsCompanyIndustry}
                                value={optionsCompanyIndustry.filter(
                                    (option) => formData?.companyIndustry?.includes(option.value),
                                )}
                                disabled={disabled}
                                onChange={handleMultiOptionChange.bind(null, 'companyIndustry')}
                            />

                            {showAdvanced && (
                                <div className={styles.advanced}>
                                    <FormFieldInput
                                        label={t('search.company_keywords.label')}
                                        name='companyKeywords'
                                        placeholder={t('search.company_keywords.placeholder')}
                                        helperText={t('search.company_keywords.helper_text')}
                                        disabled={disabled}
                                        value={formData.companyKeywords}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}

                            <FormFieldGroup
                                name='revenue'
                                label={t('search.revenue.label')}
                                helperText={t('search.revenue.helper_text')}
                                className={styles['item-range']}
                            >
                                <FormFieldSelect
                                    name='companyRevenueFrom'
                                    placeholder={t('search.revenue.placeholder')}
                                    options={optionsCompanyRevenue}
                                    value={formData?.companyRevenueFrom}
                                    disabled={disabled}
                                    onChange={handleOptionChange.bind(null, 'companyRevenueFrom')}
                                />
                                <span>{t('search.to')}</span>
                                <FormFieldSelect
                                    name='companyRevenueTo'
                                    placeholder={t('search.revenue.placeholder')}
                                    options={optionsCompanyRevenue}
                                    value={formData?.companyRevenueTo}
                                    disabled={disabled}
                                    onChange={handleOptionChange.bind(null, 'companyRevenueTo')}
                                />
                            </FormFieldGroup>

                            <FormFieldInput
                                label={t('search.domain_name.label')}
                                name='companyDomain'
                                placeholder={t('search.domain_name.placeholder')}
                                helperText={t('search.domain_name.helper_text')}
                                disabled={disabled}
                                value={formData.companyDomain}
                                onChange={handleInputChange}
                            />

                            <FormFieldInput
                                label={t('search.company_location.label')}
                                name='companyLocation'
                                placeholder={t('search.company_location.placeholder')}
                                helperText={t('search.company_location.helper_text')}
                                disabled={disabled}
                                value={formData.companyLocation}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className={styles.item22}>
                            <FormFieldGroup
                                name=''
                                label={t('search.employee_count.label')}
                                helperText={t('search.employee_count.helper_text')}
                                className={styles['item-range']}
                            >
                                <FormFieldInput
                                    name='companyEmployeeCountFrom'
                                    type='number'
                                    min={0}
                                    placeholder={t('search.employee_count.from_placeholder')}
                                    disabled={disabled}
                                    value={formData.companyEmployeeCountFrom?.toString()}
                                    onChange={handleInputChange}
                                />
                                <span>{t('search.to')}</span>
                                <FormFieldInput
                                    name='companyEmployeeCountTo'
                                    type='number'
                                    min={0}
                                    placeholder={t('search.employee_count.to_placeholder')}
                                    disabled={disabled}
                                    value={formData.companyEmployeeCountTo?.toString()}
                                    onChange={handleInputChange}
                                />
                            </FormFieldGroup>

                            {showAdvanced && (
                                <div className={styles.advanced}>
                                    <FormFieldGroup
                                        name='employees_by_department'
                                        label={t('search.employees_by_department.label')}
                                        className={styles['item-col']}
                                    >
                                        <FormFieldSelect
                                            name='companyEmployeesByDepartment'
                                            placeholder={t(
                                                'search.employees_by_department.placeholder',
                                            )}
                                            options={[optionAny, ...optionsDepartment]}
                                            value={formData?.companyEmployeesByDepartment}
                                            disabled={disabled}
                                            onChange={handleOptionChange.bind(
                                                null,
                                                'companyEmployeesByDepartment',
                                            )}
                                        />

                                        <div className={styles['item-range']}>
                                            <FormFieldInput
                                                name='companyEmployeesByDepartmentFrom'
                                                type='number'
                                                min={0}
                                                placeholder={t(
                                                    // eslint-disable-next-line max-len
                                                    'search.employees_by_department.from_placeholder',
                                                )}
                                                disabled={disabled}
                                                value={// eslint-disable-next-line max-len
                                                formData.companyEmployeesByDepartmentFrom?.toString()}
                                                onChange={handleInputChange}
                                            />
                                            <span>{t('search.to')}</span>
                                            <FormFieldInput
                                                name='companyEmployeesByDepartmentTo'
                                                type='number'
                                                min={0}
                                                placeholder={t(
                                                    'search.employees_by_department.to_placeholder',
                                                )}
                                                disabled={disabled}
                                                value={// eslint-disable-next-line max-len
                                                formData.companyEmployeesByDepartmentTo?.toString()}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </FormFieldGroup>
                                </div>
                            )}

                            <FormFieldGroup
                                name='founded_year'
                                label={t('search.founded_year.label')}
                                helperText={t('search.founded_year.helper_text')}
                                className={styles['item-range']}
                            >
                                <FormFieldInput
                                    name='companyFoundedYearFrom'
                                    type='number'
                                    min={0}
                                    placeholder={t('search.founded_year.from_placeholder')}
                                    disabled={disabled}
                                    value={formData.companyFoundedYearFrom?.toString()}
                                    onChange={handleInputChange}
                                />
                                <span>{t('search.to')}</span>
                                <FormFieldInput
                                    name='companyFoundedYearTo'
                                    type='number'
                                    min={0}
                                    placeholder={t('search.founded_year.to_placeholder')}
                                    disabled={disabled}
                                    value={formData.companyFoundedYearTo?.toString()}
                                    onChange={handleInputChange}
                                />
                            </FormFieldGroup>

                            <FormFieldMultiSelect
                                label={t('search.funding_level.label')}
                                name='companyFundingLevel'
                                placeholder={t('search.funding_level.placeholder')}
                                helperText={t('search.funding_level.helper_text')}
                                options={optionsCompanyFundingLevel}
                                value={optionsCompanyFundingLevel.filter(
                                    (option) =>
                                        formData?.companyFundingLevel?.includes(option.value),
                                )}
                                disabled={disabled}
                                onChange={handleMultiOptionChange.bind(null, 'companyFundingLevel')}
                            />

                            <FormFieldMultiSelect
                                label={t('search.technologies.label')}
                                name='companyTechnologies'
                                placeholder={t('search.technologies.placeholder')}
                                helperText={t('search.technologies.helper_text')}
                                options={optionsCompanyTechnologies}
                                value={optionsCompanyTechnologies.filter(
                                    (option) =>
                                        formData?.companyTechnologies?.includes(option.value),
                                )}
                                disabled={disabled}
                                onChange={handleMultiOptionChange.bind(null, 'companyTechnologies')}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.submit}>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={submitDisabled} type='submit'>
                        {t('buttons.search')}
                    </Button>
                </Form.Submit>
                {leadSearchLoading && <Loading size={32} />}
            </div>
        </Form.Root>
    );
};

export default FormLeadSearch;
