import { forwardRef } from 'react';
import classNames from 'classnames';
import * as Form from '@radix-ui/react-form';
import * as Select from '@radix-ui/react-select';

import Icon, { ICON_NAMES } from 'components/Icon';

import styles from './style.module.scss';

export type FormFieldOption = {
    label: string;
    value: string;
    disabled?: boolean;
};

type Props = {
    id?: string;
    name: string;
    label?: string;
    options: FormFieldOption[];
    value?: string;
    placeholder?: string;
    helperText?: string;
    disabled?: boolean;
    variant?: 'default' | 'small';
    className?: string;
    onChange?: (_value: string) => void;
};

export const FormFieldSelect: React.FC<Props> = ({
    id,
    name,
    label,
    options,
    value,
    placeholder,
    helperText,
    disabled = false,
    variant = 'default',
    className,
    onChange,
}) => {
    const classContainer = classNames(
        styles.container,
        {
            [styles.disabled]: disabled,
        },
        className,
    );
    const classNamesLabel = classNames(styles.label, {
        [styles['label-small']]: variant === 'small',
    });
    const classNamesHelperText = classNames(styles['helper-text'], {
        [styles['helper-text-small']]: variant === 'small',
    });

    // event is not a ChangeEvent<HTMLInputElement> but a string
    // this is due to a custom implementation of the Select component (Radix UI thingy)
    // to be improved in the future 🤷‍♂️
    const handleChange = (event: unknown): void => {
        if (onChange) onChange(event as string);
    };

    return (
        <Form.Field name={name} className={classContainer}>
            {label && <Form.Label className={classNamesLabel}>{label}</Form.Label>}
            <Form.Control asChild value={value} disabled={disabled} onChange={handleChange}>
                <FormFieldSelectControl
                    placeholder={placeholder}
                    options={options}
                    variant={variant}
                >
                    {options.map((option) => (
                        <FormFieldSelectItem
                            key={`option-${option.value}`}
                            variant={variant}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </FormFieldSelectItem>
                    ))}
                </FormFieldSelectControl>
            </Form.Control>
            {helperText && (
                <Form.Label htmlFor={id} className={classNamesHelperText}>
                    {helperText}
                </Form.Label>
            )}
        </Form.Field>
    );
};

type FormFieldSelectControlProps = {
    name?: string;
    defaultValue?: string;
    placeholder?: string;
    options: FormFieldOption[];
    value?: string;
    variant: 'default' | 'small';
    onChange?: (_event: unknown) => void;
    children: React.ReactNode;
};

const FormFieldSelectControl = forwardRef<HTMLButtonElement, FormFieldSelectControlProps>(
    ({ children, placeholder, options, variant, ...props }, ref) => {
        const classesSelect = classNames(styles.select, {
            [styles['select-small']]: variant === 'small',
            [styles.placeholder]: !props.value,
        });

        const handleValueChange = (value: string): void => {
            if (props.onChange) props.onChange(value);
        };

        const option = options.find((option) => option.value === props.value);
        const optionLabel = option ? option.label : placeholder;

        return (
            <Select.Root {...props} onValueChange={handleValueChange}>
                <Select.Trigger className={classesSelect} ref={ref}>
                    <Select.Value placeholder={placeholder}>{optionLabel}</Select.Value>
                </Select.Trigger>
                <Select.Portal>
                    <Select.Content className={styles.content}>
                        <Select.ScrollUpButton>
                            <Icon name={ICON_NAMES.ChevronUp} />
                        </Select.ScrollUpButton>
                        <Select.Viewport className={styles.viewport}>{children}</Select.Viewport>
                        <Select.ScrollDownButton>
                            <Icon name={ICON_NAMES.ChevronDown} />
                        </Select.ScrollDownButton>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        );
    },
);

type FormFieldSelectItemProps = {
    children: React.ReactNode;
    variant: 'default' | 'small';
    value: string;
    disabled?: boolean;
};

const FormFieldSelectItem = forwardRef<HTMLDivElement, FormFieldSelectItemProps>(
    ({ children, variant, ...props }, ref) => (
        <Select.Item
            className={classNames(styles.item, {
                [styles['item-small']]: variant === 'small',
            })}
            {...props}
            ref={ref}
        >
            <Select.ItemText>{children}</Select.ItemText>
            <Select.ItemIndicator className={styles['select-indicator']}>
                <Icon name={ICON_NAMES.Check} width={16} height={16} />
            </Select.ItemIndicator>
        </Select.Item>
    ),
);

export default FormFieldSelect;
