import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTES } from 'constants/routes/routes';
import HeaderLogin from 'components/HeaderLogin';
import Loading from 'components/Loading';
import {
    useAuthClearErrorAndMessage,
    useClearCRMInfo,
    useInvalidateProxyLogin,
    useLoadingUser,
} from 'stores/useBoundStore';
import { fetchUserByInternalRef } from 'services/honeycomb';
import { storeSessionToken, storeUserId } from 'services/storage';

import 'react-toastify/dist/ReactToastify.css';
import styles from './style.module.scss';

const ProxyLoginPage = (): JSX.Element => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const token = searchParams.get('token');
    const internalRef = searchParams.get('userId');

    const loading = useLoadingUser();
    const invalidateProxyLogin = useInvalidateProxyLogin();
    const clearErrorAndMessage = useAuthClearErrorAndMessage();
    const clearCRMInfo = useClearCRMInfo();

    useEffect(() => {
        clearErrorAndMessage();
        return clearErrorAndMessage;
    }, []);

    useEffect(() => {
        if (!token || !internalRef) {
            return navigate(ROUTES.LOGIN);
        }
        const fetchUserInfo = async (): Promise<void> => {
            try {
                const user = await fetchUserByInternalRef(internalRef);
                if (user) {
                    storeUserId(String(user.id));
                    invalidateProxyLogin();
                    clearCRMInfo(); // clear crm info on proxy login
                    return navigate(ROUTES.SIGNALS);
                }
                navigate(ROUTES.LOGOUT);
            } catch (error) {
                navigate(ROUTES.LOGOUT);
            }
        };
        storeSessionToken(token);
        fetchUserInfo();
    }, [token]);

    return (
        <div className={styles.container}>
            <HeaderLogin />
            {loading && <Loading absolute />}
        </div>
    );
};

export default ProxyLoginPage;
