import classNames from 'classnames';
import * as Form from '@radix-ui/react-form';

import styles from './style.module.scss';

type FormFieldGroupProps = React.InputHTMLAttributes<HTMLDivElement> & {
    name: string;
    label?: string;
    helperText?: string;
    className?: string;
    children?: React.ReactNode;
};

export const FormFieldGroup: React.FC<FormFieldGroupProps> = ({
    name,
    label,
    helperText,
    className,
    children,
}) => {
    const classNamesChildren = classNames(styles.children, className);
    return (
        <Form.Field name={name} className={styles.container}>
            <div className={styles['label-container']}>
                {label && <Form.Label className={styles.label}>{label}</Form.Label>}
            </div>
            <div className={classNamesChildren}>{children}</div>
            {helperText && <Form.Label className={styles['helper-text']}>{helperText}</Form.Label>}
        </Form.Field>
    );
};

export default FormFieldGroup;
