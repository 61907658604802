import React from 'react';
import classNames from 'classnames';
import * as Form from '@radix-ui/react-form';
import * as Switch from '@radix-ui/react-switch';

import styles from './style.module.scss';

type FormFieldSwitchProps = {
    name: string;
    label: string | React.ReactNode;
    value?: boolean;
    disabled?: boolean;
    variant?: 'default' | 'small';
    align?: 'left' | 'right' | 'center';
    onChange?: (_value: boolean) => void;
};

export const FormFieldSwitch: React.FC<FormFieldSwitchProps> = ({
    name,
    label,
    disabled = false,
    value,
    variant = 'default',
    align = 'left',
    onChange,
}) => {
    const classContainer = classNames(styles.container, {
        [styles.disabled]: disabled,
    });
    const classControl = classNames(styles.control, {
        [styles['control-right']]: align === 'right',
        [styles['control-center']]: align === 'center',
    });
    const classLabel = classNames(styles.label, {
        [styles['label-small']]: variant === 'small',
    });

    const handleCheckedChange = (checked: boolean): void => {
        if (onChange) onChange(checked);
    };

    return (
        <Form.Field name={name} className={classContainer}>
            <Form.Label className={classLabel}>{label}</Form.Label>
            <div className={classControl}>
                <Form.Control asChild checked={value} disabled={disabled}>
                    <Switch.Root className={styles.switch} onCheckedChange={handleCheckedChange}>
                        <Switch.Thumb className={styles['switch-thumb']} />
                    </Switch.Root>
                </Form.Control>
            </div>
        </Form.Field>
    );
};

export default FormFieldSwitch;
