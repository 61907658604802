import { type IntegrationAppClient, type ConnectionAccessor } from '@integration-app/sdk';
import type { StateCreator } from 'zustand';

import type { CRMItem, CRMProspect, CRMProspectFullInfo } from 'interfaces/crm.type';
import { handleAPIError } from 'services/errorHandler';
import {
    type IAResponseSendEmail,
    type IASendEmailPayload,
    type IAResponseContantDetails,
    executeAction,
    getFirstContactItem,
} from 'services/integrationApp';
import * as nimbusService from 'services/nimbus';
import { removeCRMItems, removeCRMProspects } from 'services/storage';
import { type SignalEmailPayload } from 'stores/matchSlice';

import { findOrCreateContact } from './findOrCreateContact';
import { getActiveConnection } from './getActiveConnection';
import { getCreatedDateTimeFromContactHistory } from './getCreatedDateTimeFromContactHistory';
import { getSFUserSignature } from './getSFUserSignature';
import { prepareEmailMessage } from './prepareEmailMessage';
import { getConnectionsFiltered } from './getConnectionsFiltered';
import { getSFUserInfo } from './getSFUserInfo';
import { getGmailProfile } from './getGmailProfile';

export type CRMConnectionState = 'unknown' | 'connected' | 'disconnected';

export type IntegrationProfile = {
    integrationKey: string; // gmail | outlook | salesforce
    email: string;
};

export type CRMSlice = {
    loadingCRM: boolean;
    errorCRM: string | undefined;
    iaToken: string | undefined;
    crmProspects: Record<string, CRMProspect | undefined> | undefined;
    crmMeetings: Record<string, CRMItem | undefined> | undefined;
    crmCalls: Record<string, CRMItem | undefined> | undefined;
    crmEmails: Record<string, CRMItem | undefined> | undefined;
    // TODO: implement connection state in store? not used for now
    crmConnection: ConnectionAccessor | undefined;
    crmConnectionState: CRMConnectionState;
    integrationProfiles: IntegrationProfile[];

    fetchIAToken: () => Promise<string | undefined>;
    sendCRMEmail: (
        _integrationApp: IntegrationAppClient,
        _payload: SignalEmailPayload,
    ) => Promise<nimbusService.APIResponseUpdateSignal | undefined>;
    fetchCRMLastContactInfo: (
        _integrationApp: IntegrationAppClient,
        _prospectId: string,
        _email: string,
    ) => Promise<void>;
    getCRMProspectInfoById: (_id: string) => CRMProspectFullInfo | undefined;
    clearCRMInfo: () => void;
    fetchIntegrationProfiles: (_integrationApp: IntegrationAppClient) => Promise<void>;
};

export const createCRMSlice: StateCreator<CRMSlice> = (set, get) => ({
    loadingCRM: false,
    errorCRM: undefined,
    iaToken: undefined,
    crmProspects: undefined,
    crmMeetings: undefined,
    crmCalls: undefined,
    crmEmails: undefined,
    crmConnection: undefined,
    crmConnectionState: 'unknown',
    integrationProfiles: [],

    fetchIAToken: async (): Promise<string | undefined> => {
        set((_state) => ({ loadingCRM: true, errorCRM: undefined }));
        let iaToken: string | undefined;
        try {
            const iaTokenInfo = await nimbusService.getIAToken();
            if (iaTokenInfo) {
                const { token: iaToken } = iaTokenInfo;
                set((_state) => ({ iaToken }));
            }
        } catch (error: unknown) {
            set((_state) => ({
                errorCRM: error?.toString(),
                iaToken,
            }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingCRM: false }));
        }
        return iaToken;
    },

    sendCRMEmail: async (
        integrationApp: IntegrationAppClient,
        payload: SignalEmailPayload,
    ): Promise<nimbusService.APIResponseUpdateSignal | undefined> => {
        const sfUserSignaturePromise = getSFUserSignature(integrationApp);
        const findOrCreateContactPromise = findOrCreateContact(integrationApp, payload);

        const [userSignature, contact] = await Promise.all([
            sfUserSignaturePromise,
            findOrCreateContactPromise,
        ]);

        const emailBody = prepareEmailMessage(payload.email.message, userSignature);

        const actionPayload: IASendEmailPayload = {
            emailTo: payload.email.address,
            emailSubject: payload.email.subjectLine,
            emailBody,
        };
        if (contact?.id) {
            actionPayload.recipientId = contact.id;
        }
        const sendEmailResponse = await executeAction<IASendEmailPayload, IAResponseSendEmail>(
            integrationApp,
            'send-email',
            'salesforce',
            actionPayload,
        );
        if (!sendEmailResponse?.[0] || sendEmailResponse[0]?.isSuccess === false) {
            console.error('Send error', sendEmailResponse);
            throw new Error('Error sending email via CRM');
        }

        const updateSignalEmailMessageResponse = await nimbusService.updateSignalEmailMessage(
            payload.signalId,
            {
                emailSubject: payload.email.subjectLine,
                emailMessage: payload.email.message,
                emailSent: true,
            },
        );
        return updateSignalEmailMessageResponse;
    },

    // todo: split into separate actions
    fetchCRMLastContactInfo: async (
        integrationApp: IntegrationAppClient,
        prospectId: string,
        email: string,
    ): Promise<void> => {
        const crmProspectDefault: CRMProspect = {
            loading: true,
        };
        // get prospect from the store
        let crmProspect = get().crmProspects?.[prospectId];

        // if prospect found
        // and updated within the last 24 hours, return without fetching from crm
        if (
            crmProspect?.updated &&
            new Date().getTime() - crmProspect.updated.getTime() < 86400000 // 24h
        ) {
            return;
        }

        try {
            const findResponse = await integrationApp.connections.find();
            if (
                !findResponse.items ||
                !Array.isArray(findResponse.items) ||
                findResponse.items.length === 0
            ) {
                set((_state) => ({ crmConnectionState: 'disconnected' }));
                return;
            }

            const activeConnection = getActiveConnection(findResponse.items);
            if (activeConnection) {
                set((_state) => ({ crmConnectionState: 'connected' }));
            }
            if (activeConnection?.integration?.key !== 'hubspot') {
                return;
            }
        } catch (error: unknown) {
            set((_state) => ({ crmConnectionState: 'disconnected' }));
            return;
        }

        // if prospect was not found or expired, create a new prospect record and fetch it
        crmProspect = crmProspectDefault;

        set((state) => ({
            loadingCRM: true,
            errorCRM: undefined,
            crmProspects: {
                ...state.crmProspects,
                [prospectId]: crmProspect,
            },
        }));

        try {
            const contact = await executeAction<{ email: string }, IAResponseContantDetails>(
                integrationApp,
                'find-prospect-by-email',
                'hubspot',
                { email },
            );
            crmProspect.loading = false;
            set((state) => ({
                crmProspects: {
                    ...state.crmProspects,
                    [prospectId]: crmProspect,
                },
            }));

            if (contact?.id) {
                if (contact?.uri) {
                    crmProspect.contactUrl = contact.uri;
                    set((state) => ({
                        crmProspects: {
                            ...state.crmProspects,
                            [prospectId]: crmProspect,
                        },
                    }));
                }

                const crmMeeting: CRMItem = { loading: true };
                set((state) => ({
                    crmMeetings: {
                        ...state.crmMeetings,
                        [prospectId]: crmMeeting,
                    },
                }));

                const promiseMeeting = getFirstContactItem(
                    integrationApp,
                    'get-meetings-by-prospect-id',
                    'hubspot',
                    {
                        contactId: String(contact.id),
                    },
                ).then((contactItemMeeting) => {
                    crmMeeting.loading = false;
                    crmMeeting.lastContactDate =
                        getCreatedDateTimeFromContactHistory(contactItemMeeting);
                    set((state) => ({
                        crmMeetings: {
                            ...state.crmMeetings,
                            [prospectId]: crmMeeting,
                        },
                    }));
                });

                const crmCall: CRMItem = { loading: true };
                set((state) => ({
                    crmCalls: {
                        ...state.crmCalls,
                        [prospectId]: crmCall,
                    },
                }));

                const promiseCall = getFirstContactItem(
                    integrationApp,
                    'get-calls-by-prospect-id',
                    'hubspot',
                    {
                        contactId: String(contact.id),
                    },
                ).then((contactItemCall) => {
                    crmCall.loading = false;
                    crmCall.lastContactDate = getCreatedDateTimeFromContactHistory(contactItemCall);
                    set((state) => ({
                        crmCalls: {
                            ...state.crmCalls,
                            [prospectId]: crmCall,
                        },
                    }));
                });

                const crmEmail: CRMItem = { loading: true };
                set((state) => ({
                    crmEmails: {
                        ...state.crmEmails,
                        [prospectId]: crmEmail,
                    },
                }));

                const promiseEmail = getFirstContactItem(
                    integrationApp,
                    'get-emails-by-prospect-id',
                    'hubspot',
                    {
                        contactId: String(contact.id),
                    },
                ).then((contactItemEmail) => {
                    crmEmail.loading = false;
                    crmEmail.lastContactDate =
                        getCreatedDateTimeFromContactHistory(contactItemEmail);
                    set((state) => ({
                        crmEmails: {
                            ...state.crmEmails,
                            [prospectId]: crmEmail,
                        },
                    }));
                });

                await Promise.all([promiseMeeting, promiseCall, promiseEmail]);

                crmProspect.updated = new Date();
                set((state) => ({
                    crmProspects: {
                        ...state.crmProspects,
                        [prospectId]: crmProspect,
                    },
                }));
            }
        } catch (error: unknown) {
            set((state) => ({
                errorCRM: error?.toString(),
                crmProspects: {
                    ...state.crmProspects,
                    [prospectId]: {
                        ...(state.crmProspects?.[prospectId] || crmProspectDefault),
                        loading: false,
                    },
                },
                crmMeetings: {
                    ...state.crmMeetings,
                    [prospectId]: {
                        ...state.crmMeetings?.[prospectId],
                        loading: false,
                    },
                },
                crmCalls: {
                    ...state.crmCalls,
                    [prospectId]: {
                        ...state.crmCalls?.[prospectId],
                        loading: false,
                    },
                },
                crmEmails: {
                    ...state.crmEmails,
                    [prospectId]: {
                        ...state.crmEmails?.[prospectId],
                        loading: false,
                    },
                },
            }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingCRM: false }));
        }
    },

    getCRMProspectInfoById: (id: string): CRMProspectFullInfo | undefined => {
        const crmProspects = get().crmProspects;
        let result: CRMProspectFullInfo = {
            loading: false,
            contactUrl: '',
            updated: new Date(),
            loadingMeetingInfo: false,
            lastContactDateMeeting: undefined,
            loadingCallInfo: false,
            lastContactDateCall: undefined,
            loadingEmailInfo: false,
            lastContactDateEMail: undefined,
        };
        if (crmProspects?.[id]) {
            const meetingInfo = get().crmMeetings?.[id];
            const callInfo = get().crmCalls?.[id];
            const emailInfo = get().crmEmails?.[id];
            result = {
                ...result,
                ...crmProspects[id],
                loadingMeetingInfo: meetingInfo?.loading || false,
                lastContactDateMeeting: meetingInfo?.lastContactDate,
                loadingCallInfo: callInfo?.loading || false,
                lastContactDateCall: callInfo?.lastContactDate,
                loadingEmailInfo: emailInfo?.loading || false,
                lastContactDateEMail: emailInfo?.lastContactDate,
            };
            if (result) return result;
        }
        return undefined;
    },

    clearCRMInfo: (): void => {
        removeCRMItems('meetings');
        removeCRMItems('calls');
        removeCRMItems('emails');
        removeCRMProspects();
        set((_state) => ({
            crmProspects: undefined,
            crmMeetings: undefined,
            crmCalls: undefined,
            crmEmails: undefined,
        }));
    },

    fetchIntegrationProfiles: async (integrationApp: IntegrationAppClient): Promise<void> => {
        set((_state) => ({ loadingCRM: true, errorCRM: undefined }));
        try {
            if (!integrationApp?.connections) {
                return;
            }

            const findResponse = await integrationApp.connections.find();
            const connectionsCRM = getConnectionsFiltered(findResponse.items, 'crm');
            const connectionsEmail = getConnectionsFiltered(findResponse.items, 'email');

            let sfUserProfilePromise: Promise<string | undefined> | undefined;
            let gmailProfilePromise: Promise<string | undefined> | undefined;
            const profilesPromises: Promise<string | undefined>[] = [];
            const profilesResult: IntegrationProfile[] = [];

            if (connectionsCRM && connectionsCRM.length > 0) {
                const activeConnectionCRM = getActiveConnection(connectionsCRM);
                if (activeConnectionCRM && activeConnectionCRM.integration?.key === 'salesforce') {
                    sfUserProfilePromise = getSFUserInfo(integrationApp);
                    profilesPromises.push(sfUserProfilePromise);
                }
            }
            if (!sfUserProfilePromise) {
                profilesPromises.push(Promise.resolve(undefined));
            }

            if (connectionsEmail && connectionsEmail.length > 0) {
                const activeConnectionEmail = getActiveConnection(connectionsEmail);
                if (activeConnectionEmail && activeConnectionEmail.integration?.key === 'gmail') {
                    gmailProfilePromise = getGmailProfile(integrationApp);
                    profilesPromises.push(gmailProfilePromise);
                }
            }
            if (!gmailProfilePromise) {
                profilesPromises.push(Promise.resolve(undefined));
            }

            const profilesTemp = await Promise.all(profilesPromises);

            if (profilesTemp[0]) {
                profilesResult.push({ integrationKey: 'salesforce', email: profilesTemp[0] });
            }
            if (profilesTemp[1]) {
                profilesResult.push({ integrationKey: 'gmail', email: profilesTemp[1] });
            }

            set((_state) => ({ integrationProfiles: profilesResult }));
        } catch (error: unknown) {
            set((_state) => ({ errorCRM: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingCRM: false }));
        }
    },
});
