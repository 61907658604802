import authApi, { AuthAPIError } from 'services/authApi';
import { setEventParameters, trackEventLogIn, trackEventLogOut } from './eventsTracker';
import { clearStorage, storeToken } from './storage';

const URLS = {
    auth: '/api/auth/local',
    forgotPassword: '/api/auth/forgot-password',
    resetPassword: '/api/auth/reset-password',
    changePassword: '/api/auth/change-password',
} as const;

type APIResponseAuth = {
    jwt: string;
    user: Record<string, string | number | boolean>;
};

type APIResponseForgotPassword = {
    ok: boolean;
};

const getAPIErrorMessageKey = (error: AuthAPIError): string => {
    let errorMessageKey = 'error';
    if (error.code === 400 && error.data?.error) {
        const { message } = error.data.error;
        if (error.data.error.name === 'ValidationError') {
            if (message === 'Invalid identifier or password') {
                errorMessageKey = 'email_or_password_incorrect';
            } else if (message === 'email must be a valid email') {
                errorMessageKey = 'email_incorrect';
            } else {
                errorMessageKey = 'authentication_error';
            }
        } else if (error.data.error.name === 'ApplicationError') {
            if (message === 'Your account has been blocked by an administrator') {
                errorMessageKey = 'email_or_password_incorrect';
            }
        }
    }
    return `auth:error.${errorMessageKey}`;
};

export const signIn = async ({
    email,
    password,
}: {
    email: string;
    password: string;
}): Promise<void> => {
    if (!email || !password) {
        throw new Error('auth:error.email_and_password_required');
    }

    const postData = {
        identifier: email,
        password: password,
    };
    try {
        const authResponse = await authApi.post<APIResponseAuth>(URLS.auth, postData);
        if (authResponse) {
            const { jwt, user } = authResponse;
            storeToken(jwt);
            trackEventLogIn();
            setEventParameters({
                user_id: String(user.internalRef || user.id),
            });
        }
    } catch (error: unknown) {
        if (error instanceof AuthAPIError) {
            error.message = getAPIErrorMessageKey(error);
            throw error;
        }
    }
};

export const signOut = (): void => {
    clearStorage();
    trackEventLogOut();
};

export const forgotPassword = async ({ email }: { email: string }): Promise<boolean> => {
    if (!email) {
        throw new Error('auth:error.email_required');
    }

    const postData = {
        email,
    };
    try {
        const authResponse = await authApi.post<APIResponseForgotPassword>(
            URLS.forgotPassword,
            postData,
        );
        if (authResponse) {
            const { ok } = authResponse;
            return ok;
        }
    } catch (error: unknown) {
        if (error instanceof AuthAPIError) {
            error.message = getAPIErrorMessageKey(error);
            throw error;
        }
    }
    return false;
};

export const resetPassword = async ({
    password,
    passwordConfirmation,
    code,
}: {
    password: string;
    passwordConfirmation: string;
    code: string;
}): Promise<void> => {
    if (!password || !passwordConfirmation) {
        throw new Error('auth:error.password_with_confirmation_required');
    }

    const postData = {
        password,
        passwordConfirmation,
        code,
    };
    try {
        const authResponse = await authApi.post<APIResponseAuth>(URLS.resetPassword, postData);
        if (authResponse) {
            const { jwt } = authResponse;
            storeToken(jwt);
        }
    } catch (error: unknown) {
        if (error instanceof AuthAPIError) {
            error.message = getAPIErrorMessageKey(error);
            throw error;
        }
    }
};

export const changePassword = async ({
    password,
    passwordConfirmation,
    currentPassword,
}: {
    password: string;
    passwordConfirmation: string;
    currentPassword: string;
}): Promise<void> => {
    if (!password || !passwordConfirmation) {
        throw new Error('auth:error.password_required');
    }

    const postData = {
        password,
        passwordConfirmation,
        currentPassword,
    };
    try {
        const authResponse = await authApi.post<APIResponseAuth>(URLS.changePassword, postData);
        if (authResponse) {
            const { jwt } = authResponse;
            storeToken(jwt);
        }
    } catch (error: unknown) {
        if (error instanceof AuthAPIError) {
            error.message = getAPIErrorMessageKey(error);
            throw error;
        }
    }
};
