import { AxiosError } from 'axios';

import env from '../env';
import createApiClient from './api';

type HoneycombErrorResponseData = {
    data: unknown;
    error: {
        status: number;
        name: string;
        message: string;
        details: unknown;
    };
};

export class HoneycombAPIError extends Error {
    code?: number;
    status?: string;
    data?: HoneycombErrorResponseData;
    constructor(
        message?: string,
        code?: number,
        status?: string,
        data?: HoneycombErrorResponseData,
    ) {
        super(message);
        this.name = 'APIError';
        this.code = code;
        this.status = status;
        this.data = data;
    }
}

export const handleDataError = (error: unknown): unknown => {
    if (error instanceof AxiosError) {
        if (error.request) console.error(error.request);
        if (error.response) console.error(error.response.data);
        return new HoneycombAPIError(
            `${error.response?.status}: ${error.response?.statusText}`,
            error.response?.status,
            error.response?.statusText,
            error.response?.data,
        );
    } else {
        return error;
    }
};

const honeycombApi = createApiClient(
    {
        baseURL: env.BASE_URL,
        withCredentials: true,
    },
    handleDataError,
);

export default honeycombApi;
