import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes/routes';

import { type CRMProspectFullInfo } from 'interfaces/crm.type';
import { PROFILE_TABS } from 'interfaces/profile.type';

import Button from 'components/Button';
import CircledTag from 'components/CircledTag';
import ContactInfoItem from 'components/ContactInfoItem';
import Icon, { ICON_NAMES } from 'components/Icon';
import Message from 'components/Message';
import Typography from 'components/Typography';

import styles from './style.module.scss';

type Props = {
    crmProspect: CRMProspectFullInfo | undefined;
    connectionState?: 'connected' | 'disconnected' | 'unknown';
};

const CRMLastContactInfo: React.FC<Props> = ({ crmProspect, connectionState }) => {
    const { t } = useTranslation('matches');

    const navigate = useNavigate();

    const handleConnectClick = (): void => {
        navigate(ROUTES.PROFILE_DETAIL.replace(':page', PROFILE_TABS.Integrations));
    };

    return (
        <div className={styles.container}>
            {connectionState === 'disconnected' && (
                <>
                    <ContactInfoItem type='custom'>
                        <CircledTag icon={ICON_NAMES.Mail} disabled />
                        <Typography variant='bodyRegular2'>
                            {t('crm.last_contact_sample')}
                        </Typography>
                    </ContactInfoItem>
                    <Message info noIcon>
                        <div style={{ width: 180, marginBottom: '-2px' }}>
                            <Typography variant='bodyRegular2'>{t('crm.connect_crm')} </Typography>
                            <Button variant='link' size='medium' onClick={handleConnectClick}>
                                {t('crm.connect_now')}
                            </Button>
                        </div>
                    </Message>
                </>
            )}
            {connectionState !== 'disconnected' && (
                <>
                    {crmProspect?.contactUrl && (
                        <>
                            <ContactInfoItem
                                type='meeting'
                                contactDate={crmProspect.lastContactDateMeeting}
                                loading={crmProspect.loadingMeetingInfo}
                                disabled={!crmProspect.lastContactDateMeeting}
                            />
                            <ContactInfoItem
                                type='call'
                                contactDate={crmProspect.lastContactDateCall}
                                loading={crmProspect.loadingCallInfo}
                                disabled={!crmProspect.lastContactDateCall}
                            />
                            <ContactInfoItem
                                type='email'
                                contactDate={crmProspect.lastContactDateEMail}
                                loading={crmProspect.loadingEmailInfo}
                                disabled={!crmProspect.lastContactDateEMail}
                            />
                            <Link
                                to={crmProspect.contactUrl}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                <Typography variant='bodyRegular1' className={styles['open-text']}>
                                    <Icon name={ICON_NAMES.HubSpot} width={16} height={16} />{' '}
                                    {t('crm.open_hubspot')}
                                </Typography>
                            </Link>
                        </>
                    )}
                    {connectionState === 'connected' &&
                        crmProspect &&
                        !crmProspect?.loading &&
                        !crmProspect?.contactUrl && (
                            <ContactInfoItem type='custom' disabled>
                                <CircledTag icon={ICON_NAMES.Question} disabled />
                                <Typography variant='bodyRegular2'>
                                    {t('crm.no_prospect')}
                                </Typography>
                            </ContactInfoItem>
                        )}
                </>
            )}
        </div>
    );
};

export default CRMLastContactInfo;
