import { ROUTES } from 'constants/routes/routes';
import { type Match } from 'interfaces/match.type';

const breakTitle = (title: string): string[] => {
    return title
        .toLowerCase()
        .trim()
        .split(/[\s,-///(/)]+/);
};

const containsAny = (elements1: string[], elements2: string[]): boolean => {
    return elements1.some((item) => elements2.includes(item));
};

export const computeSeniority = (title?: string): string => {
    if (!title || title.trim().length === 0) {
        return '';
    }
    const titleWords = breakTitle(title);

    if (containsAny(['partner', 'founder'], titleWords)) {
        return 'Partner';
    }
    if (
        containsAny(
            [
                'chief',
                'chairman',
                'officer',
                'geschäftsführer',
                'geschäftsführin',
                'executive',
                'cco',
                'chrm',
                'cso',
                'cgo',
                'cao',
                'cmo',
                'cdo',
                'ceo',
                'cto',
                'coo',
                'cio',
            ],
            titleWords,
        )
    ) {
        return 'CXO';
    }
    if (containsAny(['vp', 'vice'], titleWords)) {
        return 'VP';
    }
    if (containsAny(['director', 'head', 'gruppenleiter'], titleWords)) {
        return 'Director';
    }
    if (
        containsAny(
            [
                'manager',
                'lead',
                'teamlead',
                'leader',
                'managerin',
                'projektleiter',
                'management',
                'leiterin',
                'leiter',
                'teamleiter',
                'projektmanager',
                'coordinator',
                'supervisor',
                'projektmanagerin',
            ],
            titleWords,
        )
    ) {
        return 'Manager';
    }
    if (containsAny(['junior', 'associate'], titleWords)) {
        return 'Entry';
    }
    if (containsAny(['intern', 'student', 'trainee', 'werkstudent'], titleWords)) {
        return 'Training';
    }
    if (containsAny(['volunteer'], titleWords)) {
        return 'Unpaid';
    }
    if (containsAny(['owner'], titleWords)) {
        return 'Owner';
    }
    if (
        containsAny(
            [
                'senior',
                'principal',
                'staff',
                'expert',
                'analyst',
                'consultant',
                'specialist',
                'analyst',
                'architect',
                'assistant',
            ],
            titleWords,
        )
    ) {
        return 'Senior';
    }

    return 'Senior';
};

export const getProspectSignalUrl = (prospect: Match): string => {
    if (!prospect.signals || prospect.signals.length === 0) {
        return ROUTES.SIGNALS_PROSPECT.replace(':prospectId', prospect.prospectId);
    }
    return ROUTES.SIGNALS_PROSPECT_DETAIL.replace(':prospectId', prospect.prospectId).replace(
        ':signalId',
        prospect.signals[0]?.id || '',
    );
};
