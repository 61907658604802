import { type IntegrationAppClient } from '@integration-app/sdk';

import { isObject } from 'utils/isObject';

export const executeAction = async <T, R>(
    integrationApp: IntegrationAppClient,
    action: string,
    integrationKey: string,
    parameters?: T,
): Promise<R | undefined> => {
    const response = await integrationApp
        .actionInstance({
            parentKey: action,
            integrationKey,
            autoCreate: true,
        })
        .run(parameters);

    if (isObject(response?.output)) {
        return response.output as R;
    }

    return undefined;
};

export type CRMContactHistoryRecord = {
    id: string;
    name: string;
    createdTime: string;
    updatedTime: string;
    fields: {
        ownerId: string;
        status: string;
        title: string;
        createdBy: string;
        createdTime: string;
        updatedBy: string;
        updatedTime: string;
    };
};

export type IASendEmailPayload = {
    emailTo: string;
    emailSubject: string;
    emailBody: string;
    recipientId?: string;
};

export type IAResponseSFUserInfo = {
    name?: string;
    email?: string;
    user_id?: string;
};

export type IAResponseGmailProfile = {
    emailAddress?: string;
};

export type IAResponseSFUserDetails = {
    id: string;
    name: string;
    uri: string;
    fields?: {
        Email?: string;
        FirstName?: string;
        LastName?: string;
        Signature?: string;
    };
};

export type IARequestCreateContact = {
    firstName: string;
    lastName: string;
    email: string;
};

export type IAResponseContantDetails = {
    id: string;
    name: string;
    uri: string;
    fields?: {
        id?: string;
        firstName?: string;
        lastName?: string;
        fullName?: string;
        emails?: string[];
        primaryEmail?: string;
    };
};

export type IAResponseSendEmailAction = {
    actionName: string;
    errors: unknown; // don't know the type, maybe string[], but when no error it's null
    isSuccess: boolean;
};

export type IAResponseSendEmail = IAResponseSendEmailAction[];

export const getFirstContactItem = async (
    integrationApp: IntegrationAppClient,
    action: string,
    integrationKey: string,
    parameters: Record<string, string>,
): Promise<CRMContactHistoryRecord | null | undefined> => {
    // returns null if not found, undefined if error
    try {
        const response = await integrationApp
            .actionInstance({
                parentKey: action,
                integrationKey,
                autoCreate: true,
            })
            .run(parameters);

        if (
            response?.output &&
            response?.output?.records &&
            Array.isArray(response.output.records) &&
            response.output.records.length > 0
        ) {
            const lastContactItem = response.output.records[0];
            return lastContactItem as CRMContactHistoryRecord;
        }
    } catch (error) {
        console.error('Error while fetching CRM data', error);
        return undefined;
    }

    return null;
};
