import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

const NotFoundPage = (): JSX.Element => {
    const { t } = useTranslation('home');

    return (
        <>
            <header className={styles.title}>404: {t('not_found')}</header>

            <main>{t('content_not_found')}</main>
        </>
    );
};

export default NotFoundPage;
