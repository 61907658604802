import React from 'react';
import classNames from 'classnames';

import Icon, { type IconName } from 'components/Icon';

import styles from './style.module.scss';

type TagProps = {
    icon?: IconName;
    iconSize?: number;
    text?: string;
    inverted?: boolean;
    disabled?: boolean;
    gradient?: boolean;
    variant?: 'primary' | 'secondary' | 'strong' | 'info';
    hideTextOnMobile?: boolean;
    color?: string;
    bgColor?: string;
};

const Tag: React.FC<TagProps> = ({
    icon,
    iconSize,
    text,
    variant = 'primary',
    inverted = false,
    disabled = false,
    gradient = false,
    hideTextOnMobile = false,
    color,
    bgColor,
}) => {
    const tagClassNames = classNames(styles.container, {
        [styles.inverted]: inverted,
        [styles.disabled]: disabled,
        [styles.gradient]: gradient && !disabled,
        [styles.info]: variant === 'info',
    });
    const tagStyle = {
        color: color,
        backgroundColor: bgColor,
    };
    const textClassNames = classNames(styles.text, {
        [styles['secondary']]: variant === 'secondary',
        [styles['strong']]: variant === 'strong',
        [styles['text-gradient']]: gradient && !disabled,
        [styles['text-info']]: variant === 'info',
    });
    const textStyleClassNames = classNames({
        [styles['hide-text-on-mobile']]: hideTextOnMobile,
    });

    return (
        <div className={tagClassNames} style={tagStyle}>
            <div className={textClassNames}>
                {icon && <Icon name={icon} height={iconSize} width={iconSize} />}
                {text && <span className={textStyleClassNames}>{text}</span>}
            </div>
        </div>
    );
};

export default Tag;
