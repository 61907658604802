import { useTranslation } from 'react-i18next';

import { type ActivityRecord } from 'interfaces/activity.type';
import ActivityDataItem from 'components/ActivityDataItem';
import Button from 'components/Button';
import { useGetActivityNextPage } from 'stores/useActivityStore';

import styles from './style.module.scss';

type ActivityDataListProps = {
    data: ActivityRecord[];
    loading: boolean;
};

export const ActivityDataList: React.FC<ActivityDataListProps> = ({ data, loading = false }) => {
    const { t } = useTranslation('matches');

    const getActivityNextPage = useGetActivityNextPage();

    return (
        <div className={styles.container}>
            {data.length === 0 && (
                <div className={styles['no-data']}>{t('autopilot.no_messages_found')}</div>
            )}
            {data.map((item) => (
                <ActivityDataItem key={item.signal._id} item={item} />
            ))}
            {data.length > 0 && (
                <div className={styles['load-more']}>
                    {loading && (
                        <div className={styles['loading-indicator']}>{t('info.loading')}</div>
                    )}
                    {!loading && (
                        <Button variant='link' onClick={getActivityNextPage} disabled={loading}>
                            {t('info.view_more')}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
};

export default ActivityDataList;
