import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { type Match, type Signal } from 'interfaces/match.type';

import ButtonWithOptions from 'components/ButtonWithOptions';
import CircledTag from 'components/CircledTag';
import Icon, { ICON_NAMES } from 'components/Icon';
import SignalCardContent from 'components/SignalCardContent';
import SignalTypeTag from 'components/SignalTypeTag';
import Typography from 'components/Typography';
import { trackEventSignalOpenLink } from 'services/eventsTracker';
import { getSelectOptionsWithTranslation } from 'services/form';

import DiscardReasonDialog from './DiscardReasonDialog';
import { getSignalDateDistanceAndString, getScoreString } from './utils';

import styles from './style.module.scss';

/* eslint-disable no-unused-vars */
export enum SignalDiscardReasons {
    MessagedElsewhere = 'messaged_elsewhere',
    SignalNotUseful = 'signal_not_useful',
    BadTiming = 'bad_timing',
    Other = 'other',
}
/* eslint-enable no-unused-vars */

const OPTIONS_DISCARD_SIGNAL_REASON: string[] = Object.values(SignalDiscardReasons);

type Props = {
    match: Match;
    signal: Signal;
    disabled?: boolean;
    onDiscard?: (_reason?: SignalDiscardReasons, _customReason?: string) => void;
};

const SignalCard: React.FC<Props> = ({ match, signal, /*disabled = false, */ onDiscard }) => {
    const [showCustomReasonModal, setShowCustomReasonModal] = useState(false);

    const { t } = useTranslation('matches');

    const optionsDiscardSignalReason = useMemo(
        () => getSelectOptionsWithTranslation(t, 'discard_reason', OPTIONS_DISCARD_SIGNAL_REASON),
        [t],
    );

    const classNamesContainer = classNames(styles.container, {
        // do not use disable for now
        // [styles['container-disabled']]: disabled,
    });

    const scoreString = getScoreString(signal.score);
    const signalDate = getSignalDateDistanceAndString(signal);

    const handleClickLink = (link: string): void => {
        if (link) {
            if (match) trackEventSignalOpenLink(match, signal, link);
            window.open(link, '_blank');
        }
    };

    const handleDiscardClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (typeof onDiscard === 'function') {
            onDiscard();
        }
    };

    const handleDiscardOptionClick = (
        _event: React.MouseEvent<HTMLElement>,
        value: string,
    ): void => {
        if (value === SignalDiscardReasons.Other) {
            setShowCustomReasonModal(true);
            return;
        }
        if (typeof onDiscard === 'function') {
            onDiscard(value as SignalDiscardReasons);
        }
    };

    const handleDiscardDialogSubmit = (value: string): void => {
        if (typeof onDiscard === 'function') {
            onDiscard(SignalDiscardReasons.Other, value);
        }
        setShowCustomReasonModal(false);
    };

    const handleDiscardDialogClose = (): void => {
        setShowCustomReasonModal(false);
    };

    return (
        <div className={classNamesContainer}>
            <div className={styles.header}>
                <div className={styles.info}>
                    <SignalTypeTag signal={signal} onClick={handleClickLink} />
                    {scoreString && <CircledTag text={scoreString} />}
                </div>
                <ButtonWithOptions
                    size='small'
                    options={optionsDiscardSignalReason}
                    onClick={handleDiscardClick}
                    onOptionClick={handleDiscardOptionClick}
                >
                    {t('info.discard_signal')}
                </ButtonWithOptions>
                {showCustomReasonModal && (
                    <DiscardReasonDialog
                        open={showCustomReasonModal}
                        onSubmit={handleDiscardDialogSubmit}
                        onClose={handleDiscardDialogClose}
                    />
                )}
            </div>

            <div className={styles.content}>
                <SignalCardContent match={match} signal={signal} />
            </div>

            <div className={styles.footer}>
                {signal.scoringMetaCategory && (
                    <div className={styles.category}>
                        <Icon name={ICON_NAMES.Folder} />
                        <Typography>{signal.scoringMetaCategory}</Typography>
                    </div>
                )}
                <div className={styles['update-time']} title={signalDate.formattedDate}>
                    <Icon name={ICON_NAMES.Calendar} />
                    <Typography>{signalDate.distance}</Typography>
                </div>
            </div>
        </div>
    );
};

export default SignalCard;
