import { intervalToDuration, parseISO } from 'date-fns';

import { type Signal, type Match, SIGNAL_TYPES } from 'interfaces/match.type';
import type { SignalCategory, UserSignalCategory } from 'interfaces/userEx.type';

export const isSignalToBeSentViaAutopilot = (signal: Signal): boolean => {
    return Boolean(signal.sendViaAutopilot) && signal.sendViaAutopilotAt !== undefined;
};

export const isProspectHasAutopilotSignal = (prospect: Match | undefined): boolean => {
    return (
        prospect !== undefined &&
        !prospect.done &&
        Array.isArray(prospect.signals) &&
        prospect.signals.some(isSignalToBeSentViaAutopilot)
    );
};

const formatNumber = (num: number): string => num.toString().padStart(2, '0');

export const getSignalToSendViaAupilot = (signals: Signal[]): Signal | undefined => {
    return signals.find(isSignalToBeSentViaAutopilot);
};

export const calculateSignalAutopilotTimer = (signal: Signal): string | undefined => {
    if (signal.sendViaAutopilotAt === undefined) return;

    const emailWillBeSentAt = parseISO(signal.sendViaAutopilotAt);

    const nowDate = new Date();
    if (nowDate > emailWillBeSentAt) {
        return '0:00:00';
    }

    const interval = intervalToDuration({
        start: nowDate,
        end: emailWillBeSentAt,
    });
    const { hours, minutes, seconds } = interval;
    if (hours === undefined || minutes === undefined || seconds === undefined) {
        return;
    }
    if (hours < 0 || minutes < 0 || seconds < 0) {
        return;
    }
    return `${hours}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
};

export const getSignalTypeLabel = (signal: Signal): string => {
    return signal.type === SIGNAL_TYPES.LinkedinPost
        ? signal.postIsRepost
            ? 'linkedin_repost'
            : signal.type
        : signal.type;
};

export const getSignalCategoryLabel = (
    signalCategories: SignalCategory[],
    language: string,
    userSignalCategory?: UserSignalCategory,
): string => {
    if (!userSignalCategory) return '';
    const signalCategory = signalCategories.find(
        (category) => category._id === userSignalCategory.categoryId,
    );
    if (!signalCategory) return '';
    return signalCategory.name[language] || '';
};
