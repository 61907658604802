import classNames from 'classnames';
import * as Form from '@radix-ui/react-form';

import styles from './style.module.scss';

type Props = {
    id?: string;
    name: string;
    label: string;
    caption?: string;
    value?: string;
    checked?: boolean;
    disabled?: boolean;
    standalone?: boolean;
    className?: string;
    onChange?: (_value: boolean) => void;
};

export const FormFieldCheckbox: React.FC<Props> = ({
    id,
    name,
    label,
    caption,
    value = 'on',
    checked = undefined,
    disabled = false,
    standalone = false,
    className,
    onChange,
}) => {
    const classCheckboxLabel = classNames(styles.label, {
        [styles['label-disabled']]: disabled,
        [styles['label-standalone']]: standalone,
    });
    const classControl = classNames(styles.control, className);
    const classCheckboxCaption = classNames(styles.caption, {
        [styles['label-disabled']]: disabled,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event?.target;
        if (onChange) onChange(checked);
    };

    const checkboxControl = (): JSX.Element => (
        <>
            <Form.Control
                className={styles.input}
                type='checkbox'
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
            />
            <Form.Label htmlFor={id} className={classCheckboxLabel}>
                {label}
            </Form.Label>
        </>
    );

    return (
        <Form.Field id={id} name={name} className={caption ? styles.field : classControl}>
            {caption ? (
                <>
                    <div className={classControl}>{checkboxControl()}</div>
                    <p className={classCheckboxCaption}>{caption}</p>
                </>
            ) : (
                checkboxControl()
            )}
        </Form.Field>
    );
};

export default FormFieldCheckbox;
