import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldSelect, { type FormFieldOption } from 'components/FormFieldSelect';
import FormFieldInput from 'components/FormFieldInput';

import styles from './style.module.scss';

export type FormEmailSettingsFormData = {
    sendColdMessagesVia?: string; // 'honeysales' | 'gmail' | 'salesforce'
    sendWarmMessagesVia?: string; // 'honeysales' | 'gmail' | 'salesforce'
    bccAddress?: string;
};

type FormEmailSettingsProps = {
    optionsSendViaCold: {
        integrationKey: string; // 'honeysales' | 'gmail' | 'salesforce'
        email: string;
    }[];
    optionsSendViaWarm: {
        integrationKey: string; // 'honeysales' | 'gmail' | 'salesforce'
        email: string;
    }[];
    initialValues?: FormEmailSettingsFormData;
    disabled?: boolean;
    onSubmit: (_values: FormEmailSettingsFormData) => void;
};

const FormEmailSettings: React.FC<FormEmailSettingsProps> = ({
    optionsSendViaCold,
    optionsSendViaWarm,
    initialValues = {},
    disabled = false,
    onSubmit,
}) => {
    const [formData, setFormData] = React.useState<FormEmailSettingsFormData>(initialValues);

    const { t } = useTranslation(['forms', 'profile']);

    const optionsEmailColdOptions = useMemo(
        (): FormFieldOption[] =>
            optionsSendViaCold.map<FormFieldOption>(({ integrationKey, email }) => ({
                label: t('email_settings.email_with_integration', {
                    integration: t(`profile:email_integration.${integrationKey}`),
                    email,
                }),
                value: integrationKey,
            })),
        [t, optionsSendViaCold],
    );

    const optionsEmailWarmOptions = useMemo(
        (): FormFieldOption[] =>
            optionsSendViaWarm.map<FormFieldOption>(({ integrationKey, email }) => ({
                label: t('email_settings.email_with_integration', {
                    integration: t(`profile:email_integration.${integrationKey}`),
                    email,
                }),
                value: integrationKey,
            })),
        [t, optionsSendViaWarm],
    );

    useEffect(() => {
        if (initialValues) setFormData(initialValues);
    }, [initialValues]);

    const handleOptionChangeCold = (value: string): void => {
        setFormData((prev) => ({ ...prev, sendColdMessagesVia: value }));
    };
    const handleOptionChangeWarm = (value: string): void => {
        setFormData((prev) => ({ ...prev, sendWarmMessagesVia: value }));
    };
    const handleInputChangeBcc = (name: string, value: string): void => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = Object.fromEntries(new FormData(event.currentTarget));
        if (onSubmit) onSubmit(formData);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <FormFieldSelect
                className={styles.select}
                label={t('email_settings.send_cold_messages_via.label')}
                placeholder={t('email_settings.send_cold_messages_via.placeholder')}
                name='sendColdMessagesVia'
                options={optionsEmailColdOptions}
                value={formData.sendColdMessagesVia}
                disabled={disabled}
                onChange={handleOptionChangeCold}
            />
            <FormFieldSelect
                className={styles.select}
                label={t('email_settings.send_warm_messages_via.label')}
                placeholder={t('email_settings.send_warm_messages_via.placeholder')}
                name='sendWarmMessagesVia'
                options={optionsEmailWarmOptions}
                value={formData.sendWarmMessagesVia}
                disabled={disabled}
                onChange={handleOptionChangeWarm}
            />
            <FormFieldInput
                label={t('email_settings.bcc_address.label')}
                name='bccAddress'
                type='email'
                value={formData.bccAddress}
                helperText={t('email_settings.bcc_address.helper_text')}
                errorMessageTypeMismatch={t('email_settings.error_invalid_email')}
                disabled={disabled}
                onChange={handleInputChangeBcc}
            />
            <div className={styles.submit}>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={disabled}>
                        {t('email_settings.button_save_settings')}
                    </Button>
                </Form.Submit>
            </div>
        </Form.Root>
    );
};

export default FormEmailSettings;
