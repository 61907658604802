import { useTranslation } from 'react-i18next';

import Dialog from 'components/Dialog';
import FormDiscardReason, { type DiscardReasonFormData } from 'forms/FormDiscardReason';
import Typography from 'components/Typography';

import styles from './style.module.scss';

type DiscardReasonDialogProps = {
    open: boolean;
    onSubmit: (_customReason: string) => void;
    onClose?: () => void;
};

const DiscardReasonDialog: React.FC<DiscardReasonDialogProps> = ({ open, onSubmit, onClose }) => {
    const { t } = useTranslation('forms');

    const handleSubmit = async (values: DiscardReasonFormData): Promise<void> => {
        onSubmit(values.customReason);
        onClose?.();
    };

    return (
        <Dialog open={open} classNameContainer={styles.dialog} onClose={onClose}>
            <Typography variant='heading4' className={styles.title}>
                {t('discard_signal.dialog_title')}
            </Typography>
            <FormDiscardReason onSubmit={handleSubmit} onCancel={onClose} />
        </Dialog>
    );
};

export default DiscardReasonDialog;
