import { useState } from 'react';

import FormFieldInput from 'components/FormFieldInput';
import Icon, { ICON_NAMES } from 'components/Icon';

type Props = {
    id?: string;
    name: string;
    label: string;
    placeholder?: string;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    autoComplete?: string;
    variant?: 'default' | 'large';
    onChange?: (_name: string, _value: string) => void;
};

export const FormFieldPasswordInput: React.FC<Props> = ({
    id,
    name,
    label,
    placeholder,
    value,
    required = false,
    disabled = false,
    autoComplete,
    variant = 'default',
    onChange,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPasswordClick = (): void => {
        if (disabled) return;
        setShowPassword(!showPassword);
    };

    const handleChange = (name: string, value: string): void => {
        if (onChange) onChange(name, value);
    };

    return (
        <FormFieldInput
            id={id}
            name={name}
            type={showPassword ? 'text' : 'password'}
            label={label}
            placeholder={placeholder}
            autoComplete={autoComplete}
            required={required}
            disabled={disabled}
            value={value}
            variant={variant}
            endAdornment={
                showPassword ? (
                    <Icon name={ICON_NAMES.Eye} onClick={handleShowPasswordClick} />
                ) : (
                    <Icon name={ICON_NAMES.EyeOff} onClick={handleShowPasswordClick} />
                )
            }
            onChange={handleChange}
        />
    );
};

export default FormFieldPasswordInput;
