import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import FormFieldSelect from 'components/FormFieldSelect';
import Button from 'components/Button';
import FormFieldCheckboxGroup from 'components/FormFieldCheckboxGroup';
import { getSelectOptionsWithTranslation } from 'services/form';

import styles from './style.module.scss';

const OPTIONS_TIME_PERIOD: string[] = [
    'current_quarter',
    'last_quarter',
    'current_year',
    'last_year',
];

const OPTIONS_MESSAGES: string[] = ['sent', 'opened', 'answered', 'scheduled_meeting'];

type Props = {
    disabled?: boolean;
    onUpdate: (_values: Record<string, FormDataEntryValue>) => void;
};

const FormFilterStatistics: React.FC<Props> = ({ disabled = false, onUpdate }) => {
    const { t } = useTranslation('forms');

    const optionsTimePeriod = useMemo(
        () => getSelectOptionsWithTranslation(t, 'time_period', OPTIONS_TIME_PERIOD),
        [t],
    );

    const optionsMessages = useMemo(
        () => getSelectOptionsWithTranslation(t, 'messages', OPTIONS_MESSAGES),
        [t],
    );

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = Object.fromEntries(new FormData(event.currentTarget));
        if (onUpdate) onUpdate(formData);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <FormFieldSelect
                label={t('statistics_filter.time_period')}
                name={'time_period'}
                variant='small'
                options={optionsTimePeriod}
                disabled={disabled}
            />
            <FormFieldCheckboxGroup
                label={t('statistics_filter.messages')}
                name={'messages'}
                options={optionsMessages}
                disabled={disabled}
            />
            <div>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={disabled}>
                        {t('statistics_filter.update')}
                    </Button>
                </Form.Submit>
            </div>
        </Form.Root>
    );
};

export default FormFilterStatistics;
