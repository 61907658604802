import React, { useState, type FormEvent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldCheckbox from 'components/FormFieldCheckbox';
import FormFieldInput from 'components/FormFieldInput';
import Icon, { ICON_NAMES } from 'components/Icon';
import Message from 'components/Message';
import Typography from 'components/Typography';
import { ROUTES } from 'constants/routes/routes';
import { retrieveEmail, storeEmail } from 'services/storage';

import { extractFormData } from './extractFormData';

import styles from './style.module.scss';

export type LoginFormData = {
    email: string;
    password: string;
};

type Props = {
    error?: string;
    disabled?: boolean;
    onSubmit?: (_values: LoginFormData) => void;
};

const FormLogin: React.FC<Props> = ({ error, disabled = false, onSubmit }) => {
    const [showPassword, setShowPassword] = useState(false);
    const savedEmail = useMemo(() => retrieveEmail() || undefined, []);

    const { t } = useTranslation('auth');

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (onSubmit) {
            const formData = new FormData(event.target as HTMLFormElement);
            const data = extractFormData(formData);
            if (formData.get('save_data')) {
                storeEmail(data.email);
            }
            onSubmit(data);
        }
    };

    const handleShowPasswordClick = (): void => {
        if (disabled) return;
        setShowPassword(!showPassword);
    };

    return (
        <div className={styles.container}>
            <Typography className={styles['text-welcome']}>{t('form.text_welcome')}</Typography>
            <div className={styles['text-container']}>
                <Typography inline className={styles['text-login']}>
                    {t('form.text_login')}
                </Typography>
                <Typography inline className={styles['text-to']}>
                    {t('form.text_to')} honeysales
                </Typography>
            </div>
            <Typography variant='bodyRegular1'>{t('form.text_motivation')}</Typography>
            {error && <Message error>{t(error)}</Message>}
            <Form.Root
                className={styles.form}
                onSubmit={handleFormSubmit}
                noValidate // TODO: to remove later
            >
                <div className={styles.fields}>
                    <FormFieldInput
                        id='email'
                        name='email'
                        label={t('form.label_email')}
                        placeholder={t('form.placeholder_email')}
                        autoComplete='username'
                        variant='large'
                        required
                        defaultValue={savedEmail}
                        disabled={disabled}
                    />
                    <FormFieldInput
                        id='current-password'
                        name={'password'}
                        type={showPassword ? 'text' : 'password'}
                        label={t('form.label_password')}
                        placeholder={t('form.placeholder_password')}
                        autoComplete='current-password'
                        variant='large'
                        required
                        disabled={disabled}
                        endAdornment={
                            showPassword ? (
                                <Icon name={ICON_NAMES.Eye} onClick={handleShowPasswordClick} />
                            ) : (
                                <Icon name={ICON_NAMES.EyeOff} onClick={handleShowPasswordClick} />
                            )
                        }
                    />
                </div>

                <div className={styles.extra}>
                    <FormFieldCheckbox
                        name='save_data'
                        label={t('form.label_save_data')}
                        disabled={disabled}
                    />
                    <Link to={ROUTES.FORGOT_PASSWORD}>
                        <Typography variant='bodyRegular2' inline>
                            {t('form.link_forgot_password')}
                        </Typography>
                    </Link>
                </div>

                <div className={styles.submit}>
                    <Form.Submit asChild disabled={disabled}>
                        <Button block size='large'>
                            {t('form.login')}
                        </Button>
                    </Form.Submit>
                </div>
            </Form.Root>
        </div>
    );
};

export default FormLogin;
