import { type Match, type Signal } from 'interfaces/match.type';
import { gtagEvent, gtagPageView, gtagSet } from './gtag';

export type AnalyticsEventParameters = Record<string, string | number | undefined>;

export type AnalyticsEvent = {
    name: string;
    parameters?: AnalyticsEventParameters;
};

export const trackPageView = (): void => {
    gtagPageView(new URL(window.location.toString()));
};

export const trackEvent = (event: AnalyticsEvent): void => {
    gtagEvent(event.name, event.parameters);
};

export const setEventParameters = (eventSettings: AnalyticsEventParameters): void => {
    gtagSet(eventSettings);
};

export const trackEventLogIn = (): void => {
    const event: AnalyticsEvent = {
        name: 'log_in',
    };
    trackEvent(event);
};

export const trackEventLogOut = (): void => {
    const event: AnalyticsEvent = {
        name: 'log_out',
    };
    trackEvent(event);
};

export const trackEventMatchView = (matches: Match[], match: Match): void => {
    const event: AnalyticsEvent = {
        name: 'match_view',
        parameters: {
            prospect_id: match.prospectId || undefined,
            num_signals: match.signals.length,
            match_position: matches.indexOf(match),
            value: match.score || undefined,
        },
    };
    trackEvent(event);
};

export const trackEventSignalView = (matches: Match[], match: Match, signal: Signal): void => {
    const event: AnalyticsEvent = {
        name: 'signal_view',
        parameters: {
            prospect_id: match.prospectId,
            signal_id: signal.id,
            signal_type: signal.type,
            match_position: matches.indexOf(match),
            signal_position: match.signals.indexOf(signal),
            value: signal.score || undefined,
        },
    };
    trackEvent(event);
};

export type SendMessageTrackingInfo = {
    emailSubjectModified?: boolean;
    emailBodyModified?: boolean;
};

export const trackEventSendMessage = (
    matches: Match[],
    match: Match,
    signal: Signal,
    trackingInfo: SendMessageTrackingInfo,
): void => {
    const event: AnalyticsEvent = {
        name: 'send_message',
        parameters: {
            prospect_id: match.prospectId,
            signal_id: signal.id,
            signal_type: signal.type,
            match_position: matches.indexOf(match),
            signal_position: match.signals.indexOf(signal),
            value: signal.score || undefined,
            subject_modified: trackingInfo.emailSubjectModified ? '1' : '0',
            body_modified: trackingInfo.emailBodyModified ? '1' : '0',
        },
    };
    trackEvent(event);
};

export const trackEventSignalOpenLink = (match: Match, signal: Signal, link_url: string): void => {
    const event: AnalyticsEvent = {
        name: 'signal_open_link',
        parameters: {
            prospect_id: match.prospectId,
            signal_id: signal.id,
            signal_type: signal.type,
            link_url,
        },
    };
    trackEvent(event);
};

export const trackEventSignalRead = (
    match: Match,
    signal: Signal,
    action: 'expand' | 'contract',
): void => {
    const event: AnalyticsEvent = {
        name: 'signal_read',
        parameters: {
            prospect_id: match.prospectId,
            signal_id: signal.id,
            signal_type: signal.type,
            action,
        },
    };
    trackEvent(event);
};

export type EventProspectProfileType = 'linkedin' | 'twitter';

export const trackEventOpenProspectProfile = (
    match: Match,
    profileType: EventProspectProfileType,
    linkUrl: string,
): void => {
    const event: AnalyticsEvent = {
        name: 'open_prospect_profile',
        parameters: {
            prospect_id: match.prospectId,
            profile_type: profileType,
            link_url: linkUrl,
        },
    };
    trackEvent(event);
};

export const trackEventSupportFeedback = (): void => {
    const event: AnalyticsEvent = {
        name: 'support_feedback',
    };
    trackEvent(event);
};

export const trackEventLocaleChange = (localeOld: string, localeNew: string): void => {
    const event: AnalyticsEvent = {
        name: 'locale_change',
        parameters: {
            old_locale: localeOld,
            new_locale: localeNew,
        },
    };
    trackEvent(event);
};

export const trackEventProfileInfoChange = (): void => {
    const event: AnalyticsEvent = {
        name: 'profile_info_change',
    };
    trackEvent(event);
};
