import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type {
    UserIndustryPitch,
    UserInfoExFormData,
    UserSignalCategory,
} from 'interfaces/userEx.type';
import FormOfferingAndPitch, {
    type FormOfferingAndPitchFormData,
} from 'forms/FormOfferingAndPitch';
import FormSignalCategories from 'forms/FormSignalCategories';
import FormIndustryPitches from 'forms/FormIndustryPitches';
import Typography from 'components/Typography';

import {
    useLoadingUserEx,
    useSignalCategories,
    useUpdateUserEx,
    useUserEx,
} from 'stores/useBoundStore';

import styles from './style.module.scss';

const ProfileSignalsAndMessages: React.FC = () => {
    const { t } = useTranslation('profile');

    const user = useUserEx();
    const loadingUserEx = useLoadingUserEx();
    const updateUserEx = useUpdateUserEx();
    const signalCategories = useSignalCategories();

    const loading = loadingUserEx;
    const formDisabled = loading;

    const formOfferingAndPitchDefaultValues: FormOfferingAndPitchFormData = useMemo(
        () => ({
            offeringDescription: user?.settings?.pitches?.offeringDescription || '',
            defaultPitch: user?.settings?.pitches?.default || '',
            referenceCase: user?.settings?.pitches?.defaultReferenceCase || '',
        }),
        [user],
    );

    const handleSubmitOfferingAndPitch = async (
        values: FormOfferingAndPitchFormData,
    ): Promise<void> => {
        const { offeringDescription, defaultPitch, referenceCase } = values;
        if (
            typeof offeringDescription === 'string' &&
            typeof defaultPitch === 'string' &&
            typeof referenceCase === 'string'
        ) {
            const formData: UserInfoExFormData = {
                settings: {
                    pitches: {
                        offeringDescription,
                        default: defaultPitch,
                        defaultReferenceCase: referenceCase,
                        byIndustry: user?.settings?.pitches?.byIndustry || [],
                    },
                },
            };
            const success = await updateUserEx(formData);
            if (success) {
                toast.success(t('offering_and_pitch.update_success'));
            }
        }
    };

    const handleSubmitSignalCategory = async (
        userSignalCategory: UserSignalCategory,
    ): Promise<void> => {
        const signalCategoryIndex =
            user?.settings?.signalCategories?.findIndex(
                (category) => category.categoryId === userSignalCategory?.categoryId,
            ) ?? -1;
        const signalCategoriesUpdated = user?.settings?.signalCategories?.concat() || [];

        if (signalCategoryIndex === -1) {
            // category not found in settings - adding new
            signalCategoriesUpdated.push(userSignalCategory);
        } else {
            // category found in settings - updating
            signalCategoriesUpdated[signalCategoryIndex] = userSignalCategory;
        }
        const formData: UserInfoExFormData = {
            settings: {
                signalCategories: [...signalCategoriesUpdated],
            },
        };
        const success = await updateUserEx(formData);
        if (success) {
            toast.success(t('signal_categories.update_success'));
        }
    };

    const handleDisableSignalCategory = async (
        userSignalCategory: UserSignalCategory,
    ): Promise<void> => {
        const signalCategoryIndex =
            user?.settings?.signalCategories?.findIndex(
                (category) => category.categoryId === userSignalCategory?.categoryId,
            ) ?? -1;
        const signalCategoriesUpdated = user?.settings?.signalCategories?.concat() || [];

        if (signalCategoryIndex === -1) {
            // category not found in settings - skipping
        } else {
            // category found in settings - updating
            signalCategoriesUpdated[signalCategoryIndex] = userSignalCategory;
        }
        const formData: UserInfoExFormData = {
            settings: {
                signalCategories: [...signalCategoriesUpdated],
            },
        };
        const success = await updateUserEx(formData);
        if (success) {
            toast.success(t('signal_categories.update_success'));
        }
    };

    const handleSubmitIndustryPitch = async (
        userIndustryPitch: UserIndustryPitch,
    ): Promise<void> => {
        const industryPitchIndex =
            user?.settings?.pitches?.byIndustry?.findIndex(
                (pitch) => pitch.pitchName === userIndustryPitch?.pitchName,
            ) ?? -1;
        const industryPitchesUpdated = user?.settings?.pitches?.byIndustry?.concat() || [];

        if (industryPitchIndex === -1) {
            // pitch not found in settings - adding new
            industryPitchesUpdated.push(userIndustryPitch);
        } else {
            // pitch found in settings - updating
            industryPitchesUpdated[industryPitchIndex] = userIndustryPitch;
        }
        const formData: UserInfoExFormData = {
            settings: {
                pitches: {
                    ...(user?.settings?.pitches || {
                        default: '',
                        defaultReferenceCase: '',
                    }),
                    byIndustry: [...industryPitchesUpdated],
                },
            },
        };
        const success = await updateUserEx(formData);
        if (success) {
            toast.success(t('industry_pitches.update_success'));
        }
    };

    const handleDeleteIndustryPitch = async (
        userIndustryPitch: UserIndustryPitch,
    ): Promise<void> => {
        const industryPitchesUpdated =
            user?.settings?.pitches?.byIndustry?.filter(
                (pitch) => pitch.pitchName !== userIndustryPitch?.pitchName,
            ) || [];

        const formData: UserInfoExFormData = {
            settings: {
                pitches: {
                    ...(user?.settings?.pitches || {
                        default: '',
                        defaultReferenceCase: '',
                    }),
                    byIndustry: [...industryPitchesUpdated],
                },
            },
        };
        const success = await updateUserEx(formData);
        if (success) {
            toast.success(t('industry_pitches.update_success'));
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <Typography variant='heading3'>{t('offering_and_pitch.title')}</Typography>
                <FormOfferingAndPitch
                    showEnabled
                    initialValues={formOfferingAndPitchDefaultValues}
                    disabled={formDisabled}
                    onSubmit={handleSubmitOfferingAndPitch}
                />
            </div>
            <div className={styles.card}>
                <Typography variant='heading3'>{t('signal_categories.title')}</Typography>
                <FormSignalCategories
                    signalCategories={signalCategories}
                    userSignalCategories={user?.settings?.signalCategories}
                    disabled={formDisabled}
                    onSubmitCategory={handleSubmitSignalCategory}
                    onDisableCategory={handleDisableSignalCategory}
                />
            </div>
            <div className={styles.card}>
                <Typography variant='heading3'>{t('industry_pitches.title')}</Typography>
                <FormIndustryPitches
                    userIndustryPitches={user?.settings?.pitches?.byIndustry}
                    disabled={formDisabled}
                    onSubmitIndustryPitch={handleSubmitIndustryPitch}
                    onDeleteIndustryPitch={handleDeleteIndustryPitch}
                />
            </div>
        </div>
    );
};
export default ProfileSignalsAndMessages;
