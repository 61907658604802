import { useEffect } from 'react';

import ActivityDataList from 'components/ActivityDataList';
import FormFilterActivity, { type FormFilterActivityFormData } from 'forms/FormFilterActivity';

import { useActivityData, useActivityLoading, useGetActivity } from 'stores/useActivityStore';

import styles from './style.module.scss';

const defaultValues: FormFilterActivityFormData = {
    timeRange: 'ThirtyDays',
    contactStatus: 'all',
    source: 'all',
    signalType: 'all',
};

export type ActivityPageContentProps = {
    onUpdate: () => void;
};

export const ActivityPageContent: React.FC<ActivityPageContentProps> = ({ onUpdate }) => {
    const data = useActivityData();
    const loading = useActivityLoading();
    const getActivity = useGetActivity();

    useEffect(() => {
        getActivity(defaultValues);
    }, []);

    const handleFilterUpdate = async (values: FormFilterActivityFormData): Promise<void> => {
        const filterValues: FormFilterActivityFormData = {
            timeRange: values.timeRange ?? defaultValues.timeRange,
            contactStatus: values.contactStatus ?? defaultValues.contactStatus,
            source: values.source ?? defaultValues.source,
            signalType: values.signalType ?? defaultValues.signalType,
        };
        await getActivity(filterValues);
        onUpdate();
    };

    return (
        <div className={styles.content}>
            <div className={styles.filter}>
                <FormFilterActivity
                    initialValues={defaultValues}
                    onUpdate={handleFilterUpdate}
                    loading={loading}
                />
            </div>
            <ActivityDataList data={data} loading={loading} />
        </div>
    );
};

export default ActivityPageContent;
