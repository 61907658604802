import React from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

type Props = {
    height: number;
    width: number;
    rounded?: boolean;
    inverted?: boolean;
};

const Skeleton: React.FC<Props> = ({ height, width, rounded = false, inverted = false }) => {
    const containerClassNames = classNames(styles.container, {
        [styles.inverted]: inverted,
        [styles.rounded]: rounded,
    });
    return <div className={containerClassNames} style={{ width, height }} />;
};

export default Skeleton;
