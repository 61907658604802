import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './style.module.scss';

export type InformationDialogProps = {
    open?: boolean;
    title?: string;
    content: React.ReactNode;
    labelConfirm?: string;
    confirmVariant?: 'primary' | 'primary-danger';
    className?: string;
    onClose?: () => void;
};

export const InformationDialog: React.FC<InformationDialogProps> = ({
    open,
    title,
    content,
    labelConfirm,
    confirmVariant,
    className,
    onClose,
}) => {
    const { t } = useTranslation('forms');

    const classNameDialog = classNames(styles.dialog, className);

    return (
        <Dialog
            open={open}
            className={classNameDialog}
            classNameContainer={styles['dialog-container']}
            onClose={onClose}
        >
            <div className={styles.content}>
                {title && <Typography variant='heading4'>{title}</Typography>}
                {content}
            </div>
            <div className={styles.action}>
                <Button variant={confirmVariant || 'primary'} autoFocus onClick={onClose}>
                    {labelConfirm || t('information.ok')}
                </Button>
            </div>
        </Dialog>
    );
};

export default InformationDialog;
