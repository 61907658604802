import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import { SIGNAL_TYPES_FILTER } from 'interfaces/match.type';
import Button from 'components/Button';
import FormFieldSelect from 'components/FormFieldSelect';
// import FormFieldCheckboxGroup from 'components/FormFieldCheckboxGroup';
import Loading from 'components/Loading';

import { getSelectOptionsWithTranslation } from 'services/form';
import { PROSPECT_CONTACT_STATUS, PROSPECT_SOURCE } from 'services/honeycomb';

import styles from './style.module.scss';

const OPTIONS_TIME_RANGE = ['ThirtyDays', 'NinetyDays', 'HalfYear', 'Year'] as const;
type OptionTimeRange = (typeof OPTIONS_TIME_RANGE)[number];

const OPTIONS_SIGNAL_TYPE = ['all', ...Object.values(SIGNAL_TYPES_FILTER)] as const;
type OptionSignalType = (typeof OPTIONS_SIGNAL_TYPE)[number];

const OPTIONS_PROSPECT_SOURCE = ['all', ...Object.values(PROSPECT_SOURCE)] as const;
type OptionProspectSource = (typeof OPTIONS_PROSPECT_SOURCE)[number];

const OPTIONS_PROSPECT_CONTACT_STATUS = ['all', ...Object.values(PROSPECT_CONTACT_STATUS)] as const;
type OptionProspectContactStatus = (typeof OPTIONS_PROSPECT_CONTACT_STATUS)[number];
// const OPTIONS_MESSAGE_STATUS: string[] = ['sent', 'bounced', 'opened', 'replied'];

export type FormFilterActivityFormData = {
    timeRange?: OptionTimeRange;
    contactStatus?: OptionProspectContactStatus;
    source?: OptionProspectSource;
    signalType?: OptionSignalType;
};

type FormFilterActivityProps = {
    initialValues?: FormFilterActivityFormData;
    disabled?: boolean;
    loading?: boolean;
    onUpdate: (_values: FormFilterActivityFormData) => void;
};

const FormFilterActivity: React.FC<FormFilterActivityProps> = ({
    initialValues = {},
    disabled = false,
    loading = false,
    onUpdate,
}) => {
    const [formData, setFormData] = React.useState<FormFilterActivityFormData>(initialValues);

    const { t } = useTranslation('forms');

    const formDisabled = disabled || loading;

    const optionsTimeRange = useMemo(
        () => getSelectOptionsWithTranslation(t, 'time_range', OPTIONS_TIME_RANGE),
        [t],
    );

    const optionsProspectSource = useMemo(
        () =>
            getSelectOptionsWithTranslation(t, 'matches:prospect.source', OPTIONS_PROSPECT_SOURCE),
        [t, PROSPECT_SOURCE],
    );

    const optionsProspectContactStatus = useMemo(
        () =>
            getSelectOptionsWithTranslation(
                t,
                'matches:prospect.contact_status',
                OPTIONS_PROSPECT_CONTACT_STATUS,
            ),
        [t, PROSPECT_CONTACT_STATUS],
    );

    const optionsSignalType = useMemo(
        () => getSelectOptionsWithTranslation(t, 'matches:signal', OPTIONS_SIGNAL_TYPE),
        [t],
    );

    // const optionsMessageStatus = useMemo(
    //     () => getSelectOptionsWithTranslation(t, 'messages', OPTIONS_MESSAGE_STATUS),
    //     [t],
    // );

    useEffect(() => {
        if (initialValues) setFormData(initialValues);
    }, [initialValues]);

    const handleChangeTimeRange = (value: string): void => {
        setFormData((prev) => ({ ...prev, timeRange: value as OptionTimeRange }));
    };

    const handleChangeContactStatus = (value: string): void => {
        setFormData((prev) => ({ ...prev, contactStatus: value as OptionProspectContactStatus }));
    };

    const handleChangeSource = (value: string): void => {
        setFormData((prev) => ({ ...prev, source: value as OptionProspectSource }));
    };

    const handleChangeSignalType = (value: string): void => {
        setFormData((prev) => ({ ...prev, signalType: value as OptionSignalType }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = Object.fromEntries(new FormData(event.currentTarget));
        if (onUpdate) onUpdate(formData);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <FormFieldSelect
                label={t('activity_filter.time_range')}
                name='timeRange'
                options={optionsTimeRange}
                value={formData.timeRange}
                disabled={formDisabled}
                onChange={handleChangeTimeRange}
            />
            <FormFieldSelect
                className={styles.select}
                label={t('matches:prospect.contact_status.title')}
                name='contactStatus'
                options={optionsProspectContactStatus}
                value={formData.contactStatus}
                disabled={formDisabled}
                onChange={handleChangeContactStatus}
            />
            <FormFieldSelect
                className={styles.select}
                label={t('matches:prospect.source.title')}
                name='source'
                options={optionsProspectSource}
                value={formData.source}
                disabled={formDisabled}
                onChange={handleChangeSource}
            />
            <FormFieldSelect
                label={t('activity_filter.signal_type')}
                name='signalType'
                options={optionsSignalType}
                value={formData.signalType}
                disabled={formDisabled}
                onChange={handleChangeSignalType}
            />
            {/* <FormFieldCheckboxGroup
                label={t('activity_filter.message_status')}
                name='messageStatus'
                options={optionsMessages}
                disabled={formDisabled}
            /> */}
            <div>
                <Form.Submit asChild>
                    <div className={styles.submit}>
                        <Button variant='primary' disabled={formDisabled}>
                            {t('activity_filter.update')}
                        </Button>
                        {loading && <Loading size={32} />}
                    </div>
                </Form.Submit>
            </div>
        </Form.Root>
    );
};

export default FormFilterActivity;
