import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import classNames from 'classnames';

import type { CompanyNewsSignal, Match } from 'interfaces/match.type';

import Icon, { ICON_NAMES } from 'components/Icon';
import SignalCardImage from 'components/SignalCardImage';
import Typography from 'components/Typography';
import { trackEventSignalOpenLink, trackEventSignalRead } from 'services/eventsTracker';

import styles from './style.module.scss';

type Props = {
    match: Match;
    signal: CompanyNewsSignal;
    canExpand?: boolean;
};

const LinkRenderer: React.FC<
    React.ClassAttributes<HTMLAnchorElement> & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = (props) => {
    return (
        <a href={props.href} target='_blank' rel='noreferrer'>
            {props.children}
        </a>
    );
};

const SignalContentCompanyNews: React.FC<Props> = ({ match, signal, canExpand = true }) => {
    const { t } = useTranslation('matches');

    const [expanded, setExpanded] = useState(false);

    const contentClasses = classNames(styles.content, {
        [styles.expanded]: expanded || canExpand === false,
    });
    const overlayClasses = classNames(styles.overlay, {
        [styles['overlay-expanded']]: expanded,
    });

    const handleOpenClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();
        if (signal.newsUrl) {
            trackEventSignalOpenLink(match, signal, signal.newsUrl);
            window.open(signal.newsUrl, '_blank');
        }
    };

    const handleExpandClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();
        setExpanded(!expanded);
        trackEventSignalRead(match, signal, expanded ? 'contract' : 'expand');
    };

    return (
        <div className={styles.container}>
            <SignalCardImage
                imageSrc={signal.newsImageUrl}
                imageAlt={t('info.alt_news')}
                linkUrl={signal.newsUrl}
                linkText={t('info.open_news')}
                onClick={handleOpenClick}
            />

            <div className={contentClasses}>
                {signal.newsHeadline && (
                    <Typography variant='bodyBold2'>{signal.newsHeadline}</Typography>
                )}
                {signal.newsSource && (
                    <Typography variant='bodyRegular3' className={styles.source}>
                        {signal.newsSource}
                    </Typography>
                )}
                {signal.newsContent && (
                    <pre>
                        {/* @ts-ignore */}
                        <Markdown components={{ a: LinkRenderer }}>{signal.newsContent}</Markdown>
                    </pre>
                )}
                {canExpand && (
                    <div
                        className={overlayClasses}
                        onClick={handleExpandClick}
                        title={expanded ? t('info.show_less') : t('info.show_more')}
                    >
                        {expanded ? (
                            <Icon name={ICON_NAMES.ChevronUp} />
                        ) : (
                            <Icon name={ICON_NAMES.ChevronDown} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignalContentCompanyNews;
