import { type IntegrationAppClient } from '@integration-app/sdk';

import {
    type IARequestCreateContact,
    type IAResponseContantDetails,
    executeAction,
} from 'services/integrationApp';
import { type SignalEmailPayload } from 'stores/matchSlice';

export const findOrCreateContact = async (
    integrationAppClient: IntegrationAppClient,
    payload: SignalEmailPayload,
): Promise<IAResponseContantDetails | undefined> => {
    const findContactResponse = await executeAction<{ email: string }, IAResponseContantDetails>(
        integrationAppClient,
        'find-contact-by-email',
        'salesforce',
        { email: payload.email.address },
    );

    if (findContactResponse) {
        // contact found, return data
        return findContactResponse;
    }

    const findLeadResponse = await executeAction<{ email: string }, IAResponseContantDetails>(
        integrationAppClient,
        'find-lead-by-email',
        'salesforce',
        { email: payload.email.address },
    );

    if (findLeadResponse) {
        // lead found, return data
        return findLeadResponse;
    }

    const createContactResponse = await executeAction<
        IARequestCreateContact,
        IAResponseContantDetails
    >(integrationAppClient, 'create-contact-sf', 'salesforce', {
        firstName: payload.email.firstName,
        lastName: payload.email.lastName,
        email: payload.email.address,
    });

    return createContactResponse;
};
