import findLast from 'lodash.findlast';

import type { Match } from 'interfaces/match.type';

export const findNextMatch = (matches: Match[], prospectId: string): Match | undefined => {
    const matchIndex = matches.findIndex((match) => match.prospectId === prospectId);
    if (matchIndex === -1) return;
    if (matchIndex === matches.length - 1) return;
    // find next match starting from matchIndex that has !done
    return matches.find((match, index) => index > matchIndex && match.done !== true);
};

export type NextMatchSignal = {
    prospectId: string;
    signalId: string;
};

export const findNextMatchSignal = (
    matches: Match[],
    prospectId: string,
    signalId: string,
): NextMatchSignal | undefined => {
    const matchIndex = matches.findIndex((match) => match.prospectId === prospectId);
    if (matchIndex === -1) return undefined;

    const match = matches[matchIndex];

    // if only one signal - move to the next match
    if (match.signals.length === 1) {
        if (matches.length === 1) return undefined;

        // find next match starting from matchIndex that is !done
        let nextMatch = matches.find((match, index) => index > matchIndex && match.done !== true);
        if (!nextMatch) {
            // if not found (was the last one)
            // find previous match starting from matchIndex that is !done
            nextMatch = findLast(
                matches,
                (match, index) => index < matchIndex && match.done !== true,
            );
            if (!nextMatch) return undefined;
        }
        if (!nextMatch?.signals.length) return undefined;

        return {
            prospectId: nextMatch.prospectId,
            signalId: nextMatch.signals[0].id,
        };
    }

    // find next signal
    const signalIndex = match.signals.findIndex((signal) => signal.id === signalId);
    if (signalIndex === -1) return undefined;

    let nextSignalIndex;
    if (signalIndex < match.signals.length - 1) {
        nextSignalIndex = signalIndex + 1;
    } else if (signalIndex > 0) {
        nextSignalIndex = signalIndex - 1;
    } else {
        return undefined;
    }

    return {
        prospectId: match.prospectId,
        signalId: match.signals[nextSignalIndex].id,
    };
};
