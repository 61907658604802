import classNames from 'classnames';
import { type MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type ActivityRecord } from 'interfaces/activity.type';
import { mapNimbusSentSignalWithAllInfoToProspect } from 'services/mapper';

import Icon, { ICON_NAMES } from 'components/Icon';
import { getInitials } from 'components/MatchCard/utils';
import ProspectAvatar from 'components/ProspectAvatar';
import { formatISODateAsString, getSignalDateDistanceAndString } from 'components/SignalCard/utils';
import SignalCardContent from 'components/SignalCardContent';
import Tag from 'components/Tag';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import { getSignalTypeLabel } from 'services/signal';

import styles from './style.module.scss';

type ActivityDataItemProps = {
    item: ActivityRecord;
};

export const ActivityDataItem: React.FC<ActivityDataItemProps> = ({ item }) => {
    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation('matches');

    const classNamesBody = classNames(styles.body, {
        [styles['body-expanded']]: expanded,
    });

    const prospect = mapNimbusSentSignalWithAllInfoToProspect(item);
    const signal = prospect.signals[0];
    const signalDate = signal ? getSignalDateDistanceAndString(signal) : undefined;
    const signalTypeLabel = signal ? getSignalTypeLabel(signal) : undefined;
    const message = signal.emailMessage || '';

    const handleItemClick = (): void => {
        setExpanded(!expanded);
    };

    const handleOpenLinkedInPost = (event: MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation();
        if (item.linkedInPost?.url) {
            window.open(item.linkedInPost?.url, '_blank');
        }
    };

    return (
        <div className={styles.item} onClick={handleItemClick}>
            <div className={styles.date}>
                <Typography variant='bodyBold2' inline>
                    {item.signal?.email?.sentAt
                        ? formatISODateAsString(item.signal?.email?.sentAt, 'PP')
                        : 'n/a'}
                </Typography>
                {item.signal?.email?.sentAt && (
                    <Typography variant='bodyRegular2' inline>
                        {formatISODateAsString(item.signal?.email?.sentAt, 'pp')}
                    </Typography>
                )}
            </div>
            <div className={styles['prospect-container']}>
                <div className={styles.prospect}>
                    <ProspectAvatar
                        variant='small'
                        contactStatus={item.signal.userProspect?.contactStatus}
                        source={item.signal.userProspect?.source}
                        src={item.prospect.linkedin.profilePictureUrl}
                        showTooltip={false}
                        fallback={getInitials(item.prospect.firstName, item.prospect.lastName)}
                    />
                    <div className={styles['prospect-info']}>
                        <Typography variant='bodyBold2'>
                            {item.prospect.firstName} {item.prospect.lastName}
                        </Typography>
                        <Typography variant='bodyRegular2' className={styles['company-name']}>
                            {item.signal.userProspect?.jobTitle} {t('match.role_at')}{' '}
                            {item.company.name}
                        </Typography>
                    </div>
                    <div
                        className={styles['signal-type']}
                        onClick={(): void => {
                            item.linkedInPost?.url && window.open(item.linkedInPost?.url, '_blank');
                        }}
                    >
                        <Tooltip content={t('autopilot.opens_in_new_tab')}>
                            <Typography variant='bodyBold2' inline>
                                {t(`signal.${signalTypeLabel}`)}
                            </Typography>
                        </Tooltip>
                    </div>
                </div>
                {expanded && signal && (
                    <>
                        <div className={styles.signal}>
                            <SignalCardContent match={prospect} signal={signal} canExpand={false} />
                        </div>
                        <div className={styles['signal-footer']}>
                            <div
                                className={styles['update-time']}
                                title={signalDate?.formattedDate}
                            >
                                <Icon name={ICON_NAMES.Calendar} />
                                <Typography>{signalDate?.formattedDate}</Typography>
                            </div>
                            {item.linkedInPost?.url && (
                                <div className={styles.link} onClick={handleOpenLinkedInPost}>
                                    <Typography variant='bodyRegular2' inline>
                                        {t('autopilot.open', {
                                            name: t(`signal.${signalTypeLabel}`),
                                        })}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={styles.message}>
                <Typography variant='bodyBold2' className={styles.subject}>
                    {item.signal.email?.subject}
                </Typography>
                <Typography variant='bodyRegular2' className={classNamesBody}>
                    {message}
                </Typography>
            </div>
            <div className={styles.info}>
                {item.signal.email?.isQueued && (
                    <Tooltip content={t('info.queued')}>
                        <Tag
                            icon={ICON_NAMES.Clock}
                            variant='info'
                            color='#4069ff'
                            bgColor='#d9e1ff'
                            iconSize={14}
                        />
                    </Tooltip>
                )}
                {item.signal.email?.sentViaAutopilot && (
                    <Tooltip content={t('autopilot.sent_with_autopilot')}>
                        <Tag icon={ICON_NAMES.Autopilot} variant='info' iconSize={14} />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default ActivityDataItem;
