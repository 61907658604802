import React from 'react';

import type { ObjectValues } from 'interfaces/types';

import { ReactComponent as SvgIconCompany } from 'assets/icons/company.svg';
import { ReactComponent as SvgIconLocation } from 'assets/icons/location.svg';
import { ReactComponent as SvgIconLinkedIn } from 'assets/icons/linkedin.svg';
import { ReactComponent as SvgIconBirthday } from 'assets/icons/birthday.svg';
import { ReactComponent as SvgIconJobChange } from 'assets/icons/jobchange.svg';
import { ReactComponent as SvgIconCalendar } from 'assets/icons/calendar.svg';
import { ReactComponent as SvgIconChevronUp } from 'assets/icons/chevron-up.svg';
import { ReactComponent as SvgIconChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as SvgIconChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as SvgIconChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as SvgIconLink } from 'assets/icons/link.svg';
import { ReactComponent as SvgIconUser } from 'assets/icons/user.svg';
import { ReactComponent as SvgIconExit } from 'assets/icons/exit.svg';
import { ReactComponent as SvgIconParty } from 'assets/icons/party.svg';
import { ReactComponent as SvgIconLanguage } from 'assets/icons/language.svg';
import { ReactComponent as SvgIconCheckCircle } from 'assets/icons/check-circle.svg';
import { ReactComponent as SvgIconXCircle } from 'assets/icons/x-circle.svg';
import { ReactComponent as SvgIconAlertCircle } from 'assets/icons/alert-circle.svg';
import { ReactComponent as SvgIconEye } from 'assets/icons/eye.svg';
import { ReactComponent as SvgIconEyeOff } from 'assets/icons/eye-off.svg';
import { ReactComponent as SvgIconUsers } from 'assets/icons/users.svg';
import { ReactComponent as SvgIconPhone } from 'assets/icons/phone.svg';
import { ReactComponent as SvgIconMail } from 'assets/icons/mail.svg';
import { ReactComponent as SvgIconShare } from 'assets/icons/share.svg';
import { ReactComponent as SvgIconQuestion } from 'assets/icons/question.svg';
import { ReactComponent as SvgIconHubspot } from 'assets/icons/hubspot.svg';
import { ReactComponent as SvgIconTrash } from 'assets/icons/trash.svg';
import { ReactComponent as SvgIconCRM } from 'assets/icons/crm.svg';
import { ReactComponent as SvgIconHoneysales } from 'assets/icons/honeysales.svg';
import { ReactComponent as SvgIconAutopilot } from 'assets/icons/autopilot.svg';
import { ReactComponent as SvgIconCheck } from 'assets/icons/check2.svg';
import { ReactComponent as SvgIconX } from 'assets/icons/x.svg';
import { ReactComponent as SvgIconPencil } from 'assets/icons/pencil.svg';
import { ReactComponent as SvgIconPlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as SvgIconLightbulb } from 'assets/icons/lightbulb.svg';
import { ReactComponent as SvgIconLightbulb2 } from 'assets/icons/lightbulb2.svg';
import { ReactComponent as SvgIconFolder } from 'assets/icons/folder.svg';
import { ReactComponent as SvgIconBell } from 'assets/icons/bell.svg';
import { ReactComponent as SvgIconMore } from 'assets/icons/more.svg';
import { ReactComponent as SvgIconClock } from 'assets/icons/clock.svg';

export const ICON_NAMES = {
    Company: 'company',
    Location: 'location',
    Calendar: 'calendar',
    Link: 'link',
    ChevronUp: 'chevron-up',
    ChevronDown: 'chevron-down',
    ChevronRight: 'chevron-right',
    ChevronLeft: 'chevron-left',
    LinkedIn: 'linkedin',
    Birthday: 'birthday',
    Party: 'party',
    JobChange: 'jobchange',
    User: 'user',
    Exit: 'exit',
    Language: 'language',
    CheckCircle: 'check-circle',
    XCircle: 'x-circle',
    AlertCircle: 'alert-circle',
    Eye: 'eye',
    EyeOff: 'eye-off',
    Question: 'question',
    Users: 'users',
    Mail: 'mail',
    Phone: 'phone',
    Share: 'share',
    HubSpot: 'hubspot',
    Trash: 'trash',
    CRM: 'crm',
    Honeysales: 'honeysales',
    Autopilot: 'autopilot',
    Check: 'check',
    X: 'x',
    Pencil: 'pencil',
    PlusCircle: 'plus-circle',
    Lightbulb: 'lightbulb',
    Lightbulb2: 'lightbulb2',
    Folder: 'folder',
    Bell: 'bell',
    More: 'more',
    Clock: 'clock',
} as const;

export type IconName = ObjectValues<typeof ICON_NAMES>;

type IconProps = {
    name: IconName;
    height?: number | string | undefined;
    width?: number | string | undefined;
    className?: string;
    onClick?: (_event: React.MouseEvent<SVGSVGElement>) => void;
};

const Icon: React.FC<IconProps> = ({ name, height, width, className, onClick }) => {
    const svgProps: React.SVGProps<SVGSVGElement> & {
        onClick?: (_event: React.MouseEvent<SVGSVGElement>) => void;
    } = {
        onClick,
    };
    if (className) svgProps['className'] = className;
    if (height) svgProps['height'] = height;
    if (width) svgProps['width'] = width;

    switch (name) {
        case ICON_NAMES.Company:
            return <SvgIconCompany {...svgProps} />;
        case ICON_NAMES.Location:
            return <SvgIconLocation {...svgProps} />;
        case ICON_NAMES.LinkedIn:
            return <SvgIconLinkedIn {...svgProps} />;
        case ICON_NAMES.Birthday:
            return <SvgIconBirthday {...svgProps} />;
        case ICON_NAMES.JobChange:
            return <SvgIconJobChange {...svgProps} />;
        case ICON_NAMES.Calendar:
            return <SvgIconCalendar {...svgProps} />;
        case ICON_NAMES.ChevronUp:
            return <SvgIconChevronUp {...svgProps} />;
        case ICON_NAMES.ChevronDown:
            return <SvgIconChevronDown {...svgProps} />;
        case ICON_NAMES.ChevronRight:
            return <SvgIconChevronRight {...svgProps} />;
        case ICON_NAMES.ChevronLeft:
            return <SvgIconChevronLeft {...svgProps} />;
        case ICON_NAMES.Link:
            return <SvgIconLink {...svgProps} />;
        case ICON_NAMES.Party:
            return <SvgIconParty {...svgProps} />;
        case ICON_NAMES.User:
            return <SvgIconUser {...svgProps} />;
        case ICON_NAMES.Exit:
            return <SvgIconExit {...svgProps} />;
        case ICON_NAMES.Language:
            return <SvgIconLanguage {...svgProps} />;
        case ICON_NAMES.CheckCircle:
            return <SvgIconCheckCircle {...svgProps} />;
        case ICON_NAMES.XCircle:
            return <SvgIconXCircle {...svgProps} />;
        case ICON_NAMES.AlertCircle:
            return <SvgIconAlertCircle {...svgProps} />;
        case ICON_NAMES.Eye:
            return <SvgIconEye {...svgProps} />;
        case ICON_NAMES.EyeOff:
            return <SvgIconEyeOff {...svgProps} />;
        case ICON_NAMES.Users:
            return <SvgIconUsers {...svgProps} />;
        case ICON_NAMES.Phone:
            return <SvgIconPhone {...svgProps} />;
        case ICON_NAMES.Mail:
            return <SvgIconMail {...svgProps} />;
        case ICON_NAMES.Share:
            return <SvgIconShare {...svgProps} />;
        case ICON_NAMES.Question:
            return <SvgIconQuestion {...svgProps} />;
        case ICON_NAMES.HubSpot:
            return <SvgIconHubspot {...svgProps} />;
        case ICON_NAMES.Trash:
            return <SvgIconTrash {...svgProps} />;
        case ICON_NAMES.CRM:
            return <SvgIconCRM {...svgProps} />;
        case ICON_NAMES.Honeysales:
            return <SvgIconHoneysales {...svgProps} />;
        case ICON_NAMES.Autopilot:
            return <SvgIconAutopilot {...svgProps} />;
        case ICON_NAMES.Check:
            return <SvgIconCheck {...svgProps} />;
        case ICON_NAMES.X:
            return <SvgIconX {...svgProps} />;
        case ICON_NAMES.Pencil:
            return <SvgIconPencil {...svgProps} />;
        case ICON_NAMES.PlusCircle:
            return <SvgIconPlusCircle {...svgProps} />;
        case ICON_NAMES.Lightbulb:
            return <SvgIconLightbulb {...svgProps} />;
        case ICON_NAMES.Lightbulb2:
            return <SvgIconLightbulb2 {...svgProps} />;
        case ICON_NAMES.Folder:
            return <SvgIconFolder {...svgProps} />;
        case ICON_NAMES.Bell:
            return <SvgIconBell {...svgProps} />;
        case ICON_NAMES.More:
            return <SvgIconMore {...svgProps} />;
        case ICON_NAMES.Clock:
            return <SvgIconClock {...svgProps} />;
        default:
            return null;
    }
};

export default Icon;
