import type { StateCreator } from 'zustand';

import { type ActivityRecord } from 'interfaces/activity.type';
import { type FormFilterActivityFormData } from 'forms/FormFilterActivity';
import * as nimbusService from 'services/nimbus';
import { handleAPIError } from 'services/errorHandler';

import { getMergedActivityRecords } from './getMergedRecords';

export type ActivitySlice = {
    loading: boolean;
    error: string | undefined;
    data: ActivityRecord[];
    currentPage: number;
    currentFilter: FormFilterActivityFormData;

    getActivity: (_filter: FormFilterActivityFormData) => Promise<void>;
    getActivityNextPage: () => Promise<void>;
};

export const createActivitySlice: StateCreator<ActivitySlice> = (set, get) => ({
    loading: false,
    error: undefined,
    data: [],
    currentPage: 0,
    currentFilter: {},

    getActivity: async (filter: FormFilterActivityFormData): Promise<void> => {
        set((_state) => ({ loading: true, error: undefined }));
        try {
            set((_state) => ({ currentFilter: filter, currentPage: 0 }));

            const records = await nimbusService.fetchSentEmails(filter, 0);
            if (records) {
                set((_state) => ({ data: records }));
            }
        } catch (error: unknown) {
            set((_state) => ({ error: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loading: false }));
        }
    },

    getActivityNextPage: async (): Promise<void> => {
        set((_state) => ({ loading: true, error: undefined }));
        try {
            const filter = get().currentFilter;
            const nextPage = get().currentPage + 1;
            const records = await nimbusService.fetchSentEmails(filter, nextPage);
            if (records) {
                const hasMore =
                    records.length === nimbusService.NUMBER_OF_ACTIVITY_RECORDS_PER_PAGE;
                const mergedRecords = getMergedActivityRecords(get().data, records);
                set((_state) => ({
                    data: mergedRecords,
                    currentPage: hasMore ? nextPage : _state.currentPage,
                }));
            }
        } catch (error: unknown) {
            set((_state) => ({ error: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loading: false }));
        }
    },
});
