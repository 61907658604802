import type { ObjectValues } from './types';

export const PROFILE_TABS = {
    PersonalInformation: 'personal-information',
    SignalsAndMessages: 'signals-and-messages',
    Integrations: 'integrations',
    EmailSettings: 'email',
    Notifications: 'notifications',
} as const;

export type ProfileTab = ObjectValues<typeof PROFILE_TABS>;
