import { AxiosError } from 'axios';

import env from '../env';
import createApiClient from './api';

type ErrorResponseData = {
    data: unknown;
    error: {
        status: number;
        name: string;
        message: string;
        details: unknown;
    };
};

export class AuthAPIError extends Error {
    code?: number;
    status?: string;
    data?: ErrorResponseData;
    constructor(message?: string, code?: number, status?: string, data?: ErrorResponseData) {
        super(message);
        this.name = 'AuthError';
        this.code = code;
        this.status = status;
        this.data = data;
    }
}

export const handleAuthError = (error: unknown): unknown => {
    if (error instanceof AxiosError) {
        if (error.request) console.error(error.request);
        if (error.response) console.error(error.response.data);
        return new AuthAPIError(
            `${error.response?.status}: ${error.response?.statusText}`,
            error.response?.status,
            error.response?.statusText,
            error.response?.data,
        );
    } else {
        return error;
    }
};

const authApi = createApiClient(
    {
        baseURL: env.BASE_URL,
    },
    handleAuthError,
);

export default authApi;
