import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import * as NavigationMenuRadix from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';

import styles from './style.module.scss';

export type NavItem = {
    title: string;
    path: string;
};

type Props = {
    items: NavItem[];
};

const NavigationMenu: React.FC<Props> = ({ items }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { t } = useTranslation('matches');

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>, item: NavItem): void => {
        event.preventDefault();
        navigate(item.path);
    };

    return (
        <NavigationMenuRadix.Root className={styles.nav}>
            <NavigationMenuRadix.List className={styles['nav-list']}>
                {items.map((item) => (
                    <NavigationMenuRadix.Item key={item.path}>
                        <NavigationMenuRadix.Link
                            className={classNames(styles['nav-link'], {
                                [styles.active]: location.pathname.startsWith(item.path),
                            })}
                            onClick={(event: React.MouseEvent<HTMLAnchorElement>): void =>
                                handleLinkClick(event, item)
                            }
                            href={item.path}
                        >
                            {t(`header.${item.title}`)}
                        </NavigationMenuRadix.Link>
                    </NavigationMenuRadix.Item>
                ))}
            </NavigationMenuRadix.List>
        </NavigationMenuRadix.Root>
    );
};

export default NavigationMenu;
