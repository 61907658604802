import type { TFunction } from 'i18next';

import type { Match } from 'interfaces/match.type';

export const getInitials = (firstName: string, lastName: string): string => {
    let result = '';
    if (firstName) {
        result = firstName.trim().charAt(0).toLocaleUpperCase();
    }
    if (lastName) {
        result += lastName.trim().charAt(0).toLocaleUpperCase();
    }
    if (result !== '') {
        return result;
    }
    return '?';
};

export const getMatchInitials = (item: Match): string => {
    const { firstName, lastName } = item;
    return getInitials(firstName, lastName);
};

export const getMatchScoreString = (item: Match): string => {
    const { score } = item;
    if (!score) return '';
    if (score === 10) return '10';
    return Number(score).toFixed(1);
};

export const getProspectSignalsTagsString = (t: TFunction, item: Match): string | undefined => {
    const { signals } = item;
    if (!signals || signals.length === 0) {
        return undefined;
    }
    let result: string =
        signals[0].scoringMetaCategory || t(`signal.${signals[0].type}`) || signals[0].type;
    if (signals.length > 1) {
        result += ` +${signals.length - 1}`;
    }
    return result;
};
