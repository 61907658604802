import {
    type JobChangeSignal,
    type LinkedInPostSignal,
    type CompanyNewsSignal,
    type Match,
    type Signal,
    type Tag,
    type WithSignalInfo,
    SIGNAL_TYPES,
} from 'interfaces/match.type';
import {
    type NimbusSignal,
    type NimbusProspectWithSignals,
    type NimbusSentSignalWithAllInfo,
} from 'interfaces/nimbusSignal';
import { COUNTRY_HASH } from 'constants/countries';
import type { ProspectData, SignalData } from './honeycomb';
import { computeSeniority } from './prospect';

export type ProspectWithSignals = ProspectData & {
    isRead: boolean;
    emailSent: boolean;
    signals: SignalData[];
};

export type MatchesHash = {
    [id: string]: Match;
};

const makeLinkedInURLFromVMID = (vmid: string | null): string | undefined =>
    vmid ? `https://linkedin.com/in/${vmid}` : undefined;

const formatHeadcount = (
    headcountFrom: number | null | undefined,
    headcountTo: number | null | undefined,
): string | undefined => {
    if (
        (headcountFrom === null || headcountFrom === undefined) &&
        (headcountTo === null || headcountTo === undefined)
    ) {
        return undefined;
    }
    if (headcountTo === null || headcountTo === undefined) {
        return String(headcountFrom);
    }
    return `${headcountFrom}-${headcountTo}`;
};

const formatCountryByCode = (countryCode: string | undefined | null): string | undefined => {
    if (!countryCode) return undefined;
    return COUNTRY_HASH[countryCode];
};

const makeTagFromProspectWithSignals = (record: NimbusProspectWithSignals): Tag => {
    const result: Tag = {};
    const seniority = computeSeniority(record.userProspect?.jobTitle);
    const headcount = formatHeadcount(
        record.company?.headcount?.from,
        record.company?.headcount?.to,
    );
    const { industries, orientation } = record.company;
    const headquarter = formatCountryByCode(record.company?.location?.countryCode);

    if (seniority) result.seniority = seniority;
    if (headcount) result.headcount = headcount;
    if (orientation) result.orientation = orientation;
    if (industries) result.industry = industries.join(', ');
    if (headquarter) result.headquarter = headquarter;

    return result;
};

const sortSignalByScore = (a: Signal, b: Signal): number => {
    const comparisonResult = (b.score || 0) - (a.score || 0);
    if (comparisonResult === 0 && a.updatedAt && b.updatedAt) {
        return a.updatedAt < b.updatedAt ? 1 : a.updatedAt > b.updatedAt ? -1 : 0;
    }
    return comparisonResult;
};

const sortProspectsByScore = (a: Match, b: Match): number => {
    const comparisonResult = (b.signals[0].score || 0) - (a.signals[0].score || 0);
    if (comparisonResult === 0) {
        if (a.updatedAt && b.updatedAt) {
            return a.updatedAt < b.updatedAt ? 1 : a.updatedAt > b.updatedAt ? -1 : 0;
        }
        return a.lastName.localeCompare(b.lastName);
    }
    return comparisonResult;
};

export const mapNimbusSignalToSignal = (
    record: NimbusSignal | NimbusSentSignalWithAllInfo,
): Signal | undefined => {
    const { signal, linkedInPost, companyNews, jobChange } = record;
    let signalMeta;
    if ('signalMeta' in record) signalMeta = record.signalMeta;

    const signalMetaData: WithSignalInfo = {
        id: signal._id,
        score: signal.score || undefined,
        emailFrom: signal.email?.from || undefined,
        emailSubject: signal.email?.subject || undefined,
        emailMessage: signal.email?.message || undefined,
        emailSent: signal.email?.isSent ?? false,
        emailSentAt: signal.email?.sentAt || undefined,
        scoringMetaReasoning: signalMeta?.scoringMeta?.reasoning,
        scoringMetaCategory: signalMeta?.scoringMeta?.category,
        sendViaAutopilot: signal.sendViaAutopilot ?? false,
        sendViaAutopilotAt: signal.sendViaAutopilotAt || undefined,
    };

    switch (signal.type) {
        case 'job_change':
            const signalJobChange: JobChangeSignal = {
                ...signalMetaData,
                type: SIGNAL_TYPES.JobChange,
                companyNameNew: jobChange?.newRole.company.name,
                companyNameOld: undefined,
                jobTitleNew: jobChange?.newRole.title,
                jobTitleOld: undefined,
                jobChangeType: undefined, // to be fixed
                changeDate: jobChange?.newRole.startDate || signal.publishDate,
                updatedAt: signal.publishDate,
            };
            return signalJobChange;

        case 'linkedin_post':
            const linkedInPostSignal: LinkedInPostSignal = {
                ...signalMetaData,
                type: SIGNAL_TYPES.LinkedinPost,
                post: linkedInPost?.content,
                postUrl: linkedInPost?.url,
                postImageUrl: linkedInPost?.imgUrl || undefined,
                postPublicationDate: linkedInPost?.approxCreatedAt,
                postOriginalAuthor: undefined,
                postIsRepost: linkedInPost?.isRepost ?? undefined,
                updatedAt: signal.publishDate || linkedInPost?.approxCreatedAt,
            };
            return linkedInPostSignal;

        case 'company_news':
            const companyNewsSignal: CompanyNewsSignal = {
                ...signalMetaData,
                type: SIGNAL_TYPES.CompanyNews,
                newsHeadline: companyNews?.headline || '',
                newsContent: companyNews?.content || companyNews?.summary || undefined,
                newsUrl: companyNews?.url || '',
                newsImageUrl: companyNews?.imgUrl || undefined,
                newsSource: companyNews?.source || undefined,
                newsPublicationDate: companyNews?.publishedAt || signal.publishDate,
                // to show author
                newsAuthor: undefined,
                updatedAt: signal.publishDate || companyNews?.publishedAt,
            };
            return companyNewsSignal;

        default:
    }
    return undefined;
};

// for activity page
export const mapNimbusSentSignalWithAllInfoToProspect = (
    item: NimbusSentSignalWithAllInfo,
): Match => {
    const prospectId = item.prospect._id;
    const signals = [];
    const signal = mapNimbusSignalToSignal(item);
    if (signal) signals.push(signal);
    const prospect: Match = {
        prospectId,
        userProspectId: item.signal.userProspect._id,
        firstName: item.prospect.firstName,
        lastName: item.prospect.lastName,
        companyName: item.company.name,
        email: item.signal.userProspect?.email,
        jobTitle: item.signal.userProspect?.jobTitle || '',
        score: item.signal.score,
        signals,
        linkedInUrl: item.prospect.linkedin.urn?.id
            ? makeLinkedInURLFromVMID(item.prospect.linkedin.urn?.id)
            : undefined,
        imageUrl: item.prospect.linkedin.profilePictureUrl,
        location: item.prospect?.location?.countryCode
            ? formatCountryByCode(item.prospect.location.countryCode) || ''
            : '',
        tag: {},
        source: item.signal.userProspect?.source || undefined,
        contactStatus: item.signal.userProspect?.contactStatus || undefined,
    };
    return prospect;
};

// for list of prospects
export const mapAPIResponseSignalsToProspects = (records: NimbusProspectWithSignals[]): Match[] => {
    if (records && Array.isArray(records)) {
        const prospects = records
            .filter((item) => {
                if (item.prospect && item.signals && item.signals.length) {
                    const isAnySent = item.signals.some((signal) => signal.signal.email?.isSent);
                    return !isAnySent;
                }
                return false;
            })
            .map((item: NimbusProspectWithSignals) => {
                const prospectId = item.prospect._id;
                const signals = item.signals
                    .map((signal: NimbusSignal) => {
                        const mappedSignal = mapNimbusSignalToSignal(signal);
                        return mappedSignal;
                    })
                    .filter((signal: Signal | undefined): signal is Signal => signal !== undefined);
                const sortedSignals = signals.concat().sort(sortSignalByScore);

                const isRead = item.signals.some((signal) => signal.signal.isRead);
                const isEmailSent = item.signals.some((signal) => signal.signal.email?.isSent);

                const prospect: Match = {
                    prospectId,
                    userProspectId: item.userProspect._id,
                    firstName: item.prospect.firstName,
                    lastName: item.prospect.lastName,
                    companyName: item.company.name,
                    email: item.userProspect.email,
                    jobTitle: item.userProspect.jobTitle || '',
                    score: sortedSignals?.[0]?.score,
                    signals: sortedSignals,
                    linkedInUrl: item.prospect.linkedin.urn?.id
                        ? makeLinkedInURLFromVMID(item.prospect.linkedin.urn?.id)
                        : undefined,
                    imageUrl: item.prospect.linkedin.profilePictureUrl,
                    location: item.prospect?.location?.countryCode
                        ? formatCountryByCode(item.prospect.location.countryCode) || ''
                        : '',
                    tag: makeTagFromProspectWithSignals(item),
                    read: isRead ?? false,
                    done: isEmailSent ?? false,
                    source: item.userProspect.source || undefined,
                    contactStatus: item.userProspect.contactStatus || undefined,
                };
                return prospect;
            });

        const sortedProspects = sortProspects(prospects);
        return sortedProspects;
    }
    return [];
};

export const sortProspects = (prospects: Match[]): Match[] => {
    return prospects.concat().sort(sortProspectsByScore);
};
