import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PERFORMANCE_TAB_VALUES } from 'interfaces/performance.type';
import { NAV_MENU_ITEMS } from 'constants/routes/navLinks';

import Tabs, { type TabItem } from 'components/Tabs';
import Header from 'components/Header';
import PerformanceTabContent from 'components/PerformanceTabContent';

import styles from './style.module.scss';

const PerformancePage = (): JSX.Element => {
    const { t } = useTranslation('performance');

    const tabs: TabItem[] = useMemo(() => {
        return PERFORMANCE_TAB_VALUES.map((tab) => ({
            label: t(`tabs.${tab}`),
            value: tab,
        }));
    }, [t]);

    return (
        <>
            <Header navMenuItems={NAV_MENU_ITEMS} />

            <div className={styles.container}>
                <Tabs items={tabs}>
                    {PERFORMANCE_TAB_VALUES.map((tab) => (
                        <Tabs.Content key={tab} value={tab}>
                            <PerformanceTabContent tab={tab} />
                        </Tabs.Content>
                    ))}
                </Tabs>
            </div>
        </>
    );
};

export default PerformancePage;
