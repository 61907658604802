import React, { type FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldInput from 'components/FormFieldInput';
import Message from 'components/Message';
import Typography from 'components/Typography';
import { ROUTES } from 'constants/routes/routes';

import { extractFormData } from './extractFormData';

import { ReactComponent as SvgImageBack } from '../../assets/images/back.svg';

import styles from './style.module.scss';

export type ForgotPasswordFormData = {
    email: string;
};

type Props = {
    error?: string;
    message?: string;
    disabled?: boolean;
    onSubmit?: (_values: ForgotPasswordFormData) => void;
};

const FormForgotPassword: React.FC<Props> = ({ error, message, disabled = false, onSubmit }) => {
    const { t } = useTranslation('auth');

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (onSubmit) {
            const formData = new FormData(event.target as HTMLFormElement);
            const data = extractFormData(formData);
            onSubmit(data);
        }
    };

    return (
        <div className={styles.container}>
            <Link to={ROUTES.LOGIN}>
                <Typography variant='bodyRegular1' className={styles['text-link']}>
                    <SvgImageBack /> {t('form.text_back')}
                </Typography>
            </Link>
            <div className={styles['text-container']}>
                <Typography inline className={styles['text-title']}>
                    {t('form.text_reset_password_title')}
                </Typography>
                <Typography variant='bodyRegular1'>{t('form.text_reset_password_link')}</Typography>
            </div>
            {error && <Message error>{t(error)}</Message>}
            {message && <Message success>{t(message)}</Message>}
            <Form.Root
                className={styles.form}
                onSubmit={handleFormSubmit}
                noValidate // TODO: to remove later
            >
                <div className={styles.fields}>
                    <FormFieldInput
                        id='email'
                        name='email'
                        label={t('form.label_email')}
                        placeholder={t('form.placeholder_email')}
                        autoComplete='username'
                        variant='large'
                        required
                        disabled={disabled || !!message}
                    />
                </div>

                <div className={styles.extra}>
                    <Link to={ROUTES.LOGIN}>
                        <Typography variant='bodyRegular2'>
                            {t('form.text_back_to_login')}
                        </Typography>
                    </Link>
                </div>

                <div className={styles.submit}>
                    <Form.Submit asChild disabled={disabled || !!message}>
                        <Button block size='large'>
                            {t('form.reset_password')}
                        </Button>
                    </Form.Submit>
                </div>
            </Form.Root>
        </div>
    );
};

export default FormForgotPassword;
