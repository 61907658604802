import { type IntegrationAppClient } from '@integration-app/sdk';

import { type IAResponseGmailProfile, executeAction } from 'services/integrationApp';

export const getGmailProfile = async (
    integrationAppClient: IntegrationAppClient,
): Promise<string | undefined> => {
    // fetch profile info
    const profileInfo = await executeAction<never, IAResponseGmailProfile>(
        integrationAppClient,
        'get-profile-gmail',
        'gmail',
    );

    // When no user email address found - result is undefined
    return profileInfo?.emailAddress;
};
