import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import FormSendTestEmail, { type TestEmailFormData } from 'forms/FormSendTestEmail';

import { useSendTestGmailMessage } from 'stores/useBoundStore';

import styles from './style.module.scss';

type SendTestEmailDialogProps = {
    open: boolean;
    onClose?: () => void;
};

const SendTestEmailDialog: React.FC<SendTestEmailDialogProps> = ({ open, onClose }) => {
    const { t } = useTranslation('profile');

    const sendTestGmailMessage = useSendTestGmailMessage();

    const handleSubmit = async (values: TestEmailFormData): Promise<void> => {
        const result = await sendTestGmailMessage({
            emailAddress: values.recipient,
        });
        if (result) {
            toast.success(t('email_integration.send_test_email_success'));
            onClose?.();
            return;
        }
        toast.error(t('email_integration.send_test_email_error'));
    };

    return (
        <Dialog
            open={open}
            className={styles.dialog}
            title={t('email_integration.send_test_email_title')}
            onClose={onClose}
        >
            <Typography variant='bodyRegular1'>
                {t('email_integration.send_test_email_text')}
            </Typography>

            <FormSendTestEmail onSubmit={handleSubmit} />
        </Dialog>
    );
};

export default SendTestEmailDialog;
