import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTES } from 'constants/routes/routes';
import { AnimatedStars } from 'components/AnimatedStars';
import HeaderLogin from 'components/HeaderLogin';
import FormSetPassword, { type SetPasswordFormData } from 'forms/FormSetPassword';
import Loading from 'components/Loading';
import {
    useAuthClearErrorAndMessage,
    useAuthError,
    useAuthLoading,
    useAuthResetPassword,
} from 'stores/useBoundStore';

import 'react-toastify/dist/ReactToastify.css';
import styles from './style.module.scss';

const USE_ANIMATION = true;

const AuthSetPasswordPage = (): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const code = searchParams.get('code');

    const resetPassword = useAuthResetPassword();
    const loading = useAuthLoading();
    const error = useAuthError();
    const clearErrorAndMessage = useAuthClearErrorAndMessage();

    useEffect((): (() => void) => {
        clearErrorAndMessage();
        return clearErrorAndMessage;
    }, []);

    useEffect(() => {
        if (code === null) {
            navigate(ROUTES.LOGIN);
        }
    }, [code]);

    const handleSubmit = async (values: SetPasswordFormData): Promise<void> => {
        if (!code) {
            navigate(ROUTES.LOGIN);
            return;
        }
        const submitData = {
            ...values,
            code,
        };
        const success = await resetPassword(submitData);
        if (success) navigate(ROUTES.SIGNALS);
    };

    return (
        <>
            <div className={styles.container}>
                <HeaderLogin />
                {loading && <Loading absolute />}
                <div className={styles.content}>
                    <FormSetPassword error={error} onSubmit={handleSubmit} disabled={loading} />
                    <div ref={containerRef} className={styles['stars-container']}>
                        {USE_ANIMATION && <AnimatedStars containerRef={containerRef} />}
                        <div className={styles['image-container']} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuthSetPasswordPage;
