import type { FormLeadSearchFormData } from 'forms/FormLeadSearch';
import type { SearchLeadsRequest } from 'services/nimbus';

export const mapLeadSearchFormDataToRequest = (
    values: FormLeadSearchFormData,
): SearchLeadsRequest => {
    return {
        person: {
            ...(values.personJobTitle && {
                jobTitles: {
                    include: values.personJobTitle
                        ?.trim()
                        .split(',')
                        .map((title) => title.trim()),
                },
            }),
            ...(values.personManagementLevel &&
                values.personManagementLevel.length > 0 && {
                    managementLevels: values.personManagementLevel,
                }),
            ...(values.personDepartment &&
                values.personDepartment.length > 0 && {
                    departments: values.personDepartment,
                }),
            ...(values.personLocation && {
                locations: values.personLocation
                    ?.trim()
                    .split(',')
                    .map((location) => location.trim()),
            }),
        },
        company: {
            ...(values.companyIndustry &&
                values.companyIndustry.length > 0 && {
                    industries: {
                        include: values.companyIndustry,
                    },
                }),
            ...(values.companyKeywords && {
                keywords: values.companyKeywords
                    ?.trim()
                    .split(',')
                    .map((keyword) => keyword.trim()),
            }),
            revenue: {
                ...(values.companyRevenueFrom && {
                    min: parseFloat(values.companyRevenueFrom),
                }),
                ...(values.companyRevenueTo && {
                    max: parseFloat(values.companyRevenueTo),
                }),
            },
            ...(values.companyDomain && {
                domains: values.companyDomain
                    ?.trim()
                    .split(',')
                    .map((domain) => domain.trim()),
            }),
            ...(values.companyLocation && {
                locations: values.companyLocation
                    ?.trim()
                    .split(',')
                    .map((location) => location.trim()),
            }),
            employees: {
                ...(values.companyEmployeeCountFrom && {
                    min: values.companyEmployeeCountFrom,
                }),
                ...(values.companyEmployeeCountTo && {
                    max: values.companyEmployeeCountTo,
                }),
            },
            employeesByDepartment: {
                ...(values.companyEmployeesByDepartment && {
                    department: values.companyEmployeesByDepartment,
                }),
                ...(values.companyEmployeesByDepartmentFrom && {
                    min: values.companyEmployeesByDepartmentFrom,
                }),
                ...(values.companyEmployeesByDepartmentTo && {
                    max: values.companyEmployeesByDepartmentTo,
                }),
            },
            foundedYear: {
                ...(values.companyFoundedYearFrom && {
                    min: values.companyFoundedYearFrom,
                }),
                ...(values.companyFoundedYearTo && {
                    max: values.companyFoundedYearTo,
                }),
            },
            ...(values.companyFundingLevel &&
                values.companyFundingLevel.length > 0 && {
                    fundingLevels: values.companyFundingLevel,
                }),
            ...(values.companyTechnologies &&
                values.companyTechnologies.length > 0 && {
                    technologies: values.companyTechnologies,
                }),
        },
        page: 1,
    } satisfies SearchLeadsRequest;
};
