import { useTranslation } from 'react-i18next';

import { type Signal, SIGNAL_TYPES } from 'interfaces/match.type';
import { getIconNameBySignal } from 'components/SignalCard/utils';
import Tag from 'components/Tag';
import { getSignalTypeLabel } from 'services/signal';

type SignalTypeTagProps = {
    signal: Signal;
    onClick?: (_link: string) => void;
};

const SignalTypeTag: React.FC<SignalTypeTagProps> = ({ signal, onClick }) => {
    const { t } = useTranslation('matches');

    const icon = getIconNameBySignal(signal);

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        if (onClick) {
            event.preventDefault();
            event.stopPropagation();
            onClick(event.currentTarget.href);
        }
    };

    const tagTextSignalType = getSignalTypeLabel(signal);

    const TagElement = <Tag text={t(`signal.${tagTextSignalType}`)} icon={icon} variant='strong' />;

    if (
        (signal.type === SIGNAL_TYPES.IndustryNews || signal.type === SIGNAL_TYPES.LinkedinPost) &&
        signal?.postUrl
    ) {
        return (
            <a href={signal.postUrl} target='_blank' rel='noreferrer' onClick={handleClick}>
                {TagElement}
            </a>
        );
    }

    if (signal.type === SIGNAL_TYPES.CompanyNews && signal?.newsUrl) {
        return (
            <a href={signal.newsUrl} target='_blank' rel='noreferrer' onClick={handleClick}>
                {TagElement}
            </a>
        );
    }
    return TagElement;
};

export default SignalTypeTag;
