import { type UserEx, type OutboundEmailType, OUTBOUND_EMAIL_TYPE } from 'interfaces/userEx.type';
import { type IntegrationProfile } from 'stores/crmSlice';
import { PROSPECT_CONTACT_STATUS, type ProspectContactStatus } from './honeycomb';

export const getOutboundEmailType = (integrationKey: string): OutboundEmailType => {
    switch (integrationKey) {
        case 'honeysales':
            return 'HONEYSALES';
        case 'gmail':
            return 'GMAIL';
        case 'salesforce':
            return 'CRM';
        default:
            return '';
    }
};

export const getIntegrationKey = (
    outboundEmailType: OutboundEmailType | undefined,
): string | undefined => {
    switch (outboundEmailType) {
        case 'HONEYSALES':
            return 'honeysales';
        case 'GMAIL':
            return 'gmail';
        case 'CRM':
            return 'salesforce';
        default:
            return undefined;
    }
};

export const getEmailIntegrationSetting = (
    integrationProfiles: IntegrationProfile[],
    user: UserEx | undefined,
    contactStatus?: ProspectContactStatus,
): {
    integrationKey?: string;
    email?: string;
    isIntegrationAvailable: boolean;
    isAnyIntegrationAvailable: boolean;
} => {
    let email;
    let isIntegrationAvailable = false;

    const isColdProspect =
        contactStatus === PROSPECT_CONTACT_STATUS.cold || contactStatus === undefined;

    let isAnyIntegrationAvailable =
        integrationProfiles.length > 0 ||
        (isColdProspect ? !!user?.esp?.cold?.email : !!user?.esp?.warm?.email);

    let integrationKey = isColdProspect
        ? getIntegrationKey(user?.settings?.emails?.sendColdMessagesVia)
        : getIntegrationKey(user?.settings?.emails?.sendWarmMessagesVia);

    switch (integrationKey) {
        case 'honeysales':
            email = isColdProspect ? user?.esp?.cold?.email : user?.esp?.warm?.email;
            isIntegrationAvailable = email !== undefined;
            break;

        case 'gmail':
        case 'salesforce':
            const integrationProfile = integrationProfiles.find(
                (profile) => profile.integrationKey === integrationKey,
            );
            isIntegrationAvailable = !!integrationProfile;
            email = integrationProfile?.email;
            break;

        default:
            isIntegrationAvailable = false;
            break;
    }

    if (!email) {
        isIntegrationAvailable = false;
    }

    return {
        integrationKey,
        email,
        isIntegrationAvailable,
        isAnyIntegrationAvailable,
    };
};

export const isSendViaHoneysales = (user?: UserEx): boolean => {
    if (!user) return false;
    return (
        user?.settings?.emails?.sendWarmMessagesVia === OUTBOUND_EMAIL_TYPE.Honeysales ||
        user?.settings?.emails?.sendColdMessagesVia === OUTBOUND_EMAIL_TYPE.Honeysales
    );
};
