import { type UserInfoFormData } from 'forms/FormPersonalInformation';
import honeycombApi from 'services/honeycombApi';

const URLS = {
    fetchUserByInternalRef: '/api/users?filters[internalRef][$eq]=:internalRef',
    fetchUserById: '/api/users/:id',
    fetchCurrentUser: '/api/users/me',
    updateUser: '/api/users/:id',
    fetchSignals: '/api/signals',
} as const;

export const PROSPECT_SOURCE = {
    crm: 'crm',
    honeysales: 'honeysales',
} as const;

export const PROSPECT_CONTACT_STATUS = {
    cold: 'cold',
    lead: 'lead',
    opportunity: 'opportunity',
    customer: 'customer',
} as const;

export type ProspectSource = (typeof PROSPECT_SOURCE)[keyof typeof PROSPECT_SOURCE] | undefined;

export type ProspectContactStatus =
    | (typeof PROSPECT_CONTACT_STATUS)[keyof typeof PROSPECT_CONTACT_STATUS]
    | undefined;

type APIResponseUser = {
    id: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    company: string;
};

export type SignalDataJobChange = {
    jobChangeType: string;
    previousCompany: string;
    previousJobTitle: string;
    changeDate: string;
    __component: 'signals.job-change';
};

export type SignalDataPost = {
    postContent: string;
    publishDate: string;
    postUrl: string;
    postImageUrl?: string | null;
    isRepost: boolean | null;
    __component: 'signals.post';
};

export type SignalDataCompanyNews = {
    newsHeadline: string;
    newsSummary: string | null;
    newsContent: string | null;
    publishDate?: string;
    newsUrl: string;
    newsImageUrl: string | null;
    newsSource: string | null;
    __component: 'signals.company-news';
};

export type SignalData = {
    id: string;
} & (SignalDataJobChange | SignalDataPost | SignalDataCompanyNews);

export type ProspectData = {
    id: number;
    internalRef: string;
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    jobTitle: string;
    profileImageUrl: string;
    sourceId: string | null;
    industries: string | null;
    headcountFrom: number | null;
    headcountTo: number | null;
    orientation: string | null;
    leadCountryCode: string | null;
    source: ProspectSource | null;
    contactStatus: ProspectContactStatus | null;
};

export type SignalWithProspectData = {
    id: number;
    internalRef: string;
    isRead: boolean;
    emailSent: boolean;
    score: number | null;
    emailFrom: string | null;
    emailSubject: string | null;
    emailMessage: string | null;
    emailSentAt: string | null;
    signal: SignalData[];
    prospect: ProspectData | null;
    createdAt: string;
    updatedAt: string;
};

export type APIResponseMetadata = {
    pagination: {
        page: number;
        pageSize: number;
        pageCount: number;
        total: number;
    };
};

export type APIResponseSignals = {
    data: SignalWithProspectData[];
    meta: APIResponseMetadata;
};

export const fetchUserById = async (id: string): Promise<APIResponseUser | undefined> => {
    const usersResponse = await honeycombApi.get<APIResponseUser>(
        URLS.fetchUserById.replace(':id', id),
    );
    return usersResponse;
};

export const fetchUserByInternalRef = async (
    internalRef: string,
): Promise<APIResponseUser | undefined> => {
    const usersResponse = await honeycombApi.get<APIResponseUser[]>(
        URLS.fetchUserByInternalRef.replace(':internalRef', internalRef),
    );
    if (usersResponse.length === 0) return;
    return usersResponse[0];
};

export const fetchCurrentUser = async (): Promise<APIResponseUser | undefined> => {
    const userResponse = await honeycombApi.get<APIResponseUser>(URLS.fetchCurrentUser);
    return userResponse;
};

export const updateUser = async (
    id: number,
    data: UserInfoFormData,
): Promise<APIResponseUser | undefined> => {
    const userResponse = await honeycombApi.put<APIResponseUser>(
        URLS.updateUser.replace(':id', String(id)),
        data,
    );
    return userResponse;
};

export const fetchSignals = async (): Promise<APIResponseSignals | undefined> => {
    const signalsResponse = await honeycombApi.get<APIResponseSignals>(URLS.fetchSignals);
    return signalsResponse;
};
