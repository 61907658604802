import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldCheckboxGroup from 'components/FormFieldCheckboxGroup';
import FormFieldSelect from 'components/FormFieldSelect';
import FormFieldSwitch from 'components/FormFieldSwitch';
import Typography from 'components/Typography';
import { PROSPECT_CONTACT_STATUS } from 'services/honeycomb';
import { getSelectOptionsWithTranslation } from 'services/form';

import styles from './style.module.scss';

export const MAX_SEND_FREQUENCY = {
    Once: 'ONCE',
    EveryWeek: 'EVERY_WEEK',
    EveryTwoWeeks: 'EVERY_TWO_WEEKS',
    EveryMonth: 'EVERY_MONTH',
    EveryTwoMonths: 'EVERY_TWO_MONTHS',
} as const;

const OPTIONS_MAX_SEND_FREQUENCY: string[] = Object.values(MAX_SEND_FREQUENCY);

export const DELAY_BEFORE_AUTOSEND = {
    NoDelay: 'NO_DELAY',
    TwoHours: 'TWO_HOURS',
    FourHours: 'FOUR_HOURS',
    EightHours: 'EIGHT_HOURS',
} as const;

const OPTIONS_DELAY_BEFORE_AUTOSEND: string[] = Object.values(DELAY_BEFORE_AUTOSEND);

const OPTIONS_PROSPECT_STATUS: string[] = Object.values(PROSPECT_CONTACT_STATUS);

export type FormAutopilotSettingsFormData = {
    enabled?: boolean;
    contactStatus?: string[];
    maxSendFrequency?: string;
    delayBeforeAutosend?: string;
};

type FormAutopilotSettingsProps = {
    showEnabled: boolean;
    initialValues?: FormAutopilotSettingsFormData;
    disabled?: boolean;
    sendViaHoneysales: boolean;
    onSubmit: (_values: FormAutopilotSettingsFormData) => void;
    onCancel?: () => void;
};

const defaultValues: FormAutopilotSettingsFormData = {
    enabled: true,
    contactStatus: [PROSPECT_CONTACT_STATUS.cold],
    maxSendFrequency: MAX_SEND_FREQUENCY.EveryTwoWeeks,
    delayBeforeAutosend: DELAY_BEFORE_AUTOSEND.FourHours,
};

const getFormData = (
    formValues: { [key: string]: FormDataEntryValue },
    values: FormAutopilotSettingsFormData = defaultValues,
): FormAutopilotSettingsFormData => {
    return {
        enabled: (formValues?.enabled ?? 'off') === 'on',
        contactStatus:
            formValues?.contactStatus === undefined
                ? values.contactStatus
                : formValues.contactStatus.toString().split(','),
        maxSendFrequency: (formValues?.maxSendFrequency || values.maxSendFrequency) as string,
        delayBeforeAutosend: (formValues?.delayBeforeAutosend ||
            values.delayBeforeAutosend) as string,
    };
};

const FormAutopilotSettings: React.FC<FormAutopilotSettingsProps> = ({
    showEnabled = true,
    initialValues = defaultValues,
    disabled = false,
    sendViaHoneysales,
    onSubmit,
    onCancel,
}) => {
    const [formData, setFormData] = React.useState<FormAutopilotSettingsFormData>(initialValues);

    const { t } = useTranslation(['forms', 'matches']);

    const optionsProspectStatus = useMemo(
        () =>
            getSelectOptionsWithTranslation(
                t,
                'matches:prospect.contact_status',
                OPTIONS_PROSPECT_STATUS,
            ),
        [t],
    );

    const optionsMaxSendFrequency = useMemo(
        () =>
            getSelectOptionsWithTranslation(
                t,
                'autopilot_settings.max_send_frequency',
                OPTIONS_MAX_SEND_FREQUENCY,
                (option: string): boolean =>
                    sendViaHoneysales && option !== MAX_SEND_FREQUENCY.Once,
            ),
        [t, sendViaHoneysales],
    );

    const optionsDelayBeforeAutosend = useMemo(
        () =>
            getSelectOptionsWithTranslation(
                t,
                'autopilot_settings.delay_before_autosend',
                OPTIONS_DELAY_BEFORE_AUTOSEND,
            ),
        [t],
    );

    const fieldValueDisabled = disabled || !formData.enabled;
    const submitDisabled = disabled || formData.contactStatus?.length === 0;

    useEffect(() => {
        if (initialValues) {
            const values: FormAutopilotSettingsFormData = {
                ...initialValues,
                maxSendFrequency: sendViaHoneysales
                    ? MAX_SEND_FREQUENCY.Once
                    : initialValues.maxSendFrequency,
            };
            setFormData(values);
        }
    }, [initialValues]);

    const handleSwitchChange = (value: boolean): void => {
        setFormData((prev) => ({ ...prev, enabled: value }));
    };

    const handleChangeContactStatus = (value: string[]): void => {
        setFormData((prev) => ({ ...prev, contactStatus: value }));
    };

    const handleOptionChangeMaxSendFrequency = (value: string): void => {
        setFormData((prev) => ({ ...prev, maxSendFrequency: value }));
    };

    const handleOptionChangeDelayBeforeAutosend = (value: string): void => {
        setFormData((prev) => ({ ...prev, delayBeforeAutosend: value }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formDataFromEntries = Object.fromEntries(new FormData(event.currentTarget));
        const formDataParsed = getFormData(formDataFromEntries, formData);
        if (onSubmit) onSubmit(formDataParsed);
    };

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (onCancel) onCancel();
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <Typography variant='bodyRegular1'>{t('autopilot_settings.description')}</Typography>
            {showEnabled && (
                <FormFieldSwitch
                    label={t('autopilot_settings.enabled')}
                    name={'enabled'}
                    value={formData.enabled}
                    disabled={disabled}
                    onChange={handleSwitchChange}
                />
            )}
            <FormFieldCheckboxGroup
                label={t('autopilot_settings.contact_status.title')}
                name={'contactStatus'}
                helperText={
                    formData.contactStatus?.length === 0
                        ? t('autopilot_settings.contact_status.helper_text')
                        : ''
                }
                options={optionsProspectStatus}
                value={formData.contactStatus}
                disabled={fieldValueDisabled}
                onChange={handleChangeContactStatus}
            />
            <FormFieldSelect
                label={t('autopilot_settings.max_send_frequency.title')}
                name={'maxSendFrequency'}
                placeholder={t('autopilot_settings.max_send_frequency.title')}
                helperText={
                    sendViaHoneysales
                        ? t('autopilot_settings.max_send_frequency.helper_text_wp')
                        : t('autopilot_settings.max_send_frequency.helper_text')
                }
                options={optionsMaxSendFrequency}
                value={formData.maxSendFrequency}
                disabled={fieldValueDisabled}
                onChange={handleOptionChangeMaxSendFrequency}
            />
            <FormFieldSelect
                label={t('autopilot_settings.delay_before_autosend.title')}
                name={'delayBeforeAutosend'}
                placeholder={t('autopilot_settings.delay_before_autosend.title')}
                helperText={t('autopilot_settings.delay_before_autosend.helper_text')}
                options={optionsDelayBeforeAutosend}
                value={formData.delayBeforeAutosend}
                disabled={fieldValueDisabled}
                onChange={handleOptionChangeDelayBeforeAutosend}
            />

            <div className={styles.submit}>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={submitDisabled}>
                        {t('autopilot_settings.save')}
                    </Button>
                </Form.Submit>
                {showEnabled === false && (
                    <Button variant='secondary' disabled={disabled} onClick={handleCancel}>
                        {t('autopilot_settings.cancel')}
                    </Button>
                )}
            </div>
        </Form.Root>
    );
};

export default FormAutopilotSettings;
