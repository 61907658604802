import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import Typography from 'components/Typography';
import FormFieldSelect from 'components/FormFieldSelect';
import FormFieldTextArea from 'components/FormFieldTextArea';

import styles from './style.module.scss';

type FormSignalCategoryProps = {
    initialValues?: SignalCategoryFormData;
    disabled?: boolean;
    onChange: (_values: SignalCategoryFormData) => void;
    onCancel: () => void;
};

export type SignalCategoryFormData = {
    clarification: string;
    pitch: string;
    weight: number;
};

const getFormData = (
    formValues: { [key: string]: FormDataEntryValue },
    values: SignalCategoryFormData,
): SignalCategoryFormData => {
    return {
        clarification: (formValues?.clarification || values.clarification) as string,
        pitch: (formValues?.pitch || values.pitch) as string,
        weight: formValues?.weight ? parseInt(formValues.weight as string) : values.weight,
    };
};

const defaultValues: SignalCategoryFormData = {
    clarification: '',
    pitch: '',
    weight: 0,
};

const FormSignalCategory: React.FC<FormSignalCategoryProps> = ({
    initialValues = defaultValues,
    disabled = false,
    onChange,
    onCancel,
}) => {
    const [formData, setFormData] = React.useState<SignalCategoryFormData>(initialValues);

    const { t } = useTranslation(['forms']);

    const submitDisabled = disabled;

    const optionsWeight = useMemo(() => {
        return Array.from({ length: 10 }, (_, i) => i).map((i) => ({
            value: ((10 - i) * 10).toString(),
            label: `${((10 - i) * 10).toString()} %`,
        }));
    }, []);

    useEffect(() => {
        if (initialValues) setFormData(initialValues);
    }, [initialValues]);

    const handleChangeInput = (name: string, value: string): void => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleOptionChangeWeight = (value: string): void => {
        setFormData((prev) => ({ ...prev, weight: parseInt(value) }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formDataFromEntries = Object.fromEntries(new FormData(event.currentTarget));
        const formDataParsed = getFormData(formDataFromEntries, formData);
        if (onChange) onChange(formDataParsed);
    };

    const handleClickCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (onCancel) onCancel();
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <Typography variant='bodyRegular1'>{t('signal_category.description')}</Typography>

            <FormFieldTextArea
                label={t('signal_category.clarification.title')}
                name={'clarification'}
                placeholder={t('signal_category.clarification.placeholder')}
                helperText={t('signal_category.clarification.helper_text')}
                rows={4}
                maxLength={500}
                value={formData.clarification}
                disabled={disabled}
                onChange={handleChangeInput}
            />

            <FormFieldTextArea
                label={t('signal_category.pitch.title')}
                name={'pitch'}
                placeholder={t('signal_category.pitch.placeholder')}
                helperText={t('signal_category.pitch.helper_text')}
                rows={4}
                maxLength={500}
                value={formData.pitch}
                disabled={disabled}
                onChange={handleChangeInput}
            />

            <FormFieldSelect
                label={t('signal_category.weight.title')}
                name={'weight'}
                placeholder={t('signal_category.weight.title')}
                helperText={t('signal_category.weight.helper_text')}
                options={optionsWeight}
                value={formData.weight.toString()}
                disabled={disabled}
                onChange={handleOptionChangeWeight}
            />

            <div className={styles.submit}>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={submitDisabled}>
                        {t('signal_category.button_save')}
                    </Button>
                </Form.Submit>
                <Button variant='secondary' disabled={disabled} onClick={handleClickCancel}>
                    {t('personal_information.cancel')}
                </Button>
            </div>
        </Form.Root>
    );
};

export default FormSignalCategory;
