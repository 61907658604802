import { type ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from 'components/Table';

import styles from './style.module.scss';

export type DataTableProps<TData> = {
    columns: ColumnDef<TData>[];
    data: TData[];
};

export const DataTable = <TData,>({ columns, data }: DataTableProps<TData>): JSX.Element => {
    const { t } = useTranslation('forms');

    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
    });

    const { getHeaderGroups, getRowModel } = table;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Table>
                    <TableHeader>
                        {getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead
                                        key={header.id}
                                        style={{
                                            width: header.getSize(),
                                            maxWidth:
                                                header.column.columnDef.maxSize !==
                                                Number.MAX_SAFE_INTEGER
                                                    ? header.column.columnDef.maxSize
                                                    : undefined,
                                        }}
                                        onClick={
                                            header.column.id === 'select'
                                                ? header
                                                      .getContext()
                                                      .table.getToggleAllPageRowsSelectedHandler()
                                                : undefined
                                        }
                                    >
                                        {!header.isPlaceholder &&
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {getRowModel().rows.map((row) => (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <TableCell
                                        key={cell.id}
                                        style={{
                                            width: cell.column.getSize(),
                                            maxWidth:
                                                cell.column.columnDef.maxSize !==
                                                Number.MAX_SAFE_INTEGER
                                                    ? cell.column.columnDef.maxSize
                                                    : undefined,
                                        }}
                                        onClick={
                                            cell.column.id === 'select'
                                                ? row.getToggleSelectedHandler()
                                                : undefined
                                        }
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {getRowModel().rows.length === 0 && (
                                <TableCell align='center' colSpan={9999}>
                                    {t('generic.no_data')}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
            {/* {hasPagination && (
                <div className='flex flex-row py-3 justify-start items-start'>
                    <DataTablePagination table={table} />
                </div>
            )} */}
        </div>
    );
};

export default DataTable;
