import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConnection, useIntegrationApp } from '@integration-app/react';
import { type Connection } from '@integration-app/sdk';

import Button from 'components/Button';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import Dialog from 'components/Dialog';
import Icon, { ICON_NAMES } from 'components/Icon';
import Loading from 'components/Loading';
import Tag from 'components/Tag';
import Typography from 'components/Typography';
import { formatDateAsString } from 'components/SignalCard/utils';
import SendTestEmailDialog from './SendTestEmailDialog';

import { getActiveConnection } from '../../stores/crmSlice/getActiveConnection';

import styles from './style.module.scss';

import imgGmail from '../../assets/images/gmail.png';

const INTEGRATIONS_EMAIL = [
    {
        name: 'gmail',
        image: imgGmail,
    },
];

type EmailIntegrationContentNotConnectedProps = {
    onClose: () => Promise<void>;
};

const EmailIntegrationContentNotConnected: React.FC<EmailIntegrationContentNotConnectedProps> = ({
    onClose,
}) => {
    const [showConnectDialog, setShowConnectDialog] = useState(false);

    const { t } = useTranslation('profile');

    const integrationApp = useIntegrationApp();

    const handleConnect = (): void => {
        setShowConnectDialog(true);
    };

    const handleCloseConnectDialog = (): void => {
        setShowConnectDialog(false);
    };

    const handleConnectIntegration = (integration: string): void => {
        setShowConnectDialog(false);
        if (integrationApp) {
            integrationApp.integration(integration).open({ onClose, showPoweredBy: false });
        }
    };

    return (
        <>
            <Typography variant='bodyBold1'>{t('email_integration.connect_email')}</Typography>
            <div className={styles['info-col']}>
                <Typography variant='bodyRegular1'>
                    {t('email_integration.connect_benefits')}
                </Typography>
                <ul className={styles.list}>
                    <li>
                        <Typography variant='bodyRegular1'>
                            {t('email_integration.connect_benefits_1')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='bodyRegular1'>
                            {t('email_integration.connect_benefits_2')}
                        </Typography>
                    </li>
                </ul>
            </div>

            <div>
                <Button onClick={handleConnect} variant='primary'>
                    <div className={styles['info-row']}>
                        <Icon name={ICON_NAMES.Link} width={16} height={16} />{' '}
                        {t('email_integration.button_connect')}
                    </div>
                </Button>
            </div>

            <div className={styles['info-col']}>
                <Typography variant='bodyRegular1'>
                    {t('email_integration.providers_supported')}
                </Typography>
                <ul className={styles.list}>
                    <li>
                        <Typography variant='bodyRegular1'>
                            {t('email_integration.supported_gmail')}
                        </Typography>
                    </li>
                </ul>
            </div>

            <Dialog
                open={showConnectDialog}
                className={styles.dialog}
                title={t('email_integration.title_connect_email')}
                onClose={handleCloseConnectDialog}
            >
                <Typography variant='bodyRegular1'>
                    {t('email_integration.text_connect_email')}
                </Typography>
                <div className={styles['integrations-list']}>
                    {INTEGRATIONS_EMAIL.map((integration) => (
                        <div
                            key={integration.name}
                            className={styles['integration-item']}
                            onClick={(): void => handleConnectIntegration(integration.name)}
                        >
                            <img src={integration.image} alt={integration.name} />
                            <Typography variant='bodyBold2'>
                                {t(`email_integration.${integration.name}`)}
                            </Typography>
                        </div>
                    ))}
                </div>
            </Dialog>
        </>
    );
};

type EmailIntegrationContentConnectedProps = {
    connection: Connection;
    onClose: () => Promise<void>;
};

const EmailIntegrationContentConnected: React.FC<EmailIntegrationContentConnectedProps> = ({
    connection,
    onClose,
}) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSendTestEmailDialog, setShowSendTestEmailDialog] = useState(false);
    const { t } = useTranslation('profile');

    const { loading, accessor } = useConnection(connection.id);

    if (!connection) return null;

    const integration = INTEGRATIONS_EMAIL.find((i) => i.name === connection.integration?.key);

    const handleDisconnectClick = async (): Promise<void> => {
        setShowConfirmation(true);
    };

    const handleDisconnectConfirm = async (): Promise<void> => {
        await accessor?.archive();
        onClose();
    };

    const handleTestEmailClick = async (): Promise<void> => {
        setShowSendTestEmailDialog(true);
    };

    const handleCloseSendTestEmailDialog = (): void => {
        setShowSendTestEmailDialog(false);
    };

    return (
        <>
            <div className={classNames(styles['info-col'], styles['info-col-centered'])}>
                <Tag text={t('email_integration.email_account')} variant='strong' gradient />

                <div className={styles.image}>
                    {integration && (
                        <img
                            src={integration.image}
                            alt={t(`email_integration.${integration.name}`)}
                        />
                    )}
                    <Typography variant='bodyBold1'>{connection.name}</Typography>
                </div>

                <Typography variant='bodyRegular2'>
                    {t('email_integration.connected_on', {
                        date: formatDateAsString(new Date(), 'PP'),
                    })}
                </Typography>

                <Button
                    variant='secondary-danger'
                    disabled={loading}
                    onClick={handleDisconnectClick}
                >
                    <div className={styles['info-row']}>
                        <Icon name={ICON_NAMES.Trash} width={16} height={16} />{' '}
                        {t('email_integration.button_disconnect')}
                    </div>
                </Button>

                <Button variant='primary' disabled={loading} onClick={handleTestEmailClick}>
                    {t('email_integration.button_send_test_email')}
                </Button>
            </div>

            <ConfirmationDialog
                open={showConfirmation}
                title={t('email_integration.title_confirm_disconnect')}
                content={t('email_integration.text_confirm_disconnect')}
                labelConfirm={t('email_integration.button_disconnect')}
                confirmVariant='primary-danger'
                onConfirm={handleDisconnectConfirm}
                onCancel={(): void => setShowConfirmation(false)}
            />

            <SendTestEmailDialog
                open={showSendTestEmailDialog}
                onClose={handleCloseSendTestEmailDialog}
            />
        </>
    );
};

type SettingsEmailIntegrationProps = {
    connections: Connection[];
    loading: boolean;
    refresh: () => Promise<void>;
};

const SettingsEmailIntegration: React.FC<SettingsEmailIntegrationProps> = ({
    connections,
    loading,
    refresh,
}) => {
    const { t } = useTranslation('profile');

    const activeConnection = getActiveConnection(connections);

    const handleOnClose = async (): Promise<void> => {
        await refresh();
    };

    return (
        <div className={styles.card}>
            {loading ? (
                <div className={styles['info-row']}>
                    <Loading size={24} />{' '}
                    <Typography variant='bodyRegular1' inline>
                        {t('email_integration.loading')}
                    </Typography>
                </div>
            ) : activeConnection ? (
                <EmailIntegrationContentConnected
                    connection={activeConnection}
                    onClose={handleOnClose}
                />
            ) : (
                <EmailIntegrationContentNotConnected onClose={handleOnClose} />
            )}
        </div>
    );
};

export default SettingsEmailIntegration;
