import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useIntegrationApp } from '@integration-app/react';

import { SIGNAL_VISIBILITY_STATUS, type Match, type Signal } from 'interfaces/match.type';
import Button from 'components/Button';
import Icon, { ICON_NAMES } from 'components/Icon';
import MatchInfoHeader from 'components/MatchInfoHeader';
import Message from 'components/Message';
import MessageBox from 'components/MessageBox';
import Tabs, { type TabItem } from 'components/Tabs';
import SignalCard from 'components/SignalCard';
import SignalEmailForm, {
    type SignalEmailInputFormData,
    type SignalEmailFormData,
} from 'components/SignalEmailForm';
import Typography from 'components/Typography';

import { ROUTES } from 'constants/routes/routes';
import { trackEventSendMessage, trackEventSignalView } from 'services/eventsTracker';
import { getProspectSignalUrl } from 'services/prospect';
import { isProspectHasAutopilotSignal, isSignalToBeSentViaAutopilot } from 'services/signal';
import { type SignalEmailMessagePayload, type SignalEmailPayload } from 'stores/matchSlice';
import {
    useDiscardProspectSignal,
    useFetchCRMLastContactInfo,
    useFetchIntegrationProfiles,
    useFilterOutDoneProspects,
    useLoadingSendEmail,
    useLoadingUserEx,
    useMarkProspectMessageChangesUnsaved,
    useMatches,
    useProxyLogin,
    useSendSignalMessage,
    useUndoDiscardProspectSignal,
    useUpdateProspectSignalEmailMessage,
    useUserEx,
} from 'stores/useBoundStore';
import useDebounce from 'hooks/useDebounce';
import env from 'env';

import imgHeart from 'assets/images/heart60.png';
import { ReactComponent as SvgIconCheckCircle } from 'assets/icons/check-circle.svg';

import { findNextMatch, findNextMatchSignal } from './findNextMatch';
import { getSignalEmailInputFormData } from './getSignalEmailInputFormData';

import styles from './style.module.scss';

type Props = {
    loading?: boolean;
    item?: Match;
    signalId?: string;
};

export const MatchInfo: React.FC<Props> = ({ loading = false, item, signalId }): JSX.Element => {
    const { t } = useTranslation('matches');

    const [sent, setSent] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string | undefined>(signalId);
    const timerNextMatchRef = useRef<NodeJS.Timeout>();

    const matches = useMatches();
    const markProspectMessageChangesUnsaved = useMarkProspectMessageChangesUnsaved();
    const discardProspectSignal = useDiscardProspectSignal();
    const undoDiscardProspectSignal = useUndoDiscardProspectSignal();
    const sendSignalMessage = useSendSignalMessage();
    const filterOutDoneProspects = useFilterOutDoneProspects();
    const loadingSendEmail = useLoadingSendEmail();
    const updateProspectSignalEmailMessage = useUpdateProspectSignalEmailMessage();
    const fetchCRMLastContactInfo = useFetchCRMLastContactInfo();
    const loadingUserEx = useLoadingUserEx();
    const fetchIntegrationProfiles = useFetchIntegrationProfiles();
    const proxyLogin = useProxyLogin();
    const user = useUserEx();

    const navigate = useNavigate();

    const integrationApp = useIntegrationApp();

    const showAutosend =
        user?.settings?.autopilot?.enabled && isProspectHasAutopilotSignal(item) && !item?.done;

    const discardVisibilityStatus = useMemo(() => {
        if (proxyLogin) return SIGNAL_VISIBILITY_STATUS.AdminDiscarded;
        return SIGNAL_VISIBILITY_STATUS.UserDiscarded;
    }, [proxyLogin]);

    const handleChangeDebounced = async (
        prospectId: string,
        signal: Signal,
        values: SignalEmailFormData,
    ): Promise<void> => {
        const payload: SignalEmailMessagePayload = {
            emailSubject: values.subject,
            emailMessage: values.text,
        };
        updateProspectSignalEmailMessage(prospectId, signal.id, payload);
    };

    const debouncedOnChange = useDebounce<[string, Signal, SignalEmailFormData]>(
        500,
        handleChangeDebounced,
    );

    useEffect(() => {
        fetchIntegrationProfiles(integrationApp);
    }, [integrationApp]);

    useEffect(() => {
        if (signalId) {
            setSelectedTab(signalId);
        }
    }, [signalId]);

    useEffect(() => {
        setSent(false);
        if (item) {
            trackEventSignalView(matches, item, item.signals[0]);
        }

        const fetchCRMProspectInfo = async (): Promise<void> => {
            if (
                integrationApp &&
                item?.prospectId &&
                item?.email &&
                env.CRM_INTEGRATION_DISABLED === false &&
                env.CRM_INTEGRATION_INFO_DISABLED === false
            ) {
                fetchCRMLastContactInfo(integrationApp, item?.prospectId, item.email);
            }
        };
        fetchCRMProspectInfo();

        return () => {
            if (timerNextMatchRef?.current) {
                clearTimeout(timerNextMatchRef.current);
            }
        };
    }, [item?.prospectId]);

    const prepareFormValues = useCallback(
        (signal: Signal): SignalEmailInputFormData =>
            item
                ? getSignalEmailInputFormData(item, signal)
                : {
                      from: '',
                      recipient: '',
                      subject: '',
                      text: '',
                      sentAt: undefined,
                  },
        [item],
    );

    const tabs: TabItem[] = useMemo(() => {
        if (!item) return [];
        return item.signals.map((signal) => ({
            label: (
                <>
                    {signal.emailSent && <SvgIconCheckCircle className={styles['check-icon']} />}
                    {t(`signal.${signal.type}`)}
                </>
            ),
            value: signal.id,
        }));
    }, [item, t, signalId]);

    if (!item) return <></>;

    // used for tracking only
    const handleTabChange = (tab: TabItem): void => {
        if (!item) return;
        const signal = item.signals.find((signal) => signal.id === tab.value);
        if (signal) trackEventSignalView(matches, item, signal);
        if (signal) {
            setSelectedTab(tab.value);
            navigate(
                ROUTES.SIGNALS_PROSPECT_DETAIL.replace(':prospectId', item.prospectId).replace(
                    ':signalId',
                    signal.id,
                ),
            );
        }
    };

    const handleSubmit = async (signal: Signal, values: SignalEmailFormData): Promise<void> => {
        if (!item) return;
        if (!item?.prospectId || !item.email) return;

        const payload: SignalEmailPayload = {
            signalId: signal.id,
            email: {
                firstName: item.firstName,
                lastName: item.lastName,
                address: item.email,
                subjectLine: values.subject,
                message: values.text,
            },
        };

        const emailSent = await sendSignalMessage(item.prospectId, payload);
        setSent(emailSent);

        if (emailSent) {
            const trackingInfo = {
                emailSubjectModified: values.subject !== signal.emailSubject,
                emailBodyModified: values.text !== signal.emailMessage,
            };
            trackEventSendMessage(matches, item, signal, trackingInfo);
            timerNextMatchRef.current = setTimeout(() => {
                if (!item) return;
                const nextMatch = findNextMatch(matches, item.prospectId);
                if (!nextMatch) {
                    setSent(false);
                    return;
                }
                filterOutDoneProspects();
                navigate(getProspectSignalUrl(nextMatch));
            }, 2000);
        }
    };

    const handleChange = async (signal: Signal, values: SignalEmailFormData): Promise<void> => {
        if (!item?.prospectId) return;
        markProspectMessageChangesUnsaved(item.prospectId);
        debouncedOnChange(item.prospectId, signal, values);
    };

    const handleDiscard = async (
        signal: Signal,
        reason?: string,
        customReason?: string,
    ): Promise<void> => {
        if (!item?.prospectId) return;
        const accepted = await discardProspectSignal(
            item.prospectId,
            signal.id,
            discardVisibilityStatus,
            reason,
            customReason,
        );
        toast.success(
            <>
                <Typography variant='bodyRegular1'>{t('discard_signal.discard_done')}</Typography>
                <Button
                    variant='link'
                    onClick={(): Promise<void> => handleUndoDiscard(item, signal.id)}
                >
                    {t('discard_signal.undo')}
                </Button>
            </>,
        );
        if (accepted) {
            const nextMatchSignal = findNextMatchSignal(matches, item.prospectId, signal.id);
            if (!nextMatchSignal) {
                navigate(ROUTES.SIGNALS);
                return;
            }
            navigate(
                ROUTES.SIGNALS_PROSPECT_DETAIL.replace(
                    ':prospectId',
                    nextMatchSignal.prospectId,
                ).replace(':signalId', nextMatchSignal.signalId),
            );
        }
    };

    const handleUndoDiscard = async (prospect: Match, signalId: string): Promise<void> => {
        if (!prospect?.prospectId) return;
        const accepted = await undoDiscardProspectSignal(prospect, signalId);
        if (accepted) {
            toast.success(
                <Typography variant='bodyRegular1'>
                    {t('discard_signal.discard_undone')}
                </Typography>,
            );
            navigate(
                ROUTES.SIGNALS_PROSPECT_DETAIL.replace(':prospectId', prospect.prospectId).replace(
                    ':signalId',
                    signalId,
                ),
            );
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {loading && <div className={styles['loading-indicator']}>{t('info.loading')}</div>}
                {loading === false && item && <MatchInfoHeader item={item} />}
            </div>
            <div className={styles.details}>
                {loading === false && (
                    <Tabs items={tabs} value={selectedTab} onTabChange={handleTabChange}>
                        {item?.signals?.map((signal) => (
                            <Tabs.Content key={signal.id} value={signal.id}>
                                {signal.emailSent && (
                                    <>
                                        <Message success>{t('form.message_sent')}</Message>
                                        <div className={styles.pb12} />
                                    </>
                                )}
                                {signal?.scoringMetaReasoning && (
                                    <>
                                        <Message className={styles['message-reasoning']} noIcon>
                                            <Icon
                                                className={styles['icon-lightbulb']}
                                                name={ICON_NAMES.Lightbulb}
                                            />
                                            <Typography
                                                variant='bodyRegular1'
                                                className={styles['text-reasoning']}
                                            >
                                                {signal.scoringMetaReasoning}
                                            </Typography>
                                        </Message>
                                        <div className={styles.pb12} />
                                    </>
                                )}
                                <div className={styles['tab-content']}>
                                    <SignalCard
                                        match={item}
                                        signal={signal}
                                        onDiscard={(
                                            reason?: string,
                                            customReason?: string,
                                        ): Promise<void> =>
                                            handleDiscard(signal, reason, customReason)
                                        }
                                    />
                                    {sent && (
                                        <MessageBox
                                            imageUrl={imgHeart}
                                            primary={t('form.message_sent_primary')}
                                            secondary={t('form.message_sent_secondary')}
                                        />
                                    )}
                                    {sent === false && (
                                        <SignalEmailForm
                                            values={prepareFormValues(signal)}
                                            contactStatus={item.contactStatus}
                                            processed={signal.emailSent}
                                            loading={loadingSendEmail || loadingUserEx}
                                            changesSaved={item.messageChangesSaved}
                                            changesSavedVisible={item.messageChangesSavedVisible}
                                            sendDisabled={proxyLogin || loadingSendEmail}
                                            showAutosend={
                                                showAutosend && isSignalToBeSentViaAutopilot(signal)
                                            }
                                            timeUntilAutosend={item.timeUntilAutosend}
                                            onSubmit={(values): Promise<void> =>
                                                handleSubmit(signal, values)
                                            }
                                            onChange={(values): Promise<void> =>
                                                handleChange(signal, values)
                                            }
                                        />
                                    )}
                                </div>
                            </Tabs.Content>
                        ))}
                    </Tabs>
                )}
            </div>
        </div>
    );
};

export default MatchInfo;
