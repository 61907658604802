import { gtagConsentUpdate } from './gtag';

export const isConsentGiven = (): boolean => {
    return (
        localStorage.getItem('consentMode') !== null && localStorage.getItem('consentMode') !== ''
    );
};

export const consentAccept = (): void => {
    const consentParams: Record<string, 'granted' | 'denied'> = {
        ad_storage: 'denied',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'denied',
        security_storage: 'denied',
    };
    localStorage.setItem('consentMode', JSON.stringify(consentParams));
    gtagConsentUpdate(consentParams);
};

export const consentReject = (): void => {
    const consentParams: Record<string, 'denied'> = {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
    };
    localStorage.setItem('consentMode', JSON.stringify(consentParams));
    gtagConsentUpdate(consentParams);
};
