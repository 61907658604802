import React from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    disabled?: boolean;
    block?: boolean;
    size?: 'large' | 'medium' | 'small';
    variant?: 'primary' | 'secondary' | 'primary-danger' | 'secondary-danger' | 'link' | 'icon';
    autoFocus?: boolean;
    className?: string;
    onClick?: (_event: React.MouseEvent<HTMLButtonElement>) => void;
    children?: React.ReactNode;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
    (
        {
            disabled = false,
            block = false,
            size = 'medium',
            variant = 'primary',
            autoFocus = false,
            className,
            onClick,
            children = null,
            ...props
        },
        ref,
    ) => {
        const buttonClassNames = classNames(
            styles.container,
            {
                [styles.block]: block,
                [styles.primary]: variant === 'primary',
                [styles['primary-danger']]: variant === 'primary-danger',
                [styles.secondary]: variant === 'secondary',
                [styles['secondary-danger']]: variant === 'secondary-danger',
                [styles.link]: variant === 'link',
                [styles.icon]: variant === 'icon',
                [styles.large]: size === 'large',
                [styles.small]: size === 'small',
                [styles['secondary-small']]: variant === 'secondary' && size === 'small',
            },
            className,
        );

        if (!children) return null;

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
            if (disabled) return;
            if (onClick && typeof onClick === 'function') {
                onClick(event);
            }
        };

        return (
            <button
                className={buttonClassNames}
                disabled={disabled}
                onClick={handleClick}
                autoFocus={autoFocus}
                ref={ref}
                {...props}
            >
                {children}
            </button>
        );
    },
);

export default Button;
