import type {
    CRMItem,
    CRMItemWithoutLoadingState,
    CRMProspect,
    CRMProspectWithoutLoadingState,
} from 'interfaces/crm.type';

const STORAGE_KEY_TOKEN = 'token';
const STORAGE_KEY_USER_ID = 'userId';
const STORAGE_KEY_EMAIL = 'email';
const STORAGE_KEY_CRM_PROSPECTS = 'crm_prospects';
const STORAGE_KEY_CRM_MEETINGS = 'crm_meetings';
const STORAGE_KEY_CRM_CALLS = 'crm_calls';
const STORAGE_KEY_CRM_EMAILS = 'crm_emails';

export const retrieveToken = (): string | null => {
    return localStorage.getItem(STORAGE_KEY_TOKEN) || sessionStorage.getItem(STORAGE_KEY_TOKEN);
};

export const hasToken = (): boolean => {
    return (
        localStorage.getItem(STORAGE_KEY_TOKEN) != null ||
        sessionStorage.getItem(STORAGE_KEY_TOKEN) != null
    );
};

export const storeToken = (token: string): void => {
    sessionStorage.removeItem(STORAGE_KEY_TOKEN);
    localStorage.setItem(STORAGE_KEY_TOKEN, token);
};

export const storeSessionToken = (token: string): void => {
    localStorage.removeItem(STORAGE_KEY_TOKEN);
    sessionStorage.setItem(STORAGE_KEY_TOKEN, token);
};

export const removeToken = (): void => {
    localStorage.removeItem(STORAGE_KEY_TOKEN);
    sessionStorage.removeItem(STORAGE_KEY_TOKEN);
};

export const storeEmail = (email: string): void => {
    localStorage.setItem(STORAGE_KEY_EMAIL, email);
};

export const retrieveEmail = (): string | null => {
    return localStorage.getItem(STORAGE_KEY_EMAIL);
};

export const storeUserId = (userId: string): void => {
    sessionStorage.setItem(STORAGE_KEY_USER_ID, userId);
};

export const removeUserId = (): void => {
    sessionStorage.removeItem(STORAGE_KEY_USER_ID);
};

export const retrieveUserId = (): string | null => {
    return sessionStorage.getItem(STORAGE_KEY_USER_ID);
};

export const clearStorage = (): void => {
    localStorage.removeItem(STORAGE_KEY_TOKEN);
    sessionStorage.removeItem(STORAGE_KEY_TOKEN);
    sessionStorage.removeItem(STORAGE_KEY_USER_ID);
};

export const storeCRMProspects = (
    crmProspects: Record<string, CRMProspect | undefined> | undefined,
): void => {
    if (crmProspects) {
        const crmProspectsWithoutLoadingState: Record<string, CRMProspectWithoutLoadingState> = {};
        Object.keys(crmProspects).forEach((key) => {
            const crmProspect = crmProspects[key];
            if (crmProspect) {
                const { loading: _1, ...rest } = crmProspect;
                crmProspectsWithoutLoadingState[key] = rest;
            }
        });
        localStorage.setItem(
            STORAGE_KEY_CRM_PROSPECTS,
            JSON.stringify(crmProspectsWithoutLoadingState),
        );
    } else {
        localStorage.removeItem(STORAGE_KEY_CRM_PROSPECTS);
    }
};

export const removeCRMProspects = (): void => {
    localStorage.removeItem(STORAGE_KEY_CRM_PROSPECTS);
};

const getStorageKeyForCRM = (type: 'meetings' | 'calls' | 'emails'): string => {
    switch (type) {
        case 'meetings':
            return STORAGE_KEY_CRM_MEETINGS;
        case 'calls':
            return STORAGE_KEY_CRM_CALLS;
        case 'emails':
            return STORAGE_KEY_CRM_EMAILS;
    }
};

export const storeCRMItems = (
    type: 'meetings' | 'calls' | 'emails',
    crmItems: Record<string, CRMItem | undefined> | undefined,
): void => {
    const storageKey = getStorageKeyForCRM(type);
    if (crmItems) {
        const crmItemsWithoutLoadingState: Record<string, CRMItemWithoutLoadingState | undefined> =
            {};
        Object.keys(crmItems).forEach((key) => {
            const crmItem = crmItems[key];
            if (crmItem) {
                const { loading: _1, ...rest } = crmItem;
                if (Object.keys(rest).length === 0) {
                    delete crmItemsWithoutLoadingState[key];
                } else {
                    crmItemsWithoutLoadingState[key] = rest;
                }
            }
        });
        localStorage.setItem(storageKey, JSON.stringify(crmItemsWithoutLoadingState));
    } else {
        localStorage.removeItem(storageKey);
    }
};

export const removeCRMItems = (type: 'meetings' | 'calls' | 'emails'): void => {
    const storageKey = getStorageKeyForCRM(type);
    localStorage.removeItem(storageKey);
};

export const retrieveCRMProspects = (): Record<string, CRMProspect> | undefined => {
    const crmProspects = localStorage.getItem(STORAGE_KEY_CRM_PROSPECTS);
    if (!crmProspects) return undefined;
    try {
        const result = JSON.parse(crmProspects) as Record<string, CRMProspectWithoutLoadingState>;
        if (!result || typeof result !== 'object') return undefined;
        const prospectsWithLoadingState: Record<string, CRMProspect> = {};
        Object.keys(result).forEach((key) => {
            const value = result[key];
            if (value) {
                const { contactUrl, updated } = value;
                const record: CRMProspect = {
                    contactUrl,
                    updated: updated ? new Date(updated) : undefined,
                    loading: false,
                };
                prospectsWithLoadingState[key] = record;
            }
        });
        return prospectsWithLoadingState;
    } catch (error) {
        localStorage.removeItem(STORAGE_KEY_CRM_PROSPECTS);
        return undefined;
    }
};

export const retrieveCRMItems = (
    type: 'meetings' | 'calls' | 'emails',
): Record<string, CRMItem> | undefined => {
    const storageKey = getStorageKeyForCRM(type);
    const crmItems = localStorage.getItem(storageKey);
    if (!crmItems) return undefined;
    try {
        const result = JSON.parse(crmItems) as Record<string, CRMItemWithoutLoadingState>;
        if (!result || typeof result !== 'object') return undefined;
        const itemsWithLoadingState: Record<string, CRMItem> = {};
        Object.keys(result).forEach((key) => {
            const value = result[key];
            if (value) {
                const { lastContactDate } = value;
                const record: CRMItem = {
                    lastContactDate: lastContactDate ? new Date(lastContactDate) : lastContactDate,
                    loading: false,
                };
                itemsWithLoadingState[key] = record;
            }
        });
        return itemsWithLoadingState;
    } catch (error) {
        localStorage.removeItem(storageKey);
        return undefined;
    }
};
