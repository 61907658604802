import React from 'react';
import classNames from 'classnames';

import Icon, { type IconName } from 'components/Icon';

import styles from './style.module.scss';

type Props = {
    icon?: IconName;
    text?: string;
    disabled?: boolean;
    inverted?: boolean;
    variant?: 'default' | 'tab';
};

const CircledTag: React.FC<Props> = ({
    icon,
    text,
    disabled = false,
    inverted = false,
    variant = 'default',
}) => {
    const circledTagClassNames = classNames(styles.container, {
        [styles.disabled]: disabled,
        [styles.inverted]: inverted,
        [styles.tab]: variant === 'tab',
    });
    const textClassNames = classNames(styles.text, {
        [styles['text-tab']]: variant === 'tab',
    });

    return (
        <div className={circledTagClassNames}>
            {icon && <Icon name={icon} width={16} height={16} />}
            <div className={textClassNames}>{text && text}</div>
        </div>
    );
};

export default CircledTag;
