import classNames from 'classnames';
import * as Form from '@radix-ui/react-form';
import Select, { type ActionMeta, type MultiValue } from 'react-select';

import { type FormFieldOption } from 'components/FormFieldSelect';

import styles from './style.module.scss';

type FormFieldMultiSelectProps = {
    id?: string;
    name: string;
    label?: string;
    options: FormFieldOption[];
    value?: MultiValue<FormFieldOption>;
    maxLength?: number;
    placeholder?: string;
    helperText?: string;
    disabled?: boolean;
    variant?: 'default' | 'small';
    className?: string;
    onChange?: (
        _value: MultiValue<FormFieldOption>,
        _actionMeta: ActionMeta<FormFieldOption>,
    ) => void;
};

export const FormFieldMultiSelect: React.FC<FormFieldMultiSelectProps> = ({
    id,
    name,
    label,
    options,
    value,
    maxLength,
    placeholder,
    helperText,
    disabled = false,
    variant = 'default',
    className,
    onChange,
}) => {
    const classContainer = classNames(
        styles.container,
        {
            [styles.disabled]: disabled,
        },
        className,
    );
    const classNamesLabel = classNames(styles.label, {
        [styles['label-small']]: variant === 'small',
    });
    const classNamesHelperText = classNames(styles['helper-text'], {
        [styles['helper-text-small']]: variant === 'small',
    });

    const handleChange = (
        newValue: MultiValue<FormFieldOption>,
        actionMeta: ActionMeta<FormFieldOption>,
    ): void => {
        if (onChange) {
            if (maxLength && newValue.length > maxLength) return;
            onChange(newValue, actionMeta);
        }
    };

    return (
        <Form.Field name={name} className={classContainer}>
            {label && <Form.Label className={classNamesLabel}>{label}</Form.Label>}
            <Select
                options={options}
                isMulti
                isDisabled={disabled}
                placeholder={placeholder}
                closeMenuOnSelect={false}
                onChange={handleChange}
                components={{ IndicatorSeparator: null }}
                styles={{
                    control: (provided, { isFocused, isDisabled }) => ({
                        ...provided,
                        borderRadius: 6,
                        background: isDisabled ? '#bdbdbd1a' : '#FFF',
                        color: isDisabled ? '#727272' : '#1a1a1a',
                        border: '1px solid #eeeeee',
                        padding: '2px 0px 2px 4px',
                        outline: 'none',
                        borderColor: isFocused ? '#000480' : '#eeeeee',
                        ':hover': {
                            borderWidth: 0.6,
                            borderColor: 'rgba(0, 4, 128, 0.8)',
                        },
                        ':focus': {
                            borderWidth: 0.6,
                            borderColor: 'rgba(0, 4, 128, 0.8)',
                        },
                        boxShadow: 'none',
                    }),
                    option: (base, props) => ({
                        ...base,
                        borderRadius: 3,
                        display: 'flex',
                        alignItems: 'center',
                        color: props.isFocused ? '#fff' : '#1a1a1a',
                        backgroundColor: props.isFocused ? '#000480' : '#fff',
                        height: 32,
                        ':active': {
                            backgroundColor: '#000480',
                            color: '#fff',
                        },
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#aaaaaa',
                    }),
                    menuList: (base) => ({
                        ...base,
                        padding: 5,
                    }),
                    multiValue: (base) => ({
                        ...base,
                        borderRadius: 6,
                        backgroundColor: '#eeeeee',
                    }),
                    multiValueLabel: (base) => ({
                        ...base,
                        borderRadius: 6,
                        color: '#1a1a1a',
                        padding: '4px 4px',
                    }),
                    multiValueRemove: (base) => ({
                        ...base,
                        color: '#aaaaaa',
                        cursor: 'pointer',
                        '&:hover': {
                            color: '#727272',
                            backgroundColor: '#eeeeee',
                        },
                    }),
                }}
                value={value}
                classNames={
                    {
                        // the following code is correct, and it's better
                        // but it does not work,
                        // because the custom classNames can be loaded
                        // before the react-select styles
                        // and in this case will not be applied
                        //
                        // control: (state) => classNames(styles.control, {
                        //     [styles['control-focused']]: state.isFocused,
                        // }),
                        // option: (state) => classNames(styles.option, {
                        //     [styles['option-selected']]: state.isFocused,
                        // }),
                        // control: () => styles.control,
                        // option: () => styles.option,
                        // menuList: () => styles['menu-list'],
                        // multiValue: () => styles['multi-value'],
                        // multiValueLabel: () => styles['multi-value-label'],
                        // multiValueRemove: () => styles['multi-value-remove'],
                    }
                }
            />
            {helperText && (
                <Form.Label htmlFor={id} className={classNamesHelperText}>
                    {helperText}
                </Form.Label>
            )}
        </Form.Field>
    );
};

export default FormFieldMultiSelect;
