import React from 'react';
import classNames from 'classnames';

import type { JobChangeSignal } from 'interfaces/match.type';

import Typography from 'components/Typography';

import imgSvgTimeline from 'assets/images/timeline.svg';

import styles from './style.module.scss';

type Props = {
    signal: JobChangeSignal;
};

const SignalContentJobChange: React.FC<Props> = ({ signal }) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.timeline}>
                    <img src={imgSvgTimeline} alt='timeline' />
                </div>
                <div className={styles.info}>
                    <div className={classNames(styles.job, styles['job-new'])}>
                        <Typography variant='bodyBold1' inline>
                            {signal.jobTitleNew}
                        </Typography>
                        <Typography variant='bodyBold1' inline>
                            {signal.companyNameNew}
                        </Typography>
                    </div>
                    <div className={classNames(styles.job, styles['job-old'])}>
                        <Typography variant='bodyBold1' inline>
                            {signal.jobTitleOld}
                        </Typography>
                        <Typography variant='bodyBold1' inline>
                            {signal.companyNameOld}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignalContentJobChange;
