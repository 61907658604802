import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent } from '@radix-ui/react-dialog';

import Button from 'components/Button';
import Typography from 'components/Typography';

import styles from './style.module.scss';

type Props = {
    onAccept?: () => void;
    onReject?: () => void;
};

const ConsentPopup: React.FC<Props> = ({ onAccept, onReject }) => {
    const { t } = useTranslation('auth');

    const [open, setOpen] = useState(true);

    const handleAccept = (): void => {
        if (onAccept) onAccept();
        setOpen(false);
    };

    const handleReject = (): void => {
        if (onReject) onReject();
        setOpen(false);
    };

    return (
        <Dialog open={open} modal={false}>
            <DialogContent className={styles.container}>
                <Typography variant='bodyRegular1'>{t('consent.consent_message')}</Typography>
                <div className={styles.action}>
                    <Button variant='secondary' onClick={handleReject}>
                        {t('consent.reject')}
                    </Button>
                    <Button variant='primary' autoFocus onClick={handleAccept}>
                        {t('consent.accept')}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ConsentPopup;
