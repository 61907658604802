import { formatDistanceToNow, parseISO, format as formatDate } from 'date-fns';
import { enUS, de } from 'date-fns/locale';
import i18next from 'i18next';

import { SIGNAL_TYPES, type Signal } from 'interfaces/match.type';

import { ICON_NAMES, type IconName } from 'components/Icon';

export const getIconNameBySignal = (signal: Signal): IconName | undefined => {
    switch (signal.type) {
        case SIGNAL_TYPES.JobChange:
            return ICON_NAMES.JobChange;
        case SIGNAL_TYPES.Birthday:
            return ICON_NAMES.Party;
        case SIGNAL_TYPES.LinkedinPost:
            return ICON_NAMES.LinkedIn;
        default:
            return undefined;
    }
};

export const getScoreString = (score: number | undefined): string => {
    if (!score) return '';
    if (score === 10) return '10';
    return score.toFixed(1);
};

export const getDateDistanceAndString = (
    date: Date,
): { formattedDate: string; distance: string } => {
    const locale = i18next.language === 'de' ? de : enUS;

    const formattedDate = formatDateAsString(date);
    const distance = formatDistanceToNow(date, {
        addSuffix: true,
        locale,
    });
    return {
        formattedDate,
        distance,
    };
};

export const getSignalDateDistanceAndString = (
    signal: Signal,
): { formattedDate: string; distance: string } => {
    let parsedDate;
    if (signal.type === SIGNAL_TYPES.LinkedinPost && signal.postPublicationDate) {
        parsedDate = parseISO(signal.postPublicationDate);
    } else if (signal.type === SIGNAL_TYPES.CompanyNews && signal.newsPublicationDate) {
        parsedDate = parseISO(signal.newsPublicationDate);
    } else if (signal.type === SIGNAL_TYPES.JobChange && signal.changeDate) {
        parsedDate = parseISO(signal.changeDate);
    } else {
        parsedDate = new Date();
    }

    return getDateDistanceAndString(parsedDate);
};

export const formatDateAsString = (date: number | Date, format: string = 'PPpp'): string => {
    const locale = i18next.language === 'de' ? de : enUS;
    return formatDate(date, format, { locale });
};

export const formatISODateAsString = (isoDate: string, format: string = 'PPpp'): string => {
    const date = parseISO(isoDate);
    return formatDateAsString(date, format);
};
