import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from 'components/Avatar';
import Icon, { ICON_NAMES } from 'components/Icon';
import Tooltip from 'components/Tooltip';
import {
    PROSPECT_CONTACT_STATUS,
    PROSPECT_SOURCE,
    type ProspectContactStatus,
    type ProspectSource,
} from 'services/honeycomb';
import Typography from 'components/Typography';
import Chip from 'components/Chip';

import styles from './style.module.scss';

type ProspectAvatarProps = {
    source?: ProspectSource;
    contactStatus?: ProspectContactStatus;
    variant?: 'default' | 'large' | 'small';
    src?: string;
    alt?: string;
    fallback?: ReactNode;
    showTooltip?: boolean;
};

const ProspectAvatar: React.FC<ProspectAvatarProps> = ({
    source,
    contactStatus,
    variant = 'default',
    src,
    alt = 'avatar',
    fallback,
    showTooltip = true,
}) => {
    const { t } = useTranslation('matches');

    const size = variant === 'large' ? 110 : variant === 'small' ? 33 : 52;
    let badgeIcon;
    let badgeVariant: 'default' | 'gradient' = 'default';
    let borderColor;
    let prospectWithoutStatus = false;
    let styleSource;

    switch (contactStatus) {
        case PROSPECT_CONTACT_STATUS.cold:
            borderColor = 'var(--color-status-cold)';
            break;
        case PROSPECT_CONTACT_STATUS.lead:
            borderColor = 'var(--color-status-lead)';
            break;
        case PROSPECT_CONTACT_STATUS.opportunity:
            borderColor = 'var(--color-status-opportunity)';
            break;
        case PROSPECT_CONTACT_STATUS.customer:
            borderColor = 'var(--color-status-customer)';
            break;
        default:
            prospectWithoutStatus = true;
            break;
    }

    switch (source) {
        case PROSPECT_SOURCE.crm:
            badgeIcon = (
                <Icon
                    name={ICON_NAMES.CRM}
                    width={variant === 'large' ? 14 : variant === 'small' ? 8 : 8}
                    height={variant === 'large' ? 14 : variant === 'small' ? 8 : 8}
                />
            );
            styleSource = styles['source-crm'];
            break;
        case PROSPECT_SOURCE.honeysales:
            badgeIcon = (
                <Icon
                    name={ICON_NAMES.Honeysales}
                    width={variant === 'large' ? 16 : variant === 'small' ? 10 : 10}
                    height={variant === 'large' ? 16 : variant === 'small' ? 10 : 10}
                />
            );
            styleSource = styles['source-honeysales'];
            badgeVariant = 'gradient';
            break;
        default:
            break;
    }

    return (
        <Tooltip
            content={
                <>
                    <Typography>{t('prospect.contact_status.title')}</Typography>
                    <Chip color={borderColor}>{t(`prospect.contact_status.${contactStatus}`)}</Chip>
                </>
            }
            showTooltip={!prospectWithoutStatus && showTooltip}
        >
            <Avatar
                size={size}
                variant={variant}
                gradient={prospectWithoutStatus}
                badge={badgeIcon}
                badgeTooltip={
                    showTooltip ? (
                        <>
                            <Typography>{t('prospect.source.title')}</Typography>
                            <Chip
                                gradient={source === PROSPECT_SOURCE.honeysales}
                                className={styleSource}
                            >
                                {t(`prospect.source.${source}`)}
                            </Chip>
                        </>
                    ) : null
                }
                badgeVariant={badgeVariant}
                borderColor={borderColor}
                src={src}
                alt={alt}
                fallback={fallback}
            />
        </Tooltip>
    );
};

export default ProspectAvatar;
