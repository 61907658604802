import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import { SIGNAL_TYPES } from 'interfaces/match.type';
import FormFieldSelect, { type FormFieldOption } from 'components/FormFieldSelect';
import Button from 'components/Button';
import FormFieldCheckboxGroup from 'components/FormFieldCheckboxGroup';

import { getSelectOptionsWithTranslation } from 'services/form';

import styles from './style.module.scss';

const OPTIONS_TIME_PERIOD: string[] = [
    'last_30_days',
    'last_3_months',
    'last_6_months',
    'last_12_months',
];
const OPTIONS_SIGNAL: string[] = ['all', ...Object.values(SIGNAL_TYPES)];
const OPTIONS_ICP: FormFieldOption[] = [
    { label: 'AI Investors / VCs', value: 'ai_investors_vcs' },
    { label: 'Zielgruppe A', value: 'zielgruppe_a' },
    { label: 'Zielgruppe B', value: 'zielgruppe_b' },
    { label: 'Zielgruppe C', value: 'zielgruppe_c' },
];
const OPTIONS_MESSAGES: string[] = ['opened', 'answered', 'scheduled_meeting'];

type Props = {
    disabled?: boolean;
    onUpdate: (_values: Record<string, FormDataEntryValue>) => void;
};

const FormFilterMessages: React.FC<Props> = ({ disabled = false, onUpdate }) => {
    const { t } = useTranslation('forms');

    const optionsTimePeriod = useMemo(
        () => getSelectOptionsWithTranslation(t, 'time_period', OPTIONS_TIME_PERIOD),
        [t],
    );

    const optionsSignal = useMemo(
        () => getSelectOptionsWithTranslation(t, 'matches:signal', OPTIONS_SIGNAL),
        [t],
    );

    const optionsIcp = [{ label: t('icp.all'), value: 'all' }, ...OPTIONS_ICP];

    const optionsMessages = useMemo(
        () => getSelectOptionsWithTranslation(t, 'messages', OPTIONS_MESSAGES),
        [t],
    );

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = Object.fromEntries(new FormData(event.currentTarget));
        if (onUpdate) onUpdate(formData);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <FormFieldSelect
                label={t('messages_filter.time_period')}
                name={'time_period'}
                variant='small'
                options={optionsTimePeriod}
                disabled={disabled}
            />
            <FormFieldSelect
                label={t('messages_filter.signal')}
                name={'signal'}
                variant='small'
                options={optionsSignal}
                disabled={disabled}
            />
            <FormFieldSelect
                label={t('messages_filter.icp')}
                name={'icp'}
                variant='small'
                options={optionsIcp}
                disabled={disabled}
            />
            <FormFieldCheckboxGroup
                label={t('messages_filter.messages')}
                name={'messages'}
                options={optionsMessages}
                disabled={disabled}
            />
            <div>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={disabled}>
                        {t('messages_filter.update')}
                    </Button>
                </Form.Submit>
            </div>
        </Form.Root>
    );
};

export default FormFilterMessages;
