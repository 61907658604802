const clientEnv = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    BASE_URL_NIMBUS: process.env.REACT_APP_BASE_URL_NIMBUS,

    PRIVACY_POLICY_URL: process.env.REACT_APP_PRIVACY_POLICY_URL,

    CRM_INTEGRATION_DISABLED: process.env.REACT_APP_CRM_INTEGRATION_DISABLED === 'true',
    CRM_INTEGRATION_INFO_DISABLED: process.env.REACT_APP_CRM_INTEGRATION_INFO_DISABLED === 'true',

    EMAIL_INTEGRATION_DISABLED: process.env.REACT_APP_EMAIL_INTEGRATION_DISABLED === 'true',

    GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,

    BEAMER_PRODUCT_ID: process.env.REACT_APP_BEAMER_PRODUCT_ID,
};

export default clientEnv;
