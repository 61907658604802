import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import FormFieldSelect, { type FormFieldOption } from 'components/FormFieldSelect';
import Button from 'components/Button';

import styles from './style.module.scss';

const OPTIONS_ICP: FormFieldOption[] = [
    { label: 'AI Investors / VCs', value: 'ai_investors_vcs' },
    { label: 'Zielgruppe A', value: 'zielgruppe_a' },
    { label: 'Zielgruppe B', value: 'zielgruppe_b' },
    { label: 'Zielgruppe C', value: 'zielgruppe_c' },
];

type Props = {
    disabled?: boolean;
    onUpdate: (_values: Record<string, FormDataEntryValue>) => void;
};

const FormFilterClientList: React.FC<Props> = ({ disabled = false, onUpdate }) => {
    const { t } = useTranslation('forms');

    const optionsIcp = [{ label: t('icp.all'), value: 'all' }, ...OPTIONS_ICP];

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = Object.fromEntries(new FormData(event.currentTarget));
        if (onUpdate) onUpdate(formData);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <FormFieldSelect
                label={t('client_list_filter.icp')}
                name={'icp'}
                variant='small'
                options={optionsIcp}
                disabled={disabled}
            />
            <div>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={disabled}>
                        {t('client_list_filter.update')}
                    </Button>
                </Form.Submit>
            </div>
        </Form.Root>
    );
};

export default FormFilterClientList;
