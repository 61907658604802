import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import FormFieldSelect, { type FormFieldOption } from 'components/FormFieldSelect';
import FormFieldInput from 'components/FormFieldInput';
import useDebounce from 'hooks/useDebounce';

import {
    PROSPECT_CONTACT_STATUS,
    PROSPECT_SOURCE,
    type ProspectContactStatus,
    type ProspectSource,
} from 'services/honeycomb';

import styles from './style.module.scss';

type FormFilterProspectsProps = {
    values?: FormFilterProspectsFormData;
    disabled?: boolean;
    onUpdate: (_values: FormFilterProspectsFormData) => void;
};

export type FormFilterProspectsFormData = {
    searchTerm: string;
    contactStatus: ProspectContactStatus | 'all';
    source: ProspectSource | 'all';
};

const defaultValues: FormFilterProspectsFormData = {
    searchTerm: '',
    contactStatus: 'all',
    source: 'all',
};

const FormFilterProspects: React.FC<FormFilterProspectsProps> = ({
    values = defaultValues,
    disabled = false,
    onUpdate,
}) => {
    const { t } = useTranslation(['forms', 'prospects']);
    const [formData, setFormData] = React.useState<FormFilterProspectsFormData>(values);

    const optionsProspectSource = useMemo(
        (): FormFieldOption[] => [
            { label: t('prospect_list_filter.all'), value: 'all' },
            ...(Object.keys(PROSPECT_SOURCE).map((source) => ({
                label: t(`prospect_list_filter.source.${source}`),
                value: source,
            })) as FormFieldOption[]),
        ],
        [t, PROSPECT_SOURCE],
    );

    const optionsProspectContactStatus = useMemo(
        (): FormFieldOption[] => [
            { label: t('prospect_list_filter.all'), value: 'all' },
            ...(Object.keys(PROSPECT_CONTACT_STATUS).map((status) => ({
                label: t(`prospect_list_filter.contact_status.${status}`),
                value: status,
            })) as FormFieldOption[]),
        ],
        [t, PROSPECT_CONTACT_STATUS],
    );

    useEffect(() => {
        if (values) setFormData(values);
    }, [values]);

    const handleChangeSearchTerm = (name: string, value: string): void => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFilterChangeContactStatus = (value: string): void => {
        setFormData((prev) => ({ ...prev, contactStatus: value as ProspectContactStatus }));
    };

    const handleFilterChangeSource = (value: string): void => {
        setFormData((prev) => ({ ...prev, source: value as ProspectSource }));
    };

    const handleChangeDebounced = (data: FormData): void => {
        let result: FormFilterProspectsFormData = {
            contactStatus: (data.get('contactStatus') as ProspectContactStatus) || 'all',
            source: (data.get('source') as ProspectSource) || 'all',
            searchTerm: (data.get('searchTerm') as string) || '',
        };
        if (onUpdate) onUpdate(result);
    };

    const debouncedOnChange = useDebounce<[FormData]>(500, handleChangeDebounced);

    const handleChange = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        debouncedOnChange(data);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        handleChange(event);
    };

    return (
        <Form.Root className={styles.container} onChange={handleChange} onSubmit={handleSubmit}>
            <FormFieldInput
                className={styles.input}
                name='searchTerm'
                label={t('prospects:filter.search')}
                placeholder={t('prospect_list_filter.search.placeholder')}
                variant='small'
                value={formData.searchTerm}
                onChange={handleChangeSearchTerm}
            />
            <div className={styles.filter}>
                <FormFieldSelect
                    className={styles.select}
                    name='contactStatus'
                    label={t('prospects:filter.prospect_status')}
                    variant='small'
                    options={optionsProspectContactStatus}
                    value={formData.contactStatus}
                    disabled={disabled}
                    onChange={handleFilterChangeContactStatus}
                />
            </div>
            <div className={styles.filter}>
                <FormFieldSelect
                    className={styles.select}
                    name='source'
                    label={t('prospects:filter.prospect_source')}
                    variant='small'
                    options={optionsProspectSource}
                    value={formData.source}
                    disabled={disabled}
                    onChange={handleFilterChangeSource}
                />
            </div>
        </Form.Root>
    );
};

export default FormFilterProspects;
