import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes/routes';
import Icon, { ICON_NAMES } from 'components/Icon';
import SupportPopup from 'components/SupportPopup';
import { setEventParameters, trackEventLocaleChange } from 'services/eventsTracker';

import { getNextLanguage } from './getNextLanguage';

import styles from './style.module.scss';

export type NavItem = {
    title: string;
    path: string;
};

const HeaderLogout: React.FC = () => {
    const { i18n } = useTranslation();

    const handleLanguageClick = (event: React.MouseEvent<SVGSVGElement>): void => {
        event.preventDefault();
        const old_locale = i18n.language;
        i18n.changeLanguage(getNextLanguage(i18n));
        const new_locale = i18n.language;
        setEventParameters({ locale_code: new_locale });
        trackEventLocaleChange(old_locale, new_locale);
    };

    return (
        <header className={styles.container}>
            <div />
            <div className={styles['logo-container']}>
                <Link to={ROUTES.MAIN}>
                    <div className={styles.logo} />
                </Link>
            </div>
            <div className={styles['menu-items']}>
                <Link to=''>
                    <SupportPopup />
                </Link>
                <Icon name={ICON_NAMES.Language} onClick={handleLanguageClick} />
            </div>
        </header>
    );
};

export default HeaderLogout;
