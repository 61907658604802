import type { ActivityRecord } from 'interfaces/activity.type';
import type { Job, JobTypes } from 'interfaces/job';
import { SIGNAL_VISIBILITY_STATUS, type SignalVisibilityStatus } from 'interfaces/match.type';
import type {
    NimbusSignalGeneric,
    NimbusProspectWithSignals,
    NimbusUserProspect,
} from 'interfaces/nimbusSignal';
import type { LeadSearchResultItem, Prospect } from 'interfaces/prospect';
import type { Industry, SignalCategory, UserEx, UserInfoExFormData } from 'interfaces/userEx.type';
import type { FormFilterActivityFormData } from 'forms/FormFilterActivity';
import type { FormFilterProspectsFormData } from 'forms/FormFilterProspects';
import nimbusApi from 'services/nimbusApi';
import type { SignalEmailMessagePayload, SignalEmailPayload } from 'stores/matchSlice';
import type { LeadSearchConfig } from 'stores/prospectSlice';

export const NUMBER_OF_ACTIVITY_RECORDS_PER_PAGE = 20;
export const PROSPECT_PAGE_SIZE_ITEM = 'prospectPageSize';
export const NUMBER_OF_PROSPECT_RECORDS_PER_PAGE = 100;
export const NUMBER_OF_LEAD_RECORDS_PER_PAGE = 100;
export const NUMBER_OF_JOB_RECORDS_PER_PAGE = 100;
export const MAX_NUMBER_OF_LEAD_RECORDS = 1000;
export const MAX_NUMBER_OF_USER_PROSPECTS = 10000;

const URLS = {
    sendSignalMessage: '/api/signals/send-email',
    updateSignal: '/api/signals/:id',
    getIAToken: '/api/ia/token',
    currentUser: '/api/user/me',
    sendTestGmail: '/api/user/send-test-gmail',
    getSignals: '/api/user/signals',
    getSentEmails: '/api/user/sent-emails',
    getIndustries: '/api/industries',
    getSignalCategories: '/api/signals/categories',
    getProspects: '/api/user/prospects',
    updateUserProspect: '/api/user/prospects/:id',
    getLeadsConfig: '/api/leads/discover/config',
    searchLeads: '/api/leads/discover',
    getJobs: '/api/user/jobs', // to change
    importProspects: '/api/user/prospects/import', // to change
} as const;

export type APIResponseSendSignalMessage = {
    accepted: boolean;
    emailSentAt: string;
};

export type APIRequestUpdateSignal = {
    isRead?: boolean;
    emailSubject?: string;
    emailMessage?: string;
    emailSent?: boolean;
    visibilityStatus?: SignalVisibilityStatus;
    discarded?: {
        reason?: string;
        customReason?: string;
    };
};

export type APIResponseUpdateSignal = NimbusSignalGeneric;

export const sendSignalMessage = async (
    payload: SignalEmailPayload,
): Promise<APIResponseSendSignalMessage | undefined> => {
    const sendSignalMessageResponse = await nimbusApi.post<APIResponseSendSignalMessage>(
        URLS.sendSignalMessage,
        payload,
    );
    return sendSignalMessageResponse;
};

export type TestGmailPayload = {
    emailAddress: string;
};

export type APIResponseSendTestGmailMessage = {
    accepted: boolean;
};

export const sendTestGmailMessage = async (
    payload: TestGmailPayload,
): Promise<APIResponseSendTestGmailMessage | undefined> => {
    const sendResponse = await nimbusApi.post<APIResponseSendTestGmailMessage>(
        URLS.sendTestGmail,
        payload,
    );
    return sendResponse;
};

export const markSignalRead = async (id: string): Promise<APIResponseUpdateSignal | undefined> => {
    const payload: APIRequestUpdateSignal = {
        isRead: true,
    };
    const updateSignalResponse = await nimbusApi.patch<APIResponseUpdateSignal>(
        URLS.updateSignal.replace(':id', id),
        payload,
    );
    return updateSignalResponse;
};

export const discardSignal = async (
    id: string,
    visibilityStatus: SignalVisibilityStatus,
    reason?: string,
    customReason?: string,
): Promise<APIResponseUpdateSignal | undefined> => {
    const payload: APIRequestUpdateSignal = {
        visibilityStatus,
        discarded: {
            reason,
            customReason,
        },
    };
    const updateSignalResponse = await nimbusApi.patch<APIResponseUpdateSignal>(
        URLS.updateSignal.replace(':id', id),
        payload,
    );
    return updateSignalResponse;
};

export const undoDiscardSignal = async (
    id: string,
): Promise<APIResponseUpdateSignal | undefined> => {
    const payload: APIRequestUpdateSignal = {
        visibilityStatus: SIGNAL_VISIBILITY_STATUS.Active,
    };
    const updateSignalResponse = await nimbusApi.patch<APIResponseUpdateSignal>(
        URLS.updateSignal.replace(':id', id),
        payload,
    );
    return updateSignalResponse;
};

export const updateSignalEmailMessage = async (
    id: string,
    payload: SignalEmailMessagePayload,
): Promise<APIResponseUpdateSignal | undefined> => {
    const requestPayload: APIRequestUpdateSignal = {
        emailSubject: payload.emailSubject,
        emailMessage: payload.emailMessage,
        emailSent: payload.emailSent,
    };
    const updateSignalResponse = await nimbusApi.patch<APIResponseUpdateSignal>(
        URLS.updateSignal.replace(':id', id),
        requestPayload,
    );
    return updateSignalResponse;
};

export type APIResponseGetIAToken = {
    token: string;
};

export const getIAToken = async (): Promise<APIResponseGetIAToken | undefined> => {
    const getTokenResponse = await nimbusApi.get<APIResponseGetIAToken>(URLS.getIAToken);
    return getTokenResponse;
};

export type APIResponseCurrentUser = UserEx;

export const fetchCurrentUserEx = async (): Promise<APIResponseCurrentUser | undefined> => {
    const userResponse = await nimbusApi.get<APIResponseCurrentUser>(URLS.currentUser);
    return userResponse;
};

export type APIResponseUpdateCurrentUser = UserEx;

export const updateCurrentUserEx = async (
    data: UserInfoExFormData,
): Promise<APIResponseUpdateCurrentUser | undefined> => {
    const userResponse = await nimbusApi.patch<APIResponseUpdateCurrentUser>(
        URLS.currentUser,
        data,
    );
    return userResponse;
};

export type APIResponseNimbusSignals = NimbusProspectWithSignals[];

export const fetchProspectsWithSignals = async (): Promise<
    NimbusProspectWithSignals[] | undefined
> => {
    const signalsResponse = await nimbusApi.get<APIResponseNimbusSignals>(URLS.getSignals);
    return signalsResponse;
};

export type APIResponseSignalCategories = SignalCategory[];

export const fetchSignalCategories = async (): Promise<SignalCategory[] | undefined> => {
    const response = await nimbusApi.get<APIResponseSignalCategories>(URLS.getSignalCategories);
    return response;
};

export type APIResponseIndustries = Industry[];

export const fetchIndustries = async (): Promise<Industry[] | undefined> => {
    const response = await nimbusApi.get<APIResponseIndustries>(URLS.getIndustries);
    return response;
};

export type APIResponseSentEmails = ActivityRecord[];

export const fetchSentEmails = async (
    filter: FormFilterActivityFormData,
    currentPage: number = 1,
): Promise<ActivityRecord[] | undefined> => {
    const response = await nimbusApi.get<APIResponseSentEmails>(URLS.getSentEmails, {
        ...filter,
        numberOfDocsPerPage: NUMBER_OF_ACTIVITY_RECORDS_PER_PAGE,
        currentPage,
    });
    return response;
};

type APIResponseProspects = {
    data: Prospect[];
    total: number;
};

export const fetchProspects = async (
    filter: FormFilterProspectsFormData,
    currentPage: number = 1,
    pageSize: number = NUMBER_OF_PROSPECT_RECORDS_PER_PAGE,
): Promise<APIResponseProspects | undefined> => {
    const response = await nimbusApi.get<APIResponseProspects>(URLS.getProspects, {
        ...filter,
        numberOfDocsPerPage: pageSize,
        currentPage,
    });
    return response;
};

type APIResponseUpdateUserProspect = NimbusUserProspect;

export type UpdateUserProspectData = {
    set: {
        status: 'archived';
    };
};

export const updateUserProspect = async (
    userProspectId: string,
    data: UpdateUserProspectData,
): Promise<APIResponseUpdateUserProspect | undefined> => {
    const userProspectResponse = await nimbusApi.patch<APIResponseUpdateUserProspect>(
        URLS.updateUserProspect.replace(':id', userProspectId),
        data,
    );
    return userProspectResponse;
};

type APIResponseLeadSearchConfig = LeadSearchConfig;

export const fetchLeadsConfig = async (): Promise<APIResponseLeadSearchConfig | undefined> => {
    const response = await nimbusApi.get<APIResponseLeadSearchConfig>(URLS.getLeadsConfig);
    return response;
};

export type SearchLeadsRequest = {
    person?: {
        jobTitles?: {
            include?: string[];
            exclude?: string[];
        };
        managementLevels?: string[];
        departments?: string[];
        locations?: string[];
    };
    company?: {
        industries?: {
            include?: string[];
            exclude?: string[];
        };
        keywords?: string[];
        revenue?: {
            min?: number;
            max?: number;
        };
        domains?: string[];
        locations?: string[];
        employees?: {
            min?: number;
            max?: number;
        };
        employeesByDepartment?: {
            department?: string;
            min?: number;
            max?: number;
        };
        foundedYear?: {
            min?: number;
            max?: number;
        };
        fundingLevels?: string[];
        technologies?: string[];
    };
    page: number;
};

type PaginationInfo = {
    page: number;
    perPage: number;
    totalEntries: number;
    totalPages: number;
};

type PeopleInfo = LeadSearchResultItem[];

export type APIResponseSearchLeads = {
    pagination: PaginationInfo;
    people: PeopleInfo;
    userProspectCount: number;
};

export const searchLeads = async (
    filter: SearchLeadsRequest,
): Promise<APIResponseSearchLeads | undefined> => {
    const response = await nimbusApi.post<APIResponseSearchLeads>(URLS.searchLeads, filter);
    return response;
};

export type APIResponseJobs = Job[];

// export type APIResponseJobs = {
//     data: Job[];
//     total: number;
// };

export const fetchJobs = async (type: JobTypes): Promise<APIResponseJobs | undefined> => {
    const response = await nimbusApi.get<APIResponseJobs>(URLS.getJobs, {
        type,
    });
    return response;
};

export type APIResponseImportProspects = {
    accepted: boolean;
};

export const importProspects = async (): Promise<APIResponseImportProspects | undefined> => {
    const response = await nimbusApi.post<APIResponseImportProspects>(URLS.importProspects);
    return response;
};
