// import React, { useEffect } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { type UserIndustryPitch } from 'interfaces/userEx.type';
import Button from 'components/Button';
import Icon, { ICON_NAMES } from 'components/Icon';
import Typography from 'components/Typography';
import IndustryPitchItem from 'components/IndustryPitchItem';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import IndustryPitchEditDialog from 'components/dialogs/IndustryPitchEditDialog';
import { type IndustryPitchFormData } from 'forms/FormIndustryPitch';

import styles from './style.module.scss';

type FormIndustryPitchesProps = {
    userIndustryPitches?: UserIndustryPitch[];
    disabled: boolean;
    onSubmitIndustryPitch: (_industryPitch: UserIndustryPitch) => Promise<void>;
    onDeleteIndustryPitch: (_industryPitch: UserIndustryPitch) => Promise<void>;
};

const FormIndustryPitches: React.FC<FormIndustryPitchesProps> = ({
    userIndustryPitches = [],
    disabled,
    onSubmitIndustryPitch,
    onDeleteIndustryPitch,
}) => {
    const [selectedIndustryPitch, setSelectedIndustryPitch] = React.useState<
        UserIndustryPitch | undefined
    >(undefined);
    const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);

    const { t } = useTranslation(['forms']);

    const handleEditClick = (industryPitch: UserIndustryPitch): void => {
        setSelectedIndustryPitch(industryPitch);
        setOpenEditDialog(true);
    };

    const handleDeleteClick = (industryPitch: UserIndustryPitch): void => {
        setSelectedIndustryPitch(industryPitch);
        setOpenDeleteDialog(true);
    };

    const handleAddClick = (): void => {
        setSelectedIndustryPitch(undefined);
        setOpenEditDialog(true);
    };

    const handleSubmit = async (values: IndustryPitchFormData): Promise<void> => {
        const userIndustryPitch: UserIndustryPitch = {
            ...(selectedIndustryPitch || {}),
            ...values,
        };
        await onSubmitIndustryPitch(userIndustryPitch);
        setOpenEditDialog(false);
    };

    const handleCancel = (): void => {
        setOpenEditDialog(false);
    };

    const handleDeleteConfirm = async (): Promise<void> => {
        if (!selectedIndustryPitch) return;
        await onDeleteIndustryPitch(selectedIndustryPitch);
        setOpenDeleteDialog(false);
    };

    const handleDeleteCancel = (): void => {
        setOpenDeleteDialog(false);
    };

    return (
        <>
            <div className={styles.container}>
                <Typography variant='bodyRegular1'>{t('industry_pitches.description')}</Typography>
                <div className={styles.list}>
                    {userIndustryPitches.map((userIndustryPitch, index) => (
                        <IndustryPitchItem
                            key={index}
                            userIndustryPitch={userIndustryPitch}
                            onEditClick={handleEditClick}
                            onDeleteClick={handleDeleteClick}
                        />
                    ))}
                </div>
                <div>
                    <Button variant='secondary' onClick={handleAddClick}>
                        <div className={styles['button-icon']}>
                            <Icon name={ICON_NAMES.PlusCircle} />
                            {t('industry_pitches.add')}
                        </div>
                    </Button>
                </div>
            </div>
            <IndustryPitchEditDialog
                open={openEditDialog}
                userIndustryPitch={selectedIndustryPitch}
                disabled={disabled}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
            <ConfirmationDialog
                open={openDeleteDialog}
                title={t('industry_pitches.delete_title')}
                content={
                    <>
                        <Typography variant='bodyRegular1'>
                            {t('industry_pitches.delete_message')}
                        </Typography>
                        <Typography variant='bodyBold1'>
                            {selectedIndustryPitch?.pitchName}
                        </Typography>
                    </>
                }
                confirmVariant='primary-danger'
                onConfirm={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
            />
        </>
    );
};

export default FormIndustryPitches;
