import classNames from 'classnames';

import Typography from 'components/Typography';

import styles from './style.module.scss';

type ChipProps = {
    children: React.ReactNode;
    color?: string;
    className?: string;
    gradient?: boolean;
};

const Chip: React.FC<ChipProps> = ({ color, className, gradient, children }) => {
    const style: React.CSSProperties = {};
    if (color) {
        style.backgroundColor = color;
    }
    const classNamesContainer = classNames(
        styles.container,
        {
            [styles.gradient]: gradient,
        },
        className,
    );

    return (
        <div className={classNamesContainer} style={style}>
            <Typography variant='bodyRegular2'>{children}</Typography>
        </div>
    );
};

export default Chip;
