import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Dialog as RadixDialog, DialogContent, DialogOverlay } from '@radix-ui/react-dialog';

import Button from 'components/Button';
import Icon, { ICON_NAMES } from 'components/Icon';
import Typography from 'components/Typography';

import styles from './style.module.scss';

export type DialogProps = {
    open?: boolean;
    title?: string;
    classNameContainer?: string;
    className?: string;
    children: React.ReactNode;
    onClose?: () => void;
};

export const Dialog: React.FC<DialogProps> = ({
    open: openExternal,
    title,
    classNameContainer,
    className,
    children,
    onClose,
}) => {
    const [open, setOpen] = useState(false);

    const classNamesContainer = classNames(styles.container, classNameContainer);
    const classNamesContent = classNames(styles.content, className);

    useEffect(() => {
        if (openExternal !== undefined) {
            setOpen(openExternal || false);
        }
    }, [openExternal]);

    const handleClose = (): void => {
        setOpen(false);
        if (onClose) onClose();
    };

    return (
        <RadixDialog open={open} modal>
            <DialogOverlay className={styles.overlay}>
                <DialogContent
                    className={classNamesContainer}
                    onEscapeKeyDown={handleClose}
                    onPointerDownOutside={handleClose}
                >
                    <Button className={styles.close} variant='icon' onClick={handleClose}>
                        <Icon name={ICON_NAMES.X} width={16} height={16} />
                    </Button>
                    {title && <Typography variant='heading3'>{title}</Typography>}
                    <div className={classNamesContent}>{children}</div>
                </DialogContent>
            </DialogOverlay>
        </RadixDialog>
    );
};

export default Dialog;
