import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IntegrationAppProvider, useIntegrationApp } from '@integration-app/react';

import { ROUTES } from 'constants/routes/routes';
import { PROFILE_TABS } from 'interfaces/profile.type';
import { type UserInfoExFormData } from 'interfaces/userEx.type';
import FormAutopilotSettings, {
    DELAY_BEFORE_AUTOSEND,
    MAX_SEND_FREQUENCY,
    type FormAutopilotSettingsFormData,
} from 'forms/FormAutopilotSettings';
import FormEmailSettings, { type FormEmailSettingsFormData } from 'forms/FormEmailSettings';
import Typography from 'components/Typography';

import { trackEventProfileInfoChange } from 'services/eventsTracker';
import {
    getEmailIntegrationSetting,
    getIntegrationKey,
    getOutboundEmailType,
    isSendViaHoneysales,
} from 'services/email';
import { PROSPECT_CONTACT_STATUS } from 'services/honeycomb';
import {
    useFetchIAToken,
    useFetchIntegrationProfiles,
    useIAToken,
    useIntegrationProfiles,
    useLoadingCRM,
    useLoadingUserEx,
    useUpdateUserEx,
    useUserEx,
} from 'stores/useBoundStore';
import { isAuthorizedToUseAutopilot } from 'stores/userSlice/isAuthorized';

import styles from './style.module.scss';

type SettingsEmailProps = {
    disabled?: boolean;
};

const SettingsEmailContent: React.FC<SettingsEmailProps> = ({ disabled = false }) => {
    const { t } = useTranslation('profile');

    const user = useUserEx();
    const loadingUserEx = useLoadingUserEx();
    const loadingCRM = useLoadingCRM();
    const updateUserEx = useUpdateUserEx();
    const fetchIntegrationProfiles = useFetchIntegrationProfiles();
    const integrationProfiles = useIntegrationProfiles();

    const integrationApp = useIntegrationApp();

    const loading = loadingUserEx || loadingCRM;
    const formDisabled = disabled || loading;

    const formDefaultValues: FormEmailSettingsFormData = useMemo(
        () => ({
            sendColdMessagesVia: getIntegrationKey(user?.settings?.emails?.sendColdMessagesVia),
            sendWarmMessagesVia: getIntegrationKey(user?.settings?.emails?.sendWarmMessagesVia),
            bccAddress: user?.settings?.emails?.bccAddress || '',
        }),
        [user, integrationProfiles],
    );

    const optionsSendViaCold = useMemo(() => {
        const options = [];
        if (user?.esp?.cold?.email) {
            options.push({
                integrationKey: 'honeysales',
                email: user?.esp?.cold?.email,
            });
        }
        integrationProfiles.forEach(({ integrationKey, email }) =>
            options.push({
                integrationKey,
                email,
            }),
        );
        return options;
    }, [user, integrationProfiles]);

    const optionsSendViaWarm = useMemo(() => {
        const options = [];
        if (user?.esp?.warm?.email) {
            options.push({
                integrationKey: 'honeysales',
                email: user?.esp?.warm?.email,
            });
        }
        integrationProfiles.forEach(({ integrationKey, email }) =>
            options.push({
                integrationKey,
                email,
            }),
        );
        return options;
    }, [user, integrationProfiles]);

    const { isAnyIntegrationAvailable } = getEmailIntegrationSetting(integrationProfiles, user);

    useEffect(() => {
        fetchIntegrationProfiles(integrationApp);
    }, [integrationApp]);

    const handleSubmit = async (values: FormEmailSettingsFormData): Promise<void> => {
        const { sendColdMessagesVia, sendWarmMessagesVia, bccAddress } = values;
        let updated = false;
        const formData: UserInfoExFormData = {
            settings: {
                emails: {},
            },
        };
        if (typeof sendColdMessagesVia === 'string' && formData?.settings?.emails) {
            formData.settings.emails.sendColdMessagesVia =
                getOutboundEmailType(sendColdMessagesVia);
            updated = true;
        }
        if (typeof sendWarmMessagesVia === 'string' && formData?.settings?.emails) {
            formData.settings.emails.sendWarmMessagesVia =
                getOutboundEmailType(sendWarmMessagesVia);
            updated = true;
        }
        if (typeof bccAddress === 'string' && formData?.settings?.emails) {
            formData.settings.emails.bccAddress = bccAddress;
            updated = true;
        }

        if (updated) {
            const success = await updateUserEx(formData);
            if (success) {
                toast.success(t('email_settings.update_success'));
            }
            trackEventProfileInfoChange();
        }
    };

    return (
        <div className={styles.card}>
            <Typography variant='heading3'>{t('email_settings.title')}</Typography>
            {!loading && isAnyIntegrationAvailable === false && (
                <div>
                    <Typography inline variant='bodyRegular1'>
                        {t('email_settings.no_email_integrations_available_text')}
                    </Typography>{' '}
                    <Link to={ROUTES.PROFILE_DETAIL.replace(':page', PROFILE_TABS.Integrations)}>
                        <Typography className={styles.link} inline variant='bodyRegular1'>
                            {t('email_settings.go_to_integrations')}
                        </Typography>
                    </Link>{' '}
                    <Typography inline variant='bodyRegular1'>
                        {t('email_settings.no_email_integrations_available_text2')}
                    </Typography>
                </div>
            )}
            {isAnyIntegrationAvailable && (
                <FormEmailSettings
                    optionsSendViaCold={optionsSendViaCold}
                    optionsSendViaWarm={optionsSendViaWarm}
                    initialValues={formDefaultValues}
                    disabled={formDisabled}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
};

const SettingsAutopilotContent: React.FC = () => {
    const { t } = useTranslation('profile');

    const user = useUserEx();
    const loadingUserEx = useLoadingUserEx();
    const updateUserEx = useUpdateUserEx();

    const sendViaHoneysales = isSendViaHoneysales(user);

    const loading = loadingUserEx;
    const formDisabled = loading;

    const formDefaultValues: FormAutopilotSettingsFormData = useMemo(
        () => ({
            enabled: user?.settings?.autopilot?.enabled ?? false,
            contactStatus: user?.settings?.autopilot?.contactStatus || [
                PROSPECT_CONTACT_STATUS.cold,
            ],
            maxSendFrequency:
                user?.settings?.autopilot?.maxSendFrequency ||
                (sendViaHoneysales ? MAX_SEND_FREQUENCY.Once : MAX_SEND_FREQUENCY.EveryTwoWeeks),
            delayBeforeAutosend:
                user?.settings?.autopilot?.delayBeforeAutosend || DELAY_BEFORE_AUTOSEND.FourHours,
        }),
        [user],
    );

    const handleSubmit = async (values: FormAutopilotSettingsFormData): Promise<void> => {
        const { enabled, contactStatus, maxSendFrequency, delayBeforeAutosend } = values;
        if (
            Array.isArray(contactStatus) &&
            typeof maxSendFrequency === 'string' &&
            typeof delayBeforeAutosend === 'string'
        ) {
            const formData: UserInfoExFormData = {
                settings: {
                    autopilot: {
                        enabled: enabled ?? false,
                        contactStatus,
                        maxSendFrequency,
                        delayBeforeAutosend,
                    },
                },
            };
            const success = await updateUserEx(formData);
            if (success) {
                toast.success(t('email_settings.update_success'));
            }
        }
        trackEventProfileInfoChange();
    };

    return (
        <div className={styles.card}>
            <Typography variant='heading3'>{t('autopilot_settings.title')}</Typography>
            <FormAutopilotSettings
                showEnabled
                initialValues={formDefaultValues}
                disabled={formDisabled}
                sendViaHoneysales={sendViaHoneysales}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

const SettingsEmail: React.FC = () => {
    const iaToken = useIAToken();
    const fetchIAToken = useFetchIAToken();
    const loadingCRM = useLoadingCRM();
    const user = useUserEx();

    useEffect(() => {
        fetchIAToken();
    }, []);

    const isUserAuthorized = isAuthorizedToUseAutopilot(user);

    if (!iaToken) {
        return <></>;
    }

    return (
        <IntegrationAppProvider token={iaToken}>
            <div className={styles.container}>
                <SettingsEmailContent disabled={loadingCRM} />
                {isUserAuthorized && <SettingsAutopilotContent />}
            </div>
        </IntegrationAppProvider>
    );
};
export default SettingsEmail;
