import type { StateCreator } from 'zustand';

import { type User } from 'interfaces/user.type';
import {
    type Industry,
    type SignalCategory,
    type UserEx,
    type UserInfoExFormData,
} from 'interfaces/userEx.type';
import { type UserInfoFormData } from 'forms/FormPersonalInformation';
import * as dataService from 'services/honeycomb';
import * as nimbusService from 'services/nimbus';
import { handleAPIError } from 'services/errorHandler';
import { apm } from 'services/apm';
import { retrieveUserId } from 'services/storage';

export type UserSlice = {
    loadingUser: boolean;
    errorUser: string | undefined;
    user: User | undefined;

    loadingUserEx: boolean;
    errorUserEx: string | undefined;
    userEx: UserEx | undefined;

    proxyLogin: boolean;
    invalidateProxyLogin: () => void;

    signalCategories: SignalCategory[];
    industries: Industry[];

    fetchUser: (_userId: string) => Promise<User | undefined>;
    fetchCurrentUser: () => Promise<User | undefined>;
    updateUser: (_data: UserInfoFormData) => Promise<void>;

    // new User that is used in Nimbus
    fetchCurrentUserEx: () => Promise<UserEx | undefined>;
    updateUserEx: (_data: UserInfoExFormData) => Promise<boolean>;

    fetchSignalCategories: () => Promise<void>;
    fetchIndustries: () => Promise<void>;
};

export const createUserSlice: StateCreator<UserSlice> = (set, get) => ({
    loadingUser: false,
    errorUser: undefined,
    user: undefined,
    proxyLogin: false,

    loadingUserEx: false,
    errorUserEx: undefined,
    userEx: undefined,

    signalCategories: [],
    industries: [],

    invalidateProxyLogin: (): void => {
        const value: boolean = Boolean(retrieveUserId());
        set((_state) => ({ proxyLogin: value }));
    },

    fetchUser: async (userId: string): Promise<User | undefined> => {
        set((_state) => ({ loadingUser: true, errorUser: undefined }));
        let user: User | undefined;
        try {
            const userInfo = await dataService.fetchUserById(userId);
            if (userInfo) {
                user = { ...userInfo };
                set((_state) => ({ user }));
            }
        } catch (error: unknown) {
            set((_state) => ({ errorUser: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingUser: false }));
        }
        return user;
    },

    fetchCurrentUser: async (): Promise<User | undefined> => {
        set((_state) => ({ loadingUser: true, errorUser: undefined }));
        let user: User | undefined;
        try {
            const userInfo = await dataService.fetchCurrentUser();
            if (userInfo) {
                user = { ...userInfo };
                set((_state) => ({ user }));
            }
        } catch (error: unknown) {
            set((_state) => ({ errorUser: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingUser: false }));
        }
        return user;
    },

    updateUser: async (data: UserInfoFormData): Promise<void> => {
        set((_state) => ({ loadingUser: true, errorUser: undefined }));
        try {
            let user = get().user;
            if (user) {
                const userInfo = await dataService.updateUser(user?.id, data);
                if (userInfo) {
                    user = { ...userInfo };
                    set((_state) => ({ user }));
                }
            }
        } catch (error: unknown) {
            set((_state) => ({ errorUser: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingUser: false }));
        }
    },

    fetchCurrentUserEx: async (): Promise<UserEx | undefined> => {
        set((_state) => ({ loadingUserEx: true, errorUserEx: undefined }));
        let user: UserEx | undefined;
        try {
            const userInfo = await nimbusService.fetchCurrentUserEx();
            if (userInfo) {
                user = { ...userInfo };
                apm.setUserContext({
                    id: userInfo._id,
                    email: userInfo.email,
                    username: `${userInfo.firstName} ${userInfo.lastName}`,
                });
                set((_state) => ({ userEx: user }));
            }
        } catch (error: unknown) {
            set((_state) => ({ errorUserEx: error?.toString() }));
            handleAPIError(error);
        } finally {
            set((_state) => ({ loadingUserEx: false }));
        }
        return user;
    },

    updateUserEx: async (data: UserInfoExFormData): Promise<boolean> => {
        set((_state) => ({ loadingUserEx: true, errorUserEx: undefined }));
        try {
            let user = get().userEx;
            if (user) {
                const updatedUser = await nimbusService.updateCurrentUserEx(data);
                if (updatedUser) {
                    set((_state) => ({ userEx: updatedUser }));
                    return true;
                }
            }
            return false;
        } catch (error: unknown) {
            set((_state) => ({ errorUserEx: error?.toString() }));
            handleAPIError(error);
            return false;
        } finally {
            set((_state) => ({ loadingUserEx: false }));
        }
    },

    fetchSignalCategories: async (): Promise<void> => {
        try {
            const signalCategories = await nimbusService.fetchSignalCategories();
            set((_state) => ({ signalCategories }));
        } catch (error: unknown) {
            handleAPIError(error);
        }
    },

    fetchIndustries: async (): Promise<void> => {
        try {
            const industries = await nimbusService.fetchIndustries();
            set((_state) => ({ industries }));
        } catch (error: unknown) {
            handleAPIError(error);
        }
    },
});
