import { type Connection } from '@integration-app/sdk';

export const getActiveConnection = (connections: Connection[]): Connection | undefined => {
    if (connections && Array.isArray(connections)) {
        const activeConnections = connections.filter(
            (connection) => connection?.disconnected !== true,
        );
        if (activeConnections.length > 0) return activeConnections[0];
    }
    return undefined;
};
