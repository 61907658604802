import { type Match, type Signal } from 'interfaces/match.type';
import { type SignalEmailInputFormData } from 'components/SignalEmailForm';
import { getMatchFullName } from 'components/MatchInfoHeader/getMatchFullName';

export const getSignalEmailInputFormData = (
    match: Match,
    signal: Signal,
): SignalEmailInputFormData => {
    let name = getMatchFullName(match);
    if (name !== '') name += ' ';
    return {
        from: signal.emailFrom || '',
        recipient: `${name}<${match.email}>`,
        subject: signal.emailSubject || '',
        text: signal.emailMessage || '',
        sentAt: signal.emailSentAt || undefined,
    };
};
