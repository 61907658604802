import classNames from 'classnames';

import imgSpinner from 'assets/images/honeysales.png';

import styles from './styles.module.scss';

type Props = {
    size?: number;
    absolute?: boolean;
};

export const Loading: React.FC<Props> = ({ size = 48, absolute = false }) => {
    const style = { width: size, height: size };
    const classLoading = classNames(styles.loading, {
        [styles.absolute]: absolute,
    });
    return (
        <div className={classLoading} style={style}>
            <img className={styles.spinner} src={imgSpinner} alt='loading' />
        </div>
    );
};

export default Loading;
