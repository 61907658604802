import { useEffect, useState, type FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldInput from 'components/FormFieldInput';
import Loading from 'components/Loading';

import { useLoadingSendEmail } from 'stores/useBoundStore';

import styles from './style.module.scss';

export type TestEmailFormData = {
    recipient: string;
};

export const defaultValuesTestEmailFormData: TestEmailFormData = {
    recipient: '',
};

type FormSendTestEmailProps = {
    onSubmit?: (_values: TestEmailFormData) => void;
};

const FormSendTestEmail: React.FC<FormSendTestEmailProps> = ({ onSubmit }) => {
    const [formValues, setFormValues] = useState<TestEmailFormData>(defaultValuesTestEmailFormData);

    const loading = useLoadingSendEmail();
    const sendDisabled = loading || !formValues.recipient;

    const { t } = useTranslation('profile');

    useEffect(() => {
        setFormValues(defaultValuesTestEmailFormData);
    }, []);

    const handleChangeInput = (name: string, value: string): void => {
        const newFormValues = {
            ...formValues,
            [name]: value,
        };
        setFormValues(newFormValues);
    };

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        const formDataObject: TestEmailFormData = defaultValuesTestEmailFormData;
        const recipient = formData.get('recipient');
        if (typeof recipient === 'string') {
            formDataObject.recipient = recipient;
        }
        onSubmit?.(formDataObject);
    };

    return (
        <div className={styles.container}>
            <Form.Root className={styles.form} onSubmit={handleFormSubmit}>
                <div className={styles.fields}>
                    <FormFieldInput
                        label={t('email_integration.label_recipient')}
                        name='recipient'
                        type='email'
                        placeholder={t('email_integration.placeholder_recipient')}
                        errorMessageTypeMismatch={t('email_integration.error_invalid_email')}
                        disabled={loading}
                        required
                        value={formValues.recipient}
                        onChange={handleChangeInput}
                    />
                </div>

                <div className={styles.actions}>
                    <div className={styles['actions-send']}>
                        <Form.Submit asChild disabled={sendDisabled}>
                            <Button>{t('email_integration.button_send_test_email')}</Button>
                        </Form.Submit>
                        {loading && <Loading size={24} />}
                    </div>
                </div>
            </Form.Root>
        </div>
    );
};

export default FormSendTestEmail;
