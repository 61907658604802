import React from 'react';
import { Trans } from 'react-i18next';

import type { BirthdaySignal } from 'interfaces/match.type';

import Typography from 'components/Typography';

import imgBirthday from 'assets/images/birthday.jpg';

import styles from './style.module.scss';

type Props = {
    signal: BirthdaySignal;
};

const SignalContentBirthday: React.FC<Props> = ({ signal }) => {
    const signalValues: {
        name: string;
        age: string;
    } = {
        name: signal.name || '',
        age: String(signal.age) || '',
    };
    return (
        <div className={styles.container}>
            <div className={styles.image}>
                <img src={imgBirthday} alt='Birthday' />
            </div>
            <div className={styles.info}>
                <Typography variant='bodyBold1' inline>
                    <Trans i18nKey={'matches:info.birthday_signal_text'} values={signalValues}>
                        {{
                            name: signalValues.name,
                        }}{' '}
                        ist{' '}
                        {{
                            age: signalValues.age,
                        }}{' '}
                        Jahre alt geworden.
                    </Trans>
                </Typography>
            </div>
        </div>
    );
};

export default SignalContentBirthday;
