import { useEffect, useState, type FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldTextArea from 'components/FormFieldTextArea';

import styles from './style.module.scss';

export type DiscardReasonFormData = {
    customReason: string;
};

export const defaultValuesDiscardReasonFormData: DiscardReasonFormData = {
    customReason: '',
};

type FormDiscardReasonProps = {
    onSubmit?: (_values: DiscardReasonFormData) => void;
    onCancel?: () => void;
};

const FormDiscardReason: React.FC<FormDiscardReasonProps> = ({ onSubmit, onCancel }) => {
    const [formValues, setFormValues] = useState<DiscardReasonFormData>({
        ...defaultValuesDiscardReasonFormData,
    });

    const disabledSubmit = !formValues.customReason;

    const { t } = useTranslation('forms');

    useEffect(() => {
        setFormValues({
            ...defaultValuesDiscardReasonFormData,
        });
    }, []);

    const handleChangeInput = (name: string, value: string): void => {
        const newFormValues = {
            ...formValues,
            [name]: value,
        };
        setFormValues(newFormValues);
    };

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        const formDataObject: DiscardReasonFormData = { ...defaultValuesDiscardReasonFormData };
        const customReason = formData.get('customReason');
        if (typeof customReason === 'string') {
            formDataObject.customReason = customReason;
        }
        onSubmit?.(formDataObject);
    };

    return (
        <div className={styles.container}>
            <Form.Root className={styles.form} onSubmit={handleFormSubmit}>
                <div className={styles.fields}>
                    <FormFieldTextArea
                        name='customReason'
                        placeholder={t('discard_signal.placeholder_custom_reason')}
                        required
                        value={formValues.customReason}
                        rows={4}
                        onChange={handleChangeInput}
                    />
                </div>

                <div className={styles.actions}>
                    <div className={styles['actions-send']}>
                        <Form.Submit asChild disabled={disabledSubmit}>
                            <Button>{t('discard_signal.button_discard')}</Button>
                        </Form.Submit>
                        <Button variant='secondary' onClick={onCancel}>
                            {t('discard_signal.button_cancel')}
                        </Button>
                    </div>
                </div>
            </Form.Root>
        </div>
    );
};

export default FormDiscardReason;
