import React, { type FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldPasswordInput from 'components/FormFieldPasswordInput';
import Message from 'components/Message';
import Typography from 'components/Typography';

import { extractFormData } from './extractFormData';

import styles from './style.module.scss';

export type SetPasswordFormData = {
    password: string;
    passwordConfirmation: string;
    code: string;
};

type Props = {
    error?: string;
    disabled?: boolean;
    onSubmit?: (_values: SetPasswordFormData) => void;
};

const FormSetPassword: React.FC<Props> = ({ error, disabled = false, onSubmit }) => {
    const { t } = useTranslation('auth');

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        if (onSubmit) {
            const formData = new FormData(event.target as HTMLFormElement);
            const data = extractFormData(formData);
            onSubmit(data);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles['text-container']}>
                <Typography className={styles['text-title']}>
                    {t('form.text_set_password_title')}
                </Typography>
            </div>
            {error && <Message error>{t(error)}</Message>}
            <Form.Root
                className={styles.form}
                onSubmit={handleFormSubmit}
                noValidate // TODO: to remove later
            >
                <div className={styles.fields}>
                    <FormFieldPasswordInput
                        id='new-password'
                        name='password'
                        label={t('form.label_new_password')}
                        placeholder={t('form.placeholder_new_password')}
                        autoComplete='new-password'
                        variant='large'
                        required
                        disabled={disabled}
                    />
                    <FormFieldPasswordInput
                        id='new-password-confirmation'
                        name='passwordConfirmation'
                        label={t('form.label_password_confirmation')}
                        placeholder={t('form.placeholder_password_confirmation')}
                        autoComplete='new-password'
                        variant='large'
                        required
                        disabled={disabled}
                    />
                </div>

                <div className={styles.submit}>
                    <Form.Submit asChild disabled={disabled}>
                        <Button block size='large'>
                            {t('form.save_password')}
                        </Button>
                    </Form.Submit>
                </div>
            </Form.Root>
        </div>
    );
};

export default FormSetPassword;
