import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldInput from 'components/FormFieldInput';

import styles from './style.module.scss';

export type ChangePasswordFormData = {
    password: string;
    passwordConfirmation: string;
    currentPassword: string;
};

export type UserInfoFormData = {
    firstName: string;
    lastName: string;
};

export type FormPersonalInformationSubmit = {
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
};

type Props = {
    initialValues?: Partial<FormPersonalInformationSubmit>;
    disabled?: boolean;
    onSubmit: (_values: Partial<FormPersonalInformationSubmit>) => void;
};

const FormPersonalInformation: React.FC<Props> = ({
    initialValues = {},
    disabled = false,
    onSubmit,
}) => {
    const [formValues, setFormValues] =
        useState<Partial<FormPersonalInformationSubmit>>(initialValues);

    const { t } = useTranslation('forms');

    useEffect(() => {
        setFormValues(initialValues);
    }, [initialValues]);

    const handleChangeInput = (name: string, value: string): void => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = Object.fromEntries(new FormData(event.currentTarget));
        if (onSubmit) onSubmit(formData);
    };

    const handleClickCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        setFormValues(initialValues);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <div className={styles['name-group']}>
                <FormFieldInput
                    name='firstName'
                    label={t('personal_information.firstname')}
                    placeholder={t('personal_information.placeholder_firstname')}
                    disabled={disabled}
                    required
                    value={formValues.firstName}
                    onChange={handleChangeInput}
                />
                <FormFieldInput
                    name='lastName'
                    label={t('personal_information.lastname')}
                    placeholder={t('personal_information.placeholder_lastname')}
                    disabled={disabled}
                    required
                    value={formValues.lastName}
                    onChange={handleChangeInput}
                />
            </div>
            <FormFieldInput
                label={t('personal_information.email')}
                name='email'
                disabled={true}
                required
                value={formValues.email}
                onChange={handleChangeInput}
            />
            {/* changing password is disabled for now
            <FormFieldPasswordInput
                name='password'
                label={t('personal_information.password')}
                placeholder={
                    t('personal_information.placeholder_password')
                }
                autoComplete='new-password'
                disabled={disabled}
                value={formValues.password}
                onChange={handleChangeInput}
            />
            */}
            <div className={styles.submit}>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={disabled}>
                        {t('personal_information.save_changes')}
                    </Button>
                </Form.Submit>
                <Button variant='secondary' disabled={disabled} onClick={handleClickCancel}>
                    {t('personal_information.cancel')}
                </Button>
            </div>
        </Form.Root>
    );
};

export default FormPersonalInformation;
