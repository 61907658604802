import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldTextArea from 'components/FormFieldTextArea';

import styles from './style.module.scss';

export type FormOfferingAndPitchFormData = {
    offeringDescription?: string;
    defaultPitch?: string;
    referenceCase?: string;
};

type FormOfferingAndPitchProps = {
    showEnabled: boolean;
    initialValues?: FormOfferingAndPitchFormData;
    disabled?: boolean;
    onSubmit: (_values: FormOfferingAndPitchFormData) => void;
    onCancel?: () => void;
};

const defaultValues: FormOfferingAndPitchFormData = {
    offeringDescription: '',
    defaultPitch: '',
    referenceCase: '',
};

const getFormData = (
    formValues: { [key: string]: FormDataEntryValue },
    values: FormOfferingAndPitchFormData = defaultValues,
): FormOfferingAndPitchFormData => {
    return {
        offeringDescription: (formValues?.offeringDescription ||
            values.offeringDescription) as string,
        defaultPitch: (formValues?.defaultPitch || values.defaultPitch) as string,
        referenceCase: (formValues?.referenceCase || values.referenceCase) as string,
    };
};

const FormOfferingAndPitch: React.FC<FormOfferingAndPitchProps> = ({
    initialValues = defaultValues,
    disabled = false,
    onSubmit,
}) => {
    const [formData, setFormData] = React.useState<FormOfferingAndPitchFormData>(initialValues);

    const { t } = useTranslation(['forms']);

    const fieldValueDisabled = disabled;
    const submitDisabled =
        disabled ||
        !formData.offeringDescription ||
        !formData.defaultPitch ||
        !formData.referenceCase;

    useEffect(() => {
        if (initialValues) setFormData(initialValues);
    }, [initialValues]);

    const handleChangeInput = (name: string, value: string): void => {
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formDataFromEntries = Object.fromEntries(new FormData(event.currentTarget));
        const formDataParsed = getFormData(formDataFromEntries, formData);
        if (onSubmit) onSubmit(formDataParsed);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <FormFieldTextArea
                label={t('offering_and_pitch.offering_description.title')}
                name={'offeringDescription'}
                placeholder={t('offering_and_pitch.offering_description.placeholder')}
                helperText={t('offering_and_pitch.offering_description.helper_text')}
                rows={4}
                maxLength={1000}
                variant='default'
                value={formData.offeringDescription}
                disabled={fieldValueDisabled}
                onChange={handleChangeInput}
            />

            <FormFieldTextArea
                label={t('offering_and_pitch.default_pitch.title')}
                name={'defaultPitch'}
                placeholder={t('offering_and_pitch.default_pitch.placeholder')}
                helperText={t('offering_and_pitch.default_pitch.helper_text')}
                rows={4}
                maxLength={500}
                value={formData.defaultPitch}
                disabled={fieldValueDisabled}
                onChange={handleChangeInput}
            />

            <FormFieldTextArea
                label={t('offering_and_pitch.reference_case.title')}
                name={'referenceCase'}
                placeholder={t('offering_and_pitch.reference_case.placeholder')}
                helperText={t('offering_and_pitch.reference_case.helper_text')}
                rows={4}
                maxLength={500}
                value={formData.referenceCase}
                disabled={fieldValueDisabled}
                onChange={handleChangeInput}
            />

            <div className={styles.submit}>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={submitDisabled}>
                        {t('offering_and_pitch.button_save_settings')}
                    </Button>
                </Form.Submit>
            </div>
        </Form.Root>
    );
};

export default FormOfferingAndPitch;
