import { useTranslation } from 'react-i18next';
import type { ColumnDef } from '@tanstack/react-table';

import type { Job } from 'interfaces/job';
import { formatISODateAsString } from 'components/SignalCard/utils';

type HeaderWithTranslationProps = {
    className?: string;
    title: string;
};

const HeaderWithTranslation: React.FC<HeaderWithTranslationProps> = ({ className, title }) => {
    const { t } = useTranslation('prospects');
    return <div className={className}>{t(title)}</div>;
};

type ValueWithTranslationProps = {
    ns?: string;
    value?: string;
};

const ValueWithTranslation: React.FC<ValueWithTranslationProps> = ({ ns, value }) => {
    const { t } = useTranslation(ns);
    if (!value) return null;
    const result = t(value);
    return <>{result}</>;
};

export const columns: ColumnDef<Job>[] = [
    {
        accessorKey: 'state.startedAt',
        header: () => <HeaderWithTranslation title='import_jobs.started' />,
        cell: ({ getValue }): JSX.Element | null => {
            const value = getValue<string>();
            if (!value) return null;
            return <div>{formatISODateAsString(value, 'yyyy-MM-dd HH:mm')}</div>;
        },
    },
    {
        accessorKey: 'state.endedAt',
        header: () => <HeaderWithTranslation title='import_jobs.ended' />,
        cell: ({ getValue }): JSX.Element | null => {
            const value = getValue<string>();
            if (!value) return null;
            return <div>{formatISODateAsString(value, 'yyyy-MM-dd HH:mm')}</div>;
        },
    },
    {
        accessorKey: 'state.importGoal',
        header: () => <HeaderWithTranslation title='import_jobs.import_goal' />,
    },
    {
        accessorKey: 'state.imported',
        header: () => <HeaderWithTranslation title='import_jobs.imported' />,
    },
    {
        accessorKey: 'state.existing',
        header: () => <HeaderWithTranslation title='import_jobs.existing' />,
    },
    {
        accessorKey: 'state.noEmail',
        header: () => <HeaderWithTranslation title='import_jobs.no_email' />,
    },
    {
        accessorKey: 'state.stage',
        header: () => <HeaderWithTranslation title='import_jobs.status' />,
        size: 0,
        cell: ({ getValue }): JSX.Element => {
            const value = getValue<string>();
            return <ValueWithTranslation ns='prospects' value={`job_status.${value}`} />;
        },
    },
];
