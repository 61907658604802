import type { ObjectValues } from './types';

export const PERFORMANCE_TABS = {
    Messages: 'messages',
    ClientList: 'client_list',
    Statistics: 'statistics',
} as const;

export type PerformanceTab = ObjectValues<typeof PERFORMANCE_TABS>;
export const PERFORMANCE_TAB_VALUES = Object.values(PERFORMANCE_TABS);
