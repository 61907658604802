import { AxiosError } from 'axios';

import env from '../env';
import createApiClient from './api';

export enum NimbusCustomHttpErrorCodes {
    _WoodpeckerProspectExists = 1_000,
    _SendingEmailThroughGmail = 1_001,
    _SendingEmailThroughSalesForce = 1_002,
    _CreatingContactInSalesForce = 1_003,
    _GettingEmailAndSignatureFromSalesforce = 1_004,
}

type NimbusHttpErrorDetails = Record<string, unknown> & {
    code?: NimbusCustomHttpErrorCodes;
};

type Details = NimbusHttpErrorDetails & {
    validationIssues?: unknown;
};

type NimbusErrorResponseData = {
    message: string;
    details?: Details;
};

export class NimbusAPIError extends Error {
    code?: number;
    status?: string;
    data?: NimbusErrorResponseData;
    constructor(message?: string, code?: number, status?: string, data?: NimbusErrorResponseData) {
        super(message);
        this.name = 'APIError';
        this.code = code;
        this.status = status;
        this.data = data;
    }
}

export const handleAPIError = (error: unknown): unknown => {
    if (error instanceof AxiosError) {
        // for debugging
        // if (error.request) console.error(error.request);
        // if (error.response) console.error(error.response.data);
        return new NimbusAPIError(
            `${error.response?.status}: ${error.response?.statusText}`,
            error.response?.status,
            error.response?.statusText,
            error.response?.data,
        );
    } else {
        return error;
    }
};

const nimbusApi = createApiClient(
    {
        baseURL: env.BASE_URL_NIMBUS,
        withCredentials: true,
    },
    handleAPIError,
);

export default nimbusApi;
