import { useTranslation } from 'react-i18next';

import InformationDialog from '../InformationDialog';

import styles from './style.module.scss';

export type AutopilotEnabledDialogProps = {
    open?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export const AutopilotEnabledDialog: React.FC<AutopilotEnabledDialogProps> = ({
    open,
    onConfirm,
}) => {
    const { t } = useTranslation('profile');

    return (
        <InformationDialog
            className={styles.dialog}
            open={open}
            title={t('autopilot_enabled.title')}
            content={
                <div className={styles.text}>
                    {t('autopilot_enabled.text')}
                    <span>
                        {t('autopilot_enabled.text_2')}{' '}
                        <strong>{t('autopilot_enabled.text_email_settings')}</strong>.
                    </span>
                </div>
            }
            labelConfirm={t('autopilot_enabled.label_confirm')}
            onClose={onConfirm}
        />
    );
};

export default AutopilotEnabledDialog;
