import type { NavItem } from 'components/Header';
import { ROUTES } from './routes';

export const NAV_MENU_ITEMS: NavItem[] = [
    {
        title: 'signals',
        path: ROUTES.SIGNALS,
    },
    {
        title: 'activity',
        path: ROUTES.ACTIVITY,
    },
    {
        title: 'prospects',
        path: ROUTES.PROSPECTS,
    },
    // Temporarily hiding Performance tab
    // {
    //     title: 'performance',
    //     path: ROUTES.PERFORMANCE,
    // },
];
