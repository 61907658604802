import { useEffect, useState } from 'react';

export type AnimatedStar = {
    id: number;
    left: string;
    top: string;
    animationDuration: string;
};

export const useStars = (containerRef: React.RefObject<HTMLDivElement>): AnimatedStar[] => {
    const [stars, setStars] = useState<AnimatedStar[]>([]);

    useEffect(() => {
        const createStar = (): void => {
            if (!containerRef.current) return;
            const containerWidth = containerRef.current.clientWidth;
            const containerHeight = containerRef.current.clientHeight;
            const newStar: AnimatedStar = {
                id: Date.now(),
                left: `${Math.random() * containerWidth}px`,
                top: `${Math.random() * containerHeight}px`,
                animationDuration: `${Math.random() * 5 + 4}s`,
            };

            setStars((prevStars) => [...prevStars, newStar]);

            setTimeout(() => {
                setStars((prevStars) => prevStars.filter((star) => star.id !== newStar.id));
            }, 3000); // star TTL
        };

        // interval for creating next star, less value - more stars
        const starCreationInterval = setInterval(createStar, 300);

        return () => clearInterval(starCreationInterval);
    }, []);

    return stars;
};
