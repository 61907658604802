import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { SignalCategory, UserSignalCategory } from 'interfaces/userEx.type';
import Typography from 'components/Typography';
import SignalCategoryItem from 'components/SignalCategoryItem';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import SignalCategoryEditDialog from 'components/dialogs/SignalCategoryEditDialog';
import { type SignalCategoryFormData } from 'forms/FormSignalCategory';

import styles from './style.module.scss';

type FormSignalCategoriesProps = {
    signalCategories: SignalCategory[];
    userSignalCategories?: UserSignalCategory[];
    disabled: boolean;
    onSubmitCategory: (_category: UserSignalCategory) => Promise<void>;
    onDisableCategory: (_category: UserSignalCategory) => Promise<void>;
};

const FormSignalCategories: React.FC<FormSignalCategoriesProps> = ({
    signalCategories,
    userSignalCategories = [],
    disabled,
    onSubmitCategory,
    onDisableCategory,
}) => {
    const [selectedCategory, setSelectedCategory] = React.useState<
        UserSignalCategory | undefined
    >();
    const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
    const [openDisableDialog, setOpenDisableDialog] = React.useState<boolean>(false);

    const { t, i18n } = useTranslation(['forms']);

    const selectedCategoryName = useMemo(() => {
        const category = signalCategories.find(
            (signalCategory) => signalCategory._id === selectedCategory?.categoryId,
        );
        if (!category) return '';
        return category.name[i18n.language] || '';
    }, [selectedCategory, signalCategories, i18n.language]);

    const handleEditClick = (category: UserSignalCategory): void => {
        setSelectedCategory(category);
        setOpenEditDialog(true);
    };

    const handleCheckboxClick = (category: UserSignalCategory): void => {
        if (category.isActive) {
            setSelectedCategory(category);
            setOpenDisableDialog(true);
        } else {
            setSelectedCategory(category);
            setOpenEditDialog(true);
        }
    };

    const handleSubmit = async (values: SignalCategoryFormData): Promise<void> => {
        if (!selectedCategory) return;
        const userSignalCategory: UserSignalCategory = {
            isActive: true,
            categoryId: selectedCategory.categoryId,
            ...values,
        };
        await onSubmitCategory(userSignalCategory);
        setOpenEditDialog(false);
    };

    const handleCancel = (): void => {
        setOpenEditDialog(false);
    };

    const handleDisableConfirm = async (): Promise<void> => {
        if (!selectedCategory) return;
        const userSignalCategory: UserSignalCategory = {
            ...selectedCategory,
            isActive: false,
        };
        await onDisableCategory(userSignalCategory);
        setOpenDisableDialog(false);
    };

    const handleDisableCancel = (): void => {
        setOpenDisableDialog(false);
    };

    return (
        <>
            <div className={styles.container}>
                <Typography variant='bodyRegular1'>{t('signal_categories.description')}</Typography>
                <div className={styles.header}>
                    <div className={styles['header-active']}>
                        <Typography variant='bodyBold2'>{t('signal_categories.active')}</Typography>
                    </div>
                    <div className={styles['header-category']}>
                        <Typography variant='bodyBold2'>
                            {t('signal_categories.category')}
                        </Typography>
                    </div>
                    <div className={styles['header-weight']}>
                        <Typography variant='bodyBold2'>{t('signal_categories.weight')}</Typography>
                    </div>
                    <div className={styles['header-rest']}></div>
                </div>
                {signalCategories
                    .sort((a, b) => a.name[i18n.language].localeCompare(b.name[i18n.language]))
                    .map((signalCategory) => {
                        const userSignalCategory = userSignalCategories.find(
                            (category) => category.categoryId === signalCategory._id,
                        ) || {
                            categoryId: signalCategory._id,
                            isActive: false,
                            weight: 100,
                        };
                        return (
                            <SignalCategoryItem
                                key={signalCategory._id}
                                signalCategories={signalCategories}
                                userSignalCategory={userSignalCategory}
                                onEditClick={(): void => handleEditClick(userSignalCategory)}
                                onCheckboxClick={(): void =>
                                    handleCheckboxClick(userSignalCategory)
                                }
                            />
                        );
                    })
                    .filter(Boolean)}
            </div>
            <SignalCategoryEditDialog
                open={openEditDialog}
                userSignalCategory={selectedCategory}
                disabled={disabled}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
            <ConfirmationDialog
                open={openDisableDialog}
                title={t('signal_categories.disable_title')}
                content={
                    <>
                        <Typography variant='bodyRegular1'>
                            {t('signal_categories.disable_message')}
                        </Typography>
                        <Typography variant='bodyBold1'>{selectedCategoryName}</Typography>
                    </>
                }
                confirmVariant='primary-danger'
                onConfirm={handleDisableConfirm}
                onCancel={handleDisableCancel}
            />
        </>
    );
};

export default FormSignalCategories;
