import React, { useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import clientEnv from 'env';
import Icon, { ICON_NAMES } from 'components/Icon';
import NavigationMenu from 'components/NavigationMenu';
import SupportPopup from 'components/SupportPopup';
import { ROUTES } from 'constants/routes/routes';
import { useScript } from 'hooks/useScript';
import { setEventParameters, trackEventLocaleChange } from 'services/eventsTracker';
import { useUserEx } from 'stores/useBoundStore';

import { getMenuItems } from './getMenuItems';
import { getNextLanguage } from './getNextLanguage';

import styles from './style.module.scss';

export type NavItem = {
    title: string;
    path: string;
};

type Props = {
    navMenuItems: NavItem[];
};

const Header: React.FC<Props> = ({ navMenuItems }) => {
    const { i18n } = useTranslation();

    const location = useLocation();
    const user = useUserEx();

    const menuItems = useMemo(() => getMenuItems(navMenuItems, user), [user]);

    window.beamer_config = {
        product_id: clientEnv.BEAMER_PRODUCT_ID || 'TwaHjUDd66182',
        selector: '#hs-beamer',
        top: 0,
        right: 0,
        button_position: 'bottom-right',
        display: 'right',
        bounce: false,
        counter: true,
        alert: true,
        delay: 0,
        language: String(i18n.language).toUpperCase(),
    };

    useEffect(() => {
        if (!window.Beamer) return;
        window.Beamer.update({
            language: String(i18n.language).toUpperCase(),
        });
    }, [i18n.language]);

    useEffect(() => {
        if (!window.Beamer) return;
        window.Beamer.init();
    }, [location.pathname]);

    useScript({
        id: 'ze-snippet',
        url: 'https://static.zdassets.com/ekr/snippet.js?key=bb3aedb5-a1a0-4e5f-b2ee-bc4cdb02eb9a',
    });
    useScript({
        url: 'https://app.getbeamer.com/js/beamer-embed.js',
    });

    const classNamesProfile = classNames({
        [styles.active]: location.pathname.startsWith(ROUTES.PROFILE),
    });

    const handleLanguageClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        const localeOld = i18n.language;
        i18n.changeLanguage(getNextLanguage(i18n));
        const localeNew = i18n.language;
        setEventParameters({ locale_code: localeNew });
        trackEventLocaleChange(localeOld, localeNew);
    };

    return (
        <header className={styles.container}>
            <div className={styles['logo-container']}>
                <Link to={ROUTES.MAIN}>
                    <div className={styles.logo} />
                </Link>
            </div>

            <NavigationMenu items={menuItems} />

            <div className={styles['menu-items']}>
                <Link to=''>
                    <div id='hs-beamer' className={styles.beamer}>
                        <Icon name={ICON_NAMES.Bell} />
                    </div>
                </Link>
                <Link to=''>
                    <SupportPopup />
                </Link>
                <Link to='' onClick={handleLanguageClick}>
                    <Icon name={ICON_NAMES.Language} />
                </Link>
                <Link to={ROUTES.PROFILE} className={classNamesProfile}>
                    <Icon name={ICON_NAMES.User} />
                </Link>
            </div>
        </header>
    );
};

export default Header;
