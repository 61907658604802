import i18next from 'i18next';
import { toast } from 'react-toastify';
import { router } from 'router';

import { ROUTES } from 'constants/routes/routes';
import { HoneycombAPIError } from 'services/honeycombApi';
import { NimbusAPIError } from 'services/nimbusApi';

const getErrorMessageString = (error: unknown): string => {
    if (error instanceof HoneycombAPIError || error instanceof NimbusAPIError) {
        if (error.code === 403) {
            const message: string = i18next.t('auth:error.authentication_error');
            return message;
        } else if (error.code === 401) {
            const message: string = i18next.t('auth:error.authentication_error');
            return message;
        }
        if (error instanceof HoneycombAPIError) {
            // to log app error to ELK
            // return error.data?.error.message || 'error';
        }
        if (error instanceof NimbusAPIError) {
            // to log app error to ELK
            if (error.data?.details && error.data.details?.code) {
                if (i18next.exists(`auth:error.${error.data.details?.code}`)) {
                    return String(
                        i18next.t(`auth:error.${error.data.details?.code}`, {
                            code: error.data.details?.code,
                        }),
                    );
                }
                return String(
                    i18next.t('auth:error.error_with_code', {
                        code: error.data.details?.code,
                    }),
                );
            }
            if (error.data?.message) {
                return `${error.code}: ${error.data.message}`;
            }
        }
        const message = String(
            i18next.t('auth:error.error_with_code', {
                code: error.code,
            }),
        );
        return message;
    }
    if (error) {
        return error.toString();
    }
    return '';
};

export const handleAPIError = (error: unknown): void => {
    let errorMessage;
    if (error instanceof HoneycombAPIError || error instanceof NimbusAPIError) {
        error.message = getErrorMessageString(error);
        if (error.code === 401 || error.code === 403) {
            errorMessage = error.message;
            router.navigate(ROUTES.LOGOUT);
            // no need to show toast message on redirect
            return;
        } else {
            errorMessage = error?.message;
        }
    } else if (error) {
        errorMessage = error.toString();
    }
    toast.error(errorMessage || i18next.t('auth:error.error'));
};
