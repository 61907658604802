import type { ChangeEvent } from 'react';
import classNames from 'classnames';
import * as Form from '@radix-ui/react-form';

import styles from './style.module.scss';

type FormFieldInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    id?: string;
    name: string;
    type?: 'text' | 'number' | 'password' | 'email';
    label?: string;
    placeholder?: string;
    value?: string;
    defaultValue?: string;
    required?: boolean;
    disabled?: boolean;
    helperText?: string;
    autoComplete?: string;
    endAdornment?: React.ReactNode;
    variant?: 'default' | 'small' | 'large';
    errorMessageValueMissing?: string;
    errorMessageTypeMismatch?: string;
    onChange?: (_name: string, _value: string) => void;
};

export const FormFieldInput: React.FC<FormFieldInputProps> = ({
    id,
    name,
    type = 'text',
    min,
    max,
    label,
    placeholder,
    value,
    defaultValue,
    required = false,
    disabled = false,
    helperText,
    autoComplete,
    endAdornment,
    variant = 'default',
    className,
    errorMessageValueMissing,
    errorMessageTypeMismatch,
    onChange,
}) => {
    const classContainer = classNames(
        styles.container,
        {
            [styles.disabled]: disabled,
            [styles['container-large']]: variant === 'large',
        },
        className,
    );
    const classNamesLabel = classNames(styles.label, {
        [styles['label-small']]: variant === 'small',
        [styles['label-large']]: variant === 'large',
    });
    const classNamesInput = classNames(styles.input, {
        [styles['input-small']]: variant === 'small',
        [styles['input-large']]: variant === 'large',
    });
    const classNamesEndAdornment = classNames(styles['end-adornment'], {
        [styles['end-adornment-large']]: variant === 'large',
    });
    const classNamesHelperText = classNames(styles['helper-text'], {
        [styles['helper-text-large']]: variant === 'large',
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        if (onChange) onChange(name, value);
    };

    return (
        <Form.Field id={id} name={name} className={classContainer}>
            {(label || errorMessageValueMissing || errorMessageTypeMismatch) && (
                <div className={styles['label-container']}>
                    {label && (
                        <Form.Label htmlFor={id} className={classNamesLabel}>
                            {label}
                        </Form.Label>
                    )}
                    {errorMessageValueMissing && (
                        <Form.Message className={styles['error-message']} match='valueMissing'>
                            {errorMessageValueMissing}
                        </Form.Message>
                    )}
                    {errorMessageTypeMismatch && (
                        <Form.Message className={styles['error-message']} match='typeMismatch'>
                            {errorMessageTypeMismatch}
                        </Form.Message>
                    )}
                </div>
            )}
            <Form.Control
                className={classNamesInput}
                type={type}
                min={min}
                max={max}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                required={required}
                disabled={disabled}
                autoComplete={autoComplete}
                onChange={handleChange}
            />
            {endAdornment && <div className={classNamesEndAdornment}>{endAdornment}</div>}
            {helperText && (
                <Form.Label htmlFor={id} className={classNamesHelperText}>
                    {helperText}
                </Form.Label>
            )}
        </Form.Field>
    );
};

export default FormFieldInput;
