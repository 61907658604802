import { type Signal, SIGNAL_TYPES, type Match } from 'interfaces/match.type';
import SignalContentBirthday from 'components/SignalContentBirthday';
import SignalContentCompanyNews from 'components/SignalContentCompanyNews';
import SignalContentIndustryNews from 'components/SignalContentIndustryNews';
import SignalContentJobChange from 'components/SignalContentJobChange';
import SignalContentLinkedInPost from 'components/SignalContentLinkedInPost';

import style from './style.module.scss';

type Props = {
    match: Match;
    signal: Signal;
    canExpand?: boolean;
};

export const SignalCardContent: React.FC<Props> = ({ match, signal, canExpand }) => {
    switch (signal.type) {
        case SIGNAL_TYPES.Birthday:
            return <SignalContentBirthday signal={signal} />;
        case SIGNAL_TYPES.JobChange:
            return <SignalContentJobChange signal={signal} />;
        case SIGNAL_TYPES.LinkedinPost:
            return (
                <SignalContentLinkedInPost match={match} signal={signal} canExpand={canExpand} />
            );
        case SIGNAL_TYPES.CompanyNews:
            return <SignalContentCompanyNews match={match} signal={signal} canExpand={canExpand} />;
        case SIGNAL_TYPES.IndustryNews:
            return (
                <SignalContentIndustryNews match={match} signal={signal} canExpand={canExpand} />
            );
        default:
            return <div className={style['no-content']}>no content</div>;
    }
};

export default SignalCardContent;
