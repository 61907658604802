export const prepareEmailMessage = (body?: string, signature?: string): string => {
    let message = '';
    if (body) {
        message += body;
    }
    if (signature) {
        message += `\n\n${signature}`;
    }
    return message;
};
