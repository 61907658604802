import type { StateCreator } from 'zustand';

/* eslint-disable no-unused-vars */
export enum Features {
    PROSPECT_SEARCH = 'PROSPECT_SEARCH',
    PROSPECT_IMPORT = 'PROSPECT_IMPORT',
}
/* eslint-enable no-unused-vars */

export type FeaturesSlice = {
    enabledFeatures: Features[];
    invalidateFeaturesForUser: (_email: string) => void;
    isFeatureEnabled: (_feature: Features) => boolean;
};

export const createFeaturesSlice: StateCreator<FeaturesSlice> = (set, get) => ({
    enabledFeatures: [],
    invalidateFeaturesForUser: (email: string): void => {
        if (email.includes('@honeysales.io')) {
            set((_state) => ({
                enabledFeatures: [
                    Features.PROSPECT_SEARCH,
                    // Features.PROSPECT_IMPORT,
                ],
            }));
        }
    },
    isFeatureEnabled: (feature: Features): boolean => {
        return get().enabledFeatures.includes(feature);
    },
});
