import type { ProspectContactStatus, ProspectSource } from 'services/honeycomb';

import type { ObjectValues } from './types';

export const SIGNAL_TYPES = {
    Birthday: 'birthday',
    LinkedinPost: 'linkedin_post',
    CompanyNews: 'company_news',
    IndustryNews: 'industry_news',
    JobChange: 'job_change',
    Promotion: 'promotion',
    Event: 'event',
} as const;

export const SIGNAL_TYPES_FILTER = {
    LinkedinPost: 'linkedin_post',
    CompanyNews: 'company_news',
    JobChange: 'job_change',
} as const;

export type SignalType = ObjectValues<typeof SIGNAL_TYPES>;

export const SIGNAL_VISIBILITY_STATUS = {
    PendingReview: 'pending_review',
    Active: 'active',
    AdminDiscarded: 'admin_discarded',
    UserDiscarded: 'user_discarded',
} as const;

export type SignalVisibilityStatus = ObjectValues<typeof SIGNAL_VISIBILITY_STATUS>;

export type WithSignalInfo = {
    id: string;
    score: number | undefined;
    emailFrom?: string;
    emailSubject?: string;
    emailMessage?: string;
    emailSent?: boolean;
    emailSentAt?: string;
    scoringMetaReasoning?: string;
    scoringMetaCategory?: string;
    sendViaAutopilot?: boolean;
    sendViaAutopilotAt?: string;
    updatedAt?: string;
};

export type Signal =
    | CompanyNewsSignal
    | IndustryNewsSignal
    | BirthdaySignal
    | EventSignal
    | JobChangeSignal
    | LinkedInPostSignal;

export type CompanyNewsSignal = WithSignalInfo & {
    type: typeof SIGNAL_TYPES.CompanyNews;
    newsHeadline: string;
    newsContent: string | undefined;
    newsAuthor: string | undefined;
    newsUrl: string;
    newsPublicationDate: string | undefined;
    newsImageUrl?: string | undefined;
    newsSource: string | undefined;
};

export type IndustryNewsSignal = WithSignalInfo & {
    type: typeof SIGNAL_TYPES.IndustryNews;
    post: string | undefined;
    postOriginalAuthor: string | undefined;
    postUrl: string | undefined;
    postPublicationDate: string | undefined;
    postImageUrl?: string;
};

export type BirthdaySignal = WithSignalInfo & {
    type: typeof SIGNAL_TYPES.Birthday;
    name: string | undefined;
    age: number | undefined;
};

export type EventSignal = WithSignalInfo & {
    type: typeof SIGNAL_TYPES.Event;
};

export type JobChangeSignal = WithSignalInfo & {
    type: typeof SIGNAL_TYPES.JobChange;
    jobChangeType: string | undefined;
    jobTitleNew: string | undefined;
    companyNameNew: string | undefined;
    jobTitleOld: string | undefined;
    companyNameOld: string | undefined;
    changeDate?: string | undefined;
};

export type LinkedInPostSignal = WithSignalInfo & {
    type: typeof SIGNAL_TYPES.LinkedinPost;
    post: string | undefined;
    postIsRepost: boolean | undefined;
    postOriginalAuthor: string | undefined;
    postUrl: string | undefined;
    postPublicationDate: string | undefined;
    postImageUrl?: string;
    icpName?: string;
    tags?: string[];
};

// mock data:
// 'C-Level' | 'Director' | 'Senior Manager' | 'Experienced Manager' | 'Partner'
type TagSeniority = string;

// mock data:
// '11-50' | '51-200' | '200-1000' | '1001-5000' | '5001-10000' | '10000plus'
type TagHeadcount = string;

// mock data: 'B2B' | 'B2C'
type TagOrientation = string;

// mock data:
// 'Marketing Dienstleistung' | 'Transport & Logistik'
// | 'Unternehmensberatung' | 'Software' | 'Venture Capital'
type TagIndustry = string;

// mock data:
// 'dach' | 'france' | 'germany'
type TagHeadquarter = string;

export type Tag = {
    seniority?: TagSeniority;
    headcount?: TagHeadcount;
    orientation?: TagOrientation;
    industry?: TagIndustry;
    headquarter?: TagHeadquarter;
};

export type Match = {
    prospectId: string;
    userProspectId: string;
    firstName: string;
    lastName: string;
    email?: string;
    companyName: string;
    jobTitle: string;
    location: string;
    signals: Signal[];
    tag: Tag;
    score: number | undefined;
    imageUrl?: string;
    linkedInUrl?: string;
    read?: boolean;
    done?: boolean;
    source?: ProspectSource;
    contactStatus?: ProspectContactStatus;
    messageChangesSaved?: boolean;
    messageChangesSavedVisible?: boolean;
    timeUntilAutosend?: string;
    updatedAt?: string;
};
