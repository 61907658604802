import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes/routes';
import { PROSPECT_TABS } from 'interfaces/prospect';
import Button from 'components/Button';
import Icon, { ICON_NAMES } from 'components/Icon';
import ProspectSearchResultTable from 'components/LeadSearchResultTable';
import Typography from 'components/Typography';
import FormLeadSearch, { type FormLeadSearchFormData } from 'forms/FormLeadSearch';
import {
    MAX_NUMBER_OF_LEAD_RECORDS,
    MAX_NUMBER_OF_USER_PROSPECTS,
    NUMBER_OF_LEAD_RECORDS_PER_PAGE,
} from 'services/nimbus';
import { Features } from 'stores/featuresSlice';
import { useIsFeatureEnabled } from 'stores/useFeaturesStore';
import {
    useGetLeadsConfig,
    useLeadSearchResults,
    useSearchLeads,
    useLeadSearchLoading,
    useSearchLeadsNextPage,
    useSearchLeadsPrevPage,
    useLeadSearchRecordCount,
    useLeadSearchCurrentPage,
    useUserProspectCount,
} from 'stores/useProspectStore';

import { columns } from './columns';

import styles from './style.module.scss';

const formatNumber = (num: number): string => new Intl.NumberFormat().format(num);

const defaultValues: FormLeadSearchFormData = {
    personJobTitle: '',
    personExcludeJobTitle: '',
    personManagementLevel: [],
    personDepartment: [],
    personLocation: '',
    companyIndustry: [],
    companyKeywords: '',
    companyRevenueFrom: '',
    companyRevenueTo: '',
    companyDomain: '',
    companyLocation: '',
    companyEmployeeCountFrom: undefined,
    companyEmployeeCountTo: undefined,
    companyEmployeesByDepartment: '',
    companyEmployeesByDepartmentFrom: undefined,
    companyEmployeesByDepartmentTo: undefined,
    companyFoundedYearFrom: undefined,
    companyFoundedYearTo: undefined,
    companyFundingLevel: [],
    companyTechnologies: [],
};

export const ProspectSearchPageContent: React.FC = () => {
    const { t } = useTranslation('prospects');

    const [searchExecuted, setSearchExecuted] = useState<boolean>(false);
    const resultsRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const getLeadsConfig = useGetLeadsConfig();
    const leadSearchLoading = useLeadSearchLoading();
    const leadSearchResults = useLeadSearchResults();
    const leadSearchRecordCount = useLeadSearchRecordCount();
    const userProspectCount = useUserProspectCount();
    const leadSearchCurrentPage = useLeadSearchCurrentPage();
    const searchLeads = useSearchLeads();
    const searchLeadsNextPage = useSearchLeadsNextPage();
    const searchLeadsPrevPage = useSearchLeadsPrevPage();
    const isFeatureEnabled = useIsFeatureEnabled();

    const pageStart = (leadSearchCurrentPage - 1) * NUMBER_OF_LEAD_RECORDS_PER_PAGE + 1;
    const pageEnd = Math.min(
        leadSearchCurrentPage * NUMBER_OF_LEAD_RECORDS_PER_PAGE,
        leadSearchRecordCount,
    );
    const maxPage = Math.ceil(leadSearchRecordCount / NUMBER_OF_LEAD_RECORDS_PER_PAGE);
    const isPrevButtonDisabled = leadSearchLoading || leadSearchCurrentPage === 1;
    const isNextButtonDisabled =
        leadSearchLoading || leadSearchCurrentPage === maxPage || leadSearchRecordCount === 0;

    const isTooManyFirstBatch =
        leadSearchRecordCount > MAX_NUMBER_OF_LEAD_RECORDS &&
        userProspectCount + MAX_NUMBER_OF_LEAD_RECORDS <= MAX_NUMBER_OF_USER_PROSPECTS;
    const isTooManyPart =
        leadSearchRecordCount > 0 &&
        userProspectCount < MAX_NUMBER_OF_USER_PROSPECTS &&
        userProspectCount + MAX_NUMBER_OF_LEAD_RECORDS > MAX_NUMBER_OF_USER_PROSPECTS;
    const isTooManyNoImport = userProspectCount >= MAX_NUMBER_OF_USER_PROSPECTS;

    const numberOfRecordsToImport = Math.min(
        leadSearchRecordCount,
        MAX_NUMBER_OF_USER_PROSPECTS - userProspectCount,
        MAX_NUMBER_OF_LEAD_RECORDS,
    );

    const [filter] = useState<FormLeadSearchFormData>(defaultValues);

    useEffect(() => {
        getLeadsConfig();
        setSearchExecuted(false);
    }, []);

    const handleBackToProspectsClick = (): void => {
        navigate(ROUTES.PROSPECTS);
    };

    const handleSubmit = async (values: FormLeadSearchFormData): Promise<void> => {
        setSearchExecuted(true);
        await searchLeads(values);
        if (resultsRef.current) {
            resultsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleNextClick = async (): Promise<void> => {
        await searchLeadsNextPage();
    };

    const handlePrevClick = async (): Promise<void> => {
        await searchLeadsPrevPage();
    };

    const handleImportProspectsClick = async (): Promise<void> => {
        await Promise.resolve();
        navigate(ROUTES.PROSPECTS_TAB.replace(':page', PROSPECT_TABS.ImportJobs), {
            state: { jobStarted: true },
        });
    };

    return (
        <div className={styles.content}>
            <div className={styles.breadcrumbs}>
                <Button variant='link' onClick={handleBackToProspectsClick}>
                    <Icon
                        className={styles['button-icon-left']}
                        name={ICON_NAMES.ChevronLeft}
                        width={12}
                        height={12}
                    />
                    {t('buttons.back_to_prospects')}
                </Button>
            </div>

            <FormLeadSearch
                disabled={leadSearchLoading}
                initialValues={filter}
                onSubmit={handleSubmit}
            />

            {searchExecuted && !leadSearchLoading && isTooManyFirstBatch && (
                <div className={styles.info}>
                    <Icon name={ICON_NAMES.AlertCircle} width={22} height={22} />
                    {t('search.too_many_results_import_first_batch', {
                        total: formatNumber(leadSearchRecordCount),
                        max: formatNumber(MAX_NUMBER_OF_LEAD_RECORDS),
                    })}
                </div>
            )}

            {searchExecuted && !leadSearchLoading && isTooManyPart && (
                <div className={styles.info}>
                    <Icon name={ICON_NAMES.AlertCircle} width={22} height={22} />
                    {t('search.too_many_results_import_part', {
                        total: formatNumber(leadSearchRecordCount),
                        left: formatNumber(numberOfRecordsToImport),
                        max: formatNumber(MAX_NUMBER_OF_USER_PROSPECTS),
                    })}
                </div>
            )}

            {searchExecuted && !leadSearchLoading && isTooManyNoImport && (
                <div className={styles.info}>
                    <Icon name={ICON_NAMES.AlertCircle} width={22} height={22} />
                    {t('search.too_many_results_no_import', {
                        total: formatNumber(leadSearchRecordCount),
                        max: formatNumber(MAX_NUMBER_OF_USER_PROSPECTS),
                    })}
                </div>
            )}

            {/* Search results */}
            {searchExecuted && leadSearchResults.length > 0 && (
                <div className={styles.table} ref={resultsRef}>
                    <div className={styles['table-header']}>
                        <div className={styles['flex-row']}>
                            <Typography variant='bodyBold2'>
                                {formatNumber(leadSearchRecordCount)}
                            </Typography>
                            <Typography variant='bodyRegular2'>
                                {t('count.search_results', { count: leadSearchRecordCount })}
                            </Typography>
                        </div>
                        <div>
                            {numberOfRecordsToImport > 0 &&
                                isFeatureEnabled(Features.PROSPECT_IMPORT) && (
                                    <Button onClick={handleImportProspectsClick}>
                                        {t('count.import_results', {
                                            count: numberOfRecordsToImport,
                                            total: formatNumber(numberOfRecordsToImport),
                                        })}
                                    </Button>
                                )}
                        </div>
                    </div>
                    <ProspectSearchResultTable data={leadSearchResults} columns={columns} />
                    <div className={styles['table-footer']}>
                        {leadSearchRecordCount > 0 && (
                            <>
                                <div className={styles['table-info']}>
                                    <Typography variant='bodyRegular2'>
                                        <strong>
                                            {formatNumber(pageStart)}-{formatNumber(pageEnd)}
                                        </strong>{' '}
                                        {t('count.of')}{' '}
                                        <strong>{formatNumber(leadSearchRecordCount)}</strong>{' '}
                                        {t('count.search_results', {
                                            count: leadSearchRecordCount,
                                        })}
                                    </Typography>
                                </div>
                                <div className={styles['table-pagination']}>
                                    <Button
                                        size='small'
                                        variant='secondary'
                                        disabled={isPrevButtonDisabled}
                                        onClick={handlePrevClick}
                                    >
                                        <Icon
                                            className={styles['button-icon-left']}
                                            name={ICON_NAMES.ChevronLeft}
                                            width={12}
                                            height={12}
                                        />
                                        {t('buttons.prev')}
                                    </Button>
                                    <Typography variant='bodyRegular2'>
                                        {t('count.page_of', {
                                            page: formatNumber(leadSearchCurrentPage),
                                            total: formatNumber(maxPage),
                                        })}
                                    </Typography>
                                    <Button
                                        size='small'
                                        variant='secondary'
                                        disabled={isNextButtonDisabled}
                                        onClick={handleNextClick}
                                    >
                                        {t('buttons.next')}
                                        <Icon
                                            className={styles['button-icon-right']}
                                            name={ICON_NAMES.ChevronRight}
                                            width={12}
                                            height={12}
                                        />
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            {searchExecuted && !leadSearchLoading && leadSearchResults.length === 0 && (
                <div className={styles['no-results']}>{t('search.no_results')}</div>
            )}
        </div>
    );
};

export default ProspectSearchPageContent;
