import type { Resource, i18n } from 'i18next';

export const getNextLanguage = (i18n: i18n): string => {
    const languages = Object.keys(i18n.options.resources as Resource);
    let languageIndex = languages.findIndex((language) => language === i18n.language);
    if (languageIndex === languages.length - 1) {
        languageIndex = 0;
    } else {
        languageIndex += 1;
    }
    const nextLanguage = languages[languageIndex];
    return nextLanguage;
};
