import type { SignalEmailFormData } from '.';

export const defaultValuesSignalFormData: SignalEmailFormData = {
    recipient: '',
    subject: '',
    text: '',
    sentAt: undefined,
};

export const extractSignalEmailFormData = (formData: FormData): SignalEmailFormData => {
    const payload: SignalEmailFormData = defaultValuesSignalFormData;
    const recipient = formData.get('recipient');
    if (typeof recipient === 'string') {
        payload.recipient = recipient;
    }
    const subject = formData.get('subject');
    if (typeof subject === 'string') {
        payload.subject = subject;
    }
    const text = formData.get('text');
    if (typeof text === 'string') {
        payload.text = text;
    }
    return payload;
};
