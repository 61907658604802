import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { UserIndustryPitch } from 'interfaces/userEx.type';
import Dialog from 'components/Dialog';
import FormIndustryPitch, { type IndustryPitchFormData } from 'forms/FormIndustryPitch';
import { useIndustries } from 'stores/useBoundStore';

import styles from './style.module.scss';

export type IndustryPitchEditDialogProps = {
    open?: boolean;
    userIndustryPitch?: UserIndustryPitch;
    disabled: boolean;
    onSubmit: (_values: IndustryPitchFormData) => Promise<void>;
    onCancel: () => void;
};

export const IndustryPitchEditDialog: React.FC<IndustryPitchEditDialogProps> = ({
    open: openExternal,
    userIndustryPitch,
    disabled,
    onSubmit,
    onCancel,
}) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation('profile');

    const industries = useIndustries();

    const formDefaultValues: IndustryPitchFormData = useMemo(
        () => ({
            pitchName: userIndustryPitch?.pitchName || '',
            industries: userIndustryPitch?.industries || [],
            pitch: userIndustryPitch?.pitch || '',
            referenceCase: userIndustryPitch?.referenceCase || '',
        }),
        [userIndustryPitch],
    );

    useEffect(() => {
        if (openExternal !== undefined) {
            setOpen(openExternal);
        }
    }, [openExternal]);

    const handleSubmit = async (values: IndustryPitchFormData): Promise<void> => {
        if (onSubmit) {
            await onSubmit(values);
        }
        setOpen(false);
    };

    const handleCancel = (): void => {
        if (onCancel) onCancel();
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            title={
                userIndustryPitch === undefined
                    ? t('forms:industry_pitch.title_add')
                    : t('forms:industry_pitch.title_edit')
            }
            onClose={handleCancel}
            className={styles.dialog}
        >
            <FormIndustryPitch
                industries={industries}
                initialValues={formDefaultValues}
                disabled={disabled}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        </Dialog>
    );
};

export default IndustryPitchEditDialog;
