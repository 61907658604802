import { useLayoutEffect, useRef } from 'react';

type UseScriptProps = {
    id?: string;
    url: string;
};

export const useScript = ({ id, url }: UseScriptProps): void => {
    const scriptRef = useRef<HTMLScriptElement | null>(null);

    const head = document.getElementsByTagName('head')[0];

    useLayoutEffect(() => {
        if (!window) {
            return;
        }

        scriptRef.current = document.createElement('script');

        if (id) scriptRef.current.setAttribute('id', id);
        scriptRef.current.setAttribute('defer', 'defer');
        scriptRef.current.src = url;

        head.appendChild(scriptRef.current);

        return () => {
            if (scriptRef.current) {
                head.removeChild(scriptRef.current);
            }
        };
    }, []);
};
