import { useMemo, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { CellContext, ColumnDef } from '@tanstack/react-table';

import type { NimbusProspect } from 'interfaces/nimbusSignal';
import type { Prospect } from 'interfaces/prospect';
import ButtonWithDropdown from 'components/ButtonWithDropdown';
import Icon, { ICON_NAMES } from 'components/Icon';
import { formatISODateAsString } from 'components/SignalCard/utils';
import { useSelectProspectId, useSetOpenArchiveDialog } from 'stores/useProspectStore';

import styles from './style.module.scss';

const makeLinkedInURLFromVMID = (urn: string | undefined): string | undefined =>
    urn ? `https://linkedin.com/in/${urn}` : undefined;

const prospectOptions = [
    {
        label: 'archive_prospect',
        value: 'archive',
    },
];

const ActionMenu: React.FC<CellContext<Prospect, unknown>> = ({ row }): JSX.Element => {
    const { t } = useTranslation('prospects');

    const prospect = row.original;
    const selectProspectId = useSelectProspectId();
    const setOpenArchiveDialog = useSetOpenArchiveDialog();

    const prospectOptionsTranslated = useMemo(() => {
        return prospectOptions.map((option) => ({
            ...option,
            label: t(`actions.options.${option.label}`),
        }));
    }, [t]);

    const handleOptionClick = async (
        _event: MouseEvent<HTMLElement>,
        value: string,
    ): Promise<void> => {
        if (value === 'archive') {
            selectProspectId(prospect._id);
            setOpenArchiveDialog(true);
        }
    };

    return (
        <ButtonWithDropdown
            icon={ICON_NAMES.More}
            options={prospectOptionsTranslated}
            size='small'
            onOptionClick={handleOptionClick}
        />
    );
};

type HeaderWithTranslationProps = {
    className?: string;
    title: string;
};

const HeaderWithTranslation: React.FC<HeaderWithTranslationProps> = ({ className, title }) => {
    const { t } = useTranslation('prospects');
    return <div className={className}>{t(title)}</div>;
};

type ValueWithTranslationProps = {
    ns?: string;
    value?: string;
};

const ValueWithTranslation: React.FC<ValueWithTranslationProps> = ({ ns, value }) => {
    const { t } = useTranslation(ns);
    if (!value) return null;
    const result = t(value);
    return <>{result}</>;
};

export const columns: ColumnDef<Prospect>[] = [
    {
        accessorKey: 'prospect',
        header: () => <HeaderWithTranslation title='prospects.name' />,
        cell: ({ getValue }): JSX.Element => {
            const prospect = getValue<NimbusProspect>();
            return (
                <div>
                    {prospect.firstName} {prospect.lastName}
                </div>
            );
        },
    },
    {
        accessorKey: 'jobTitle',
        header: () => <HeaderWithTranslation title='prospects.jobTitle' />,
    },
    {
        accessorKey: 'company',
        header: () => <HeaderWithTranslation title='prospects.company' />,
        cell: ({ getValue }): JSX.Element => {
            const company = getValue<Prospect['company']>();
            return <div>{company?.name}</div>;
        },
    },
    {
        accessorKey: 'email',
        header: () => <HeaderWithTranslation title='prospects.email' />,
        size: 0,
    },
    {
        accessorKey: 'contactStatus',
        header: () => <HeaderWithTranslation title='prospects.contactStatus' />,
        size: 0,
        cell: ({ getValue }) => (
            <ValueWithTranslation
                ns='forms'
                value={`prospect_list_filter.contact_status.${getValue<
                    Prospect['contactStatus']
                >()}`}
            />
        ),
    },
    {
        accessorKey: 'source',
        header: () => <HeaderWithTranslation title='prospects.source' />,
        size: 0,
        cell: ({ getValue }) => (
            <ValueWithTranslation
                ns='forms'
                value={`prospect_list_filter.source.${getValue<Prospect['source']>()}`}
            />
        ),
    },
    {
        accessorKey: 'lastReplyDate',
        header: () => <HeaderWithTranslation title='prospects.lastContact' />,
        size: 64,
        cell: ({ getValue }): string => {
            const date = getValue<string>();
            if (!date) return '-';
            try {
                const dateString = formatISODateAsString(date, 'yyyy-MM-dd');
                return dateString;
            } catch (err) {
                return '-';
            }
        },
    },
    {
        accessorKey: 'prospect.linkedin.urn',
        header: () => <></>,
        size: 0,
        cell: ({ getValue }): JSX.Element | null => {
            const url = makeLinkedInURLFromVMID(getValue<NimbusProspect['linkedin']['urn']>()?.id);
            if (!url) return null;
            return (
                <Link to={url} target='_blank' className={styles.link}>
                    <Icon name={ICON_NAMES.LinkedIn} height={16} width={16} />
                </Link>
            );
        },
    },
    {
        id: 'actions',
        size: 0,
        cell: (props) => <ActionMenu {...props} />,
    },
];
