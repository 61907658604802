import React from 'react';
import classNames from 'classnames';

import styles from './style.module.scss';

type Props = {
    className?: string;
    inline?: boolean;
    flex?: boolean;
    pre?: boolean;
    variant?:
        | 'heading1'
        | 'heading2'
        | 'heading3'
        | 'heading4'
        | 'bodyRegular1'
        | 'bodyRegular2'
        | 'bodyRegular3'
        | 'bodyBold1'
        | 'bodyBold2'
        | 'bodyBold3';
    span?: boolean;
    children?: React.ReactNode;
};

const Typography: React.FC<Props> = ({
    className,
    inline,
    pre,
    flex,
    variant = 'bodyRegular2',
    span,
    children,
}) => {
    const typographyClassNames = classNames(styles.text, className, {
        [styles.heading1]: variant === 'heading1',
        [styles.heading2]: variant === 'heading2',
        [styles.heading3]: variant === 'heading3',
        [styles.heading4]: variant === 'heading4',
        [styles.bodyRegular1]: variant === 'bodyRegular1',
        [styles.bodyRegular2]: variant === 'bodyRegular2',
        [styles.bodyRegular3]: variant === 'bodyRegular3',
        [styles.bodyBold1]: variant === 'bodyBold1',
        [styles.bodyBold2]: variant === 'bodyBold2',
        [styles.bodyBold3]: variant === 'bodyBold3',
        [styles.inline]: inline,
        [styles.flex]: flex,
        [styles.pre]: pre,
    });

    if (span) {
        return <span className={typographyClassNames}>{children}</span>;
    }

    return <p className={typographyClassNames}>{children}</p>;
};

export default Typography;
