import classNames from 'classnames';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { NAV_MENU_ITEMS } from 'constants/routes/navLinks';

import Header from 'components/Header';
import HeaderProxy from 'components/HeaderProxy';
import ProspectSearchPageContent from 'components/ProspectSearchPageContent';

import {
    useFetchCurrentUserEx,
    useInvalidateProxyLogin,
    useProxyLogin,
} from 'stores/useBoundStore';

import styles from './style.module.scss';

const ProspectSearchPage = (): JSX.Element => {
    const fetchCurrentUserEx = useFetchCurrentUserEx();
    const invalidateProxyLogin = useInvalidateProxyLogin();
    const proxyLogin = useProxyLogin();

    const classContainer = classNames(styles.container, {
        [styles['container-proxy']]: proxyLogin,
    });

    useEffect(() => {
        invalidateProxyLogin();
        fetchCurrentUserEx();
    }, []);

    return (
        <>
            {proxyLogin && <HeaderProxy />}
            <Header navMenuItems={NAV_MENU_ITEMS} />

            <div className={classContainer}>
                <ProspectSearchPageContent />
            </div>

            <ToastContainer position='bottom-right' />
        </>
    );
};

export default ProspectSearchPage;
