import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon, { ICON_NAMES } from 'components/Icon';

import styles from './style.module.scss';

type Props = {
    imageSrc?: string;
    imageAlt?: string;
    linkUrl?: string;
    linkText?: string;
    onClick: (_event: React.MouseEvent<HTMLDivElement>) => void;
};

const SignalCardImage: React.FC<Props> = ({ imageSrc, imageAlt, linkUrl, linkText, onClick }) => {
    const { t } = useTranslation('matches');

    if (!imageSrc) return null;

    const imageAltText = imageAlt || t('info.alt_post');
    const linkUrlText = linkText || t('info.open');

    return (
        <div className={styles.image} onClick={onClick}>
            <img src={imageSrc} alt={imageAltText} />
            {linkUrl && (
                <a
                    className={styles['link-button']}
                    href={linkUrl}
                    target='_blank'
                    rel='noreferrer'
                >
                    <Icon name={ICON_NAMES.Link} /> {linkUrlText}
                </a>
            )}
        </div>
    );
};

export default SignalCardImage;
