import i18next from 'i18next';

import matchesDe from './de/matches.json';
import matchesEn from './en/matches.json';
import prospectsDe from './de/prospects.json';
import prospectsEn from './en/prospects.json';
import performanceDe from './de/performance.json';
import performanceEn from './en/performance.json';
import profileDe from './de/profile.json';
import profileEn from './en/profile.json';
import formsDe from './de/forms.json';
import formsEn from './en/forms.json';
import authDe from './de/auth.json';
import authEn from './en/auth.json';

export const initI18n = (): void => {
    i18next.init({
        interpolation: { escapeValue: false },
        lng: 'en',
        fallbackLng: 'en',
        resources: {
            en: {
                matches: matchesEn,
                performance: performanceEn,
                profile: profileEn,
                forms: formsEn,
                auth: authEn,
                prospects: prospectsEn,
            },
            de: {
                matches: matchesDe,
                performance: performanceDe,
                profile: profileDe,
                forms: formsDe,
                auth: authDe,
                prospects: prospectsDe,
            },
        },
    });
};
