import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import Button from 'components/Button';
import FormFieldCheckbox from 'components/FormFieldCheckbox';

import styles from './style.module.scss';

export type FormNotificationsSettingsSubmit = {
    emailSignals: boolean;
};

type Props = {
    initialValues?: Partial<FormNotificationsSettingsSubmit>;
    disabled?: boolean;
    onSubmit: (_values: Partial<FormNotificationsSettingsSubmit>) => void;
};

const FormNotificationsSettings: React.FC<Props> = ({
    initialValues = {},
    disabled = false,
    onSubmit,
}) => {
    const [formValues, setFormValues] =
        useState<Partial<FormNotificationsSettingsSubmit>>(initialValues);

    const { t } = useTranslation('forms');

    useEffect(() => {
        setFormValues(initialValues);
    }, [initialValues]);

    const handleChange = (value: boolean): void => {
        setFormValues({
            ...formValues,
            emailSignals: value,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = { ...formValues };
        if (onSubmit) onSubmit(formData);
    };

    return (
        <Form.Root className={styles.container} onSubmit={handleSubmit}>
            <FormFieldCheckbox
                label={t('notifications.new_signal')}
                caption={t('notifications.new_signal_caption')}
                name='emailSignals'
                standalone
                disabled={disabled}
                checked={formValues.emailSignals}
                onChange={handleChange}
            />
            <div className={styles.submit}>
                <Form.Submit asChild>
                    <Button variant='primary' disabled={disabled}>
                        {t('notifications.save')}
                    </Button>
                </Form.Submit>
            </div>
        </Form.Root>
    );
};

export default FormNotificationsSettings;
