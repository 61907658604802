import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import Typography from 'components/Typography';

import styles from './style.module.scss';

export type ConfirmationDialogProps = {
    open?: boolean;
    title?: string;
    content: React.ReactNode;
    labelConfirm?: string;
    labelCancel?: string;
    confirmVariant?: 'primary' | 'primary-danger';
    className?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open: openExternal,
    title,
    content,
    labelConfirm,
    labelCancel,
    confirmVariant,
    className,
    onConfirm,
    onCancel,
}) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation('forms');

    const classNameDialog = classNames(styles.dialog, className);

    useEffect(() => {
        if (openExternal !== undefined) {
            setOpen(openExternal || false);
        }
    }, [openExternal]);

    const handleConfirm = (): void => {
        if (onConfirm) onConfirm();
        setOpen(false);
    };

    const handleCancel = (): void => {
        if (onCancel) onCancel();
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            className={classNameDialog}
            classNameContainer={styles['dialog-container']}
            onClose={handleCancel}
        >
            <div className={styles.content}>
                <Typography variant='heading4'>{title || t('confirmation.title')}</Typography>
                {content}
            </div>
            <div className={styles.action}>
                <Button variant={confirmVariant || 'primary'} autoFocus onClick={handleConfirm}>
                    {labelConfirm || t('confirmation.confirm')}
                </Button>
                <Button variant='secondary' onClick={handleCancel}>
                    {labelCancel || t('confirmation.cancel')}
                </Button>
            </div>
        </Dialog>
    );
};

export default ConfirmationDialog;
