import type { ChangeEvent } from 'react';
import classNames from 'classnames';
import * as Form from '@radix-ui/react-form';

import styles from './style.module.scss';

type Props = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    id?: string;
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    value?: string;
    defaultValue?: string;
    required?: boolean;
    disabled?: boolean;
    rows?: number;
    variant?: 'default' | 'small' | 'large';
    onChange?: (_name: string, _value: string) => void;
};

export const FormFieldTextArea: React.FC<Props> = ({
    id,
    name,
    label,
    placeholder,
    helperText,
    value,
    defaultValue,
    required = false,
    disabled = false,
    rows,
    variant = 'default',
    onChange,
    ...rest
}) => {
    const classContainer = classNames(styles.container, {
        [styles.disabled]: disabled,
        [styles['container-large']]: variant === 'large',
    });
    const classNamesLabel = classNames(styles.label, {
        [styles['label-small']]: variant === 'small',
    });
    const classNamesInput = classNames(styles.input, {
        [styles['input-small']]: variant === 'small',
        [styles['input-large']]: variant === 'large',
    });
    const classNamesHelperText = classNames(styles['helper-text'], {
        [styles['helper-text-large']]: variant === 'large',
    });

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        const { value } = event.target;
        if (onChange) onChange(name, value);
    };

    return (
        <Form.Field id={id} name={name} className={classContainer}>
            {label && (
                <Form.Label htmlFor={id} className={classNamesLabel}>
                    {label}
                </Form.Label>
            )}
            <Form.Control asChild disabled={disabled}>
                <textarea
                    className={classNamesInput}
                    rows={rows}
                    required={required}
                    disabled={disabled}
                    autoComplete='off'
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={handleChange}
                    {...rest}
                />
            </Form.Control>
            {helperText && (
                <Form.Label htmlFor={id} className={classNamesHelperText}>
                    {helperText}
                </Form.Label>
            )}
        </Form.Field>
    );
};

export default FormFieldTextArea;
