import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Form from '@radix-ui/react-form';

import FormFieldSelect, { type FormFieldOption } from 'components/FormFieldSelect';
import FormFieldSwitch from 'components/FormFieldSwitch';
import Icon, { ICON_NAMES } from 'components/Icon';
import Tooltip from 'components/Tooltip';

import {
    PROSPECT_CONTACT_STATUS,
    PROSPECT_SOURCE,
    type ProspectContactStatus,
    type ProspectSource,
} from 'services/honeycomb';

import styles from './style.module.scss';

type FormFilterProspectListProps = {
    values?: FormFilterProspectListFormData;
    disabled?: boolean;
    isAuthorizedToUseAutopilot?: boolean;
    onUpdate: (_values: FormFilterProspectListFormData) => void;
};

export type FormFilterProspectListFormData = {
    contactStatus: ProspectContactStatus | 'all';
    source: ProspectSource | 'all';
    autopilot: boolean;
};

const defaultValues: FormFilterProspectListFormData = {
    contactStatus: 'all',
    source: 'all',
    autopilot: false,
};

const FormFilterProspectList: React.FC<FormFilterProspectListProps> = ({
    values = defaultValues,
    disabled = false,
    isAuthorizedToUseAutopilot = false,
    onUpdate,
}) => {
    const { t } = useTranslation('forms');
    const [formData, setFormData] = React.useState<FormFilterProspectListFormData>(values);

    const optionsProspectSource = useMemo(
        (): FormFieldOption[] => [
            { label: t('prospect_list_filter.all'), value: 'all' },
            ...(Object.keys(PROSPECT_SOURCE).map((source) => ({
                label: t(`prospect_list_filter.source.${source}`),
                value: source,
            })) as FormFieldOption[]),
        ],
        [t, PROSPECT_SOURCE],
    );

    const optionsProspectContactStatus = useMemo(
        (): FormFieldOption[] => [
            { label: t('prospect_list_filter.all'), value: 'all' },
            ...(Object.keys(PROSPECT_CONTACT_STATUS).map((status) => ({
                label: t(`prospect_list_filter.contact_status.${status}`),
                value: status,
            })) as FormFieldOption[]),
        ],
        [t, PROSPECT_CONTACT_STATUS],
    );

    useEffect(() => {
        if (values) setFormData(values);
    }, [values]);

    const handleFilterChangeContactStatus = (value: string): void => {
        setFormData((prev) => ({ ...prev, contactStatus: value as ProspectContactStatus }));
    };

    const handleFilterChangeSource = (value: string): void => {
        setFormData((prev) => ({ ...prev, source: value as ProspectSource }));
    };

    const handleFilterChangeAutopilot = (value: boolean): void => {
        setFormData((prev) => ({ ...prev, autopilot: value }));
    };

    const handleChange = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let result: FormFilterProspectListFormData = {
            contactStatus: (data.get('contactStatus') as ProspectContactStatus) || 'all',
            source: (data.get('source') as ProspectSource) || 'all',
            autopilot: (data.get('autopilot') as string) === 'on',
        };
        if (onUpdate) onUpdate(result);
    };

    return (
        <Form.Root className={styles.container} onChange={handleChange}>
            <FormFieldSelect
                className={styles.select}
                label={t('prospect_list_filter.contact_status.title')}
                name='contactStatus'
                variant='small'
                options={optionsProspectContactStatus}
                value={formData.contactStatus}
                disabled={disabled}
                onChange={handleFilterChangeContactStatus}
            />
            <FormFieldSelect
                className={styles.select}
                label={t('prospect_list_filter.source.title')}
                name='source'
                variant='small'
                options={optionsProspectSource}
                value={formData.source}
                disabled={disabled}
                onChange={handleFilterChangeSource}
            />
            <Tooltip
                asChild
                content={t(
                    isAuthorizedToUseAutopilot
                        ? 'prospect_list_filter.autopilot_tooltip'
                        : 'prospect_list_filter.autopilot_tooltip_disabled',
                )}
            >
                <div>
                    <FormFieldSwitch
                        label={
                            <div className={styles.label}>
                                <Icon name={ICON_NAMES.Autopilot} width={14} height={14} />
                                {t('prospect_list_filter.autopilot')}
                            </div>
                        }
                        name={'autopilot'}
                        disabled={disabled}
                        value={isAuthorizedToUseAutopilot ? formData.autopilot : false}
                        align='center'
                        variant='small'
                        onChange={handleFilterChangeAutopilot}
                    />
                </div>
            </Tooltip>
        </Form.Root>
    );
};

export default FormFilterProspectList;
