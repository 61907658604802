import { Fragment, useEffect } from 'react';
import { IntegrationAppProvider, useConnections } from '@integration-app/react';

import SettingsCRMIntegration from 'components/SettingsCRMIntegration';
import SettingsEmailIntegration from 'components/SettingsEmailIntegration';

import { useFetchIAToken, useIAToken } from 'stores/useBoundStore';
import { getConnectionsFiltered } from 'stores/crmSlice/getConnectionsFiltered';

import clientEnv from 'env';

import styles from './style.module.scss';

const SettingsIntegrationsContent: React.FC = () => {
    const { connections, loading, refresh } = useConnections();

    const connectionsCRM = getConnectionsFiltered(connections, 'crm');
    const connectionsEmail = getConnectionsFiltered(connections, 'email');

    return (
        <div className={styles.container}>
            <SettingsCRMIntegration
                connections={connectionsCRM}
                loading={loading}
                refresh={refresh}
            />
            {clientEnv.EMAIL_INTEGRATION_DISABLED === false && (
                <SettingsEmailIntegration
                    connections={connectionsEmail}
                    loading={loading}
                    refresh={refresh}
                />
            )}
        </div>
    );
};

const SettingsIntegrations: React.FC = () => {
    const iaToken = useIAToken();
    const fetchIAToken = useFetchIAToken();

    useEffect(() => {
        fetchIAToken();
    }, []);

    if (!iaToken) {
        return <Fragment />;
    }

    return (
        <IntegrationAppProvider token={iaToken}>
            <SettingsIntegrationsContent />
        </IntegrationAppProvider>
    );
};

export default SettingsIntegrations;
