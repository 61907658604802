import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes/routes';
import { AnimatedStars } from 'components/AnimatedStars';
import HeaderLogin from 'components/HeaderLogin';
import FormLogin, { type LoginFormData } from 'forms/FormLogin';
import Loading from 'components/Loading';
import {
    useAuthClearErrorAndMessage,
    useAuthError,
    useAuthLoading,
    useAuthSignIn,
} from 'stores/useBoundStore';

import 'react-toastify/dist/ReactToastify.css';
import styles from './style.module.scss';

const USE_ANIMATION = true;

const LoginPage = (): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);

    const location = useLocation();
    const navigate = useNavigate();

    const signIn = useAuthSignIn();
    const loading = useAuthLoading();
    const error = useAuthError();
    const clearErrorAndMessage = useAuthClearErrorAndMessage();

    useEffect((): (() => void) => {
        clearErrorAndMessage();
        return clearErrorAndMessage;
    }, []);

    const handleSubmit = async (values: LoginFormData): Promise<void> => {
        const authSuccess = await signIn(values);
        if (authSuccess) {
            if (location.state?.from) {
                return navigate(location.state.from);
            }
            navigate(ROUTES.SIGNALS);
        }
    };

    return (
        <>
            <div className={styles.container}>
                <HeaderLogin />
                {loading && <Loading absolute />}
                <div className={styles.content}>
                    <FormLogin error={error} onSubmit={handleSubmit} disabled={loading} />
                    <div ref={containerRef} className={styles['stars-container']}>
                        {USE_ANIMATION && <AnimatedStars containerRef={containerRef} />}
                        <div className={styles['image-container']} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
