import Typography from 'components/Typography';

import styles from './style.module.scss';

type Props = {
    imageUrl?: string;
    imageAlt?: string;
    primary?: string;
    secondary?: string;
};

export const MessageBox: React.FC<Props> = ({ imageUrl, imageAlt, primary, secondary }) => {
    return (
        <div className={styles['message-box']}>
            {imageUrl && (
                <img src={imageUrl} alt={imageAlt || ''} className={styles['message-image']} />
            )}
            {primary && (
                <Typography variant='bodyBold1' className={styles['text-primary']}>
                    {primary}
                </Typography>
            )}
            {secondary && (
                <Typography variant='bodyRegular2' className={styles['text-secondary']}>
                    {secondary}
                </Typography>
            )}
        </div>
    );
};

export default MessageBox;
