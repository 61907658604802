import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import type { Match } from 'interfaces/match.type';

import CircledTag from 'components/CircledTag';
import Icon, { ICON_NAMES } from 'components/Icon';
import { getMatchFullName } from 'components/MatchInfoHeader/getMatchFullName';
import ProspectAvatar from 'components/ProspectAvatar';
import Tag from 'components/Tag';
import Typography from 'components/Typography';
import { isProspectHasAutopilotSignal } from 'services/signal';
import { useUserEx } from 'stores/useBoundStore';

import { getMatchInitials, getMatchScoreString, getProspectSignalsTagsString } from './utils';

import styles from './style.module.scss';

type Props = {
    item: Match;
    active?: boolean;
    onClick?: (_item: Match) => void;
};

export const MatchCard: React.FC<Props> = ({ item, active = false, onClick }) => {
    const { t } = useTranslation('matches');

    const user = useUserEx();

    const showAutosend = user?.settings?.autopilot?.enabled && isProspectHasAutopilotSignal(item);

    const tagsString = getProspectSignalsTagsString(t, item);
    const matchName = getMatchFullName(item);
    const scoreString = getMatchScoreString(item);

    const matchCardClassNames = classNames(styles.container, {
        [styles.active]: active,
        [styles.read]: item.read || item.done,
        [styles.done]: item.done,
    });
    const iconClassNames = classNames(styles.icon, {
        [styles['icon-active']]: active,
    });
    const companyClassNames = classNames(styles['company-text'], {
        [styles['company-active']]: active,
    });
    const extraClassNames = classNames(styles.extra, {
        [styles['extra-active']]: active,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        if (typeof onClick === 'function') onClick(item);
    };

    return (
        <div className={styles.wrapper} onClick={handleClick}>
            <div className={matchCardClassNames}>
                <div className={styles.avatar}>
                    <ProspectAvatar
                        variant='default'
                        contactStatus={item.contactStatus}
                        source={item.source}
                        src={item.imageUrl}
                        fallback={getMatchInitials(item)}
                        showTooltip={false}
                    />
                </div>
                <div className={styles.info}>
                    <Typography className={styles.title} variant='heading4'>
                        {matchName}
                    </Typography>
                    <div className={styles.company}>
                        <span className={iconClassNames}>
                            <Icon name={ICON_NAMES.Company} />
                        </span>
                        <Typography className={companyClassNames} variant='bodyRegular2'>
                            {item.companyName}
                        </Typography>
                    </div>
                    <div className={styles.tags}>
                        {tagsString && (
                            <Tag text={tagsString} inverted={active} variant='secondary' />
                        )}
                        {showAutosend && item.timeUntilAutosend && (
                            <Tag
                                icon={ICON_NAMES.Autopilot}
                                iconSize={12}
                                text={item.timeUntilAutosend}
                                inverted={active}
                                variant='info'
                                hideTextOnMobile
                            />
                        )}
                    </div>
                </div>
                <div className={extraClassNames}>
                    {item.done ? (
                        <Icon name={ICON_NAMES.CheckCircle} />
                    ) : (
                        scoreString && <CircledTag text={scoreString} inverted={active} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MatchCard;
