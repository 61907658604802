import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { UserInfoExFormData } from 'interfaces/userEx.type';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { useUpdateUserEx } from 'stores/useBoundStore';

import styles from './style.module.scss';

export type DisableAutopilotDialogProps = {
    open?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export const DisableAutopilotDialog: React.FC<DisableAutopilotDialogProps> = ({
    open: openExternal,
    onConfirm,
    onCancel,
}) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation('profile');

    const updateUserEx = useUpdateUserEx();

    useEffect(() => {
        if (openExternal !== undefined) {
            setOpen(openExternal);
        }
    }, [openExternal]);

    const handleConfirm = async (): Promise<void> => {
        const formData: UserInfoExFormData = {
            settings: {
                autopilot: {
                    enabled: false,
                },
            },
        };
        const success = await updateUserEx(formData);
        if (success) {
            if (onConfirm) onConfirm();
            setOpen(false);
            toast.success(t('disable_autopilot.disable_success'));
        }
        // trackEventProfileInfoChange();
        if (onConfirm) onConfirm();
        setOpen(false);
    };

    const handleCancel = (): void => {
        if (onCancel) onCancel();
        setOpen(false);
    };

    return (
        <ConfirmationDialog
            open={open}
            title={t('disable_autopilot.title')}
            content={t('disable_autopilot.text_confirm')}
            labelConfirm={t('disable_autopilot.button_confirm')}
            labelCancel={t('disable_autopilot.button_cancel')}
            confirmVariant='primary-danger'
            className={styles.dialog}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        />
    );
};

export default DisableAutopilotDialog;
