import { create } from 'zustand';

import type { Job } from 'interfaces/job';
import type { Prospect, LeadSearchResultItem } from 'interfaces/prospect';
import type { FormFilterProspectsFormData } from 'forms/FormFilterProspects';
import type { FormLeadSearchFormData } from 'forms/FormLeadSearch';
import { createProspectSlice, type LeadSearchConfig, type ProspectSlice } from './prospectSlice';

export const useProspectStore = create<ProspectSlice>()(createProspectSlice);

export const useProspectsCurrentPage = (): number => useProspectStore((state) => state.currentPage);
export const useProspectsRecordCount = (): number => useProspectStore((state) => state.recordCount);
export const useProspectsLoading = (): boolean => useProspectStore((state) => state.loading);
export const useProspectsError = (): string | undefined => useProspectStore((state) => state.error);
export const useProspectsData = (): Prospect[] => useProspectStore((state) => state.data);

export const useGetProspects = (): ((_filter: FormFilterProspectsFormData) => Promise<void>) =>
    useProspectStore((state) => state.getProspects);
export const useGetProspectsNextPage = (): (() => Promise<void>) =>
    useProspectStore((state) => state.getProspectsNextPage);
export const useGetProspectsPrevPage = (): (() => Promise<void>) =>
    useProspectStore((state) => state.getProspectsPrevPage);
export const useArchiveProspect = (): ((_userProspectId: string) => Promise<boolean>) =>
    useProspectStore((state) => state.archiveProspect);

export const useSelectedProspectId = (): string | undefined =>
    useProspectStore((state) => state.selectedProspectId);

export const useSelectProspectId = (): ((_userProspectId: string | undefined) => void) =>
    useProspectStore((state) => state.selectProspectId);

export const useOpenArchiveDialog = (): boolean =>
    useProspectStore((state) => state.openArchiveDialog);

export const useSetOpenArchiveDialog = (): ((_open: boolean) => void) =>
    useProspectStore((state) => state.setOpenArchiveDialog);

export const useLeadSearchLoading = (): boolean => useProspectStore((state) => state.loadingSearch);
export const useLeadSearchResults = (): LeadSearchResultItem[] =>
    useProspectStore((state) => state.searchResults);
export const useLeadSearchConfig = (): LeadSearchConfig =>
    useProspectStore((state) => state.leadSearchConfig);
export const useLeadSearchRecordCount = (): number =>
    useProspectStore((state) => state.leadSearchRecordCount);
export const useUserProspectCount = (): number =>
    useProspectStore((state) => state.userProspectCount);
export const useLeadSearchCurrentPage = (): number =>
    useProspectStore((state) => state.leadSearchCurrentPage);

export const useGetLeadsConfig = (): (() => Promise<void>) =>
    useProspectStore((state) => state.getLeadsConfig);
export const useSearchLeads = (): ((_values: FormLeadSearchFormData) => Promise<void>) =>
    useProspectStore((state) => state.searchLeads);
export const useSearchLeadsNextPage = (): (() => Promise<void>) =>
    useProspectStore((state) => state.searchLeadsNextPage);
export const useSearchLeadsPrevPage = (): (() => Promise<void>) =>
    useProspectStore((state) => state.searchLeadsPrevPage);

export const useImportJobs = (): Job[] => useProspectStore((state) => state.importJobs);
export const useImportJobsLoading = (): boolean =>
    useProspectStore((state) => state.importJobsLoading);
export const useGetImportJobs = (): (() => Promise<void>) =>
    useProspectStore((state) => state.getImportJobs);
