import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { type Match } from 'interfaces/match.type';
import { getMatchInitials } from 'components/MatchCard/utils';

import ButtonWithDropdown from 'components/ButtonWithDropdown';
import CRMLastContactInfo from 'components/CRMLastContactInfo';
import Icon, { ICON_NAMES } from 'components/Icon';
import ProspectAvatar from 'components/ProspectAvatar';
import Tag from 'components/Tag';
import Typography from 'components/Typography';
import { ArchiveProspectConfirmationDialog } from 'components/dialogs/ArchiveProspectConfirmationDialog';
import {
    useCRMCalls,
    useCRMConnectionState,
    useCRMEmails,
    useCRMMeetings,
    useCRMProspects,
    useGetCRMProspectInfoById,
    useArchiveProspect,
} from 'stores/useBoundStore';
import {
    useOpenArchiveDialog,
    useSelectedProspectId,
    useSelectProspectId,
    useSetOpenArchiveDialog,
} from 'stores/useProspectStore';
import {
    type EventProspectProfileType,
    trackEventOpenProspectProfile,
} from 'services/eventsTracker';
import env from 'env';

import { getMatchFullName } from './getMatchFullName';

import styles from './style.module.scss';

const prospectOptions = [
    {
        label: 'archive_prospect',
        value: 'archive',
    },
];

type Props = {
    item: Match;
};

const MatchInfoHeader: React.FC<Props> = ({ item }) => {
    const { t } = useTranslation(['matches', 'prospects']);

    const getCRMProspectInfoById = useGetCRMProspectInfoById();
    const crmConnectionState = useCRMConnectionState();
    const crmProspects = useCRMProspects();
    const crmMeetings = useCRMMeetings();
    const crmCalls = useCRMCalls();
    const crmEmails = useCRMEmails();
    const archiveProspect = useArchiveProspect();
    const selectedProspectId = useSelectedProspectId();
    const selectProspectId = useSelectProspectId();
    const openArchiveDialog = useOpenArchiveDialog();
    const setOpenArchiveDialog = useSetOpenArchiveDialog();

    const crmProspectFullInfo = useMemo(() => {
        const prospect = getCRMProspectInfoById(item.prospectId);
        if (env.CRM_INTEGRATION_DISABLED === false && env.CRM_INTEGRATION_INFO_DISABLED === false) {
            if (prospect) {
                return prospect;
            }
        }
        return undefined;
    }, [item.prospectId, crmProspects, crmMeetings, crmCalls, crmEmails]);

    const prospectOptionsTranslated = useMemo(() => {
        return prospectOptions.map((option) => ({
            ...option,
            label: t(`prospect.options.${option.label}`),
        }));
    }, [t]);

    const name = getMatchFullName(item);

    const hasAnyTag =
        item?.tag &&
        (item.tag?.seniority ||
            item.tag?.headcount ||
            item.tag?.headquarter ||
            item.tag?.orientation ||
            item.tag?.industry);

    const handleClickLink = (
        event: React.MouseEvent<HTMLAnchorElement>,
        profileType: EventProspectProfileType,
    ): void => {
        trackEventOpenProspectProfile(item, profileType, event.currentTarget.href);
    };

    const handleProspectOptionClick = (
        _event: React.MouseEvent<HTMLElement>,
        value: string,
    ): void => {
        if (value === 'archive') {
            selectProspectId(item.userProspectId);
            setOpenArchiveDialog(true);
        }
    };

    const handleArchiveConfirm = async (): Promise<void> => {
        if (!selectedProspectId) return;
        const result = await archiveProspect(selectedProspectId);
        if (result) {
            toast.success(t('prospects:dialogs.archive_prospect.success'));
        }
        setOpenArchiveDialog(false);
        selectProspectId(undefined);
    };

    const handleArchiveCancel = (): void => {
        setOpenArchiveDialog(false);
        selectProspectId(undefined);
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles['info-container']}>
                    <div className={styles.avatar}>
                        <ProspectAvatar
                            variant='large'
                            contactStatus={item.contactStatus}
                            source={item.source}
                            src={item.imageUrl}
                            fallback={getMatchInitials(item)}
                        />
                    </div>

                    <div className={styles.info}>
                        <div className={styles.name}>
                            <Typography variant='heading3'>{name}</Typography>
                            {item.linkedInUrl && (
                                <div className={styles.icons}>
                                    <span className={styles.icon}>
                                        <Link
                                            target='_blank'
                                            to={item.linkedInUrl}
                                            onClick={(event): void =>
                                                handleClickLink(event, 'linkedin')
                                            }
                                        >
                                            <Icon name={ICON_NAMES.LinkedIn} />
                                        </Link>
                                    </span>
                                    <span className={styles.icon}>
                                        <ButtonWithDropdown
                                            options={prospectOptionsTranslated}
                                            icon={ICON_NAMES.More}
                                            size='small'
                                            onOptionClick={handleProspectOptionClick}
                                        />
                                    </span>
                                </div>
                            )}
                        </div>

                        <Typography variant='bodyBold1'>
                            {item.jobTitle} {t('match.role_at')} {item.companyName}
                        </Typography>

                        {item.location && (
                            <div className={styles.location}>
                                <span className={styles.icon}>
                                    <Icon name={ICON_NAMES.Location} />
                                </span>
                                <Typography>{item.location}</Typography>
                            </div>
                        )}

                        {hasAnyTag && (
                            <div className={styles.tags}>
                                {item?.tag?.seniority && <Tag text={item.tag.seniority} gradient />}
                                {item?.tag?.headcount && (
                                    <Tag
                                        text={item.tag.headcount + ' ' + t('tag.employees')}
                                        gradient
                                    />
                                )}
                                {item?.tag?.headquarter && (
                                    <Tag text={item.tag.headquarter} gradient />
                                )}
                                {item?.tag?.orientation && (
                                    <Tag text={item.tag.orientation} gradient />
                                )}
                                {item?.tag?.industry && <Tag text={item.tag.industry} gradient />}
                            </div>
                        )}
                    </div>
                </div>

                {env.CRM_INTEGRATION_DISABLED === false &&
                    env.CRM_INTEGRATION_INFO_DISABLED === false && (
                        <div className={styles['crm-info']}>
                            <CRMLastContactInfo
                                crmProspect={crmProspectFullInfo}
                                connectionState={crmConnectionState}
                            />
                        </div>
                    )}
            </div>
            <ArchiveProspectConfirmationDialog
                open={openArchiveDialog}
                onConfirm={handleArchiveConfirm}
                onCancel={handleArchiveCancel}
            />
        </>
    );
};

export default MatchInfoHeader;
