import React from 'react';

import { ReactComponent as SvgStar } from 'assets/images/star.svg';

import { useStars } from './useStars';

import styles from './style.module.scss';

type Props = {
    containerRef: React.RefObject<HTMLDivElement>;
};

export const AnimatedStars: React.FC<Props> = ({ containerRef }): JSX.Element => {
    const stars = useStars(containerRef);

    return (
        <>
            {stars.map(({ id, left, top, animationDuration }) => (
                <div key={id} className={styles.star} style={{ left, top, animationDuration }}>
                    <SvgStar />
                </div>
            ))}
        </>
    );
};

export default AnimatedStars;
