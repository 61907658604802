import { PROFILE_TABS, type ProfileTab } from 'interfaces/profile.type';
import PersonalInformation from 'components/PersonalInformation';
import ProfileSignalsAndMessages from 'components/ProfileSignalsAndMessages';
import SettingsEmail from 'components/SettingsEmail';
import SettingsIntegrations from 'components/SettingsIntegrations';
import SettingsNotifications from 'components/SettingsNotifications';

import styles from './style.module.scss';

type Props = {
    tab: ProfileTab;
};

export const ProfileTabContent: React.FC<Props> = ({ tab }) => {
    return (
        <div className={styles['tab-content']}>
            {tab === PROFILE_TABS.PersonalInformation && <PersonalInformation />}
            {tab === PROFILE_TABS.SignalsAndMessages && <ProfileSignalsAndMessages />}
            {tab === PROFILE_TABS.Integrations && <SettingsIntegrations />}
            {tab === PROFILE_TABS.EmailSettings && <SettingsEmail />}
            {tab === PROFILE_TABS.Notifications && <SettingsNotifications />}
        </div>
    );
};

export default ProfileTabContent;
