import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Icon, { ICON_NAMES } from 'components/Icon';
import Typography from 'components/Typography';
import { ROUTES } from 'constants/routes/routes';
import { retrieveUserId } from 'services/storage';
import { useFetchUser, useUser } from 'stores/useBoundStore';

import styles from './style.module.scss';

export type NavItem = {
    title: string;
    path: string;
};

const HeaderProxy: React.FC = () => {
    const { t } = useTranslation('auth');

    const user = useUser();
    const fetchUser = useFetchUser();

    useEffect(() => {
        const userId = retrieveUserId();
        if (userId) fetchUser(userId);
    }, []);

    return (
        <header className={styles.container}>
            <div className={styles.message}>
                {user && (
                    <>
                        <Typography variant='bodyRegular1'>{t('message.proxy_login')}</Typography>
                        <Typography variant='bodyBold1'>
                            {user.firstName} {user.lastName}, {user.email}
                        </Typography>
                    </>
                )}
            </div>

            <div className={styles['menu-items']}>
                <Link to={ROUTES.LOGOUT}>
                    <Icon name={ICON_NAMES.Exit} />
                </Link>
            </div>
        </header>
    );
};

export default HeaderProxy;
