import { create } from 'zustand';

import { type ActivityRecord } from 'interfaces/activity.type';
import { type FormFilterActivityFormData } from 'forms/FormFilterActivity';
import { createActivitySlice, type ActivitySlice } from './activitySlice';

export const useActivityStore = create<ActivitySlice>()(createActivitySlice);

export const useActivityLoading = (): boolean => useActivityStore((state) => state.loading);
export const useActivityError = (): string | undefined => useActivityStore((state) => state.error);
export const useActivityData = (): ActivityRecord[] => useActivityStore((state) => state.data);
export const useGetActivity = (): ((_filter: FormFilterActivityFormData) => Promise<void>) =>
    useActivityStore((state) => state.getActivity);
export const useGetActivityNextPage = (): (() => Promise<void>) =>
    useActivityStore((state) => state.getActivityNextPage);
