import { Navigate, Outlet, createBrowserRouter, useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes/routes';
import AnalyticsPageViewTracker from 'components/AnalyticsPageViewTracker';

import ActivityPage from 'pages/Activity';
import AuthForgotPasswordPage from 'pages/AuthForgotPassword';
import AuthLoginPage from 'pages/AuthLogin';
import AuthProxyLoginPage from 'pages/AuthProxyLogin';
import AuthLogoutPage from 'pages/AuthLogout';
import AuthSetPassword from 'pages/AuthSetPassword';
import MatchesPage from 'pages/Matches';
import NotFoundPage from 'pages/NotFound';
import PerformancePage from 'pages/Performance';
import ProfilePage from 'pages/Profile';
import ProspectsPage from 'pages/Prospects';
import ProspectSearchPage from 'pages/ProspectSearch';
import { hasToken } from 'services/storage';
import { PROFILE_TABS } from 'interfaces/profile.type';

const AuthorizedRoute = (): JSX.Element => {
    const location = useLocation();

    if (hasToken() === false) {
        return <Navigate to={ROUTES.LOGIN} state={{ from: location.pathname }} replace />;
    }

    return <Outlet />;
};

export const router = createBrowserRouter([
    {
        element: <AnalyticsPageViewTracker />,
    },
    {
        path: ROUTES.MAIN,
        element: <Navigate to={ROUTES.SIGNALS} replace />,
    },
    {
        path: ROUTES.LOGIN,
        element: <AuthLoginPage />,
    },
    {
        path: ROUTES.PROXY_LOGIN,
        element: <AuthProxyLoginPage />,
    },
    {
        path: ROUTES.LOGOUT,
        element: <AuthLogoutPage />,
    },
    {
        path: ROUTES.FORGOT_PASSWORD,
        element: <AuthForgotPasswordPage />,
    },
    {
        path: ROUTES.SET_PASSWORD,
        element: <AuthSetPassword />,
    },
    {
        element: <AuthorizedRoute />,
        children: [
            {
                path: ROUTES.SIGNALS,
                element: <MatchesPage />,
            },
            {
                path: ROUTES.SIGNALS_PROSPECT,
                element: <MatchesPage />,
            },
            {
                path: ROUTES.SIGNALS_PROSPECT_DETAIL,
                element: <MatchesPage />,
            },
            {
                path: ROUTES.MATCHES,
                element: <Navigate to={ROUTES.SIGNALS} replace />,
            },
            {
                path: ROUTES.MATCH_DETAIL,
                element: <MatchesPage />,
            },
            {
                path: ROUTES.MATCH_SIGNAL_DETAIL,
                element: <MatchesPage />,
            },
            {
                path: ROUTES.ACTIVITY,
                element: <ActivityPage />,
            },
            {
                path: ROUTES.PROSPECTS,
                element: <ProspectsPage />,
            },
            {
                path: ROUTES.PROSPECTS_TAB,
                element: <ProspectsPage />,
            },
            {
                path: ROUTES.PROSPECTS_SEARCH,
                element: <ProspectSearchPage />,
            },
            {
                path: ROUTES.PERFORMANCE,
                element: <PerformancePage />,
            },
            {
                path: ROUTES.PROFILE,
                element: <ProfilePage />,
            },
            // redirect for old CRM integration page, can be deleted after few iterations
            {
                path: ROUTES.PROFILE_DETAIL.replace(':page', 'crm_integration'),
                element: (
                    <Navigate
                        to={ROUTES.PROFILE_DETAIL.replace(':page', PROFILE_TABS.Integrations)}
                        replace
                    />
                ),
            },
            {
                path: ROUTES.PROFILE_DETAIL,
                element: <ProfilePage />,
            },
        ],
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
]);
