import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as HoverCard from '@radix-ui/react-hover-card';

import ConsentPopup from 'components/ConsentPopup';
import Typography from 'components/Typography';
import { consentAccept, consentReject } from 'services/consent';
import { trackEventSupportFeedback } from 'services/eventsTracker';
import { ReactComponent as SvgIconQuestion } from 'assets/icons/question.svg';
import env from 'env';

import styles from './style.module.scss';

const SupportPopup: React.FC = () => {
    const [consentOpen, setConsentOpen] = useState(false);

    const { t } = useTranslation('matches');

    const handleChangeCookieSettingsClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        setConsentOpen(true);
    };

    const handleConsentAccept = (): void => {
        consentAccept();
        setConsentOpen(false);
    };

    const handleConsentReject = (): void => {
        consentReject();
        setConsentOpen(false);
    };

    // have to handle links because HoverCard prevents click events
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        const link = event.currentTarget as HTMLAnchorElement;
        trackEventSupportFeedback();
        window.open(link.href, link.target);
    };

    return (
        <>
            <HoverCard.Root openDelay={200} closeDelay={300}>
                <HoverCard.Trigger asChild>
                    <SvgIconQuestion />
                </HoverCard.Trigger>
                <HoverCard.Portal>
                    <HoverCard.Content className={styles.content} sideOffset={5}>
                        <Typography>
                            <a href='.' onClick={handleChangeCookieSettingsClick}>
                                {t('header.change_cookie_settings')}
                            </a>
                        </Typography>
                        <Typography>
                            <a
                                href={env.PRIVACY_POLICY_URL}
                                target='_blank'
                                rel='noreferrer'
                                onClick={handleLinkClick}
                            >
                                {t('header.privacy_policy')}
                            </a>
                        </Typography>
                        <HoverCard.Arrow className={styles.arrow} />
                    </HoverCard.Content>
                </HoverCard.Portal>
            </HoverCard.Root>
            {consentOpen && (
                <ConsentPopup onAccept={handleConsentAccept} onReject={handleConsentReject} />
            )}
        </>
    );
};

export default SupportPopup;
