import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';

export const useDebounce = <T extends unknown[]>(
    ms: number,
    callback?: (..._arguments: T) => void,
): ((..._arguments: T) => void) => {
    const ref = useRef<(..._arguments: T) => void>();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = (...args: T): void => {
            if (ref.current) ref.current(...args);
        };

        return debounce(func, ms || 300);
    }, []);

    return debouncedCallback;
};

export default useDebounce;
