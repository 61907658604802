import type { StateCreator } from 'zustand';

import { type ForgotPasswordFormData } from 'forms/FormForgotPassword';
import { type LoginFormData } from 'forms/FormLogin';
import { type SetPasswordFormData } from 'forms/FormSetPassword';
import { type ChangePasswordFormData } from 'forms/FormPersonalInformation';
import * as authService from 'services/auth';
import { AuthAPIError } from 'services/authApi';
import { removeUserId } from 'services/storage';
import { type CRMSlice } from 'stores/crmSlice';
import { type MatchSlice } from 'stores/matchSlice';
import { type UserSlice } from 'stores/userSlice';

export type AuthSlice = {
    loading: boolean;
    error: string | undefined;
    message: string | undefined;
    clearErrorAndMessage: () => void;
    signIn: (_data: LoginFormData) => Promise<boolean>;
    signOut: () => Promise<void>;
    forgotPassword: (_data: ForgotPasswordFormData) => Promise<boolean>;
    resetPassword: (_data: SetPasswordFormData) => Promise<boolean>;
    changePassword: (_data: ChangePasswordFormData) => Promise<boolean>;
};

const getErrorMessageKey = (error: unknown): string => {
    if (error instanceof AuthAPIError || error instanceof Error) {
        return error.message;
    }
    return 'auth:error.error';
};

export const createAuthSlice: StateCreator<
    AuthSlice & CRMSlice & MatchSlice & UserSlice,
    [],
    [],
    AuthSlice
> = (set, get) => ({
    loading: false,
    error: undefined,
    message: undefined,

    clearErrorAndMessage: (): void => {
        set((_state) => ({ loading: false, error: undefined, message: undefined }));
    },

    signIn: async ({ email, password }: LoginFormData): Promise<boolean> => {
        let success = false;
        set((_state) => ({ loading: true, error: undefined }));
        try {
            await authService.signIn({ email, password });
            success = true;
            get().clearCRMInfo();
            get().initMatches();
        } catch (error: unknown) {
            set((_state) => ({ error: getErrorMessageKey(error) }));
        } finally {
            set((_state) => ({ loading: false }));
        }
        return success;
    },

    signOut: async (): Promise<void> => {
        set((_state) => ({ loading: true, error: undefined }));
        try {
            get().initMatches();
            removeUserId();
            get().invalidateProxyLogin();
            authService.signOut();
            get().clearCRMInfo();
        } catch (error: unknown) {
            set((_state) => ({ error: getErrorMessageKey(error) }));
        } finally {
            set((_state) => ({ loading: false }));
        }
    },

    forgotPassword: async ({ email }: ForgotPasswordFormData): Promise<boolean> => {
        set((_state) => ({ loading: true, error: undefined, message: undefined }));
        try {
            const forgotPasswordResult = await authService.forgotPassword({ email });
            set((_state) => ({ message: 'message.password_reset_link_sent' }));
            return forgotPasswordResult;
        } catch (error: unknown) {
            set((_state) => ({ error: getErrorMessageKey(error) }));
            return false;
        } finally {
            set((_state) => ({ loading: false }));
        }
    },

    resetPassword: async ({
        password,
        passwordConfirmation,
        code,
    }: SetPasswordFormData): Promise<boolean> => {
        let success = false;
        set((_state) => ({ loading: true, error: undefined, message: undefined }));
        try {
            await authService.resetPassword({
                password,
                passwordConfirmation,
                code,
            });
            success = true;
        } catch (error: unknown) {
            set((_state) => ({ error: getErrorMessageKey(error) }));
        } finally {
            set((_state) => ({ loading: false }));
        }
        return success;
    },

    changePassword: async ({
        password,
        passwordConfirmation,
        currentPassword,
    }: ChangePasswordFormData): Promise<boolean> => {
        let success = false;
        set((_state) => ({ loading: true, error: undefined }));
        try {
            await authService.changePassword({
                password,
                passwordConfirmation,
                currentPassword,
            });
            success = true;
        } catch (error: unknown) {
            set((_state) => ({ error: getErrorMessageKey(error) }));
        } finally {
            set((_state) => ({ loading: false }));
        }
        return success;
    },
});
