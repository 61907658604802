import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { RouterProvider } from 'react-router-dom';
import { TooltipProvider } from '@radix-ui/react-tooltip';

import AnalyticsProviderGA4 from 'components/AnalyticsProviderGA4';
import ConsentPopup from 'components/ConsentPopup';
import { consentAccept, consentReject, isConsentGiven } from 'services/consent';
import 'services/apm';

import reportWebVitals from './reportWebVitals';
import { router } from './router';
import { initI18n } from './translations/i18n';

import 'constants/theme/global.scss';

initI18n();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <TooltipProvider delayDuration={250} skipDelayDuration={200}>
                <AnalyticsProviderGA4 />
                {isConsentGiven() === false && (
                    <ConsentPopup onAccept={consentAccept} onReject={consentReject} />
                )}
                <RouterProvider router={router} />
            </TooltipProvider>
        </I18nextProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
