import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import styles from './style.module.scss';

type TooltipProps = {
    children: React.ReactNode;
    content: React.ReactNode;
    showTooltip?: boolean;
    asChild?: boolean;
};

const Tooltip: React.FC<TooltipProps> = ({
    children,
    content,
    showTooltip = true,
    asChild = false,
}) => {
    if (!showTooltip) return <>{children}</>;

    return (
        <TooltipPrimitive.Root>
            <TooltipPrimitive.Trigger asChild={asChild} className={styles.trigger}>
                {children}
            </TooltipPrimitive.Trigger>
            <TooltipPrimitive.Portal>
                <TooltipPrimitive.Content className={styles.container} side='bottom'>
                    {content}
                    <TooltipPrimitive.Arrow className={styles.arrow} />
                </TooltipPrimitive.Content>
            </TooltipPrimitive.Portal>
        </TooltipPrimitive.Root>
    );
};

export default Tooltip;
