import { type LoginFormData } from '.';

const defaultValuesLoginFormData: LoginFormData = {
    email: '',
    password: '',
};

export const extractFormData = (formData: FormData): LoginFormData => {
    const payload: LoginFormData = defaultValuesLoginFormData;
    const email = formData.get('email');
    if (typeof email === 'string') {
        payload.email = email;
    }
    const password = formData.get('password');
    if (typeof password === 'string') {
        payload.password = password;
    }
    return payload;
};
