import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as TabsRadix from '@radix-ui/react-tabs';

import Icon, { ICON_NAMES } from 'components/Icon';

import styles from './style.module.scss';

export type TabItem = {
    label: JSX.Element | string;
    value: string;
    link?: string;
};

type TabsContentProps = {
    value: string;
    children?: React.ReactNode;
};

type TabsContentComponent = React.FC<TabsContentProps>;

const TabsContent: TabsContentComponent = ({ value, children = null }) => {
    return (
        <TabsRadix.Content className={styles.content} value={value}>
            {children}
        </TabsRadix.Content>
    );
};

type TabsProps = {
    items?: TabItem[];
    value?: string;
    children: React.ReactNode;
    onTabChange?: (_item: TabItem) => void;
};

type TabsComponent = React.FC<TabsProps> & {
    Content: TabsContentComponent;
};

const Tabs: TabsComponent = ({ items, value, children = null, onTabChange }) => {
    const [tabValue, setTabValue] = useState<string | undefined>(value);
    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

    const tabsRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (value) {
            setTabValue(value);
        } else if (items?.length && items.length > 0) {
            setTabValue(items[0].value);
        }
    }, [items, value]);

    useLayoutEffect(() => {
        evaluateScrollArea();
        scrollIntoView();
    }, [items, value]);

    const scrollIntoView = (): void => {
        const target = tabsRef.current;
        if (!target) return;
        const idx = items?.findIndex((item) => item.value === tabValue);
        if (!idx || idx === -1) return;
        const child = target.children[idx] as HTMLElement;
        if (!child) return;
        const { offsetLeft, clientWidth } = child;
        let performScroll = false;
        let scrollTo = 0;
        if (offsetLeft + clientWidth + 24 > target.scrollLeft + target.clientWidth) {
            scrollTo = offsetLeft + clientWidth + 24 - target.clientWidth;
            if (scrollTo >= target.scrollWidth) {
                scrollTo = target.scrollWidth - clientWidth;
            }
            performScroll = true;
        }
        if (offsetLeft - 24 < target.scrollLeft) {
            scrollTo = offsetLeft - 24;
            if (scrollTo < 0) {
                scrollTo = 0;
            }
            performScroll = true;
        }
        if (performScroll) target.scrollTo({ left: scrollTo, behavior: 'smooth' });
    };

    const evaluateScrollArea = (): void => {
        const target = tabsRef.current;
        if (!target) return;
        const { scrollLeft, scrollWidth, clientWidth } = target;

        const isScrollStart = scrollLeft === 0;
        const isScrollEnd = scrollLeft >= scrollWidth - clientWidth;

        if (isScrollStart) {
            setShowLeftArrow(false);
        } else {
            setShowLeftArrow(true);
        }
        if (isScrollEnd) {
            setShowRightArrow(false);
        } else {
            setShowRightArrow(true);
        }
    };

    const handleValueChange = (value: string): void => {
        setTabValue(value);
        if (onTabChange) {
            const foundItem = items?.find((item) => item.value === value);
            if (foundItem) onTabChange(foundItem);
        }
    };

    const handleScrollLeftClick = (_event: React.UIEvent<HTMLDivElement>): void => {
        const target = tabsRef.current;
        if (!target) return;
        const { scrollLeft, clientWidth } = target;

        let scrollTo = scrollLeft - clientWidth + 24;
        if (scrollTo < 0) {
            scrollTo = 0;
        }

        target.scrollTo({ left: scrollTo, behavior: 'smooth' });
        evaluateScrollArea();
    };

    const handleScrollRightClick = (_event: React.UIEvent<HTMLDivElement>): void => {
        const target = tabsRef.current;
        if (!target) return;
        const { scrollLeft, scrollWidth, clientWidth } = target;

        let scrollTo = scrollLeft + clientWidth - 24;
        if (scrollTo >= scrollWidth) {
            scrollTo = scrollWidth - clientWidth;
        }

        target.scrollTo({ left: scrollTo, behavior: 'smooth' });
        evaluateScrollArea();
    };

    const handleScroll = (_event: React.UIEvent<HTMLDivElement>): void => {
        evaluateScrollArea();
    };

    return (
        <TabsRadix.Root value={tabValue} onValueChange={handleValueChange}>
            <div className={styles.container}>
                {showLeftArrow && (
                    <div className={styles['arrow-left']} onClick={handleScrollLeftClick}>
                        <Icon name={ICON_NAMES.ChevronLeft} />
                    </div>
                )}
                <TabsRadix.List className={styles.tabs} onScroll={handleScroll} ref={tabsRef}>
                    {items?.map((item) => (
                        <TabsRadix.Trigger
                            key={item.value}
                            value={item.value}
                            className={styles.item}
                        >
                            {item.label}
                        </TabsRadix.Trigger>
                    ))}
                </TabsRadix.List>
                {showRightArrow && (
                    <div className={styles['arrow-right']} onClick={handleScrollRightClick}>
                        <Icon name={ICON_NAMES.ChevronRight} />
                    </div>
                )}
            </div>
            {children}
        </TabsRadix.Root>
    );
};

Tabs.Content = TabsContent;

export default Tabs;
