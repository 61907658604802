import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { UserSignalCategory } from 'interfaces/userEx.type';
import Dialog from 'components/Dialog';
import FormSignalCategory, { type SignalCategoryFormData } from 'forms/FormSignalCategory';
import { getSignalCategoryLabel } from 'services/signal';
import { useSignalCategories } from 'stores/useBoundStore';

import styles from './style.module.scss';

export type SignalCategoryEditDialogProps = {
    open?: boolean;
    userSignalCategory?: UserSignalCategory;
    disabled?: boolean;
    onSubmit: (_values: SignalCategoryFormData) => Promise<void>;
    onCancel: () => void;
};

export const SignalCategoryEditDialog: React.FC<SignalCategoryEditDialogProps> = ({
    open: openExternal,
    userSignalCategory,
    disabled = false,
    onSubmit,
    onCancel,
}) => {
    const [open, setOpen] = useState(false);

    const { i18n } = useTranslation('profile');

    const signalCategories = useSignalCategories();

    const formDefaultValues: SignalCategoryFormData = useMemo(
        () => ({
            clarification: userSignalCategory?.clarification || '',
            pitch: userSignalCategory?.pitch || '',
            weight: userSignalCategory?.weight || 0,
        }),
        [userSignalCategory],
    );

    useEffect(() => {
        if (openExternal !== undefined) {
            setOpen(openExternal);
        }
    }, [openExternal]);

    const handleSubmit = async (values: SignalCategoryFormData): Promise<void> => {
        if (onSubmit) {
            await onSubmit(values);
        }
        setOpen(false);
    };

    const handleCancel = (): void => {
        if (onCancel) onCancel();
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            title={getSignalCategoryLabel(signalCategories, i18n.language, userSignalCategory)}
            onClose={handleCancel}
            className={styles.dialog}
        >
            <FormSignalCategory
                initialValues={formDefaultValues}
                disabled={disabled}
                onChange={handleSubmit}
                onCancel={handleCancel}
            />
        </Dialog>
    );
};

export default SignalCategoryEditDialog;
