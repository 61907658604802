import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { UserInfoExFormData } from 'interfaces/userEx.type';
import Dialog from 'components/Dialog';
import FormAutopilotSettings, {
    type FormAutopilotSettingsFormData,
    DELAY_BEFORE_AUTOSEND,
    MAX_SEND_FREQUENCY,
} from 'forms/FormAutopilotSettings';
import { isSendViaHoneysales } from 'services/email';
import { PROSPECT_CONTACT_STATUS } from 'services/honeycomb';
import { isAuthorizedToUseAutopilot } from 'stores/userSlice/isAuthorized';
import { useLoadingUserEx, useUpdateUserEx, useUserEx } from 'stores/useBoundStore';

import styles from './style.module.scss';

export type EnableAutopilotDialogProps = {
    open?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export const EnableAutopilotDialog: React.FC<EnableAutopilotDialogProps> = ({
    open: openExternal,
    onConfirm,
    onCancel,
}) => {
    const [open, setOpen] = useState(false);

    const { t } = useTranslation('profile');

    const user = useUserEx();
    const loadingUserEx = useLoadingUserEx();
    const updateUserEx = useUpdateUserEx();

    const isUserAllowed = isAuthorizedToUseAutopilot(user);

    const sendViaHoneysales = isSendViaHoneysales(user);

    const loading = loadingUserEx;
    const formDisabled = loading;

    const formDefaultValues: FormAutopilotSettingsFormData = useMemo(
        () => ({
            enabled: true,
            contactStatus: user?.settings?.autopilot?.contactStatus || [
                PROSPECT_CONTACT_STATUS.cold,
            ],
            maxSendFrequency:
                user?.settings?.autopilot?.maxSendFrequency ||
                (sendViaHoneysales ? MAX_SEND_FREQUENCY.Once : MAX_SEND_FREQUENCY.EveryTwoWeeks),
            delayBeforeAutosend:
                user?.settings?.autopilot?.delayBeforeAutosend || DELAY_BEFORE_AUTOSEND.FourHours,
        }),
        [user],
    );

    const handleSubmit = async (values: FormAutopilotSettingsFormData): Promise<void> => {
        const { contactStatus, maxSendFrequency, delayBeforeAutosend } = values;
        const formData: UserInfoExFormData = {
            settings: {
                autopilot: {
                    enabled: true,
                    contactStatus,
                    maxSendFrequency,
                    delayBeforeAutosend,
                },
            },
        };
        const success = await updateUserEx(formData);
        if (success) {
            if (onConfirm) onConfirm();
            setOpen(false);
            toast.success(t('enable_autopilot.enable_success'));
        }
        // trackEventProfileInfoChange();
    };

    useEffect(() => {
        if (openExternal !== undefined) {
            setOpen(openExternal);
        }
    }, [openExternal]);

    const handleCancel = (): void => {
        if (onCancel) onCancel();
        setOpen(false);
    };

    if (!isUserAllowed) {
        return null;
    }

    return (
        <Dialog
            open={open}
            title={t('enable_autopilot.title')}
            onClose={handleCancel}
            className={styles.dialog}
        >
            <FormAutopilotSettings
                showEnabled={false}
                initialValues={formDefaultValues}
                disabled={formDisabled}
                sendViaHoneysales={sendViaHoneysales}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        </Dialog>
    );
};

export default EnableAutopilotDialog;
