import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { NAV_MENU_ITEMS } from 'constants/routes/navLinks';
import { PROSPECT_TABS } from 'interfaces/prospect';

import Header from 'components/Header';
import HeaderProxy from 'components/HeaderProxy';
import ProspectsImportJobsPageContent from 'components/ProspectsImportJobsPageContent';
import ProspectsPageContent from 'components/ProspectsPageContent';
import Tabs, { type TabItem } from 'components/Tabs';
import { useInvalidateFeaturesForUser, useIsFeatureEnabled } from 'stores/useFeaturesStore';
import { Features } from 'stores/featuresSlice';

import {
    useFetchCurrentUserEx,
    useInvalidateProxyLogin,
    useProxyLogin,
    useUserEx,
} from 'stores/useBoundStore';

import styles from './style.module.scss';

const ProspectsPage = (): JSX.Element => {
    const { t } = useTranslation('prospects');

    const params = useParams();
    const navigate = useNavigate();

    const userEx = useUserEx();
    const fetchCurrentUserEx = useFetchCurrentUserEx();
    const invalidateProxyLogin = useInvalidateProxyLogin();
    const proxyLogin = useProxyLogin();
    const invalidateFeaturesForUser = useInvalidateFeaturesForUser();
    const isFeatureEnabled = useIsFeatureEnabled();

    const classContainer = classNames(styles.container, {
        [styles['container-proxy']]: proxyLogin,
    });

    const tabs: TabItem[] = useMemo(
        () => [
            {
                label: t('tabs.active_prospects'),
                value: 'active-prospects',
                link: '/prospects',
            },
            ...(isFeatureEnabled(Features.PROSPECT_IMPORT)
                ? [
                      {
                          label: t('tabs.import_jobs'),
                          value: 'import-jobs',
                          link: '/prospects/import-jobs',
                      },
                  ]
                : []),
        ],
        [t],
    );

    useEffect(() => {
        invalidateProxyLogin();
        fetchCurrentUserEx();
    }, []);

    useEffect(() => {
        if (userEx?.email) invalidateFeaturesForUser(userEx?.email);
    }, [userEx]);

    const handleTabChange = (tab: TabItem): void => {
        if (tab.link) navigate(tab.link);
    };

    return (
        <>
            {proxyLogin && <HeaderProxy />}
            <Header navMenuItems={NAV_MENU_ITEMS} />

            <div className={classContainer}>
                <Tabs
                    items={tabs}
                    value={params?.page ?? PROSPECT_TABS.ActiveProspects}
                    onTabChange={handleTabChange}
                >
                    <Tabs.Content value={PROSPECT_TABS.ActiveProspects}>
                        <ProspectsPageContent />
                    </Tabs.Content>
                    <Tabs.Content value={PROSPECT_TABS.ImportJobs}>
                        <ProspectsImportJobsPageContent />
                    </Tabs.Content>
                </Tabs>
            </div>

            <ToastContainer position='bottom-right' />
        </>
    );
};

export default ProspectsPage;
