import * as Form from '@radix-ui/react-form';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { SignalCategory, UserSignalCategory } from 'interfaces/userEx.type';
import FormFieldCheckbox from 'components/FormFieldCheckbox';
import Icon, { ICON_NAMES } from 'components/Icon';
import { getSignalCategoryLabel } from 'services/signal';

import styles from './style.module.scss';

type SignalCategoryItemProps = {
    signalCategories: SignalCategory[];
    userSignalCategory: UserSignalCategory;
    onEditClick?: (_category: UserSignalCategory) => void;
    onCheckboxClick?: (_category: UserSignalCategory) => void;
};

const SignalCategoryItem: React.FC<SignalCategoryItemProps> = ({
    signalCategories,
    userSignalCategory,
    onEditClick,
    onCheckboxClick,
}) => {
    const { isActive, weight } = userSignalCategory;

    const { i18n } = useTranslation('profile');

    const classEdit = classNames(styles.edit, {
        [styles['edit-active']]: isActive,
    });

    const handleCheckboxClick = (): void => {
        if (onCheckboxClick) onCheckboxClick(userSignalCategory);
    };

    const handleEditClick = (): void => {
        if (!isActive) return;
        if (onEditClick) onEditClick(userSignalCategory);
    };

    return (
        <div className={styles.container}>
            <Form.Root className={styles.name}>
                <FormFieldCheckbox
                    name='isActive'
                    label={getSignalCategoryLabel(
                        signalCategories,
                        i18n.language,
                        userSignalCategory,
                    )}
                    checked={isActive}
                    className={styles.checkbox}
                    onChange={handleCheckboxClick}
                />
            </Form.Root>
            <div className={styles.weight}>{isActive ? `${weight} %` : '-'}</div>
            <div className={classEdit} onClick={handleEditClick}>
                {isActive && <Icon name={ICON_NAMES.Pencil} />}
            </div>
        </div>
    );
};

export default SignalCategoryItem;
