import type { Prospect } from 'interfaces/prospect';
import { DataTable, type DataTableProps } from 'components/DataTable';

type ProspectsTableProps = DataTableProps<Prospect>;

export const ProspectsTable = ({ columns, data }: ProspectsTableProps): JSX.Element => {
    return <DataTable columns={columns} data={data} />;
};

export default ProspectsTable;
