export enum ProspectJobStages {
    /* eslint-disable no-unused-vars */
    Parsing = 'PARSING',
    SixDegreesEnrichment = 'SIXDEGREES_ENRICHMENT',
    EmailEnrichment = 'EMAIL_ENRICHMENT',
    GenderEnrichment = 'GENDER_ENRICHMENT',
    DbUploading = 'DB_UPLOADING',
    Finished = 'FINISHED',
    /* eslint-enable no-unused-vars */
}

export enum JobTypes {
    /* eslint-disable no-unused-vars */
    EnrichProspects = 'ENRICH_PROSPECTS',
    GenerateSignalsEmailMessages = 'GENERATE_SIGNALS_EMAIL_MESSAGES',
    ImportProspects = 'IMPORT_PROSPECTS',
    /* eslint-enable no-unused-vars */
}

export type JobState = {
    stage: ProspectJobStages;
    org?: string;
    fileName?: string;
    initiator?: {
        firstName: string;
        lastName: string;
    };
    user?: {
        firstName: string;
        lastName: string;
    };
    initialProspects?: number;
    emailEnriched?: number;
    existingProspects?: number;
    vmidEnriched?: number;
    startedAt?: string;
    endedAt?: string;
};

export type Job = {
    _id: string;
    createdAt: string;
    updatedAt: string;
    initiator: string;
    type: JobTypes;
    state: JobState;
};
