import { type IntegrationAppClient } from '@integration-app/sdk';

import { type IAResponseSFUserInfo, executeAction } from 'services/integrationApp';

export const getSFUserInfo = async (
    integrationAppClient: IntegrationAppClient,
): Promise<string | undefined> => {
    // fetch user info
    const currentUserInfo = await executeAction<never, IAResponseSFUserInfo>(
        integrationAppClient,
        'get-sf-user-info',
        'salesforce',
    );

    // When no user email found - result is undefined
    return currentUserInfo?.email;
};
