import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { Match } from 'interfaces/match.type';
import { getProspectSignalUrl } from 'services/prospect';

import Button from 'components/Button';
import AutopilotEnabledDialog from 'components/dialogs/AutopilotEnabledDialog';
import EnableAutopilotDialog from 'components/dialogs/EnableAutopilotDialog';
import DisableAutopilotDialog from 'components/dialogs/DisableAutopilotDialog';
import FormFilterProspectList, {
    type FormFilterProspectListFormData,
} from 'forms/FormFilterProspectList';
import Loading from 'components/Loading';
import MatchCard from 'components/MatchCard';
import Typography from 'components/Typography';
import { useUserEx } from 'stores/useBoundStore';
import { isAuthorizedToUseAutopilot } from 'stores/userSlice/isAuthorized';

import styles from './style.module.scss';

type Props = {
    loading?: boolean;
    items?: Match[];
    selectedItemId?: string;
    onSelect?: (_item: Match) => void;
};

const MatchList: React.FC<Props> = ({
    loading = false,
    items,
    selectedItemId,
    onSelect,
}): JSX.Element => {
    const { t } = useTranslation('forms');

    const [showEnableAutopilotDialog, setShowEnableAutopilotDialog] = useState(false);
    const [showAutopilotEnabledDialog, setShowAutopilotEnabledDialog] = useState(false);
    const [showDisableAutopilotDialog, setShowDisableAutopilotDialog] = useState(false);

    const user = useUserEx();

    const [filter, setFilter] = useState<FormFilterProspectListFormData>({
        contactStatus: 'all',
        source: 'all',
        autopilot: user?.settings?.autopilot?.enabled ?? false,
    });

    const isUserAuthorizedToUseAutopilot = isAuthorizedToUseAutopilot(user);

    const itemsFiltered = useMemo(() => {
        if (!items) return [];
        return items.filter((item) => {
            if (filter.contactStatus !== 'all' && item.contactStatus !== filter.contactStatus) {
                return false;
            }
            if (filter.source !== 'all' && item.source !== filter.source) {
                return false;
            }
            return true;
        });
    }, [items, filter]);

    useEffect(() => {
        if (isUserAuthorizedToUseAutopilot) {
            setFilter((prev) => ({
                ...prev,
                autopilot: user?.settings?.autopilot?.enabled ?? false,
            }));
        }
    }, [user, isUserAuthorizedToUseAutopilot]);

    const handleClick = (match: Match): void => {
        if (onSelect) onSelect(match);
    };

    const handleFilterUpdate = (values: FormFilterProspectListFormData): void => {
        setFilter(values);
        if (user?.settings?.autopilot?.enabled === false && values.autopilot === true) {
            setShowEnableAutopilotDialog(true);
        } else if (user?.settings?.autopilot?.enabled === true && values.autopilot === false) {
            setShowDisableAutopilotDialog(true);
        }
    };

    const handleResetFilterClick = (): void => {
        setFilter({
            contactStatus: 'all',
            source: 'all',
            autopilot: false,
        });
    };

    const handleConfirmEnableAutopilot = (): void => {
        setShowEnableAutopilotDialog(false);
        setShowAutopilotEnabledDialog(true);
    };

    const handleCancelEnableAutopilot = (): void => {
        setFilter((prev) => ({ ...prev, autopilot: false }));
        setShowEnableAutopilotDialog(false);
    };

    const handleConfirmDisableAutopilot = (): void => {
        setShowDisableAutopilotDialog(false);
    };

    const handleCancelDisableAutopilot = (): void => {
        setFilter((prev) => ({ ...prev, autopilot: true }));
        setShowDisableAutopilotDialog(false);
    };

    const handleCloseAutopilotEnabled = (): void => {
        setShowAutopilotEnabledDialog(false);
    };

    return (
        <div className={styles.container}>
            {loading && <Loading absolute />}
            {loading && (
                <div className={styles['loading-indicator']}>{t('matches:info.loading')}</div>
            )}
            {loading === false && items && (
                <FormFilterProspectList
                    disabled={loading}
                    values={filter}
                    isAuthorizedToUseAutopilot={isUserAuthorizedToUseAutopilot}
                    onUpdate={handleFilterUpdate}
                />
            )}
            {loading === false &&
                items &&
                itemsFiltered.map((item) => (
                    <Link
                        key={item.prospectId}
                        to={getProspectSignalUrl(item)}
                        className={styles.link}
                    >
                        <MatchCard
                            item={item}
                            active={item.prospectId === selectedItemId}
                            onClick={handleClick}
                        />
                    </Link>
                ))}
            {loading === false && items && items.length > 0 && itemsFiltered.length === 0 && (
                <div className={styles.info}>
                    <span>{t('prospect_list_filter.no_signals')}</span>
                    <Button variant='link' onClick={handleResetFilterClick}>
                        {t('prospect_list_filter.reset_filters')}
                    </Button>
                </div>
            )}
            {loading === false && (!items || items.length === 0) && (
                <div className={styles.info}>
                    <Typography>{t('generic.no_signals')}</Typography>
                </div>
            )}
            {showEnableAutopilotDialog && (
                <EnableAutopilotDialog
                    open={showEnableAutopilotDialog}
                    onConfirm={handleConfirmEnableAutopilot}
                    onCancel={handleCancelEnableAutopilot}
                />
            )}
            {showDisableAutopilotDialog && (
                <DisableAutopilotDialog
                    open={showDisableAutopilotDialog}
                    onConfirm={handleConfirmDisableAutopilot}
                    onCancel={handleCancelDisableAutopilot}
                />
            )}
            {showAutopilotEnabledDialog && (
                <AutopilotEnabledDialog
                    open={showAutopilotEnabledDialog}
                    onConfirm={handleCloseAutopilotEnabled}
                    onCancel={handleCloseAutopilotEnabled}
                />
            )}
        </div>
    );
};

export default MatchList;
