export const ROUTES = {
    MAIN: '/',
    MATCHES: '/matches',
    MATCH_DETAIL: '/matches/:prospectId',
    MATCH_SIGNAL_DETAIL: '/matches/:prospectId/:signalId',
    SIGNALS: '/signals',
    SIGNALS_PROSPECT: '/signals/:prospectId',
    SIGNALS_PROSPECT_DETAIL: '/signals/:prospectId/:signalId',
    ACTIVITY: '/activity',
    PERFORMANCE: '/performance',
    PROFILE: '/profile',
    PROFILE_DETAIL: '/profile/:page',
    LOGIN: '/login',
    PROXY_LOGIN: '/proxy-login',
    LOGOUT: '/logout',
    FORGOT_PASSWORD: '/forgot-password',
    SET_PASSWORD: '/set-password',
    PROSPECTS: '/prospects',
    PROSPECTS_SEARCH: '/prospects/search',
    PROSPECTS_TAB: '/prospects/:page',
} as const;
