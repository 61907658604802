import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Icon, { ICON_NAMES } from 'components/Icon';
import ProspectsTable from 'components/ProspectsTable';

import {
    useArchiveProspect,
    useGetProspects,
    useGetProspectsNextPage,
    useGetProspectsPrevPage,
    useOpenArchiveDialog,
    useProspectsCurrentPage,
    useProspectsData,
    useProspectsLoading,
    useProspectsRecordCount,
    useSelectedProspectId,
    useSelectProspectId,
    useSetOpenArchiveDialog,
} from 'stores/useProspectStore';
import FormFilterProspects, { type FormFilterProspectsFormData } from 'forms/FormFilterProspects';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { ArchiveProspectConfirmationDialog } from 'components/dialogs/ArchiveProspectConfirmationDialog';
import { NUMBER_OF_PROSPECT_RECORDS_PER_PAGE, PROSPECT_PAGE_SIZE_ITEM } from 'services/nimbus';
import { ROUTES } from 'constants/routes/routes';
import { Features } from 'stores/featuresSlice';
import { useIsFeatureEnabled } from 'stores/useFeaturesStore';

import { columns } from './columns';

import styles from './style.module.scss';

const formatNumber = (num: number): string => new Intl.NumberFormat().format(num);

const defaultValues: FormFilterProspectsFormData = {
    searchTerm: '',
    contactStatus: 'all',
    source: 'all',
};

export const ProspectsPageContent: React.FC = () => {
    const { t } = useTranslation('prospects');

    const navigate = useNavigate();

    const prospects = useProspectsData();
    const loading = useProspectsLoading();
    const currentPage = useProspectsCurrentPage();
    const prospectCount = useProspectsRecordCount();
    const getProspects = useGetProspects();
    const getProspectsPrevPage = useGetProspectsPrevPage();
    const getProspectsNextpage = useGetProspectsNextPage();
    const selectedProspectId = useSelectedProspectId();
    const selectProspectId = useSelectProspectId();
    const openArchiveDialog = useOpenArchiveDialog();
    const setOpenArchiveDialog = useSetOpenArchiveDialog();
    const archiveProspect = useArchiveProspect();
    const isFeatureEnabled = useIsFeatureEnabled();

    const [filter, setFilter] = useState<FormFilterProspectsFormData>(defaultValues);
    const [pageSize, setPageSize] = useState<string>(String(NUMBER_OF_PROSPECT_RECORDS_PER_PAGE));

    useEffect(() => {
        if (localStorage.getItem(PROSPECT_PAGE_SIZE_ITEM)) {
            setPageSize(
                localStorage.getItem(PROSPECT_PAGE_SIZE_ITEM) ??
                    String(NUMBER_OF_PROSPECT_RECORDS_PER_PAGE),
            );
        }
        getProspects(defaultValues);
    }, []);

    const pageSizeValue = parseInt(pageSize);
    const pageStart = currentPage * pageSizeValue + 1;
    const pageEnd = Math.min((currentPage + 1) * pageSizeValue, prospectCount);
    const maxPage = Math.ceil(prospectCount / pageSizeValue);
    const isPrevButtonDisabled = loading || currentPage === 0;
    const isNextButtonDisabled = loading || currentPage === maxPage - 1 || prospectCount === 0;

    const handleAddProspectsClick = (): void => {
        navigate(ROUTES.PROSPECTS_SEARCH);
    };

    const handleFilterUpdate = async (values: FormFilterProspectsFormData): Promise<void> => {
        const filterValues: FormFilterProspectsFormData = {
            searchTerm: values.searchTerm ?? defaultValues.searchTerm,
            contactStatus: values.contactStatus ?? defaultValues.contactStatus,
            source: values.source ?? defaultValues.source,
        };
        setFilter(filterValues);
        await getProspects(filterValues);
    };

    const handleNextClick = async (): Promise<void> => {
        await getProspectsNextpage();
    };

    const handlePrevClick = async (): Promise<void> => {
        await getProspectsPrevPage();
    };

    const handleArchiveConfirm = async (): Promise<void> => {
        if (!selectedProspectId) return;
        const result = await archiveProspect(selectedProspectId);
        if (result) {
            toast.success(t('dialogs.archive_prospect.success'));
        }
        setOpenArchiveDialog(false);
        selectProspectId(undefined);
    };

    const handleArchiveCancel = (): void => {
        setOpenArchiveDialog(false);
        selectProspectId(undefined);
    };

    // Do not show page size for now
    // const handlePageSizeChange = (value: string): void => {
    //     setPageSize(value);
    //     localStorage.setItem(PROSPECT_PAGE_SIZE_ITEM, value);
    // };

    return (
        <>
            <div className={styles.content}>
                <div className={styles.filters}>
                    <FormFilterProspects
                        disabled={loading}
                        values={filter}
                        onUpdate={handleFilterUpdate}
                    />
                    {isFeatureEnabled(Features.PROSPECT_SEARCH) && (
                        <div>
                            <Button
                                variant='primary'
                                onClick={handleAddProspectsClick}
                                disabled={loading}
                            >
                                {t('buttons.add_prospects')}
                            </Button>
                        </div>
                    )}
                </div>
                <div className={styles.table}>
                    <div className={styles['table-header']}>
                        <Typography variant='bodyBold2'>{formatNumber(prospectCount)}</Typography>
                        <Typography variant='bodyRegular2'>
                            {t('count.active_prospects', { count: prospectCount })}
                        </Typography>
                    </div>
                    <ProspectsTable columns={columns} data={prospects} />
                    <div className={styles['table-footer']}>
                        {prospectCount > 0 && (
                            <>
                                <div className={styles['table-info']}>
                                    <Typography variant='bodyRegular2'>
                                        <strong>
                                            {formatNumber(pageStart)}-{formatNumber(pageEnd)}
                                        </strong>{' '}
                                        {t('count.of')}{' '}
                                        <strong>{formatNumber(prospectCount)}</strong>{' '}
                                        {t('count.active_prospects', { count: prospectCount })}
                                    </Typography>
                                </div>
                                <div className={styles['table-pagination']}>
                                    {/* Do not show page size for now */}
                                    {/* <Form.Root className={styles['flex-row']}>
                                        <Typography variant='bodyRegular2'>
                                            {t('actions.page_size')}
                                        </Typography>
                                        <FormFieldSelect
                                            name='pageSize'
                                            options={[
                                                { label: '20', value: '20' },
                                                { label: '50', value: '50' },
                                                { label: '100', value: '100' },
                                            ]}
                                            value={pageSize}
                                            variant='small'
                                            onChange={handlePageSizeChange}
                                        />
                                    </Form.Root> */}
                                    <Button
                                        size='small'
                                        variant='secondary'
                                        disabled={isPrevButtonDisabled}
                                        onClick={handlePrevClick}
                                    >
                                        <Icon
                                            className={styles['button-icon-left']}
                                            name={ICON_NAMES.ChevronLeft}
                                            width={12}
                                            height={12}
                                        />
                                        {t('buttons.prev')}
                                    </Button>
                                    <Typography variant='bodyRegular2'>
                                        {t('count.page_of', {
                                            page: formatNumber(currentPage + 1),
                                            total: formatNumber(maxPage),
                                        })}
                                    </Typography>
                                    <Button
                                        size='small'
                                        variant='secondary'
                                        disabled={isNextButtonDisabled}
                                        onClick={handleNextClick}
                                    >
                                        {t('buttons.next')}
                                        <Icon
                                            className={styles['button-icon-right']}
                                            name={ICON_NAMES.ChevronRight}
                                            width={12}
                                            height={12}
                                        />
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ArchiveProspectConfirmationDialog
                open={openArchiveDialog}
                onConfirm={handleArchiveConfirm}
                onCancel={handleArchiveCancel}
            />
        </>
    );
};

export default ProspectsPageContent;
