import { PERFORMANCE_TABS, type PerformanceTab } from 'interfaces/performance.type';
import FormFilterMessages from 'forms/FormFilterMessages';
import FormFilterClientList from 'forms/FormFilterClientList';
import FormFilterStatistics from 'forms/FormFilterStatistics';

import styles from './style.module.scss';

type Props = {
    tab: PerformanceTab;
};

export const PerformanceTabContent: React.FC<Props> = ({ tab }) => {
    return (
        <div className={styles['tab-content']}>
            {tab === PERFORMANCE_TABS.Messages && (
                <>
                    <div className={styles.filter}>
                        <FormFilterMessages onUpdate={console.log} />
                    </div>
                    <div className={styles.info}>Messages data</div>
                </>
            )}
            {tab === PERFORMANCE_TABS.ClientList && (
                <>
                    <div className={styles.filter}>
                        <FormFilterClientList onUpdate={console.log} />
                    </div>
                    <div className={styles.info}>Client list data</div>
                </>
            )}
            {tab === PERFORMANCE_TABS.Statistics && (
                <>
                    <div className={styles.filter}>
                        <FormFilterStatistics onUpdate={console.log} />
                    </div>
                    <div className={styles.info}>Statistics chart</div>
                </>
            )}
        </div>
    );
};

export default PerformanceTabContent;
