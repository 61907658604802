import { IntegrationAppProvider } from '@integration-app/react';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { type Match } from 'interfaces/match.type';
import { NAV_MENU_ITEMS } from 'constants/routes/navLinks';
import Header from 'components/Header';
import HeaderProxy from 'components/HeaderProxy';
import MatchInfo from 'components/MatchInfo';
import MatchList from 'components/MatchList';
import { trackEventMatchView } from 'services/eventsTracker';
import { getProspectSignalUrl } from 'services/prospect';
import {
    useFetchCurrentUserEx,
    useFetchIAToken,
    useFetchMatches,
    useIAToken,
    useLoadingMatches,
    useMarkProspectSignalAsRead,
    useMatches,
    useProxyLogin,
    useInvalidateProxyLogin,
    useUserEx,
    useUpdateAutosendTimer,
} from 'stores/useBoundStore';
import { isAuthorizedToUseAutopilot } from 'stores/userSlice/isAuthorized';

import env from 'env';

import styles from './style.module.scss';

const MatchesPage = (): JSX.Element => {
    const navigate = useNavigate();
    const { prospectId, signalId } = useParams();

    const loading = useLoadingMatches();
    const matches = useMatches();
    const proxyLogin = useProxyLogin();
    const invalidateProxyLogin = useInvalidateProxyLogin();
    const fetchMatches = useFetchMatches();
    const markProspectSignalAsRead = useMarkProspectSignalAsRead();
    const iaToken = useIAToken();
    const fetchIAToken = useFetchIAToken();
    const fetchCurrentUserEx = useFetchCurrentUserEx();
    const updateAutosendTimer = useUpdateAutosendTimer();
    const user = useUserEx();

    const classContainer = classNames(styles.container, {
        [styles['container-proxy']]: proxyLogin,
    });

    const classInfoContainer = classNames(styles['info-container'], {
        [styles['info-container-proxy']]: proxyLogin,
    });

    const classMatchListContainer = classNames(styles['match-list-container'], {
        [styles['match-list-container-proxy']]: proxyLogin,
    });

    const isUserAuthorizedToUseAutopilot = isAuthorizedToUseAutopilot(user);

    const match = useMemo(() => {
        if (!prospectId) return;
        if (!matches.length) return;
        return matches.find((match) => match.prospectId === prospectId);
    }, [matches, prospectId]);

    useEffect((): void => {
        invalidateProxyLogin();
        fetchCurrentUserEx();
        fetchMatches();
        if (env.CRM_INTEGRATION_DISABLED === false) {
            fetchIAToken();
        }
    }, []);

    useEffect((): void => {
        if (!matches.length) return;
        if (!matches[0].prospectId) return;
        if (!matches[0].signals.length) return;
        if (prospectId === undefined || match === undefined) {
            navigate(getProspectSignalUrl(matches[0]));
        }
        if (isUserAuthorizedToUseAutopilot) {
            updateAutosendTimer();
        }
    }, [prospectId, signalId, match, matches]);

    useEffect((): void => {
        if (proxyLogin) return;
        if (prospectId && match) {
            if (!match.read && match.prospectId && match.signals.length) {
                markProspectSignalAsRead(match.prospectId, match.signals[0].id);
            }
            trackEventMatchView(matches, match);
        }
    }, [prospectId, match]);

    const handleSelectMatch = (match: Match): void => {
        if (!match.signals.length) return;
        navigate(getProspectSignalUrl(match));
    };

    return (
        <>
            {proxyLogin && <HeaderProxy />}
            <Header navMenuItems={NAV_MENU_ITEMS} />

            <div className={classContainer}>
                <div className={classMatchListContainer}>
                    <MatchList
                        loading={loading}
                        items={matches}
                        selectedItemId={prospectId}
                        onSelect={handleSelectMatch}
                    />
                </div>

                {env.CRM_INTEGRATION_DISABLED || !iaToken ? (
                    <div className={classInfoContainer}>
                        <MatchInfo loading={loading} item={match} signalId={signalId} />
                    </div>
                ) : (
                    <IntegrationAppProvider token={iaToken}>
                        <div className={classInfoContainer}>
                            <MatchInfo loading={loading} item={match} signalId={signalId} />
                        </div>
                    </IntegrationAppProvider>
                )}
            </div>
            <ToastContainer position='bottom-right' />
        </>
    );
};

export default MatchesPage;
