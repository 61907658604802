import React, { useState } from 'react';
import classNames from 'classnames';
import * as Form from '@radix-ui/react-form';

import type { FormFieldOption } from 'components/FormFieldSelect';

import styles from './style.module.scss';

type Props = {
    id?: string;
    name: string;
    label: string;
    options: FormFieldOption[];
    value?: string[];
    disabled?: boolean;
    variant?: 'default' | 'small';
    helperText?: string;
    onChange?: (_value: string[]) => void;
};

export const FormFieldCheckboxGroup: React.FC<Props> = ({
    id,
    name,
    label,
    options,
    disabled = false,
    value,
    variant = 'default',
    helperText,
    onChange,
}) => {
    const [localValue, setLocalValue] = useState(value || []);

    const classCheckbox = classNames(styles.checkbox, {
        [styles['checkbox-small']]: variant === 'small',
        [styles.disabled]: disabled,
    });
    const classLabel = classNames(styles.label, {
        [styles['label-small']]: variant === 'small',
    });
    const classCheckboxLabel = classNames(styles['checkbox-label'], {
        [styles['checkbox-label-small']]: variant === 'small',
        [styles['checkbox-label-disabled']]: disabled,
    });
    const classNamesHelperText = classNames(styles['helper-text'], {
        [styles['helper-text-small']]: variant === 'small',
    });

    const handleChange = (option: string): void => {
        const newValue = [...localValue];
        const index = newValue.indexOf(option);
        if (index === -1) {
            newValue.push(option);
        } else {
            newValue.splice(index, 1);
        }
        setLocalValue(newValue);
        if (onChange) onChange(newValue);
    };

    return (
        <Form.Field name={name} className={styles.container}>
            <Form.Label className={classLabel}>{label}</Form.Label>
            <Form.Control value={localValue} disabled={disabled} hidden />
            <div className={styles.group}>
                {options.map((option) => {
                    const idOption = `${name}-${option.value}`;
                    return (
                        <div key={idOption} className={classCheckbox}>
                            <input
                                className={styles.input}
                                type='checkbox'
                                id={idOption}
                                value={option.value}
                                checked={localValue.includes(option.value)}
                                disabled={disabled}
                                onChange={(): void => handleChange(option.value)}
                            />
                            <label htmlFor={idOption} className={classCheckboxLabel}>
                                {option.label}
                            </label>
                        </div>
                    );
                })}
            </div>
            {helperText && (
                <Form.Label htmlFor={id} className={classNamesHelperText}>
                    {helperText}
                </Form.Label>
            )}
        </Form.Field>
    );
};

export default FormFieldCheckboxGroup;
