import { useEffect, useRef } from 'react';

import { AnimatedStars } from 'components/AnimatedStars';
import HeaderLogin from 'components/HeaderLogin';
import FormForgotPassword, { type ForgotPasswordFormData } from 'forms/FormForgotPassword';
import Loading from 'components/Loading';
import {
    useAuthClearErrorAndMessage,
    useAuthError,
    useAuthForgotPassword,
    useAuthLoading,
    useAuthMessage,
} from 'stores/useBoundStore';

import 'react-toastify/dist/ReactToastify.css';
import styles from './style.module.scss';

const USE_ANIMATION = true;

const ForgotPasswordPage = (): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);

    const forgotPassword = useAuthForgotPassword();
    const loading = useAuthLoading();
    const error = useAuthError();
    const message = useAuthMessage();
    const clearErrorAndMessage = useAuthClearErrorAndMessage();

    useEffect((): (() => void) => {
        clearErrorAndMessage();
        return clearErrorAndMessage;
    }, []);

    const handleSubmit = async (values: ForgotPasswordFormData): Promise<void> => {
        await forgotPassword(values);
    };

    return (
        <>
            <div className={styles.container}>
                <HeaderLogin />
                {loading && <Loading absolute />}
                <div className={styles.content}>
                    <FormForgotPassword
                        error={error}
                        message={message}
                        disabled={loading}
                        onSubmit={handleSubmit}
                    />
                    <div ref={containerRef} className={styles['stars-container']}>
                        {USE_ANIMATION && <AnimatedStars containerRef={containerRef} />}
                        <div className={styles['image-container']} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordPage;
