import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { trackPageView } from 'services/eventsTracker';

const AnalyticsPageViewTracker = (): null => {
    const location = useLocation();

    useEffect(() => {
        trackPageView();
    }, [location.pathname]);

    return null;
};

export default AnalyticsPageViewTracker;
