import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import type { Job } from 'interfaces/job';
import DataTable, { type DataTableProps } from 'components/DataTable';
import Loading from 'components/Loading';
import Message from 'components/Message';
import Typography from 'components/Typography';

import {
    useGetImportJobs,
    // useGetImportJobsNextPage,
    // useGetImportJobsPrevPage,
    useImportJobs,
    useImportJobsLoading,
    useProspectsCurrentPage,
    useProspectsRecordCount,
} from 'stores/useProspectStore';
import { NUMBER_OF_JOB_RECORDS_PER_PAGE } from 'services/nimbus';

import { columns } from './columns';

import styles from './style.module.scss';

const formatNumber = (num: number): string => new Intl.NumberFormat().format(num);

type ImportJobsTableProps = DataTableProps<Job>;

const ImportJobsTable = ({ columns, data }: ImportJobsTableProps): JSX.Element => {
    return <DataTable columns={columns} data={data} />;
};

export const ProspectsImportJobsPageContent: React.FC = () => {
    const { t } = useTranslation('prospects');

    const location = useLocation();

    const [showMessageJobStarted, setShowMessageJobStarted] = useState<boolean>(false);

    const importJobs = useImportJobs();
    const loading = useImportJobsLoading();
    const currentPage = useProspectsCurrentPage();
    const prospectCount = useProspectsRecordCount();
    const getImportJobs = useGetImportJobs();
    // const getImportJobsPrevPage = useGetImportJobsPrevPage();
    // const getImportJobsNextpage = useGetImportJobsNextPage();

    useEffect(() => {
        getImportJobs();
        if (location.state?.jobStarted) {
            setShowMessageJobStarted(true);
        }
    }, []);

    const pageSizeValue = NUMBER_OF_JOB_RECORDS_PER_PAGE;
    const pageStart = currentPage * pageSizeValue + 1;
    const pageEnd = Math.min((currentPage + 1) * pageSizeValue, prospectCount);
    // const maxPage = Math.ceil(prospectCount / pageSizeValue);
    // const isPrevButtonDisabled = loading || currentPage === 0;
    // const isNextButtonDisabled = loading || currentPage === maxPage - 1 || prospectCount === 0;

    // const handleNextClick = async (): Promise<void> => {
    //     await getImportJobsNextpage();
    // };

    // const handlePrevClick = async (): Promise<void> => {
    //     await getImportJobsPrevPage();
    // };

    return (
        <>
            <div className={styles.content}>
                <div className={styles.message}>
                    {showMessageJobStarted && (
                        <Message success>{t('import_jobs.job_started')}</Message>
                    )}
                </div>
                <div className={styles.table}>
                    <div className={styles['table-header']}>
                        <Typography variant='bodyBold2'>{formatNumber(prospectCount)}</Typography>
                        <Typography variant='bodyRegular2'>
                            {t('count.import_jobs', { count: prospectCount })}
                        </Typography>
                    </div>
                    <ImportJobsTable columns={columns} data={importJobs} />
                    <div className={styles['table-footer']}>
                        {prospectCount > 0 && (
                            <>
                                <div className={styles['table-info']}>
                                    <Typography variant='bodyRegular2'>
                                        <strong>
                                            {formatNumber(pageStart)}-{formatNumber(pageEnd)}
                                        </strong>{' '}
                                        {t('count.of')}{' '}
                                        <strong>{formatNumber(prospectCount)}</strong>{' '}
                                        {t('count.import_jobs', { count: prospectCount })}
                                    </Typography>
                                </div>
                                {/* <div className={styles['table-pagination']}>
                                    <Button
                                        size='small'
                                        variant='secondary'
                                        disabled={isPrevButtonDisabled}
                                        onClick={handlePrevClick}
                                    >
                                        <Icon
                                            className={styles['button-icon-left']}
                                            name={ICON_NAMES.ChevronLeft}
                                            width={12}
                                            height={12}
                                        />
                                        {t('buttons.prev')}
                                    </Button>
                                    <Typography variant='bodyRegular2'>
                                        {t('count.page_of', {
                                            page: formatNumber(currentPage + 1),
                                            total: formatNumber(maxPage),
                                        })}
                                    </Typography>
                                    <Button
                                        size='small'
                                        variant='secondary'
                                        disabled={isNextButtonDisabled}
                                        onClick={handleNextClick}
                                    >
                                        {t('buttons.next')}
                                        <Icon
                                            className={styles['button-icon-right']}
                                            name={ICON_NAMES.ChevronRight}
                                            width={12}
                                            height={12}
                                        />
                                    </Button>
                                </div> */}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {loading && <Loading absolute />}
        </>
    );
};

export default ProspectsImportJobsPageContent;
