import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import CircledTag from 'components/CircledTag';
import { ICON_NAMES, type IconName } from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import { getDateDistanceAndString } from 'components/SignalCard/utils';

import styles from './style.module.scss';

type Props = {
    type: 'meeting' | 'call' | 'email' | 'custom';
    icon?: IconName;
    contactDate?: Date | null;
    customText?: string;
    loading?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
};

const ContactInfoItem: React.FC<Props> = ({
    type,
    icon,
    contactDate,
    loading = false,
    disabled = false,
    children,
}) => {
    const { t } = useTranslation('matches');

    if (loading) {
        return (
            <div className={styles.container}>
                <Skeleton width={32} height={32} rounded />
                <div className={styles.loading}>
                    <Skeleton width={160} height={16} />
                </div>
            </div>
        );
    }

    let iconName: IconName | undefined = icon;

    switch (type) {
        case 'meeting':
            iconName = ICON_NAMES.Users;
            break;
        case 'call':
            iconName = ICON_NAMES.Phone;
            break;
        case 'email':
            iconName = ICON_NAMES.Mail;
            break;
        default:
            break;
    }

    const dateInfo = contactDate ? getDateDistanceAndString(contactDate) : undefined;

    const classNamesContainer = classNames(styles.container, {
        [styles.disabled]: disabled,
    });

    if (contactDate === undefined) {
        return null;
    }

    return (
        <div className={classNamesContainer} title={dateInfo?.formattedDate}>
            {iconName && <CircledTag icon={iconName} disabled={disabled} />}
            {type === 'custom' ? (
                children
            ) : (
                <>
                    {contactDate === null && (
                        <Typography variant='bodyRegular2' inline>
                            {t(`crm.no_info_${type}`)}
                        </Typography>
                    )}
                    {contactDate && (
                        <div className={styles.info}>
                            <Typography variant='bodyBold1' inline>
                                {t(`crm.${type}`)}
                            </Typography>
                            {dateInfo && (
                                <Typography
                                    variant='bodyRegular2'
                                    inline
                                    className={styles['date-info']}
                                >
                                    {dateInfo.distance}
                                </Typography>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ContactInfoItem;
