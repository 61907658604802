import { create } from 'zustand';

import { createFeaturesSlice, type Features, type FeaturesSlice } from './featuresSlice';

export const useFeaturesStore = create<FeaturesSlice>()(createFeaturesSlice);

export const useInvalidateFeaturesForUser = (): ((_email: string) => void) =>
    useFeaturesStore((state) => state.invalidateFeaturesForUser);

export const useIsFeatureEnabled = (): ((_feature: Features) => boolean) =>
    useFeaturesStore((state) => state.isFeatureEnabled);
