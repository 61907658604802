import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { NAV_MENU_ITEMS } from 'constants/routes/navLinks';

import ActivityPageContent from 'components/ActivityPageContent';
import Header from 'components/Header';
import HeaderProxy from 'components/HeaderProxy';

import {
    useFetchCurrentUserEx,
    useInvalidateProxyLogin,
    useProxyLogin,
} from 'stores/useBoundStore';

import styles from './style.module.scss';

const ActivityPage = (): JSX.Element => {
    const fetchCurrentUserEx = useFetchCurrentUserEx();
    const invalidateProxyLogin = useInvalidateProxyLogin();
    const proxyLogin = useProxyLogin();

    const activityRef = useRef<HTMLDivElement>(null);

    const classContainer = classNames(styles.container, {
        [styles['container-proxy']]: proxyLogin,
    });

    useEffect(() => {
        invalidateProxyLogin();
        fetchCurrentUserEx();
    }, []);

    const handleOnFilterUpdate = async (): Promise<void> => {
        activityRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            {proxyLogin && <HeaderProxy />}
            <Header navMenuItems={NAV_MENU_ITEMS} />

            <div className={classContainer} ref={activityRef}>
                <ActivityPageContent onUpdate={handleOnFilterUpdate} />
            </div>
        </>
    );
};

export default ActivityPage;
