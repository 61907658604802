import React, { type FormEvent, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Form from '@radix-ui/react-form';

import { PROFILE_TABS } from 'interfaces/profile.type';
import { ROUTES } from 'constants/routes/routes';
import Button from 'components/Button';
import FormFieldInput from 'components/FormFieldInput';
import FormFieldTextArea from 'components/FormFieldTextArea';
import Icon, { ICON_NAMES } from 'components/Icon';
import Loading from 'components/Loading';
import Typography from 'components/Typography';
import { formatISODateAsString } from 'components/SignalCard/utils';
import { type ProspectContactStatus } from 'services/honeycomb';
import { getEmailIntegrationSetting } from 'services/email';
import { useIntegrationProfiles, useUserEx } from 'stores/useBoundStore';
import { extractSignalEmailFormData } from './utils';

import styles from './style.module.scss';

export type SignalEmailInputFormData = {
    from?: string;
    recipient: string;
    subject: string;
    text: string;
    sentAt: string | undefined;
};

export type SignalEmailFormData = {
    recipient: string;
    subject: string;
    text: string;
    sentAt: string | undefined;
};

type Props = {
    values: SignalEmailInputFormData;
    contactStatus?: ProspectContactStatus;
    loading?: boolean;
    processed?: boolean;
    changesSaved?: boolean;
    changesSavedVisible?: boolean;
    sendDisabled?: boolean;
    showAutosend?: boolean;
    timeUntilAutosend?: string;
    onSubmit?: (_values: SignalEmailFormData) => void;
    onChange?: (_values: SignalEmailFormData) => void;
};

const SignalEmailForm: React.FC<Props> = ({
    values,
    contactStatus,
    loading = false,
    processed = false,
    changesSaved = true,
    changesSavedVisible = false,
    sendDisabled = false,
    showAutosend = false,
    timeUntilAutosend,
    onSubmit,
    onChange,
}) => {
    const [formValues, setFormValues] = useState<SignalEmailInputFormData>(values);

    const user = useUserEx();
    const integrationProfiles = useIntegrationProfiles();

    const { t } = useTranslation('matches');

    const labelNoEmailIntegrationsAvailable = useMemo(
        () => t('form.no_email_integrations_available') as string,
        [t],
    );
    const labelNoEmailIntegrationSelected = useMemo(
        () => t('form.no_email_integration_selected') as string,
        [t],
    );

    const { integrationKey, email, isIntegrationAvailable, isAnyIntegrationAvailable } =
        getEmailIntegrationSetting(integrationProfiles, user, contactStatus);

    const handleChangeInput = (name: string, value: string): void => {
        const newFormValues = {
            ...formValues,
            [name]: value,
        };
        setFormValues(newFormValues);
        onChange?.(newFormValues);
    };

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        const formDataObject = extractSignalEmailFormData(formData);
        onSubmit?.(formDataObject);
    };

    return (
        <div className={styles.container}>
            <Form.Root className={styles.form} onSubmit={handleFormSubmit}>
                {processed === true && (
                    <div className={styles.info}>
                        <table className={styles['field-list']}>
                            <tbody>
                                {formValues.from && (
                                    <tr>
                                        <th>
                                            <Typography inline span variant='bodyBold2'>
                                                {t('form.from')}:
                                            </Typography>
                                        </th>
                                        <td>
                                            <Typography inline span>
                                                {formValues.from}
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th>
                                        <Typography inline span variant='bodyBold2'>
                                            {t('form.to')}:
                                        </Typography>
                                    </th>
                                    <td>
                                        <Typography inline span>
                                            {formValues.recipient}
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <Typography inline span variant='bodyBold2'>
                                            {t('form.subject')}:
                                        </Typography>
                                    </th>
                                    <td>
                                        <Typography inline span>
                                            {formValues.subject}
                                        </Typography>
                                    </td>
                                </tr>
                                {values.sentAt && (
                                    <tr>
                                        <th>
                                            <Typography inline span variant='bodyBold2'>
                                                {t('form.sent')}:
                                            </Typography>
                                        </th>
                                        <td>
                                            <Typography inline span>
                                                {formatISODateAsString(values.sentAt)}
                                            </Typography>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}

                {processed === false && (
                    <>
                        {showAutosend && timeUntilAutosend && (
                            <div className={styles['autosend-info']}>
                                <Icon name={ICON_NAMES.Autopilot} height={16} width={16} />
                                <Typography
                                    inline
                                    variant='bodyBold1'
                                    className={styles['autosend-text']}
                                >
                                    {t('form.autosend_in', { time: timeUntilAutosend })}
                                </Typography>
                            </div>
                        )}
                        <div className={styles.info}>
                            <table className={styles['field-list']}>
                                <tbody>
                                    <tr>
                                        <th>
                                            <Typography inline span variant='bodyBold2'>
                                                {t('form.from')}:
                                            </Typography>
                                        </th>
                                        <td>
                                            <Typography inline span>
                                                {isAnyIntegrationAvailable === false &&
                                                    labelNoEmailIntegrationsAvailable}
                                                {isAnyIntegrationAvailable &&
                                                    isIntegrationAvailable === false &&
                                                    labelNoEmailIntegrationSelected}
                                                {isAnyIntegrationAvailable &&
                                                    isIntegrationAvailable &&
                                                    t('form.email_with_integration', {
                                                        integration: t(
                                                            // eslint-disable-next-line max-len
                                                            `profile:email_integration.${integrationKey}`,
                                                        ),
                                                        email,
                                                    })}
                                            </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <Typography inline span variant='bodyBold2'>
                                                {t('form.to')}:
                                            </Typography>
                                        </th>
                                        <td>
                                            <Typography inline span>
                                                {formValues.recipient}
                                            </Typography>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={styles['input-container']}>
                            <FormFieldInput
                                name='subject'
                                placeholder={t('form.placeholder_subject')}
                                variant='small'
                                disabled={loading}
                                required
                                value={formValues.subject}
                                onChange={handleChangeInput}
                            />
                        </div>
                    </>
                )}

                <div className={styles.body}>
                    {processed === true && (
                        <div className={styles.info}>
                            <ul className={styles['field-list']}>
                                <li>
                                    <Typography inline pre>
                                        {formValues.text}
                                    </Typography>
                                </li>
                            </ul>
                        </div>
                    )}

                    {processed === false && (
                        <FormFieldTextArea
                            name='text'
                            rows={formValues.text ? 12 : 6}
                            placeholder={t('form.placeholder_text')}
                            variant='small'
                            disabled={loading}
                            required
                            value={formValues.text}
                            onChange={handleChangeInput}
                        />
                    )}
                </div>

                {processed === false && (
                    <div className={styles['actions-container']}>
                        {isAnyIntegrationAvailable === false && (
                            <div>
                                <Typography inline>
                                    {t('form.no_email_integrations_available_text')}
                                </Typography>{' '}
                                <Link
                                    to={ROUTES.PROFILE_DETAIL.replace(
                                        ':page',
                                        PROFILE_TABS.Integrations,
                                    )}
                                >
                                    <Typography className={styles.link} inline>
                                        {t('form.go_to_integrations')}
                                    </Typography>
                                </Link>{' '}
                                <Typography inline>
                                    {t('form.no_email_integrations_available_text2')}
                                </Typography>
                            </div>
                        )}
                        {isAnyIntegrationAvailable && isIntegrationAvailable === false && (
                            <div>
                                <Typography inline>
                                    {isAnyIntegrationAvailable &&
                                        isIntegrationAvailable === false &&
                                        t('form.no_email_integration_selected_text')}
                                </Typography>{' '}
                                <Link
                                    to={ROUTES.PROFILE_DETAIL.replace(
                                        ':page',
                                        PROFILE_TABS.EmailSettings,
                                    )}
                                >
                                    <Typography className={styles.link} inline>
                                        {t('form.go_to_email_settings')}
                                    </Typography>
                                </Link>{' '}
                                <Typography inline>
                                    {t('form.no_email_integration_selected_text2')}
                                </Typography>
                            </div>
                        )}
                        <div className={styles.actions}>
                            <div className={styles['actions-send']}>
                                <Form.Submit
                                    asChild
                                    disabled={
                                        sendDisabled ||
                                        isAnyIntegrationAvailable === false ||
                                        (isAnyIntegrationAvailable &&
                                            isIntegrationAvailable === false)
                                    }
                                >
                                    <Button>{t('form.send')}</Button>
                                </Form.Submit>
                                {loading && <Loading size={24} />}
                            </div>
                            {changesSavedVisible && (
                                <Typography className={styles['actions-text']}>
                                    {changesSaved
                                        ? t('form.changes_saved')
                                        : t('form.changes_unsaved')}
                                </Typography>
                            )}
                        </div>
                    </div>
                )}
            </Form.Root>
        </div>
    );
};

export default SignalEmailForm;
