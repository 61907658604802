import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { ColumnDef } from '@tanstack/react-table';

import type { LeadSearchResultItem } from 'interfaces/prospect';
import Icon, { ICON_NAMES } from 'components/Icon';
import { useLeadSearchConfig } from 'stores/useProspectStore';

import styles from './style.module.scss';

type HeaderWithTranslationProps = {
    className?: string;
    title: string;
};

const HeaderWithTranslation: React.FC<HeaderWithTranslationProps> = ({ className, title }) => {
    const { t } = useTranslation('prospects');
    return <div className={className}>{t(title)}</div>;
};

export const columns: ColumnDef<LeadSearchResultItem>[] = [
    {
        id: 'name',
        header: () => <HeaderWithTranslation title='prospects.name' />,
        cell: ({ row }): JSX.Element => {
            const { firstName, lastName } = row.original;
            return (
                <div>
                    {firstName} {lastName}
                </div>
            );
        },
    },
    {
        accessorKey: 'title',
        header: () => <HeaderWithTranslation title='prospects.jobTitle' />,
    },
    {
        accessorKey: 'companyName',
        header: () => <HeaderWithTranslation title='prospects.company' />,
        cell: ({ getValue }): JSX.Element => {
            const company = getValue<string>();
            return <div>{company}</div>;
        },
    },
    {
        accessorKey: 'departments',
        header: () => <HeaderWithTranslation title='prospects.department' />,
        cell: ({ getValue }): JSX.Element => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const leadSearchConfig = useLeadSearchConfig();
            const departments = getValue<string[]>();
            if (!leadSearchConfig?.departments || departments.length === 0) return <></>;
            const departmentsMap = departments.map(
                (department) =>
                    leadSearchConfig.departments.find((d) => d.id === department)?.name ??
                    department,
            );
            return <div>{departmentsMap.join(', ')}</div>;
        },
    },
    {
        accessorKey: 'seniority',
        header: () => <HeaderWithTranslation title='prospects.management_level' />,
        cell: ({ getValue }): JSX.Element => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const leadSearchConfig = useLeadSearchConfig();
            const seniority = getValue<string>();
            if (!leadSearchConfig?.seniorities || !seniority) return <></>;
            const name = leadSearchConfig.seniorities.find((s) => s.id === seniority)?.name;
            return <div>{name}</div>;
        },
    },
    {
        id: 'personLocation',
        header: () => <HeaderWithTranslation title='prospects.person_location' />,
        cell: ({ row }): JSX.Element => {
            const { city, state, country } = row.original;
            const location = [city, state, country].filter(Boolean).join(', ');
            return <div>{location}</div>;
        },
    },
    {
        accessorKey: 'linkedinUrl',
        header: () => <></>,
        size: 0,
        cell: ({ getValue }): JSX.Element | null => {
            const url = getValue<string>();
            if (!url) return null;
            return (
                <Link to={url} target='_blank' className={styles.link}>
                    <Icon name={ICON_NAMES.LinkedIn} height={16} width={16} />
                </Link>
            );
        },
    },
];
