import { type IntegrationAppClient } from '@integration-app/sdk';
import { create } from 'zustand';

import type { Industry, SignalCategory, UserInfoExFormData } from 'interfaces/userEx.type';
import { type ForgotPasswordFormData } from 'forms/FormForgotPassword';
import { type LoginFormData } from 'forms/FormLogin';
import { type SetPasswordFormData } from 'forms/FormSetPassword';
import { type UserInfoFormData, type ChangePasswordFormData } from 'forms/FormPersonalInformation';

import type { CRMProspectFullInfo, CRMProspect, CRMItem } from 'interfaces/crm.type';
import { type Match, type SignalVisibilityStatus } from 'interfaces/match.type';
import { type User } from 'interfaces/user.type';
import { type UserEx } from 'interfaces/userEx.type';
import {
    removeCRMItems,
    removeCRMProspects,
    retrieveCRMItems,
    retrieveCRMProspects,
    storeCRMItems,
    storeCRMProspects,
} from 'services/storage';

import { type AuthSlice, createAuthSlice } from './authSlice';
import { type CRMSlice, createCRMSlice, type IntegrationProfile } from './crmSlice';
import {
    type MatchSlice,
    type SignalEmailPayload,
    type SignalEmailMessagePayload,
    type TestEmailPayload,
    createMatchSlice,
} from './matchSlice';
import { type UserSlice, createUserSlice } from './userSlice';

type Store = AuthSlice & MatchSlice & UserSlice & CRMSlice;

export const useBoundStore = create<Store>()((...a) => ({
    ...createAuthSlice(...a),
    ...createMatchSlice(...a),
    ...createUserSlice(...a),
    ...createCRMSlice(...a),
}));

export const useAuthSignIn = (): ((_data: LoginFormData) => Promise<boolean>) =>
    useBoundStore((state) => state.signIn);

export const useAuthSignOut = (): (() => Promise<void>) => useBoundStore((state) => state.signOut);

export const useAuthForgotPassword = (): ((_data: ForgotPasswordFormData) => Promise<boolean>) =>
    useBoundStore((state) => state.forgotPassword);

export const useAuthResetPassword = (): ((_data: SetPasswordFormData) => Promise<boolean>) =>
    useBoundStore((state) => state.resetPassword);

export const useAuthChangePassword = (): ((_data: ChangePasswordFormData) => Promise<boolean>) =>
    useBoundStore((state) => state.changePassword);

export const useAuthLoading = (): boolean => useBoundStore((state) => state.loading);

export const useAuthError = (): string | undefined => useBoundStore((state) => state.error);

export const useAuthMessage = (): string | undefined => useBoundStore((state) => state.message);

export const useAuthClearErrorAndMessage = (): (() => void) =>
    useBoundStore((state) => state.clearErrorAndMessage);

export const useProxyLogin = (): boolean => useBoundStore((state) => state.proxyLogin);

export const useInvalidateProxyLogin = (): (() => void) =>
    useBoundStore((state) => state.invalidateProxyLogin);

export const useFetchUser = (): ((_userId: string) => Promise<User | undefined>) =>
    useBoundStore((state) => state.fetchUser);

export const useFetchCurrentUser = (): (() => Promise<User | undefined>) =>
    useBoundStore((state) => state.fetchCurrentUser);

export const useLoadingUser = (): boolean => useBoundStore((state) => state.loadingUser);

export const useUser = (): User | undefined => useBoundStore((state) => state.user);

export const useUpdateUser = (): ((_data: UserInfoFormData) => Promise<void>) =>
    useBoundStore((state) => state.updateUser);

export const useSendTestGmailMessage = (): ((_payload: TestEmailPayload) => Promise<boolean>) =>
    useBoundStore((state) => state.sendTestGmailMessage);

export const useSendSignalMessage = (): ((
    _prospectId: string,
    _payload: SignalEmailPayload,
) => Promise<boolean>) => useBoundStore((state) => state.sendSignalMessage);

export const useFilterOutDoneProspects = (): (() => void) =>
    useBoundStore((state) => state.filterOutDoneProspects);

export const useUpdateProspectSignalEmailMessage = (): ((
    _prospectId: string,
    _id: string,
    _payload: SignalEmailMessagePayload,
) => Promise<void>) => useBoundStore((state) => state.updateProspectSignalEmailMessage);

export const useMatches = (): Match[] => useBoundStore((state) => state.matches);
export const useMatchForUndo = (): Match | undefined =>
    useBoundStore((state) => state.matchForUndo);
export const useClearMatchForUndo = (): (() => void) =>
    useBoundStore((state) => state.clearMatchForUndo);

export const useLoadingMatches = (): boolean => useBoundStore((state) => state.loadingMatches);
export const useLoadingSendEmail = (): boolean => useBoundStore((state) => state.loadingSendEmail);

export const useFetchMatches = (): (() => Promise<void>) =>
    useBoundStore((state) => state.fetchMatches);

export const useMarkProspectMessageChangesUnsaved = (): ((_prospectId: string) => void) =>
    useBoundStore((state) => state.markProspectMessageChangesUnsaved);

export const useMarkProspectSignalAsRead = (): ((
    _prospectId: string,
    _id: string,
) => Promise<void>) => useBoundStore((state) => state.markProspectSignalAsRead);

export const useDiscardProspectSignal = (): ((
    _prospectId: string,
    _id: string,
    _visibilityStatus: SignalVisibilityStatus,
    _reason?: string,
    _customReason?: string,
) => Promise<boolean>) => useBoundStore((state) => state.discardProspectSignal);

export const useUndoDiscardProspectSignal = (): ((
    _prospect: Match,
    _id: string,
) => Promise<boolean>) => useBoundStore((state) => state.undoDiscardProspectSignal);

export const useIAToken = (): string | undefined => useBoundStore((state) => state.iaToken);
export const useLoadingCRM = (): boolean => useBoundStore((state) => state.loadingCRM);

export const useIntegrationProfiles = (): IntegrationProfile[] =>
    useBoundStore((state) => state.integrationProfiles);

export const useCRMConnectionState = (): 'connected' | 'disconnected' | 'unknown' | undefined =>
    useBoundStore((state) => state.crmConnectionState);

export const useFetchIAToken = (): (() => Promise<string | undefined>) =>
    useBoundStore((state) => state.fetchIAToken);

export const useGetCRMProspectInfoById = (): ((_id: string) => CRMProspectFullInfo | undefined) =>
    useBoundStore((state) => state.getCRMProspectInfoById);

export const useFetchCRMLastContactInfo = (): ((
    _integrationApp: IntegrationAppClient,
    _prospectId: string,
    _email: string,
) => Promise<void>) => useBoundStore((state) => state.fetchCRMLastContactInfo);

// initialize crmProspects from storage
const crmProspects = retrieveCRMProspects();
if (crmProspects) useBoundStore.setState({ crmProspects });
const crmMeetings = retrieveCRMItems('meetings');
if (crmMeetings) useBoundStore.setState({ crmMeetings });
const crmCalls = retrieveCRMItems('calls');
if (crmCalls) useBoundStore.setState({ crmCalls });
const crmEmails = retrieveCRMItems('emails');
if (crmEmails) useBoundStore.setState({ crmEmails });

// subscribe to crmProspects changes and store them in storage
useBoundStore.subscribe((state, prevState) => {
    if (state.crmProspects !== prevState.crmProspects) {
        if (state.crmProspects) {
            storeCRMProspects(state.crmProspects);
        } else {
            removeCRMProspects();
        }
    }
    if (state.crmMeetings !== prevState.crmMeetings) {
        if (state.crmMeetings) {
            storeCRMItems('meetings', state.crmMeetings);
        } else {
            removeCRMItems('meetings');
        }
    }
    if (state.crmCalls !== prevState.crmCalls) {
        if (state.crmCalls) {
            storeCRMItems('calls', state.crmCalls);
        } else {
            removeCRMItems('calls');
        }
    }
    if (state.crmEmails !== prevState.crmEmails) {
        if (state.crmEmails) {
            storeCRMItems('emails', state.crmEmails);
        } else {
            removeCRMItems('emails');
        }
    }
});

export const useCRMProspects = (): Record<string, CRMProspect | undefined> | undefined =>
    useBoundStore((state) => state.crmProspects);
export const useCRMMeetings = (): Record<string, CRMItem | undefined> | undefined =>
    useBoundStore((state) => state.crmMeetings);
export const useCRMCalls = (): Record<string, CRMItem | undefined> | undefined =>
    useBoundStore((state) => state.crmCalls);
export const useCRMEmails = (): Record<string, CRMItem | undefined> | undefined =>
    useBoundStore((state) => state.crmEmails);

export const useClearCRMInfo = (): (() => void) => useBoundStore((state) => state.clearCRMInfo);
export const useFetchIntegrationProfiles = (): ((
    _integrationApp: IntegrationAppClient,
) => Promise<void>) => useBoundStore((state) => state.fetchIntegrationProfiles);

export const useFetchCurrentUserEx = (): (() => Promise<UserEx | undefined>) =>
    useBoundStore((state) => state.fetchCurrentUserEx);

export const useLoadingUserEx = (): boolean => useBoundStore((state) => state.loadingUserEx);

export const useUserEx = (): UserEx | undefined => useBoundStore((state) => state.userEx);

export const useUpdateUserEx = (): ((_data: UserInfoExFormData) => Promise<boolean>) =>
    useBoundStore((state) => state.updateUserEx);

export const useUpdateAutosendTimer = (): (() => void) =>
    useBoundStore((state) => state.updateAutosendTimer);

export const useSignalCategories = (): SignalCategory[] =>
    useBoundStore((state) => state.signalCategories);
export const useIndustries = (): Industry[] => useBoundStore((state) => state.industries);

export const useFetchSignalCategories = (): (() => void) =>
    useBoundStore((state) => state.fetchSignalCategories);

export const useFetchIndustries = (): (() => void) =>
    useBoundStore((state) => state.fetchIndustries);

export const useArchiveProspect = (): ((_userProspectId: string) => Promise<boolean>) =>
    useBoundStore((state) => state.archiveProspect);
