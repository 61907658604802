import type { ObjectValues } from 'interfaces/types';

export const OUTBOUND_EMAIL_TYPE = {
    Honeysales: 'HONEYSALES',
    Crm: 'CRM',
    Gmail: 'GMAIL',
    NotSet: '',
} as const;

export type OutboundEmailType = ObjectValues<typeof OUTBOUND_EMAIL_TYPE>;

export type Industry = {
    _id: string;
    name: {
        [key: string]: string;
    };
};

export type UserIndustryPitch = {
    pitchName?: string;
    pitch: string;
    industries: string[]; // industry ids
    referenceCase: string;
};

export type SignalCategory = {
    _id: string;
    name: {
        [key: string]: string;
    };
};

export type UserSignalCategory = {
    isActive: boolean;
    categoryId: string;
    clarification?: string;
    pitch?: string;
    weight: number;
};

export const SIGNAL_CATEGORIES: UserSignalCategory[] = [
    {
        isActive: false,
        categoryId: '666188866ae4d8a10e38167e',
        clarification: '',
        weight: 100,
    },
    {
        isActive: true,
        categoryId: '666188866ae4d8a10e381682',
        clarification: '',
        weight: 20,
    },
    {
        isActive: true,
        categoryId: '666188866ae4d8a10e38167f',
        clarification: '',
        weight: 30,
    },
    {
        isActive: true,
        categoryId: '666188866ae4d8a10e381680',
        clarification: '',
        weight: 30,
    },
];

export const INDUSTRY_PITCHES: UserIndustryPitch[] = [
    {
        pitchName: 'Industry Pitch Name 1',
        industries: ['marketing_services', 'advertising_services', 'design_services'],
        // eslint-disable-next-line max-len
        pitch: 'Mit unserer Sales AI revolutionieren wir die Vertriebswelt und unterstützen unsere Kunden bei der Neukundenakquise sowie beim Reselling.',
        referenceCase: 'Some reference case',
    },
    {
        pitchName: 'Industry Pitch Name 2',
        industries: ['marketing_services', 'advertising_services', 'design_services'],
        // eslint-disable-next-line max-len
        pitch: 'Mit unserer Sales AI revolutionieren wir die Vertriebswelt und unterstützen unsere Kunden bei der Neukundenakquise sowie beim Reselling.',
        referenceCase: 'Some reference case',
    },
    {
        pitchName: 'Industry Pitch Name 3',
        industries: ['marketing_services', 'advertising_services', 'design_services'],
        // eslint-disable-next-line max-len
        pitch: 'Mit unserer Sales AI revolutionieren wir die Vertriebswelt und unterstützen unsere Kunden bei der Neukundenakquise sowie beim Reselling.',
        referenceCase: 'Some reference case',
    },
];

export type UserEx = {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    settings?: {
        notifications?: {
            emailSignals?: boolean;
        };
        emails?: {
            sendWarmMessagesVia?: OutboundEmailType;
            sendColdMessagesVia?: OutboundEmailType;
            bccAddress?: string;
        };
        autopilot?: {
            enabled: boolean;
            contactStatus?: string[];
            maxSendFrequency?: string;
            delayBeforeAutosend?: string;
        };
        pitches?: {
            offeringDescription?: string;
            default: string;
            defaultReferenceCase: string;
            byIndustry?: UserIndustryPitch[];
        };
        signalCategories?: UserSignalCategory[];
    };
    esp?: {
        cold?: {
            email?: string;
        };
        warm?: {
            email?: string;
        };
    };
};

export type UserInfoExFormData = {
    firstName?: string;
    lastName?: string;
    settings?: {
        notifications?: {
            emailSignals?: boolean;
        };
        emails?: {
            sendWarmMessagesVia?: OutboundEmailType;
            sendColdMessagesVia?: OutboundEmailType;
            bccAddress?: string;
        };
        autopilot?: {
            enabled: boolean;
            contactStatus?: string[];
            maxSendFrequency?: string;
            delayBeforeAutosend?: string;
        };
        pitches?: {
            offeringDescription?: string;
            default: string;
            defaultReferenceCase: string;
            byIndustry?: UserIndustryPitch[];
        };
        signalCategories?: UserSignalCategory[];
    };
};
