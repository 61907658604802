import type { LeadSearchResultItem } from 'interfaces/prospect';
import { DataTable, type DataTableProps } from 'components/DataTable';

type LeadSearchResultTableProps = DataTableProps<LeadSearchResultItem>;

export const LeadSearchResultTable = ({
    columns,
    data,
}: LeadSearchResultTableProps): JSX.Element => {
    return <DataTable columns={columns} data={data} />;
};

export default LeadSearchResultTable;
