import React, { type CSSProperties, type ReactNode } from 'react';
import * as AvatarRadix from '@radix-ui/react-avatar';
import classNames from 'classnames';

import Tooltip from 'components/Tooltip';

import styles from './style.module.scss';

type Props = {
    size?: number;
    src?: string;
    alt?: string;
    gradient?: boolean;
    variant?: 'default' | 'large' | 'small';
    fallback?: ReactNode;
    borderColor?: string;
    badge?: ReactNode;
    badgeVariant?: 'default' | 'gradient';
    badgeTooltip?: ReactNode;
};

const Avatar: React.FC<Props> = ({
    size = 52,
    src,
    alt = 'avatar',
    gradient = false,
    variant = 'default',
    fallback,
    borderColor,
    badge,
    badgeVariant,
    badgeTooltip,
}) => {
    const style: CSSProperties = { width: size, height: size };
    if (borderColor) {
        style.backgroundColor = borderColor;
    }
    const containerClassNames = classNames(styles.container, {
        [styles.gradient]: gradient,
        [styles['border-color']]: borderColor,
        [styles['border-color-small']]: variant === 'small',
    });
    const fallbackClassNames = classNames(styles.fallback, {
        [styles['fallback-large']]: variant === 'large',
        [styles['fallback-small']]: variant === 'small',
    });
    const badgeContainerClassNames = classNames(styles['badge-container'], {
        [styles['badge-container-large']]: variant === 'large',
        [styles['badge-container-small']]: variant === 'small',
    });
    const badgeClassNames = classNames(styles.badge, {
        [styles['badge-gradient']]: badgeVariant === 'gradient',
        [styles['badge-large']]: variant === 'large',
        [styles['badge-small']]: variant === 'small',
    });
    return (
        <AvatarRadix.Root className={containerClassNames} style={style}>
            {badge && (
                <Tooltip asChild content={badgeTooltip} showTooltip={!!badgeTooltip}>
                    <div className={badgeContainerClassNames}>
                        <div className={badgeClassNames}>{badge}</div>
                    </div>
                </Tooltip>
            )}
            <AvatarRadix.Image className={styles.image} src={src} alt={alt} />
            <AvatarRadix.Fallback className={fallbackClassNames} delayMs={500}>
                {fallback}
            </AvatarRadix.Fallback>
        </AvatarRadix.Root>
    );
};

export default Avatar;
