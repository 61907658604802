import classNames from 'classnames';

import Icon, { ICON_NAMES } from 'components/Icon';

import styles from './style.module.scss';

type MessageProps = {
    success?: boolean;
    error?: boolean;
    info?: boolean;
    noIcon?: boolean;
    className?: string;
    children?: React.ReactNode;
};

export const Message: React.FC<MessageProps> = ({
    success,
    error,
    info,
    noIcon = false,
    className,
    children,
}) => {
    const classNamesMessage = classNames(
        styles.message,
        {
            [styles['message-success']]: success,
            [styles['message-error']]: error,
            [styles['message-info']]: info,
        },
        className,
    );

    return (
        <div className={classNamesMessage}>
            {noIcon ? null : success ? (
                <Icon name={ICON_NAMES.CheckCircle} />
            ) : error ? (
                <Icon name={ICON_NAMES.XCircle} />
            ) : (
                info && <Icon name={ICON_NAMES.AlertCircle} />
            )}
            {children}
        </div>
    );
};

export default Message;
