import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ROUTES } from 'constants/routes/routes';
import Button from 'components/Button';
import FormPersonalInformation from 'forms/FormPersonalInformation';
import Icon, { ICON_NAMES } from 'components/Icon';
import Typography from 'components/Typography';
import { signOut } from 'services/auth';
import { trackEventProfileInfoChange } from 'services/eventsTracker';
import {
    // useAuthChangePassword, // changing password is disabled for now
    useLoadingUserEx,
    useUpdateUserEx,
    useUserEx,
} from 'stores/useBoundStore';

import styles from './style.module.scss';

type Props = {
    disabled?: boolean;
    onUpdate?: (_values: Record<string, FormDataEntryValue>) => void;
};

const PersonalInformation: React.FC<Props> = ({ disabled = false, onUpdate }) => {
    const { t } = useTranslation('profile');

    const navigate = useNavigate();
    const user = useUserEx();
    const loadingUser = useLoadingUserEx();
    const updateUser = useUpdateUserEx();
    // const changePassword = useAuthChangePassword();

    const formDefaultValues = useMemo(
        () => ({
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            password: '',
        }),
        [user],
    );

    const handleSubmit = async (values: Record<string, FormDataEntryValue>): Promise<void> => {
        const { firstName, lastName } = values;
        if (typeof firstName === 'string' && typeof lastName === 'string') {
            const success = await updateUser({ firstName, lastName });
            if (success) {
                toast.success(t('personal_information.update_success'));
            }
        }
        // changing password is disabled for now
        // if (password && typeof password === 'string') {
        //     changePassword({
        //         password,
        //         passwordConfirmation: password,
        //         currentPassword: password,
        //     });
        // }
        trackEventProfileInfoChange();
        if (onUpdate) onUpdate(values);
    };

    const handleClickLogout = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        signOut();
        navigate(ROUTES.LOGOUT);
    };

    return (
        <div className={styles.container}>
            <FormPersonalInformation
                initialValues={formDefaultValues}
                disabled={disabled || loadingUser}
                onSubmit={handleSubmit}
            />
            <div className={styles.extra}>
                <Typography variant='bodyBold1'>
                    {t('personal_information.label_logout')}
                </Typography>
                <Button
                    variant='secondary'
                    disabled={disabled || loadingUser}
                    onClick={handleClickLogout}
                >
                    <Icon name={ICON_NAMES.Exit} />
                    {t('personal_information.logout')}
                </Button>
            </div>
        </div>
    );
};

export default PersonalInformation;
